@charset "UTF-8";
/* 
html {
    font-family: sans-serif;
    text-size-adjust: 100%;
}

body {
    margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
progress,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
progress {
    vertical-align: baseline;
}
[hidden],
template {
    display: none;
}
a {
    background-color: transparent;
}
a:active,
a:hover {
    outline-width: 0;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}
b,
strong {
    font-weight: inherit;
}
b, 
strong {
    font-weight: bolder;
}
dfn {
    font-style: italic;
}
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
mark {
    background-color: #ff0;
    color: #000;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
img {
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}
code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
figure {
    margin: 1em 40px;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
button,
input,
select,
textarea {
    font: inherit;
}
optgroup {
    font-weight: bold;
}
button,
input,
select {
    overflow: visible;
}
button,
input,
select,
textarea {
    margin: 0;
}
button,
select {
    text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
    cursor: pointer;
}
[disabled] {
    cursor: default;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
    appearance: button;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
button:-moz-focusring,
input:-moz-focusring {
    outline: 1px dotted ButtonText;
}
fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
textarea {
    overflow: auto;
}
[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    appearance: field;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    appearance: none;
}
*/
/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  /*@media (min-width: $screen-md-min) {
    width: $container-md;
  }*/
}
.container:before, .container:after {
  content: " ";
  display: table;
}
.container:after {
  clear: both;
}
@media (min-width: 1024px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1280px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1310px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}
.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row:before, .row:after {
  content: " ";
  display: table;
}
.row:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-ml-1, .col-lg-1, .col-xl-1, .col-xs-0, .col-sm-0, .col-md-0, .col-ml-0, .col-lg-0, .col-xl-0, .col-xs-1, .col-sm-1, .col-md-1, .col-ml-1, .col-lg-1, .col-xl-1, .col-xs-2, .col-sm-2, .col-md-2, .col-ml-2, .col-lg-2, .col-xl-2, .col-xs-3, .col-sm-3, .col-md-3, .col-ml-3, .col-lg-3, .col-xl-3, .col-xs-4, .col-sm-4, .col-md-4, .col-ml-4, .col-lg-4, .col-xl-4, .col-xs-5, .col-sm-5, .col-md-5, .col-ml-5, .col-lg-5, .col-xl-5, .col-xs-6, .col-sm-6, .col-md-6, .col-ml-6, .col-lg-6, .col-xl-6, .col-xs-7, .col-sm-7, .col-md-7, .col-ml-7, .col-lg-7, .col-xl-7, .col-xs-8, .col-sm-8, .col-md-8, .col-ml-8, .col-lg-8, .col-xl-8, .col-xs-9, .col-sm-9, .col-md-9, .col-ml-9, .col-lg-9, .col-xl-9, .col-xs-10, .col-sm-10, .col-md-10, .col-ml-10, .col-lg-10, .col-xl-10, .col-xs-11, .col-sm-11, .col-md-11, .col-ml-11, .col-lg-11, .col-xl-11, .col-xs-12, .col-sm-12, .col-md-12, .col-ml-12, .col-lg-12, .col-xl-12, .col-xs-20p, .col-sm-20p, .col-md-20p, .col-ml-20p, .col-lg-20p, .col-xl-20p, .col-xs-40p, .col-sm-40p, .col-md-40p, .col-ml-40p, .col-lg-40p, .col-xl-40p, .col-xs-60p, .col-sm-60p, .col-md-60p, .col-ml-60p, .col-lg-60p, .col-xl-60p, .col-xs-80p, .col-sm-80p, .col-md-80p, .col-ml-80p, .col-lg-80p, .col-xl-80p {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1, .col-xs-0, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-20p, .col-xs-40p, .col-xs-60p, .col-xs-80p {
  float: left;
}

.col-xs-1 {
  width: 8.3333333333%;
}

.col-xs-2 {
  width: 16.6666666667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333333333%;
}

.col-xs-5 {
  width: 41.6666666667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333333333%;
}

.col-xs-8 {
  width: 66.6666666667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333333333%;
}

.col-xs-11 {
  width: 91.6666666667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-20p {
  width: 20%;
}

.col-xs-40p {
  width: 40%;
}

.col-xs-60p {
  width: 60%;
}

.col-xs-80p {
  width: 80%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  left: -8.3333333333%;
}

.col-xs-pull-2 {
  left: -16.6666666667%;
}

.col-xs-pull-3 {
  left: -25%;
}

.col-xs-pull-4 {
  left: -33.3333333333%;
}

.col-xs-pull-5 {
  left: -41.6666666667%;
}

.col-xs-pull-6 {
  left: -50%;
}

.col-xs-pull-7 {
  left: -58.3333333333%;
}

.col-xs-pull-8 {
  left: -66.6666666667%;
}

.col-xs-pull-9 {
  left: -75%;
}

.col-xs-pull-10 {
  left: -83.3333333333%;
}

.col-xs-pull-11 {
  left: -91.6666666667%;
}

.col-xs-pull-12 {
  left: -100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.3333333333%;
}

.col-xs-push-2 {
  left: 16.6666666667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333333333%;
}

.col-xs-push-5 {
  left: 41.6666666667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333333333%;
}

.col-xs-push-8 {
  left: 66.6666666667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333333333%;
}

.col-xs-push-11 {
  left: 91.6666666667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 576px) {
  .col-sm-1, .col-sm-0, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-20p, .col-sm-40p, .col-sm-60p, .col-sm-80p {
    float: left;
  }

  .col-sm-1 {
    width: 8.3333333333%;
  }

  .col-sm-2 {
    width: 16.6666666667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.3333333333%;
  }

  .col-sm-5 {
    width: 41.6666666667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.3333333333%;
  }

  .col-sm-8 {
    width: 66.6666666667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.3333333333%;
  }

  .col-sm-11 {
    width: 91.6666666667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-20p {
    width: 20%;
  }

  .col-sm-40p {
    width: 40%;
  }

  .col-sm-60p {
    width: 60%;
  }

  .col-sm-80p {
    width: 80%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    left: -8.3333333333%;
  }

  .col-sm-pull-2 {
    left: -16.6666666667%;
  }

  .col-sm-pull-3 {
    left: -25%;
  }

  .col-sm-pull-4 {
    left: -33.3333333333%;
  }

  .col-sm-pull-5 {
    left: -41.6666666667%;
  }

  .col-sm-pull-6 {
    left: -50%;
  }

  .col-sm-pull-7 {
    left: -58.3333333333%;
  }

  .col-sm-pull-8 {
    left: -66.6666666667%;
  }

  .col-sm-pull-9 {
    left: -75%;
  }

  .col-sm-pull-10 {
    left: -83.3333333333%;
  }

  .col-sm-pull-11 {
    left: -91.6666666667%;
  }

  .col-sm-pull-12 {
    left: -100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.3333333333%;
  }

  .col-sm-push-2 {
    left: 16.6666666667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.3333333333%;
  }

  .col-sm-push-5 {
    left: 41.6666666667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.3333333333%;
  }

  .col-sm-push-8 {
    left: 66.6666666667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.3333333333%;
  }

  .col-sm-push-11 {
    left: 91.6666666667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-1, .col-md-0, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-20p, .col-md-40p, .col-md-60p, .col-md-80p {
    float: left;
  }

  .col-md-1 {
    width: 8.3333333333%;
  }

  .col-md-2 {
    width: 16.6666666667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.3333333333%;
  }

  .col-md-5 {
    width: 41.6666666667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.3333333333%;
  }

  .col-md-8 {
    width: 66.6666666667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.3333333333%;
  }

  .col-md-11 {
    width: 91.6666666667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-20p {
    width: 20%;
  }

  .col-md-40p {
    width: 40%;
  }

  .col-md-60p {
    width: 60%;
  }

  .col-md-80p {
    width: 80%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    left: -8.3333333333%;
  }

  .col-md-pull-2 {
    left: -16.6666666667%;
  }

  .col-md-pull-3 {
    left: -25%;
  }

  .col-md-pull-4 {
    left: -33.3333333333%;
  }

  .col-md-pull-5 {
    left: -41.6666666667%;
  }

  .col-md-pull-6 {
    left: -50%;
  }

  .col-md-pull-7 {
    left: -58.3333333333%;
  }

  .col-md-pull-8 {
    left: -66.6666666667%;
  }

  .col-md-pull-9 {
    left: -75%;
  }

  .col-md-pull-10 {
    left: -83.3333333333%;
  }

  .col-md-pull-11 {
    left: -91.6666666667%;
  }

  .col-md-pull-12 {
    left: -100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.3333333333%;
  }

  .col-md-push-2 {
    left: 16.6666666667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.3333333333%;
  }

  .col-md-push-5 {
    left: 41.6666666667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.3333333333%;
  }

  .col-md-push-8 {
    left: 66.6666666667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.3333333333%;
  }

  .col-md-push-11 {
    left: 91.6666666667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }

  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }

  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }

  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }

  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1024px) {
  .col-ml-1, .col-ml-0, .col-ml-1, .col-ml-2, .col-ml-3, .col-ml-4, .col-ml-5, .col-ml-6, .col-ml-7, .col-ml-8, .col-ml-9, .col-ml-10, .col-ml-11, .col-ml-12, .col-ml-20p, .col-ml-40p, .col-ml-60p, .col-ml-80p {
    float: left;
  }

  .col-ml-1 {
    width: 8.3333333333%;
  }

  .col-ml-2 {
    width: 16.6666666667%;
  }

  .col-ml-3 {
    width: 25%;
  }

  .col-ml-4 {
    width: 33.3333333333%;
  }

  .col-ml-5 {
    width: 41.6666666667%;
  }

  .col-ml-6 {
    width: 50%;
  }

  .col-ml-7 {
    width: 58.3333333333%;
  }

  .col-ml-8 {
    width: 66.6666666667%;
  }

  .col-ml-9 {
    width: 75%;
  }

  .col-ml-10 {
    width: 83.3333333333%;
  }

  .col-ml-11 {
    width: 91.6666666667%;
  }

  .col-ml-12 {
    width: 100%;
  }

  .col-ml-20p {
    width: 20%;
  }

  .col-ml-40p {
    width: 40%;
  }

  .col-ml-60p {
    width: 60%;
  }

  .col-ml-80p {
    width: 80%;
  }

  .col-ml-pull-0 {
    right: auto;
  }

  .col-ml-pull-1 {
    left: -8.3333333333%;
  }

  .col-ml-pull-2 {
    left: -16.6666666667%;
  }

  .col-ml-pull-3 {
    left: -25%;
  }

  .col-ml-pull-4 {
    left: -33.3333333333%;
  }

  .col-ml-pull-5 {
    left: -41.6666666667%;
  }

  .col-ml-pull-6 {
    left: -50%;
  }

  .col-ml-pull-7 {
    left: -58.3333333333%;
  }

  .col-ml-pull-8 {
    left: -66.6666666667%;
  }

  .col-ml-pull-9 {
    left: -75%;
  }

  .col-ml-pull-10 {
    left: -83.3333333333%;
  }

  .col-ml-pull-11 {
    left: -91.6666666667%;
  }

  .col-ml-pull-12 {
    left: -100%;
  }

  .col-ml-push-0 {
    left: auto;
  }

  .col-ml-push-1 {
    left: 8.3333333333%;
  }

  .col-ml-push-2 {
    left: 16.6666666667%;
  }

  .col-ml-push-3 {
    left: 25%;
  }

  .col-ml-push-4 {
    left: 33.3333333333%;
  }

  .col-ml-push-5 {
    left: 41.6666666667%;
  }

  .col-ml-push-6 {
    left: 50%;
  }

  .col-ml-push-7 {
    left: 58.3333333333%;
  }

  .col-ml-push-8 {
    left: 66.6666666667%;
  }

  .col-ml-push-9 {
    left: 75%;
  }

  .col-ml-push-10 {
    left: 83.3333333333%;
  }

  .col-ml-push-11 {
    left: 91.6666666667%;
  }

  .col-ml-push-12 {
    left: 100%;
  }

  .col-ml-offset-0 {
    margin-left: 0;
  }

  .col-ml-offset-1 {
    margin-left: 8.3333333333%;
  }

  .col-ml-offset-2 {
    margin-left: 16.6666666667%;
  }

  .col-ml-offset-3 {
    margin-left: 25%;
  }

  .col-ml-offset-4 {
    margin-left: 33.3333333333%;
  }

  .col-ml-offset-5 {
    margin-left: 41.6666666667%;
  }

  .col-ml-offset-6 {
    margin-left: 50%;
  }

  .col-ml-offset-7 {
    margin-left: 58.3333333333%;
  }

  .col-ml-offset-8 {
    margin-left: 66.6666666667%;
  }

  .col-ml-offset-9 {
    margin-left: 75%;
  }

  .col-ml-offset-10 {
    margin-left: 83.3333333333%;
  }

  .col-ml-offset-11 {
    margin-left: 91.6666666667%;
  }

  .col-ml-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1280px) {
  .col-lg-1, .col-lg-0, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-20p, .col-lg-40p, .col-lg-60p, .col-lg-80p {
    float: left;
  }

  .col-lg-1 {
    width: 8.3333333333%;
  }

  .col-lg-2 {
    width: 16.6666666667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.3333333333%;
  }

  .col-lg-5 {
    width: 41.6666666667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.3333333333%;
  }

  .col-lg-8 {
    width: 66.6666666667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.3333333333%;
  }

  .col-lg-11 {
    width: 91.6666666667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-20p {
    width: 20%;
  }

  .col-lg-40p {
    width: 40%;
  }

  .col-lg-60p {
    width: 60%;
  }

  .col-lg-80p {
    width: 80%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    left: -8.3333333333%;
  }

  .col-lg-pull-2 {
    left: -16.6666666667%;
  }

  .col-lg-pull-3 {
    left: -25%;
  }

  .col-lg-pull-4 {
    left: -33.3333333333%;
  }

  .col-lg-pull-5 {
    left: -41.6666666667%;
  }

  .col-lg-pull-6 {
    left: -50%;
  }

  .col-lg-pull-7 {
    left: -58.3333333333%;
  }

  .col-lg-pull-8 {
    left: -66.6666666667%;
  }

  .col-lg-pull-9 {
    left: -75%;
  }

  .col-lg-pull-10 {
    left: -83.3333333333%;
  }

  .col-lg-pull-11 {
    left: -91.6666666667%;
  }

  .col-lg-pull-12 {
    left: -100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.3333333333%;
  }

  .col-lg-push-2 {
    left: 16.6666666667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.3333333333%;
  }

  .col-lg-push-5 {
    left: 41.6666666667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.3333333333%;
  }

  .col-lg-push-8 {
    left: 66.6666666667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.3333333333%;
  }

  .col-lg-push-11 {
    left: 91.6666666667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1440px) {
  .col-xl-1, .col-xl-0, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-20p, .col-xl-40p, .col-xl-60p, .col-xl-80p {
    float: left;
  }

  .col-xl-1 {
    width: 8.3333333333%;
  }

  .col-xl-2 {
    width: 16.6666666667%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-4 {
    width: 33.3333333333%;
  }

  .col-xl-5 {
    width: 41.6666666667%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-7 {
    width: 58.3333333333%;
  }

  .col-xl-8 {
    width: 66.6666666667%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-10 {
    width: 83.3333333333%;
  }

  .col-xl-11 {
    width: 91.6666666667%;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-20p {
    width: 20%;
  }

  .col-xl-40p {
    width: 40%;
  }

  .col-xl-60p {
    width: 60%;
  }

  .col-xl-80p {
    width: 80%;
  }

  .col-xl-pull-0 {
    right: auto;
  }

  .col-xl-pull-1 {
    left: -8.3333333333%;
  }

  .col-xl-pull-2 {
    left: -16.6666666667%;
  }

  .col-xl-pull-3 {
    left: -25%;
  }

  .col-xl-pull-4 {
    left: -33.3333333333%;
  }

  .col-xl-pull-5 {
    left: -41.6666666667%;
  }

  .col-xl-pull-6 {
    left: -50%;
  }

  .col-xl-pull-7 {
    left: -58.3333333333%;
  }

  .col-xl-pull-8 {
    left: -66.6666666667%;
  }

  .col-xl-pull-9 {
    left: -75%;
  }

  .col-xl-pull-10 {
    left: -83.3333333333%;
  }

  .col-xl-pull-11 {
    left: -91.6666666667%;
  }

  .col-xl-pull-12 {
    left: -100%;
  }

  .col-xl-push-0 {
    left: auto;
  }

  .col-xl-push-1 {
    left: 8.3333333333%;
  }

  .col-xl-push-2 {
    left: 16.6666666667%;
  }

  .col-xl-push-3 {
    left: 25%;
  }

  .col-xl-push-4 {
    left: 33.3333333333%;
  }

  .col-xl-push-5 {
    left: 41.6666666667%;
  }

  .col-xl-push-6 {
    left: 50%;
  }

  .col-xl-push-7 {
    left: 58.3333333333%;
  }

  .col-xl-push-8 {
    left: 66.6666666667%;
  }

  .col-xl-push-9 {
    left: 75%;
  }

  .col-xl-push-10 {
    left: 83.3333333333%;
  }

  .col-xl-push-11 {
    left: 91.6666666667%;
  }

  .col-xl-push-12 {
    left: 100%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }

  .col-xl-offset-1 {
    margin-left: 8.3333333333%;
  }

  .col-xl-offset-2 {
    margin-left: 16.6666666667%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-4 {
    margin-left: 33.3333333333%;
  }

  .col-xl-offset-5 {
    margin-left: 41.6666666667%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-7 {
    margin-left: 58.3333333333%;
  }

  .col-xl-offset-8 {
    margin-left: 66.6666666667%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-10 {
    margin-left: 83.3333333333%;
  }

  .col-xl-offset-11 {
    margin-left: 91.6666666667%;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }
}
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}
.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-ml {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xl {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-ml-block,
.visible-ml-inline,
.visible-ml-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}

@media (max-width: 575px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 575px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 575px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 575px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .visible-ml {
    display: block !important;
  }

  table.visible-ml {
    display: table !important;
  }

  tr.visible-ml {
    display: table-row !important;
  }

  th.visible-ml,
td.visible-ml {
    display: table-cell !important;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .visible-ml-block {
    display: block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .visible-ml-inline {
    display: inline !important;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .visible-ml-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1280px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1280px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1280px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1280px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 575px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  .hidden-ml {
    display: none !important;
  }
}
@media (min-width: 1280px) and (max-width: 1439px) {
  .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 1440px) {
  .hidden-xl {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print,
td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
/* 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, picture {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, picture {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

[type="submit"]{
  border: none;
} */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**
Ion.RangeSlider, 2.3.1
© Denis Ineshin, 2010 - 2019, IonDen.com
Build date: 2019-12-19 16:51:02
*/
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  font-family: Arial, sans-serif;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}

.irs-handle.type_last {
  z-index: 2;
}

.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default;
}

.irs-min {
  left: 0;
}

.irs-max {
  right: 0;
}

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.lt-ie9 .irs-disable-mask {
  background: #000;
  filter: alpha(opacity=0);
  cursor: not-allowed;
}

.irs-disabled {
  opacity: 0.4;
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs--flat {
  height: 40px;
}

.irs--flat.irs-with-grid {
  height: 60px;
}

.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px;
}

.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: #ed5565;
}

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: #e1e4e9;
}

.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent;
}

.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #da4453;
}

.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540;
}

.irs--flat .irs-min,
.irs--flat .irs-max {
  top: 0;
  padding: 1px 3px;
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: white;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #ed5565;
  border-radius: 4px;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565;
}

.irs--flat .irs-grid-pol {
  background-color: #e1e4e9;
}

.irs--flat .irs-grid-text {
  color: #999;
}

.irs--big {
  height: 55px;
}

.irs--big.irs-with-grid {
  height: 70px;
}

.irs--big .irs-line {
  top: 33px;
  height: 12px;
  background-color: white;
  background: linear-gradient(to bottom, #ddd -50%, white 150%);
  border: 1px solid #ccc;
  border-radius: 12px;
}

.irs--big .irs-bar {
  top: 33px;
  height: 12px;
  background-color: #92bce0;
  border: 1px solid #428bca;
  background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
}

.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px;
}

.irs--big .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: rgba(66, 139, 202, 0.5);
}

.irs--big .irs-handle {
  top: 25px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #cbcfd5;
  background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
  border-radius: 30px;
}

.irs--big .irs-handle.state_hover,
.irs--big .irs-handle:hover {
  border-color: rgba(0, 0, 0, 0.45);
  background-color: #939ba7;
  background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
}

.irs--big .irs-min,
.irs--big .irs-max {
  top: 0;
  padding: 1px 5px;
  color: white;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px;
}

.irs--big .irs-from,
.irs--big .irs-to,
.irs--big .irs-single {
  color: white;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #428bca;
  background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
  border-radius: 3px;
}

.irs--big .irs-grid-pol {
  background-color: #428bca;
}

.irs--big .irs-grid-text {
  color: #428bca;
}

.irs--modern {
  height: 55px;
}

.irs--modern.irs-with-grid {
  height: 55px;
}

.irs--modern .irs-line {
  top: 25px;
  height: 5px;
  background-color: #d1d6e0;
  background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px;
}

.irs--modern .irs-bar {
  top: 25px;
  height: 5px;
  background: #20b426;
  background: linear-gradient(to bottom, #20b426 0%, #18891d 100%);
}

.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px;
}

.irs--modern .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(209, 214, 224, 0.5);
}

.irs--modern .irs-handle {
  top: 37px;
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
}

.irs--modern .irs-handle > i:nth-child(1) {
  position: absolute;
  display: block;
  top: -4px;
  left: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #a3adc1;
  background: white;
  transform: rotate(45deg);
}

.irs--modern .irs-handle > i:nth-child(2) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 10px;
  height: 12px;
  background: #e9e6e6;
  background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
  border-radius: 0 0 3px 3px;
}

.irs--modern .irs-handle > i:nth-child(3) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 3px;
  left: 3px;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1;
}

.irs--modern .irs-handle.state_hover,
.irs--modern .irs-handle:hover {
  border-color: #7685a2;
  background: #c3c7cd;
  background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
}

.irs--modern .irs-handle.state_hover > i:nth-child(1),
.irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:nth-child(3),
.irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a;
}

.irs--modern .irs-min,
.irs--modern .irs-max {
  top: 0;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: white;
  background-color: #d1d6e0;
  border-radius: 5px;
}

.irs--modern .irs-from,
.irs--modern .irs-to,
.irs--modern .irs-single {
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #20b426;
  color: white;
  border-radius: 5px;
}

.irs--modern .irs-from:before,
.irs--modern .irs-to:before,
.irs--modern .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #20b426;
}

.irs--modern .irs-grid {
  height: 25px;
}

.irs--modern .irs-grid-pol {
  background-color: #dedede;
}

.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1;
}

.irs--sharp.irs-with-grid {
  height: 57px;
}

.irs--sharp .irs-line {
  top: 30px;
  height: 2px;
  background-color: black;
  border-radius: 2px;
}

.irs--sharp .irs-bar {
  top: 30px;
  height: 2px;
  background-color: #ee22fa;
}

.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px;
}

.irs--sharp .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(0, 0, 0, 0.5);
}

.irs--sharp .irs-handle {
  top: 25px;
  width: 10px;
  height: 10px;
  background-color: #a804b2;
}

.irs--sharp .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #a804b2;
}

.irs--sharp .irs-handle.state_hover,
.irs--sharp .irs-handle:hover {
  background-color: black;
}

.irs--sharp .irs-handle.state_hover > i:first-child,
.irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: black;
}

.irs--sharp .irs-min,
.irs--sharp .irs-max {
  color: white;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 4px;
  opacity: 0.4;
  background-color: #a804b2;
  border-radius: 2px;
}

.irs--sharp .irs-from,
.irs--sharp .irs-to,
.irs--sharp .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 4px;
  background-color: #a804b2;
  color: white;
  border-radius: 2px;
}

.irs--sharp .irs-from:before,
.irs--sharp .irs-to:before,
.irs--sharp .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #a804b2;
}

.irs--sharp .irs-grid {
  height: 25px;
}

.irs--sharp .irs-grid-pol {
  background-color: #dedede;
}

.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--round {
  height: 50px;
}

.irs--round.irs-with-grid {
  height: 65px;
}

.irs--round .irs-line {
  top: 36px;
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px;
}

.irs--round .irs-bar {
  top: 36px;
  height: 4px;
  background-color: #006cfa;
}

.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--round .irs-shadow {
  height: 4px;
  bottom: 21px;
  background-color: rgba(222, 228, 236, 0.5);
}

.irs--round .irs-handle {
  top: 26px;
  width: 24px;
  height: 24px;
  border: 4px solid #006cfa;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
}

.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--round .irs-min,
.irs--round .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: #006cfa;
  color: white;
  border-radius: 4px;
}

.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #006cfa;
}

.irs--round .irs-grid {
  height: 25px;
}

.irs--round .irs-grid-pol {
  background-color: #dedede;
}

.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--square {
  height: 50px;
}

.irs--square.irs-with-grid {
  height: 60px;
}

.irs--square .irs-line {
  top: 31px;
  height: 4px;
  background-color: #dedede;
}

.irs--square .irs-bar {
  top: 31px;
  height: 4px;
  background-color: black;
}

.irs--square .irs-shadow {
  height: 2px;
  bottom: 21px;
  background-color: #dedede;
}

.irs--square .irs-handle {
  top: 25px;
  width: 16px;
  height: 16px;
  border: 3px solid black;
  background-color: white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.irs--square .irs-handle.state_hover,
.irs--square .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--square .irs-min,
.irs--square .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

.irs--square .irs-from,
.irs--square .irs-to,
.irs--square .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: black;
  color: white;
}

.irs--square .irs-grid {
  height: 25px;
}

.irs--square .irs-grid-pol {
  background-color: #dedede;
}

.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px;
}

/***********  CUSTOM  ************/
.irs-box {
  margin-bottom: 15px;
}
.irs-box input {
  display: none;
}
.irs-label {
  display: flex;
  width: 100%;
  padding: 8px 15px;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 12px;
  font-family: "Arial";
}
.irs-grid {
  bottom: 23px;
}
.irs-grid-pol.small {
  display: none;
}
.irs--flat .irs-handle {
  width: 0;
  cursor: col-resize;
}
.irs--flat .irs-handle > i:first-child {
  bottom: 3px;
  height: 24px;
  top: auto;
  width: 15px;
  background: none !important;
  margin-left: -5px;
}
.irs--flat .irs-handle > i:first-child::before {
  content: "";
  width: 5px;
  height: 100%;
  top: 0;
  left: 5px;
  position: absolute;
  background: #2C2628;
}
.irs--flat .irs-bar {
  top: 19px;
  height: 18px;
  background: #e9e5e5;
  border-radius: 0;
  width: 5px;
  cursor: pointer;
}
.irs--flat .irs-line {
  background: none;
  border-bottom: 1px solid;
  border-radius: 0;
  height: 18px;
  top: 20px;
  cursor: pointer;
}
.irs--flat .irs-grid {
  pointer-events: none;
}
.irs--flat .irs-grid-pol {
  background: #2C2628;
  z-index: 15;
  height: 18px;
  top: 2px;
}
.irs--flat .irs-grid-pol:nth-last-child(2) {
  display: none;
}
.irs--flat .irs-grid-text {
  color: #2C2628;
  font-family: "Arial";
  font-size: 14px;
  line-height: 14px;
  bottom: -28px;
}

html,
input,
textarea,
select,
body {
  line-height: 1.4;
  color: #2C2628 !important;
  background: #ffffff;
  font-family: "Montserrat";
  max-width: 100vw;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html,
input,
textarea,
select,
body {
    overflow-x: hidden;
  }
}
html.nav-open, html.sidebar-open,
input.nav-open,
input.sidebar-open,
textarea.nav-open,
textarea.sidebar-open,
select.nav-open,
select.sidebar-open,
body.nav-open,
body.sidebar-open {
  overflow: hidden;
}
html.cms-home.cms-index-index.page-layout-home_new,
input.cms-home.cms-index-index.page-layout-home_new,
textarea.cms-home.cms-index-index.page-layout-home_new,
select.cms-home.cms-index-index.page-layout-home_new,
body.cms-home.cms-index-index.page-layout-home_new {
  overflow-x: hidden;
}

html body.badaboum--custom.badaboum * {
  box-sizing: border-box;
  outline: none !important;
  font-family: "Montserrat";
}

/*********** ELEMENTS GENERAL STYLES ************/
span.data-link {
  cursor: pointer;
}

a, span.data-link {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 0;
  color: inherit;
  text-decoration: none;
  outline: none;
  text-decoration: none;
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.45;
  text-align: left;
}
@media (min-width: 768px) {
  a, span.data-link {
    font-size: 14px;
    line-height: 24px;
  }
}
a:hover, span.data-link:hover {
  color: inherit;
  text-decoration: none;
}
a:active, span.data-link:active {
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
a:visited, span.data-link:visited {
  color: #2C2628;
}

p {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.45;
}
@media (min-width: 768px) {
  p {
    font-size: 14px;
    line-height: 26px;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.img-wrapper {
  max-width: 60px;
  max-height: 60px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .img-wrapper {
    margin: 0;
    height: 60px;
  }
}
.img-wrapper--small {
  max-width: 45px;
  max-height: 36px;
}
@media (min-width: 768px) {
  .img-wrapper--small {
    max-height: unset;
  }
}
.img-wrapper > img, .img-wrapper > svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

em {
  font-style: normal;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul > li {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  outline: none;
  padding: 15px 20px;
  color: #2C2628;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
}
button:hover {
  background: #dfddcc;
}

.stroke {
  text-decoration: line-through;
}

.small {
  font-size: 0.7857142857em;
  line-height: 0.9285714286em;
}

.center {
  display: flex;
  justify-content: center;
  width: 100%;
}
.center--vertical {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-left {
  text-align: left !important;
  justify-content: flex-start;
}
.text-right {
  text-align: right;
  justify-content: flex-end;
}
.text-justify {
  text-align: justify;
}
.text-center {
  text-align: center !important;
  justify-content: center;
}
@media (min-width: 0px) and (max-width: 767px) {
  .text-center--mobile {
    text-align: center;
    justify-content: center;
  }
}
.text-center p {
  text-align: inherit;
}
.text-underline {
  text-decoration: underline;
}
.text-colored {
  color: #cd2367 !important;
}
.text-colored--green {
  color: #6DB332 !important;
}
.text-bold {
  font-weight: bold !important;
}
.text-semibold {
  font-weight: 500 !important;
}
.text-uppercase {
  text-transform: uppercase;
}

.d-flex {
  display: flex;
}
.d-block {
  display: block;
}

.align-top {
  align-self: flex-start;
  margin-bottom: 0;
}
.align-bottom {
  align-self: flex-end;
  margin-bottom: 0;
}

.page-main {
  max-width: 1540px !important;
}
@media (min-width: 0px) and (max-width: 1539px) {
  .page-main {
    padding-right: 10px;
    padding-left: 10px;
  }
  .page-main .columns .column.main {
    padding-bottom: 0;
  }
}
@media (min-width: 1540px) {
  .page-main {
    padding-left: 0;
    padding-right: 0;
  }
}

html body.badaboum--custom.badaboum .container {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1540px !important;
  width: 100%;
}
@media (min-width: 0px) and (max-width: 1539px) {
  html body.badaboum--custom.badaboum .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1540px) {
  html body.badaboum--custom.badaboum .container {
    padding-left: 0;
    padding-right: 0;
  }
}
html body.badaboum--custom.badaboum .container--nogap {
  padding: 0 !important;
}
html body.badaboum--custom.badaboum .container::before {
  display: none;
}
html body.badaboum--custom.badaboum .container html body.badaboum--custom.badaboum .container {
  width: auto !important;
  padding: 0;
}
html body.badaboum--custom.badaboum .container + html body.badaboum--custom.badaboum .container {
  margin-top: 30px;
}
html body.badaboum--custom.badaboum .container--verysmall {
  max-width: 768px !important;
}
html body.badaboum--custom.badaboum .container--small {
  max-width: 1024px !important;
}
html body.badaboum--custom.badaboum .container--medium {
  max-width: 1240px !important;
}
html body.badaboum--custom.badaboum .container--big {
  width: 100%;
  max-width: 1980px;
  margin: 0 auto !important;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .container--big {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/****************** LINKS *******************/
.link, span.data-link {
  color: #2C2628;
  font-weight: 300;
}
.links, span.data-links {
  margin: 20px auto;
}
.link:hover, .link:active, span.data-link:hover, span.data-link:active {
  color: #cd2367;
  text-decoration: none;
}
.link:visited, span.data-link:visited {
  color: #2C2628;
  text-decoration: none;
}
.link:visited:hover, span.data-link:visited:hover {
  color: #cd2367;
}
.link--hover, span.data-link--hover {
  position: relative;
}
.link--hover::before, span.data-link--hover::before {
  content: "";
  width: 0;
  background: currentColor;
  height: 1px;
  left: 0;
  bottom: -1px;
  position: absolute;
  transition: 0.5s;
}
.link--hover:hover::before, span.data-link--hover:hover::before {
  width: 100%;
}
.link > em, span.data-link > em {
  font-weight: 700;
}
.link--underline, span.data-link--underline {
  text-decoration: underline;
  cursor: pointer;
}
.link--important, span.data-link--important {
  border-bottom: 1px solid;
  font-weight: 700;
}
.link--revert, span.data-link--revert {
  color: #cd2367;
}
.link--revert:hover, .link--revert:visited, .link--revert:active, span.data-link--revert:hover, span.data-link--revert:visited, span.data-link--revert:active {
  color: #cd2367;
}
.link--normalize, span.data-link--normalize {
  font-size: 14px;
}
.link--active, span.data-link--active {
  color: #cd2367 !important;
}
.link--alt, span.data-link--alt {
  color: #e8e6e6;
  font-weight: bold;
}
.link--small, span.data-link--small {
  font-size: 0.8em;
}

/****************** JS HELPERS *******************/
[data-toggle] {
  cursor: pointer;
}

[data-atc] {
  cursor: pointer;
}

[data-lazy] {
  opacity: 0;
  height: 0;
}

/****************** PAGE LAYOUT *******************/
.full {
  width: 100% !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  .full--mobile {
    width: 100% !important;
  }
  .full--mobile.row {
    width: calc(100% + 30px) !important;
  }
}

.page-wrapper {
  overflow-x: hidden;
}
.page-title {
  margin-bottom: 30px;
  margin-top: 30px;
  position: relative;
  display: inline-block;
  line-height: 1.25;
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
@media (min-width: 0px) and (max-width: 575px) {
  .page-title {
    font-size: 20px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .page-title {
    margin-bottom: 30px;
  }
}
.page-subtitle {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
@media (min-width: 768px) {
  .page-subtitle {
    font-size: 20px;
  }
}
.page-title + .page-subtitle, .page-title + .page-description, .page-subtitle + .page-description {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .page-title + .page-subtitle, .page-title + .page-description, .page-subtitle + .page-description {
    margin-top: 20px;
  }
}
.page-description {
  color: #2C2628;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .page-description {
    font-size: 16px;
    line-height: 26px;
  }
}

.row--small {
  margin-left: -5px;
  margin-right: -5px;
}
.row--small > [class^=col] {
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .row--small--mobile {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row--small--mobile.full {
    width: calc(100% + 10px) !important;
  }
  .row--small--mobile > [class^=col] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.row-flex::before, .row-flex::after {
  display: none;
}
.row-flex--start {
  align-items: flex-start;
}
.row-flex--center {
  align-items: center;
  align-content: center;
  justify-content: center;
}
.row-flex--baseline {
  align-items: baseline;
}
.row-flex--between {
  justify-content: space-between;
}
.row-flex--justify {
  justify-content: center;
}
.row-flex > [class^=col-] {
  float: none;
  display: flex;
}
.row-flex > [class^=col-] .block {
  display: flex;
}

.col-main {
  margin-top: 0;
}

.col-flex {
  display: flex;
  flex-direction: column;
}
.col-flex--center {
  justify-content: center;
}

.flex-start {
  align-content: flex-start;
  align-items: flex-start;
}

.bg-gray {
  background-color: #f8f8f8 !important;
}

html body.badaboum--custom.badaboum .no-padding {
  padding: 0 !important;
}
html body.badaboum--custom.badaboum .no-padding--top {
  padding-top: 0 !important;
}
html body.badaboum--custom.badaboum .no-padding--left {
  padding-left: 0 !important;
}
html body.badaboum--custom.badaboum .no-padding--right {
  padding-right: 0 !important;
}
html body.badaboum--custom.badaboum .no-padding--bottom {
  padding-bottom: 0 !important;
}
html body.badaboum--custom.badaboum .no-margin {
  margin: 0 !important;
}
html body.badaboum--custom.badaboum .no-margin--top {
  margin-top: 0 !important;
}
html body.badaboum--custom.badaboum .no-margin--bottom {
  margin-bottom: 0 !important;
}
html body.badaboum--custom.badaboum .no-margin--left {
  margin-left: 0 !important;
}
html body.badaboum--custom.badaboum .no-margin--right {
  margin-right: 0 !important;
}
html body.badaboum--custom.badaboum .no-border {
  border: none !important;
}
html body.badaboum--custom.badaboum .no-background {
  background: unset !important;
}
html body.badaboum--custom.badaboum .no-before::before {
  content: none !important;
}

.hidden {
  display: none !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  .hidden--mobile {
    display: none;
  }
}
@media (min-width: 768px) {
  .hidden--desktop {
    display: none;
  }
}

.section {
  position: relative;
  clear: both;
  margin: 40px 0;
}
.section--first {
  margin-top: 0;
}
@media (min-width: 768px) {
  .section {
    margin: 60px 0;
  }
  .section--gap {
    margin-bottom: 80px;
  }
}
.section.view-more-section {
  margin-bottom: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
}
@media (min-width: 0px) and (max-width: 767px) {
  .section.view-more-section .view-more {
    padding: 0;
  }
  .section.view-more-section p {
    font-size: 11px;
  }
}
@media (min-width: 768px) {
  .section.view-more-section {
    padding-bottom: 60px;
  }
}
.section:first-child {
  margin-top: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .section-header {
    margin-bottom: 20px;
  }
}
.section-title, .section-title:first-child {
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  position: relative;
}
.section-title::before, .section-title:first-child::before {
  margin-right: 15px;
  color: #cd2367;
  font-size: 25px;
}
@media (min-width: 768px) {
  .section-title, .section-title:first-child {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 25px;
  }
  .section-title::before, .section-title:first-child::before {
    font-size: 40px;
  }
}
.section-title-link {
  position: absolute !important;
  top: 50%;
  right: 0;
}
.section-title--small {
  max-width: 695px;
  margin-left: auto;
  margin-right: auto;
  font-size: 34px;
  display: block;
  line-height: 14px;
  padding-top: 10px;
  font-weight: normal;
  font-family: "Montserrat";
}
@media (min-width: 768px) {
  .section-title--small {
    font-size: 40px;
    line-height: 34px;
  }
}
.section-title--big {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  display: block;
  position: relative;
  margin-bottom: 28px;
}
.section-title--big::before {
  content: "";
  height: 4px;
  border-radius: 2px;
  width: 50px;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #cd2367;
}
.section-title--big em {
  font-weight: bold;
  color: #cd2367;
}
@media (min-width: 768px) {
  .section-title--big {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 48px;
  }
}
.section-subtitle {
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  margin-bottom: 25px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .section-subtitle {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .section-subtitle {
    font-size: 21px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 34px;
    text-align: left;
  }
}
.section--grey {
  margin-top: 0;
  padding-top: 25px;
  padding-bottom: 55px;
  background: #e9e5e5;
}
@media (min-width: 768px) {
  .section--grey {
    padding-top: 55px;
  }
}
.section--grey + .footer {
  margin-top: -35px;
}
@media (min-width: 768px) {
  .section--grey + .footer {
    margin-top: -55px;
  }
}

.box {
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;
  text-align: left;
}
@media (min-width: 768px) {
  .box {
    padding: 30px;
  }
}
.box + .box {
  margin-top: 20px;
}
.box--shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}
.box--fill {
  background: rgba(233, 229, 229, 0.3);
}
.box--neutral {
  background: transparent;
}
.box--nogap {
  padding: 0;
}
.box--alt {
  background: #e8e6e6;
}
.box-content + .box-content {
  margin-top: 45px;
}
.box--medium {
  padding: 25px;
}
.box--small {
  padding: 12px;
}
@media (min-width: 768px) {
  .box--small {
    padding: 20px;
  }
}
.box-title {
  font-family: "Montserrat";
  margin-bottom: 20px !important;
  font-size: 18px;
  font-weight: normal;
  font-weight: 500;
}
@media (min-width: 768px) {
  .box-title {
    font-size: 16px;
  }
}
.box-title::before {
  display: block;
  margin-bottom: 20px;
  text-align: center;
  font-size: 65px;
  line-height: 1;
}
.box-subtitle {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 25px;
}
.box-strong {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.box-text {
  margin-bottom: 25px;
  font-weight: normal;
}
.box-title + .box-subtitle {
  margin-top: -25px;
}
.box-list-item:nth-child(n+3) {
  margin-top: 15px;
}
.box::before {
  font-size: 60px;
  line-height: 1;
  text-align: center;
  display: block;
  margin: 0 auto 25px;
}
.box-valign {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.box-valign-top, .box-valign-bottom {
  width: 100%;
}
.box-valign-top {
  align-self: flex-start;
}
.box-valign-bottom {
  align-self: flex-end;
}
.box-section + .box-section {
  margin-top: 20px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px 20px 0;
  border-top: 1px solid #e8e6e6;
}
@media (min-width: 1024px) {
  .box-section + .box-section {
    margin-top: 40px;
    margin-left: -40px;
    margin-right: -40px;
    padding: 40px 40px 0;
  }
  .box--small .box-section + .box-section {
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px 20px 0;
  }
}
.box-section--elevator:not(.open) {
  display: none;
}
.box-section-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px;
  color: #cd2367;
}
.box-smallgap + .box-smallgap {
  margin-top: 20px;
}
@media (min-width: 1024px) {
  .box-smallgap + .box-smallgap {
    margin-top: 0;
  }
}
.box-col {
  margin-bottom: -20px;
  padding: 35px 20px;
}
.box-cols {
  margin: -20px -20px 0;
}
@media (min-width: 1024px) {
  .box-cols {
    margin: 0 -40px;
  }
}
@media (min-width: 768px) {
  .box-col {
    padding: 40px;
  }
}
@media (min-width: 1024px) {
  .box-col {
    margin-top: -40px;
    margin-bottom: -40px;
  }
}
.box-col--small {
  padding: 20px !important;
}
@media (min-width: 768px) {
  .box-col--small {
    padding: 30px !important;
  }
}
@media (min-width: 1280px) {
  .box-col {
    padding: 40px 75px;
  }
}
.box-col + .box-col {
  border-top: 1px solid #e8e6e6;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .box-col + .box-col {
    margin-top: 0;
    border-top: 0;
    border-left: 1px solid #e8e6e6;
  }
}
@media (min-width: 1024px) {
  .box-col + .box-col {
    margin-top: -40px;
  }
}
.box-mobilegap + .box-mobilegap {
  margin-top: 35px;
}
@media (min-width: 768px) {
  .box-mobilegap + .box-mobilegap {
    margin-top: 0;
  }
}
.box--login {
  margin: 100px auto;
}

/****************** MESSAGES *******************/
.message {
  background: #e9e5e5;
  text-align: center;
  padding: 5px 10px;
  margin: 15px 0;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  border-radius: 3px;
}
.messages {
  font-weight: 500;
  padding: 0;
}
.message--error {
  color: #ffffff;
  background: rgba(183, 25, 24, 0.5);
}
.message--ok {
  color: #ffffff;
  background: rgba(109, 179, 50, 0.5);
}
.message--info {
  color: #4d586c;
  background: #e9e5e5;
}
.message-content {
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.message-content::before {
  margin-right: 8px;
}
.messages .messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.messages .messages .message {
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messages .messages .message > div::before {
  margin-top: -3px;
  position: initial;
  line-height: 13px;
  width: 28px;
}

/****************** ELEVATORS *******************/
[data-elevator] {
  overflow: hidden;
  transition: 0.5s;
}
[data-elevator]:not(.active) {
  max-height: 0;
}

@media (min-width: 768px) {
  [data-elevator--mobile] {
    overflow: initial;
  }
  [data-elevator--mobile]:not(.active) {
    max-height: none;
  }
}

[data-elevator-toggle] {
  cursor: pointer;
}

@media (min-width: 768px) {
  .elevator-toggle--mobile {
    display: none !important;
  }
}
.elevator-toggle em, .elevator-toggle--mobile em {
  position: relative;
  z-index: 5;
  display: block;
  font-style: normal !important;
}
.elevator-toggle::after, .elevator-toggle--mobile::after {
  content: "\e5c5";
  font-size: 24px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  margin-left: 5px;
  z-index: 6;
  font-family: "badaboum";
}
.elevator-toggle::before, .elevator-toggle--mobile::before {
  content: "";
  background-image: linear-gradient(180deg, rgba(233, 229, 229, 0) 0%, #e9e5e5 50%);
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% + 85px);
  width: 100%;
  opacity: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.elevator-toggle--white::before, .elevator-toggle--mobile--white::before {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
}
@media (min-width: 0px) and (max-width: 767px) {
  .elevator-toggle--whitemobile::before, .elevator-toggle--mobile--whitemobile::before {
    display: block !important;
    height: 75px;
  }
}
.elevator-toggle.active::before, .elevator-toggle--mobile.active::before {
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.elevator-toggle--nogap, .elevator-toggle--mobile--nogap {
  margin-top: 0;
}
.elevator-toggle--simple::before {
  display: none;
}
.elevator-target {
  max-height: 0;
  overflow: hidden;
  overflow: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.elevator-target--nogap {
  max-height: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .elevator-target--mobile {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
}
@media (min-width: 768px) {
  .elevator-target--mobile {
    max-height: none !important;
  }
}
.elevator-target--mobile.active, .elevator-target.active {
  max-height: none;
}

/****************** ARRANGE ORDER OF ELEMENTS *******************/
.order-begin {
  order: -1;
}
.order-end {
  order: 99999;
}
.order-2 {
  order: 2;
}
@media (min-width: 0px) and (max-width: 767px) {
  .order-mobile-begin {
    order: -1;
  }
  .order-mobile-1 {
    order: 1;
  }
  .order-mobile-2 {
    order: 2;
  }
  .order-mobile-3 {
    order: 3;
  }
  .order-mobile-4 {
    order: 4;
  }
  .order-mobile-5 {
    order: 5;
  }
  .order-mobile-6 {
    order: 6;
  }
  .order-mobile-end {
    order: 99999;
  }
}
@media (min-width: 768px) {
  .order-md-begin {
    order: -1;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-end {
    order: 99999;
  }
}
@media (min-width: 1024px) {
  .order-ml-begin {
    order: -1;
  }
  .order-ml-1 {
    order: 1;
  }
  .order-ml-2 {
    order: 2;
  }
  .order-ml-3 {
    order: 3;
  }
  .order-ml-4 {
    order: 4;
  }
  .order-ml-5 {
    order: 5;
  }
  .order-ml-6 {
    order: 6;
  }
  .order-ml-end {
    order: 99999;
  }
}
@media (min-width: 1280px) {
  .order-lg-begin {
    order: -1;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-end {
    order: 99999;
  }
}

.ok {
  color: #cd2367;
}

.ko {
  color: #B71918;
}

/****************** LOADER *******************/
@keyframes rotating {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loader {
  pointer-events: none;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  z-index: 9999;
}
.loader-overlay {
  background: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1s;
  z-index: 99999;
}
.loaded .loader-overlay {
  opacity: 0;
  pointer-events: none;
}
.loading .loader-overlay {
  opacity: 1;
  pointer-events: all;
}
.loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none !important;
}
.loader p, .loader-logo {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(205, 35, 103, 0.2);
  border-right: 1.1em solid rgba(205, 35, 103, 0.2);
  border-bottom: 1.1em solid rgba(205, 35, 103, 0.2);
  border-left: 1.1em solid #cd2367;
  transform: translateZ(0);
  animation: rotating 1.1s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0);
}

/****************** STATUS *******************/
.status {
  font-weight: normal;
}
.status--ok {
  color: #6DB332;
}
.status--warning {
  color: #EC6611;
}
.status--error {
  color: #B71918;
}

.products-main-sidebar .page-title-wrapper {
  background-color: #f4f3e9;
  display: flex;
  align-items: center;
  padding: 15px 0;
}
.products-main-sidebar .page-title-wrapper .page-title {
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #2c2628;
  line-height: 17px;
}

.rating-summary .rating-result {
  width: 148px;
  margin-left: -1px;
}
.rating-summary .rating-result::before {
  font-family: "badaboum";
  content: "\e91f" "\e91f" "\e91f" "\e91f" "\e91f";
  letter-spacing: 2px;
  font-size: 28px;
  color: #d9d8cf;
}
.rating-summary .rating-result > span::before {
  font-family: "badaboum";
  content: "\e91f" "\e91f" "\e91f" "\e91f" "\e91f";
  letter-spacing: 2px;
  color: #eec426;
}

.review-control-vote {
  letter-spacing: 2px;
  color: #d9d7cb;
}
.review-control-vote::before {
  content: "\e91f" "\e91f" "\e91f" "\e91f" "\e91f";
  font-family: "badaboum";
  color: #d9d8cf;
  letter-spacing: 2px;
}
.review-control-vote label::before {
  font-family: "badaboum";
  color: #eec426;
  letter-spacing: 2px;
}
.review-control-vote .rating-1 {
  z-index: 6;
}
.review-control-vote .rating-1::before {
  content: "\e91f";
}
.review-control-vote .rating-2 {
  z-index: 5;
}
.review-control-vote .rating-2::before {
  content: "\e91f" "\e91f";
}
.review-control-vote .rating-3 {
  z-index: 4;
}
.review-control-vote .rating-3::before {
  content: "\e91f" "\e91f" "\e91f";
}
.review-control-vote .rating-4 {
  z-index: 3;
}
.review-control-vote .rating-4::before {
  content: "\e91f" "\e91f" "\e91f" "\e91f";
}
.review-control-vote .rating-5 {
  z-index: 2;
}
.review-control-vote .rating-5::before {
  content: "\e91f" "\e91f" "\e91f" "\e91f" "\e91f";
}

textarea:not([disabled]) {
  transition: 0.4s;
}
textarea:not([disabled]):focus {
  box-shadow: 0 0 2px 1px #cd2367 inset;
  border: 1px solid rgba(244, 23, 100, 0.5) !important;
}

.faq-ask-form-container #amfaq-ask-form-inline .fieldset .field.required.choice::before {
  display: none;
}

.am-search-box-wrapper .am-widget-center {
  width: 100% !important;
}
@media (min-width: 768px) {
  .am-search-box-wrapper .am-widget-center {
    width: 40% !important;
  }
}

.amfaq_questions .am-no-items {
  font-weight: normal;
}
.amfaq_questions ~ .amfaq_questions {
  display: none;
}

.grecaptcha-badge {
  z-index: 1000 !important;
  display: none !important;
  visibility: hidden !important;
}
@media (min-width: 1024px) {
  .grecaptcha-badge {
    display: block !important;
    visibility: visible !important;
  }
}

html body.badaboum--custom.badaboum .slick-slider {
  padding-left: 0;
  padding-right: 0;
}
html body.badaboum--custom.badaboum .slick-list, html body.badaboum--custom.badaboum .slick-track {
  min-width: 100% !important;
}
html body.badaboum--custom.badaboum .slick-slide {
  margin: 0 15px;
  height: auto;
  width: 173px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .slick-slide {
    max-width: 273px;
    width: auto;
  }
}
@media (min-width: 1540px) {
  html body.badaboum--custom.badaboum .slick-slide {
    margin: 0 22px;
  }
}
html body.badaboum--custom.badaboum .slick-slide > div {
  height: inherit;
  width: inherit;
}
html body.badaboum--custom.badaboum .slick-disabled {
  display: none !important;
}
html body.badaboum--custom.badaboum .slick-arrow {
  align-items: center;
  justify-content: center;
  background: #F4F3E9 !important;
  background-image: unset !important;
  width: auto !important;
  height: auto !important;
  z-index: 2;
}
html body.badaboum--custom.badaboum .slick-arrow:before {
  font-family: "badaboum";
  content: "\e911";
  font-size: 20px;
  padding: 10px;
}
html body.badaboum--custom.badaboum .slick-arrow--right {
  right: 0;
}
html body.badaboum--custom.badaboum .slick-arrow--left {
  left: 0;
}
html body.badaboum--custom.badaboum .slick-arrow:not(.slick-disabled) {
  display: flex !important;
}

html body.badaboum--custom.badaboum .mobile-slider {
  max-width: 100%;
}

@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .common-slider {
    width: 100vw;
    min-height: 290px;
  }
}

html body.badaboum--custom.badaboum .best-sellers-slider .slick-arrow {
  background: #ffffff !important;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-regular-webfont.woff") format("woff"), url("../fonts/montserrat-regular-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-medium-webfont.woff") format("woff"), url("../fonts/montserrat-medium-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-semibold-webfont.woff") format("woff"), url("../fonts/montserrat-semibold-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-bold-webfont.woff") format("woff"), url("../fonts/montserrat-bold-webfont.woff2") format("woff2");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/montserrat-black-webfont.woff") format("woff"), url("../fonts/montserrat-black-webfont.woff2") format("woff2");
}
/* FONTICON ICOMOON, USE IF NEEDED */
/* 
@include font-face(
    $icon,
    '../../fonts/leader',
    400,
    normal,
    woff woff2
);*/
@font-face {
  font-family: "badaboum";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/badaboum.woff") format("woff"), url("../fonts/badaboum.woff2") format("woff2");
}
[class^=icon-]::before, [class^=icon-][class$=-after]::after, [class^=icon-][class*="-after "]::after, [class*=" icon-"]::before, [class*=" icon-"][class$=-after]::after, [class*=" icon-"][class*="-after "]::after {
  font-family: "badaboum" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user1::before, .icon-user1-after::after {
  content: "\e920" !important;
}
.icon-star::before, .icon-star-after::after {
  content: "\e91f" !important;
}
.icon-facebook::before, .icon-facebook-after::after {
  content: "\e910" !important;
}
.icon-instagram::before, .icon-instagram-after::after {
  content: "\e914" !important;
}
.icon-linkedin::before, .icon-linkedin-after::after {
  content: "\e937" !important;
}
.icon-twitter::before, .icon-twitter-after::after {
  content: "\e91a" !important;
}
.icon-youtube::before, .icon-youtube-after::after {
  content: "\e91b" !important;
}
.icon-pinterest::before, .icon-pinterest-after::after {
  content: "\e917" !important;
}
.icon-chevron-up::before, .icon-chevron-up-after::after {
  content: "\e90e" !important;
}
.icon-chevron-up-scroll::before, .icon-chevron-up-scroll-after::after {
  content: "\e912" !important;
}
.icon-chevron-down::before, .icon-chevron-down-after::after {
  content: "\e90d" !important;
}
.icon-chevron-right::before, .icon-chevron-right-after::after {
  content: "\e91e" !important;
}
.icon-chevron-left::before, .icon-chevron-left-after::after {
  content: "\e91d" !important;
}
.icon-arrow-left::before, .icon-arrow-left-after::after {
  content: "\e912" !important;
}
.icon-arrow-right::before, .icon-arrow-right-after::after {
  content: "\e911" !important;
}
.icon-backtoback::before, .icon-backtoback-after::after {
  content: "\e916" !important;
}
.icon-return::before, .icon-return-after::after {
  content: "\e90a" !important;
}
.icon-dropdown::before, .icon-dropdown-after::after {
  content: "\e90a" !important;
}
.icon-check::before, .icon-check-after::after {
  content: "\e913" !important;
}
.icon-close::before, .icon-close-after::after {
  content: "\e90f" !important;
}
.icon-like::before, .icon-like-after::after {
  content: "\e90b" !important;
}
.icon-time::before, .icon-time-after::after {
  content: "\e909" !important;
}
.icon-filter::before, .icon-filter-after::after {
  content: "\e914" !important;
}
.icon-calendar::before, .icon-calendar-after::after {
  content: "\e904" !important;
}
.icon-new::before, .icon-new-after::after {
  content: "\e901" !important;
}
.icon-search::before, .icon-search-after::after {
  content: "\e921" !important;
}
.icon-pin::before, .icon-pin-after::after {
  content: "\e903" !important;
}
.icon-delivery::before, .icon-delivery-after::after {
  content: "\e918" !important;
}
.icon-trash::before, .icon-trash-after::after {
  content: "\e919" !important;
}
.icon-fid::before, .icon-fid-after::after {
  content: "\e91a" !important;
}
.icon-mobile::before, .icon-mobile-after::after {
  content: "\e909" !important;
}
.icon-fav::before, .icon-fav-after::after {
  content: "\e90a" !important;
}
.icon-fav2::before, .icon-fav2-after::after {
  content: "\e923" !important;
}
.icon-star::before, .icon-star-after::after {
  content: "\e90b" !important;
}
.icon-star2::before, .icon-star2-after::after {
  content: "\e90e" !important;
}
.icon-call::before, .icon-call-after::after {
  content: "\e917" !important;
}
.icon-handi::before, .icon-handi-after::after {
  content: "\e921" !important;
}
.icon-handi2::before, .icon-handi2-after::after {
  content: "\e924" !important;
}
.icon-handi3::before, .icon-handi3-after::after {
  content: "\e922" !important;
}
.icon-foot::before, .icon-foot-after::after {
  content: "\e925" !important;
}
.icon-foot2::before, .icon-foot2-after::after {
  content: "\e928" !important;
}
.icon-foot3::before, .icon-foot3-after::after {
  content: "\e926" !important;
}
.icon-covid::before, .icon-covid-after::after {
  content: "\e925" !important;
}
.icon-covid2::before, .icon-covid2-after::after {
  content: "\e928" !important;
}
.icon-covid3::before, .icon-covid3-after::after {
  content: "\e926" !important;
}
.icon-inject::before, .icon-inject-after::after {
  content: "\e929" !important;
}
.icon-inject2::before, .icon-inject2-after::after {
  content: "\e92c" !important;
}
.icon-inject3::before, .icon-inject3-after::after {
  content: "\e92a" !important;
}
.icon-leaf::before, .icon-leaf-after::after {
  content: "\e92d" !important;
}
.icon-leaf2::before, .icon-leaf2-after::after {
  content: "\e930" !important;
}
.icon-leaf3::before, .icon-leaf3-after::after {
  content: "\e92e" !important;
}
.icon-truck::before, .icon-truck-after::after {
  content: "\e931" !important;
}
.icon-truck2::before, .icon-truck2-after::after {
  content: "\e934" !important;
}
.icon-truck3::before, .icon-truck3-after::after {
  content: "\e932" !important;
}
.icon-phone::before, .icon-phone-after::after {
  content: "\e91b" !important;
}
.icon-fidcard::before, .icon-fidcard-after::after {
  content: "\e93a" !important;
}
.icon-purse::before, .icon-purse-after::after {
  content: "\e93c" !important;
}
.icon-secure::before, .icon-secure-after::after {
  content: "\e91b" !important;
}
.icon-sav::before, .icon-sav-after::after {
  content: "\e91c" !important;
}
.icon-user::before, .icon-user-after::after {
  content: "\e909" !important;
}
.icon-clock::before, .icon-clock-after::after, .icon ::before, .icon -after::after {
  content: mail, "\e901" !important;
}
.icon-tel::before, .icon-tel-after::after {
  content: "\e907" !important;
}
.icon-itineraire::before, .icon-itineraire-after::after {
  content: "\e901" !important;
}
.icon-contacteznous::before, .icon-contacteznous-after::after {
  content: "\e900" !important;
}
.icon-cart::before, .icon-cart-after::after {
  content: "\e908" !important;
}
.icon-loupe::before, .icon-loupe-after::after {
  content: "\e907" !important;
}
.icon-heart::before, .icon-heart-after::after {
  content: "\e905" !important;
}
.icon-lock::before, .icon-lock-after::after {
  content: "\e900" !important;
}
.icon-headphone::before, .icon-headphone-after::after {
  content: "\e903" !important;
}
.icon-shipping::before, .icon-shipping-after::after {
  content: "\e902" !important;
}
.icon-map::before, .icon-map-after::after {
  content: "\e915" !important;
}
.icon-document::before, .icon-document-after::after {
  content: "\e90c" !important;
}
.icon-trash::before, .icon-trash-after::after {
  content: "\e919" !important;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  transition: 0.6s;
  border: 0;
  box-shadow: 0 5px 9px transparent;
  white-space: nowrap;
  -webkit-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  transition: opacity 0.3s, top 0.6s, transform 0.8s;
  font-weight: 400;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  height: 50px;
  padding: 0 15px !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
}
.btns {
  margin-top: 25px;
  font-size: 0;
  width: 100%;
  display: flex;
}
.btns--line {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btns--full {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btns--break {
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btns--break--mobile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0 !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .btns--break--tablet {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.btns--break .btn {
  margin-left: 0;
  margin-right: 0;
}
.btns .btn:not([style*="display: none"]) + .btn {
  margin-left: 15px;
}
@media (min-width: 768px) {
  .btns .btn:not([style*="display: none"]) + .btn {
    margin-left: 20px;
  }
}
@media (min-width: 768px) {
  .btns--gap .btn + .btn {
    margin-left: 60px;
  }
}
.btns--break .btn + .btn {
  margin-top: 10px;
  margin-left: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btns--break--mobile .btn + .btn {
    margin-top: 10px;
    margin-left: 0;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .btns--break--tablet .btn + .btn {
    margin-top: 10px;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .btns--wrap--nomobile {
    flex-wrap: wrap;
    margin-bottom: -10px;
  }
}
.btns--wrap--nomobile .btn, .btns--wrap .btn {
  margin-left: 0 !important;
  margin-right: 12px;
  margin-bottom: 10px;
}
.btn.btn--icon {
  width: 40px;
  padding: 0 20px;
  height: 40px;
}
.btn.btn--icon em::before {
  margin-right: 0;
}
.btn.btn--shadow {
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.06) !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btn {
    font-size: 16px;
  }
}
.btn-text,
.btn em,
.btn span {
  z-index: 15;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  color: inherit;
}
.btn-text::before, .btn-text::after,
.btn em::before,
.btn em::after,
.btn span::before,
.btn span::after {
  font-size: 1.6666666667em;
  line-height: 1.6666666667;
  vertical-align: middle;
}
.btn-text::before,
.btn em::before,
.btn span::before {
  margin-right: 10px;
}
.btn-text::after,
.btn em::after,
.btn span::after {
  margin-left: 10px;
}
.btn::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  width: calc(800% + 8px);
  height: 150%;
  padding-top: calc(800% + 8px);
  opacity: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  transform: translate(-32%, 0) rotate(-17deg) translate3d(0, 0, 0);
  -webkit-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  -o-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
}
.btn:hover::after,
.btn a:not(.func):hover ::after {
  opacity: 1;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
  transform: translate(-50%, 0) rotate(0) translate3d(0, 0, 0);
}
.btn:active::after, .btn.active::after, a:active .btn:not(.button-alone)::after {
  opacity: 1;
}
.btn:focus {
  outline: 0;
}
.btn--big {
  font-size: 17px !important;
  height: 50px !important;
  line-height: 50px !important;
}
.btn--inactive {
  pointer-events: none;
}
.btn--gap {
  margin-bottom: 10px;
}
.btn--primary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  transition: 0.6s;
  border: 0;
  box-shadow: 0 5px 9px transparent;
  white-space: nowrap;
  -webkit-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  transition: opacity 0.3s, top 0.6s, transform 0.8s;
  font-weight: 400;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  height: 50px;
  padding: 0 15px !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  color: #ffffff !important;
  background-color: #cd2367 !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btn--primary {
    font-size: 16px;
  }
}
.btn--primary-text,
.btn--primary em,
.btn--primary span {
  z-index: 15;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  color: inherit;
}
.btn--primary-text::before, .btn--primary-text::after,
.btn--primary em::before,
.btn--primary em::after,
.btn--primary span::before,
.btn--primary span::after {
  font-size: 1.6666666667em;
  line-height: 1.6666666667;
  vertical-align: middle;
}
.btn--primary-text::before,
.btn--primary em::before,
.btn--primary span::before {
  margin-right: 10px;
}
.btn--primary-text::after,
.btn--primary em::after,
.btn--primary span::after {
  margin-left: 10px;
}
.btn--primary::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  width: calc(800% + 8px);
  height: 150%;
  padding-top: calc(800% + 8px);
  opacity: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  transform: translate(-32%, 0) rotate(-17deg) translate3d(0, 0, 0);
  -webkit-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  -o-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
}
.btn--primary:hover::after,
.btn--primary a:not(.func):hover ::after {
  opacity: 1;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
  transform: translate(-50%, 0) rotate(0) translate3d(0, 0, 0);
}
.btn--primary:active::after, .btn--primary.active::after, a:active .btn--primary:not(.button-alone)::after {
  opacity: 1;
}
.btn--primary:hover {
  color: #ffffff;
}
.btn--primary:active, .btn--primary.active {
  color: #ffffff;
}
.btn--primary:active::after, .btn--primary.active::after {
  background: #df4482 !important;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
}
.btn--primary::after {
  background: #df4482 !important;
}
.btn--secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  transition: 0.6s;
  border: 0;
  box-shadow: 0 5px 9px transparent;
  white-space: nowrap;
  -webkit-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  transition: opacity 0.3s, top 0.6s, transform 0.8s;
  font-weight: 400;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  height: 50px;
  padding: 0 15px !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  background-color: #F4F3E9 !important;
  color: #2C2628 !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btn--secondary {
    font-size: 16px;
  }
}
.btn--secondary-text,
.btn--secondary em,
.btn--secondary span {
  z-index: 15;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  color: inherit;
}
.btn--secondary-text::before, .btn--secondary-text::after,
.btn--secondary em::before,
.btn--secondary em::after,
.btn--secondary span::before,
.btn--secondary span::after {
  font-size: 1.6666666667em;
  line-height: 1.6666666667;
  vertical-align: middle;
}
.btn--secondary-text::before,
.btn--secondary em::before,
.btn--secondary span::before {
  margin-right: 10px;
}
.btn--secondary-text::after,
.btn--secondary em::after,
.btn--secondary span::after {
  margin-left: 10px;
}
.btn--secondary::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  width: calc(800% + 8px);
  height: 150%;
  padding-top: calc(800% + 8px);
  opacity: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  transform: translate(-32%, 0) rotate(-17deg) translate3d(0, 0, 0);
  -webkit-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  -o-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
}
.btn--secondary:hover::after,
.btn--secondary a:not(.func):hover ::after {
  opacity: 1;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
  transform: translate(-50%, 0) rotate(0) translate3d(0, 0, 0);
}
.btn--secondary:active::after, .btn--secondary.active::after, a:active .btn--secondary:not(.button-alone)::after {
  opacity: 1;
}
.btn--secondary:hover {
  background-color: #F4F3E9 !important;
  color: #2C2628 !important;
}
.btn--secondary span {
  color: #2C2628 !important;
}
.btn--secondary:active, .btn--secondary.active {
  color: #dfddcc !important;
}
.btn--secondary:active::after, .btn--secondary.active::after {
  background: #dfddcc !important;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
}
.btn--secondary::after {
  background: #dfddcc !important;
}
.btn--tertiary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  transition: 0.6s;
  border: 0;
  box-shadow: 0 5px 9px transparent;
  white-space: nowrap;
  -webkit-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  transition: opacity 0.3s, top 0.6s, transform 0.8s;
  font-weight: 400;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  height: 50px;
  padding: 0 15px !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  background-color: #b0b3b2;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btn--tertiary {
    font-size: 16px;
  }
}
.btn--tertiary-text,
.btn--tertiary em,
.btn--tertiary span {
  z-index: 15;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  color: inherit;
}
.btn--tertiary-text::before, .btn--tertiary-text::after,
.btn--tertiary em::before,
.btn--tertiary em::after,
.btn--tertiary span::before,
.btn--tertiary span::after {
  font-size: 1.6666666667em;
  line-height: 1.6666666667;
  vertical-align: middle;
}
.btn--tertiary-text::before,
.btn--tertiary em::before,
.btn--tertiary span::before {
  margin-right: 10px;
}
.btn--tertiary-text::after,
.btn--tertiary em::after,
.btn--tertiary span::after {
  margin-left: 10px;
}
.btn--tertiary::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  width: calc(800% + 8px);
  height: 150%;
  padding-top: calc(800% + 8px);
  opacity: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  transform: translate(-32%, 0) rotate(-17deg) translate3d(0, 0, 0);
  -webkit-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  -o-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
}
.btn--tertiary:hover::after,
.btn--tertiary a:not(.func):hover ::after {
  opacity: 1;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
  transform: translate(-50%, 0) rotate(0) translate3d(0, 0, 0);
}
.btn--tertiary:active::after, .btn--tertiary.active::after, a:active .btn--tertiary:not(.button-alone)::after {
  opacity: 1;
}
.btn--tertiary:hover {
  background-color: #b0b3b2;
  color: #ffffff;
}
.btn--tertiary:active {
  color: #ffffff;
}
.btn--tertiary:active::after {
  background: #707070;
}
.btn--tertiary::after {
  background: #707070;
}
@media (min-width: 1024px) and (max-width: 2559px) {
  .btn--tertiary {
    font-size: 1.8rem;
    font-weight: 600;
  }
}
.btn--quaternary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  transition: 0.6s;
  border: 0;
  box-shadow: 0 5px 9px transparent;
  white-space: nowrap;
  -webkit-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  transition: opacity 0.3s, top 0.6s, transform 0.8s;
  font-weight: 400;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  height: 50px;
  padding: 0 15px !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  color: #ffffff;
  background: #756a6e;
  font-weight: 500;
  height: 60px;
  margin: 20px 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btn--quaternary {
    font-size: 16px;
  }
}
.btn--quaternary-text,
.btn--quaternary em,
.btn--quaternary span {
  z-index: 15;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  color: inherit;
}
.btn--quaternary-text::before, .btn--quaternary-text::after,
.btn--quaternary em::before,
.btn--quaternary em::after,
.btn--quaternary span::before,
.btn--quaternary span::after {
  font-size: 1.6666666667em;
  line-height: 1.6666666667;
  vertical-align: middle;
}
.btn--quaternary-text::before,
.btn--quaternary em::before,
.btn--quaternary span::before {
  margin-right: 10px;
}
.btn--quaternary-text::after,
.btn--quaternary em::after,
.btn--quaternary span::after {
  margin-left: 10px;
}
.btn--quaternary::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  width: calc(800% + 8px);
  height: 150%;
  padding-top: calc(800% + 8px);
  opacity: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  transform: translate(-32%, 0) rotate(-17deg) translate3d(0, 0, 0);
  -webkit-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  -o-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
}
.btn--quaternary:hover::after,
.btn--quaternary a:not(.func):hover ::after {
  opacity: 1;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
  transform: translate(-50%, 0) rotate(0) translate3d(0, 0, 0);
}
.btn--quaternary:active::after, .btn--quaternary.active::after, a:active .btn--quaternary:not(.button-alone)::after {
  opacity: 1;
}
.btn--quaternary:hover, a:hover .btn--quaternary:not(.button-alone) {
  background-color: #756a6e;
  color: #ffffff;
}
.btn--quaternary:active {
  color: #ffffff;
}
.btn--quaternary:active::after {
  background: #707070;
}
.btn--quaternary::after {
  background: #a79ea1;
}
.btn--small {
  font-size: 14px;
  height: 39px;
  font-weight: 300;
}
.btn--subtitled {
  height: 54px;
  border-radius: 27px;
  flex-direction: column;
  line-height: 16px;
}
.btn--subtitled em {
  font-size: 12px;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.btn-link {
  font-size: 12px;
  margin-top: 5px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btns--break--mobile.full > .btn {
    width: 100%;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .btns--break--tablet.full > .btn {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .btns--break--tablet.full > .btn {
    width: 65%;
  }
}

.btt {
  display: flex;
  position: fixed;
  bottom: 95px;
  right: 18px;
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  background: #cd2367;
  color: #ffffff;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  pointer-events: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  align-items: center;
  justify-content: center;
}
@media (min-width: 0px) and (max-width: 767px) {
  .btt {
    bottom: 85px;
  }
}
.btt.active {
  pointer-events: all;
  opacity: 0.8;
}
.btt.active:hover {
  opacity: 1;
}
.btt::before {
  font-size: 15px;
}

@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum #axeptio_main_button {
    bottom: 60px !important;
  }
}

.table {
  color: #000000;
  background: #ffffff;
  width: 100%;
}
@media (min-width: 1024px) {
  .table {
    padding: 0 25px;
    border: 1px solid #e9e5e5;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .table {
    display: block;
  }
}
.table .price {
  font-size: inherit;
  font-weight: normal;
}
.table .price-including-tax {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .table thead {
    display: none;
  }
}
.table-head {
  font-weight: 500;
  font-size: 13px;
}
.table-head th {
  vertical-align: middle;
  text-align: center;
  padding: 15px 0;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .table-head {
    width: 16.6666666667%;
  }
}
.table-head .col.total {
  width: 100px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .table tbody,
.table tfoot {
    display: block;
  }
}
.table-body-item {
  border-bottom: 1px solid #D8D8D8;
  font-weight: 400;
  font-size: 12px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .table-body-item {
    display: block;
    background: rgba(233, 229, 229, 0.3);
    padding: 10px;
    border-bottom: 0;
    border-radius: 4px;
  }
  .table-body-item + tr {
    margin-top: 20px;
  }
}
@media (min-width: 1024px) {
  .table-body-item {
    width: 40%;
  }
}
.table-body-item:last-child {
  border: none;
}
@media (min-width: 1024px) {
  .table-body-qty {
    width: 11%;
  }
}
@media (min-width: 1024px) {
  .table-body-sku {
    width: 11%;
  }
}
.table-body-price {
  width: 100%;
  order: 3 !important;
}
@media (min-width: 1024px) {
  .table-body-price {
    height: 70px !important;
    width: 11% !important;
  }
}
.table-body-price-wrapper {
  margin-right: 10px;
}
@media (min-width: 768px) {
  .table-body-price-wrapper {
    margin: 0 40px;
  }
}
.table-body-price__promo-tag {
  padding: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  background: #cd2367;
}
@media (min-width: 0px) and (max-width: 767px) {
  .table-body-price__promo-tag {
    position: absolute !important;
    top: 105px !important;
    left: 143px !important;
    z-index: 12 !important;
  }
}
.table-body-price__promo-price {
  color: #2C2628;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 33px;
  text-align: center;
  text-decoration: line-through;
}
@media (min-width: 1024px) {
  .table-body-subtotal {
    text-align: center !important;
    width: 11%;
  }
}
.table-body-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: 1279px) {
  .table-body-actions {
    justify-content: start;
  }
}
@media (min-width: 1280px) {
  .table-body-actions a.link--revert {
    margin-right: 0 !important;
    font-size: 12px;
    font-weight: 400;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .table-body span {
    margin-left: auto;
  }
}
.table td {
  padding: 20px 10px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #e9e5e5;
}
.table td :last-child > td {
  border-bottom: 0;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .table td {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 5px 0;
  }
  .table td::before {
    font-weight: 300;
    content: attr(data-th) ": ";
    margin-right: 10px;
    text-align: right;
    font-size: 13px;
  }
  .table td:first-child {
    padding-top: 0 !important;
  }
}
.table td.amount {
  padding-left: 15px !important;
}
.table-icon {
  font-size: 29px;
  line-height: 17px;
}
.table-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-icon + .table-icon {
  margin-left: 15px;
}

@media (min-width: 0px) and (max-width: 1279px) {
  .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:last-child {
    padding-bottom: 0 !important;
    padding-right: 0;
  }
}

.settings {
  border-collapse: collapse;
  color: #000000;
  width: 100%;
}
.settings a {
  color: #cd2367;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.settings a:hover {
  color: #df4482;
}
@media (min-width: 0px) and (max-width: 767px) {
  .settings {
    margin-top: 15px;
  }
  .settings-wrapper {
    position: relative;
  }
  .settings-container {
    overflow-x: auto;
  }
}
.settings-container {
  margin-bottom: 40px;
}
@media (min-width: 1280px) {
  .settings-wrapper {
    padding: 0 115px;
  }
}
.settings + .settings {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 140px;
  width: calc(50vw - 20px);
  background: #ffffff;
  display: block;
}
@media (min-width: 768px) {
  .settings + .settings {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .settings .settings-merged {
    display: none;
  }
}
@media (min-width: 768px) {
  .settings .settings-merged {
    text-align: left;
  }
}
.settings .text-right {
  position: relative;
  width: 139px;
  width: calc(50vw - 21px);
  font-weight: 500;
  background: #e9e5e5;
}
@media (min-width: 0px) and (max-width: 767px) {
  .settings .text-right {
    border: 0;
    border-bottom: 1px solid #ffffff;
  }
}
@media (min-width: 768px) {
  .settings .text-right {
    background: transparent;
    font-weight: normal;
    text-align: right;
    width: auto;
  }
}
.settings .price {
  font-size: inherit;
  font-weight: normal;
}
@media (min-width: 0px) and (max-width: 767px) {
  .settings thead {
    display: none;
  }
}
.settings-head th {
  vertical-align: middle;
}
.settings thead + tbody > :first-child {
  font-weight: 700;
}
.settings td,
.settings th {
  padding: 12px 12px;
  vertical-align: middle;
}
.settings th {
  background: #e9e5e5;
}
@media (min-width: 0px) and (max-width: 767px) {
  .settings th {
    padding: 0;
    width: 0;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .settings tr > :first-child {
    display: none;
  }
}
.settings td {
  text-align: center;
  vertical-align: middle;
  border: 1px solid #786855;
}
@media (min-width: 0px) and (max-width: 767px) {
  .settings td {
    align-items: center;
    justify-content: center;
    width: 139px;
    width: calc(50vw - 21px);
    min-width: 139px;
    min-width: calc(50vw - 21px);
  }
  .settings td::before {
    content: attr(data-th);
    text-align: right;
  }
}
.settings-separator td {
  border: 0;
  padding: 4px;
  display: table-cell !important;
}
.settings-separator--first td {
  padding: 10px;
}
.settings-invisible {
  background: transparent;
  border: 0 !important;
}
.settings-group-name {
  text-transform: uppercase;
  margin-bottom: 7px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .settings-group-name {
    display: none;
  }
}
.settings-arrow {
  display: inline-block;
  font-size: 45px;
  margin-left: -10px;
  width: 30px;
}

.order-details-items {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.order-details-comments {
  display: flex;
  flex-direction: column;
}
.order-details-comments + ul > li > a {
  color: #6DB332;
}
.order-comments {
  padding: 0 20px;
}
.order-comment + .order-comment {
  padding-top: 20px;
}
.order-comment-content {
  font-size: 14px;
}
.order-comment-date {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #695b4a !important;
}
.order-begin {
  color: #000000;
}
.order-date {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 10px;
}
.order-title {
  margin-bottom: -15px;
  padding-right: 38px;
}
.order-subtitle-wrapper {
  margin-bottom: 20px;
}
.order-subtitle-wrapper .page-subtitle {
  margin-bottom: 7px;
  font-size: 22px;
}
.order-subtitle-wrapper + ul > li > a {
  color: #6DB332;
}
.order-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
.order-actions-toolbar {
  margin: 25px 0 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.order-actions-toolbar:before {
  content: unset !important;
}
.order-actions-toolbar a.btn {
  width: 100%;
}
@media (min-width: 768px) {
  .order-actions-toolbar a.btn {
    width: calc(50% - 10px);
  }
}
.order-actions-toolbar a.btn.btn--secondary {
  margin-left: auto;
}
@media (min-width: 0px) and (max-width: 767px) {
  .order-actions-toolbar a.btn.btn--secondary {
    margin-top: 20px;
  }
}
.order-status {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
  color: #cd2367;
  margin-left: 15px;
}
.order-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.order-items {
  width: 100%;
}
.order-items-title {
  display: inline-block;
  padding: 25px;
  font-weight: 500;
  margin-bottom: -2px;
  border: 1px solid #e9e5e5;
  border-bottom: 0;
  background: #ffffff;
  margin-top: 40px;
  color: #000000;
}
.order-items-wrapper {
  width: 100%;
  border: 1px solid #e9e5e5;
  padding: 30px;
  margin-bottom: 35px;
  color: #000000;
}
.order-items-wrapper table {
  border: 0;
}
@media (min-width: 576px) and (max-width: 1023px) {
  .order-items table.table-order-items {
    display: block;
  }
}
.order-items thead tr, .order-items tbody tr {
  text-align: center;
}
.order-items thead tr > :last-child, .order-items tbody tr > :last-child {
  padding-right: 15px;
}
@media (min-width: 768px) and (max-width: 1539px) {
  .order-items thead tr > :last-child, .order-items tbody tr > :last-child {
    text-align: center !important;
    padding-right: 0;
  }
}
.order-items thead tr > :first-child, .order-items tbody tr > :first-child {
  text-align: left;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .order-items thead tr > :first-child, .order-items tbody tr > :first-child {
    padding-left: 0;
    width: 540px;
  }
}
.order-items thead td, .order-items tbody td {
  vertical-align: top;
}
.order-items thead td.qty, .order-items tbody td.qty {
  white-space: nowrap;
}
@media (min-width: 576px) and (max-width: 1023px) {
  .order-items tbody {
    display: block;
  }
}
.order-items tbody td {
  border-bottom: 1px solid #e9e5e5;
}
.order-items tbody td .price-including-tax, .order-items tbody td .price-excluding-tax {
  font-size: 1.4rem;
}
@media (min-width: 576px) and (max-width: 1023px) {
  .order-items tbody td.table-body-qty {
    justify-content: start;
  }
  .order-items tbody td > * {
    margin-left: auto !important;
  }
}
.order-items tbody :last-child > td {
  border-bottom: 0;
}
.order-items .table-footer {
  text-align: right;
  background: rgba(206, 10, 78, 0.1);
}
@media (min-width: 0px) and (max-width: 1023px) {
  .order-items .table-footer {
    margin-top: 15px;
    display: block;
  }
  .order-items .table-footer tr {
    display: block;
  }
  .order-items .table-footer .subtotal .amount {
    padding-top: 10px !important;
  }
}
.order-items .table-footer .price {
  font-weight: inherit;
}
.order-items .table-footer tr {
  text-align: right;
  margin-top: 0;
}
.order-items .table-footer tr .mark, .order-items .table-footer tr .amount {
  font-weight: 300 !important;
}
.order-items .table-footer tr .amount {
  padding-right: 10px !important;
}
@media (min-width: 1024px) {
  .order-items .table-footer tr .amount {
    padding-right: 30px !important;
  }
}
@media (min-width: 768px) and (max-width: 1539px) {
  .order-items .table-footer tr {
    width: 100%;
  }
}
.order-items .table-footer tr:last-child .amount, .order-items .table-footer tr:last-child .amount strong, .order-items .table-footer tr:first-child .amount, .order-items .table-footer tr:first-child .amount strong {
  font-weight: 600 !important;
}
.order-items .table-footer tr:last-child .mark strong {
  font-weight: 300 !important;
}
@media (min-width: 768px) {
  .order-items .table-footer tr:last-child td, .order-items .table-footer tr:last-child th {
    padding-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .order-items .table-footer tr:first-child td, .order-items .table-footer tr:first-child th {
    padding-top: 15px;
  }
}
.order-items .table-footer td {
  border-top: none !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .order-items .table-footer td {
    width: 100% !important;
  }
}
@media (min-width: 1024px) {
  .order-items .table-footer td {
    padding: 5px 15px 5px 55px;
  }
}
.order-items .table-footer td > * {
  margin-left: auto;
}
.order-items .table-footer th {
  text-align: right;
  font-size: 14px;
  padding: 5px 20px;
  border-top: none !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .order-items .table-footer th {
    display: none;
  }
}
.order-item-name {
  font-size: 17px;
}
.order-item-attributes {
  color: #786855;
  margin-top: 10px;
  margin-bottom: 10px;
}
.order-infos {
  color: #000000;
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .order-infos {
    text-align: left;
  }
}
.order-infos-title {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 22px;
}
.order-infos-col {
  font-size: 13px;
  text-align: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
}
@media (min-width: 768px) {
  .order-infos-col {
    text-align: left;
    justify-content: flex-start;
  }
}
.order-infos-col-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  width: 100%;
}

body.sales-order-print .actions-toolbar.order-actions-toolbar {
  display: none;
}
body.sales-order-print .customer-order-details-view {
  margin-bottom: 40px !important;
}
body.sales-order-print .customer-order-details-view-content {
  display: block;
}
body.sales-order-print .customer-order-details-view-content .box {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  width: calc(50% - 10px);
}
body.sales-order-print .sales-order-print-footer {
  display: block;
  margin-bottom: 40px;
}

.grid {
  display: grid;
  grid-gap: 20px;
  margin-top: 20px;
  margin-bottom: 100px;
  grid-template-areas: "item1" "item2" "item3" "item4" "item5" "item6" "item7" "item8";
  grid-template-rows: 266px 380px 266px 266px 266px 380px 380px 129px;
}
@media (min-width: 768px) {
  .grid {
    grid-template-areas: "item1 item2" "item1 item2" "item1 item2" "item3 item4" "item3 item4" "item3 item4" "item5 item6" "item5 item6" "item5 item6" "item7 item8" "item7 item8" "item7 item8";
    grid-template-rows: repeat(12, 76px);
  }
}
@media (min-width: 1024px) {
  .grid {
    grid-template-areas: "item1 item2 item3" "item1 item2 item3" "item1 item2 item3" "item4 item2 item5" "item4 item7 item5" "item4 item7 item5" "item6 item7 item5" "item6 item8 item8" "item6 item8 item8" "item6 item8 item8";
    padding: 0 25px;
    margin-top: 40px;
    margin-bottom: 151px;
    grid-template-rows: repeat(10, 76px);
  }
}
.grid-item {
  position: relative;
  border-radius: 2px;
  opacity: 0;
  transform: translateX(-20%);
}
.grid-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  -webkit-transition: 0.75s;
  -o-transition: 0.75s;
  transition: 0.75s;
  border-radius: 3px;
}
.grid-item-link {
  display: block;
  height: 100%;
  background: linear-gradient(45deg, #000000 0%, #e9e5e5 100%);
  transition: transform 0.5s;
  border-radius: 2px;
}
.grid-item-link::before, .grid-item-link::after {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
}
.grid-item-link::before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.grid-item-link::after {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}
.grid-item-link:hover::before, .grid-item-link:hover::after {
  opacity: 0.6;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.grid-item-link-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  -webkit-filter: grayscale(25%);
  filter: grayscale(25%);
}
.grid-item-link:hover .grid-item-link-image {
  opacity: 0.6;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
.grid-item-link-title {
  font-family: "Arial";
  font-size: 24px;
  font-weight: normal;
  line-height: 1.33;
  color: #ffffff;
  position: absolute;
  margin: 0 auto;
  left: 19px;
  bottom: 18px;
  z-index: 1;
  transition: 0.5s;
}
.grid-item-link:hover .grid-item-link-title {
  left: 26px;
}
body.loaded .grid-item {
  transform: none;
  opacity: 1;
}
.grid-item--1 {
  grid-area: item1;
  transition: 0.75s 0.25s;
  /*
  @if $i % 2 == 0 {
      transform: translateX(-20%);
     }

  @if $i % 3 == 0 {
      transform: translateY(20%);
     }

  @if $i % 4 == 0 {
      transform: translateY(20%);
     }
     */
}
.grid-item--2 {
  grid-area: item2;
  transition: 0.75s 0.5s;
  transform: translateX(20%);
  /*
  @if $i % 2 == 0 {
      transform: translateX(-20%);
     }

  @if $i % 3 == 0 {
      transform: translateY(20%);
     }

  @if $i % 4 == 0 {
      transform: translateY(20%);
     }
     */
}
.grid-item--3 {
  grid-area: item3;
  transition: 0.75s 0.75s;
  transform: translateX(-20%);
  /*
  @if $i % 2 == 0 {
      transform: translateX(-20%);
     }

  @if $i % 3 == 0 {
      transform: translateY(20%);
     }

  @if $i % 4 == 0 {
      transform: translateY(20%);
     }
     */
}
.grid-item--4 {
  grid-area: item4;
  transition: 0.75s 1s;
  transform: translateX(20%);
  transform: translateX(20%);
  /*
  @if $i % 2 == 0 {
      transform: translateX(-20%);
     }

  @if $i % 3 == 0 {
      transform: translateY(20%);
     }

  @if $i % 4 == 0 {
      transform: translateY(20%);
     }
     */
}
.grid-item--5 {
  grid-area: item5;
  transition: 0.75s 1.25s;
  /*
  @if $i % 2 == 0 {
      transform: translateX(-20%);
     }

  @if $i % 3 == 0 {
      transform: translateY(20%);
     }

  @if $i % 4 == 0 {
      transform: translateY(20%);
     }
     */
}
.grid-item--6 {
  grid-area: item6;
  transition: 0.75s 1.5s;
  transform: translateX(20%);
  transform: translateX(-20%);
  /*
  @if $i % 2 == 0 {
      transform: translateX(-20%);
     }

  @if $i % 3 == 0 {
      transform: translateY(20%);
     }

  @if $i % 4 == 0 {
      transform: translateY(20%);
     }
     */
}
.grid-item--7 {
  grid-area: item7;
  transition: 0.75s 1.75s;
  /*
  @if $i % 2 == 0 {
      transform: translateX(-20%);
     }

  @if $i % 3 == 0 {
      transform: translateY(20%);
     }

  @if $i % 4 == 0 {
      transform: translateY(20%);
     }
     */
}
.grid-item--8 {
  grid-area: item8;
  transition: 0.75s 2s;
  transform: translateX(20%);
  transform: translateX(20%);
  /*
  @if $i % 2 == 0 {
      transform: translateX(-20%);
     }

  @if $i % 3 == 0 {
      transform: translateY(20%);
     }

  @if $i % 4 == 0 {
      transform: translateY(20%);
     }
     */
}
.grid--5 {
  margin-bottom: 30px;
  grid-template-areas: "item1" "item2" "item3" "item4" "item5";
  grid-template-rows: 266px 266px 0px 0px 0px;
}
@media (min-width: 768px) {
  .grid--5 {
    grid-template-areas: "item1 item2" "item1 item2" "item3 item4" "item3 item4" "item5 item5" "item5 item5";
    grid-template-rows: repeat(6, 76px);
  }
}
@media (min-width: 1024px) {
  .grid--5 {
    grid-template-areas: "item1 item2 item3" "item1 item2 item3" "item1 item2 item3" "item4 item2 item3" "item4 item5 item5" "item4 item5 item5";
    padding: 0 25px;
    margin-top: 32px;
    margin-bottom: 40px;
    grid-template-rows: repeat(6, 76px);
  }
}
.grid--5 .gridlayout-item--1 {
  grid-area: item1;
}
.grid--5 .gridlayout-item--2 {
  grid-area: item2;
}
.grid--5 .gridlayout-item--3 {
  grid-area: item3;
}
.grid--5 .gridlayout-item--4 {
  grid-area: item4;
}
.grid--5 .gridlayout-item--5 {
  grid-area: item5;
}
.grid--5 .gridlayout-item--3, .grid--5 .gridlayout-item--4, .grid--5 .gridlayout-item--5 {
  display: none;
}
@media (min-width: 768px) {
  .grid--5 .gridlayout-item--3, .grid--5 .gridlayout-item--4, .grid--5 .gridlayout-item--5 {
    display: initial;
  }
}
.grid--9 {
  grid-template-areas: "item1" "item2" "item3" "item4" "item5" "item6" "item7" "item8" "item9";
  grid-template-rows: 130px 495px 380px 266px 266px 266px 380px 155px 129px;
}
@media (min-width: 768px) {
  .grid--9 {
    grid-template-areas: "item1 item2" "item1 item2" "item1 item2" "item3 item4" "item3 item4" "item3 item4" "item5 item6" "item5 item6" "item5 item6" "item7 item8" "item7 item8" "item7 item8" "item9 item9" "item9 item9" "item9 item9";
    grid-template-rows: repeat(12, 76px);
  }
}
@media (min-width: 1024px) {
  .grid--9 {
    grid-template-areas: "item1 item1 item1" "item1 item1 item1" "item1 item1 item1" "item1 item1 item1" "item2 item3 item4" "item2 item3 item4" "item2 item3 item4" "item2 item3 item7" "item2 item6 item7" "item5 item6 item7" "item5 item6 item7" "item5 item8 item8" "item9 item8 item8" "item9 item8 item8";
    grid-template-rows: repeat(14, 76px);
  }
}
.grid--9 .gridlayout-item--1 {
  grid-area: item1;
}
.grid--9 .gridlayout-item--2 {
  grid-area: item2;
}
.grid--9 .gridlayout-item--3 {
  grid-area: item3;
}
.grid--9 .gridlayout-item--4 {
  grid-area: item4;
}
.grid--9 .gridlayout-item--5 {
  grid-area: item5;
}
.grid--9 .gridlayout-item--6 {
  grid-area: item6;
}
.grid--9 .gridlayout-item--7 {
  grid-area: item7;
}
.grid--9 .gridlayout-item--8 {
  grid-area: item8;
}
.grid--9 .gridlayout-item--9 {
  grid-area: item9;
}
.grid--10 {
  grid-template-areas: "item1" "item2" "item3" "item4" "item5" "item6" "item7" "item8" "item9" "item10";
  grid-template-rows: 266px 129px 266px 380px 155px 380px 266px 495px 129px 129px;
}
@media (min-width: 768px) {
  .grid--10 {
    grid-template-areas: "item1 item2" "item1 item2" "item1 item2" "item3 item4" "item3 item4" "item3 item4" "item5 item6" "item5 item6" "item5 item6" "item7 item8" "item7 item8" "item7 item8" "item9 item10" "item9 item10" "item9 item10";
    grid-template-rows: repeat(12, 76px);
  }
}
@media (min-width: 1024px) {
  .grid--10 {
    grid-template-areas: "item1 item2 item2" "item1 item2 item2" "item1 item2 item2" "item3 item4 item5" "item3 item4 item5" "item3 item4 item8" "item6 item4 item8" "item6 item7 item8" "item6 item7 item8" "item6 item7 item8" "item9 item9 item10" "item9 item9 item10" "item9 item9 item10";
    grid-template-rows: repeat(14, 76px);
  }
}
.grid--10 .gridlayout-item--1 {
  grid-area: item1;
}
.grid--10 .gridlayout-item--2 {
  grid-area: item2;
}
.grid--10 .gridlayout-item--3 {
  grid-area: item3;
}
.grid--10 .gridlayout-item--4 {
  grid-area: item4;
}
.grid--10 .gridlayout-item--5 {
  grid-area: item5;
}
.grid--10 .gridlayout-item--6 {
  grid-area: item6;
}
.grid--10 .gridlayout-item--7 {
  grid-area: item7;
}
.grid--10 .gridlayout-item--8 {
  grid-area: item8;
}
.grid--10 .gridlayout-item--9 {
  grid-area: item9;
}
.grid--10 .gridlayout-item--10 {
  grid-area: item10;
}
.grid--12 {
  grid-template-areas: "item1" "item2" "item3" "item4" "item5" "item6" "item7" "item8" "item9" "item10" "item11" "item12";
  grid-template-rows: 266px 129px 380px 266px 266px 380px 495px 380px 129px 380px 266px 129px;
}
@media (min-width: 768px) {
  .grid--12 {
    grid-template-areas: "item1 item2" "item1 item2" "item1 item2" "item3 item4" "item3 item4" "item3 item4" "item5 item6" "item5 item6" "item5 item6" "item7 item8" "item7 item8" "item7 item8" "item9 item10" "item9 item10" "item9 item10" "item11 item12" "item11 item12" "item11 item12";
    grid-template-rows: repeat(12, 76px);
  }
}
@media (min-width: 1024px) {
  .grid--12 {
    grid-template-areas: "item1 item2 item2" "item1 item2 item2" "item1 item2 item2" "item3 item4 item5" "item3 item4 item5" "item3 item4 item5" "item3 item7 item8" "item6 item7 item8" "item6 item7 item8" "item6 item7 item8" "item6 item7 item10" "item9 item9 item10" "item9 item9 item10" "item9 item9 item10" "item11 item12 item12" "item11 item12 item12" "item11 item12 item12";
    grid-template-rows: repeat(17, 76px);
  }
}
.grid--12 .gridlayout-item--1 {
  grid-area: item1;
}
.grid--12 .gridlayout-item--2 {
  grid-area: item2;
}
.grid--12 .gridlayout-item--3 {
  grid-area: item3;
}
.grid--12 .gridlayout-item--4 {
  grid-area: item4;
}
.grid--12 .gridlayout-item--5 {
  grid-area: item5;
}
.grid--12 .gridlayout-item--6 {
  grid-area: item6;
}
.grid--12 .gridlayout-item--7 {
  grid-area: item7;
}
.grid--12 .gridlayout-item--8 {
  grid-area: item8;
}
.grid--12 .gridlayout-item--9 {
  grid-area: item9;
}
.grid--12 .gridlayout-item--10 {
  grid-area: item10;
}
.grid--12 .gridlayout-item--11 {
  grid-area: item11;
}
.grid--12 .gridlayout-item--12 {
  grid-area: item12;
}

@media (min-width: 1280px) {
  .vignettes {
    padding: 0 35px;
  }
}
.vignettes-slider {
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateY(25px);
  transition: opacity 1s, transform 1s;
}
@media (min-width: 768px) {
  .vignettes-slider {
    padding-bottom: 20px;
  }
}
.vignettes-slider.slick-initialized {
  opacity: 1;
  transform: none;
}
.vignettes-slider .slick-list {
  overflow: visible;
}
@media (min-width: 768px) {
  .vignettes-slider .slick-list {
    overflow: hidden;
  }
}
.vignettes-slider .slick-prev,
.vignettes-slider .slick-next {
  background: #ffffff !important;
}
.vignettes-slider .slick-prev::before,
.vignettes-slider .slick-next::before {
  color: #cd2367 !important;
}
.vignettes-slider .slick-prev {
  left: 0;
}
@media (min-width: 1540px) {
  .vignettes-slider .slick-prev {
    left: -70px;
  }
}
.vignettes-slider .slick-next {
  right: 0;
}
@media (min-width: 1540px) {
  .vignettes-slider .slick-next {
    right: -70px;
  }
}
.vignette-box {
  padding-bottom: 15px;
  width: 100%;
  position: relative;
  min-width: 0;
  transition: opacity 0.6s, transform 0.6s;
  /*
  @include bp(md){
  	width: 33.333%;
  	padding-bottom: 20px;
  }

  @include bp(lg){
  	width: 25%;
  }

  @include bp(xl){
  	width: 20%;
  }

  @include bp(xxl){
  	width: 14.285%;
  }

  @include bp(xxxl){
  	width: 11.111%;
  }

  &--loading{
  	opacity: 0;
  	transform: translateY(-20px) scale(0.7);
  }

  &--image{
  	height: 100%;
  }
  */
}
@media (min-width: 768px) {
  .vignette-box {
    padding-bottom: 40px;
  }
}
.slick-slide .vignette-box {
  width: 100%;
  padding-bottom: 0;
}
.vignette-box--slide {
  padding: 0 10px;
}
@media (min-width: 768px) {
  .vignette-box--slide {
    padding: 0 14px;
  }
}
.vignette-box-image {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  display: block;
  min-height: 222px;
}
.vignette-box-image-source {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vignette {
  position: relative;
  cursor: pointer;
  transition: opacity 0.75s, transform 0.75s;
  transform: perspective(1200px);
  width: 100%;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #e8e6e6;
  border-radius: 4px;
  padding: 45px 5px 5px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0;
  border: 0;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(50, 51, 53, 0.2);
  transition: box-shadow 0.5s;
}
@media (min-width: 768px) {
  .vignette {
    padding: 20px 20px 10px;
    border-radius: 40px;
  }
}
.vignette:hover {
  box-shadow: 0 2px 10px 0 rgba(50, 51, 53, 0.5);
}
.vignette.vignette--const {
  min-height: min-content;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: initial;
}
@media (min-width: 768px) {
  .vignette {
    min-height: min-content;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: initial;
  }
}
.vignette--loading {
  pointer-events: none;
}
.vignettes-list .vignette--loading {
  pointer-events: initial;
}
.vignette-tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 10px;
  margin-bottom: 8px;
}
.vignette--const .vignette-tags {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .vignette-tags {
    margin-bottom: 10px;
  }
}
.vignette-img {
  display: block;
  margin: 0 auto;
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
  object-fit: cover;
  transition: opacity 0.5s, transform 0.5s;
}
.vignette-img-container {
  position: relative;
}
@media (min-width: 768px) {
  .vignette-img {
    width: 140px;
    height: 140px;
  }
}
.vignette-img--hover {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 45px;
  transform: translateX(-20%);
}
@media (min-width: 768px) {
  .vignette-img--hover {
    top: 20px;
  }
}
.vignette:hover .vignette-img {
  opacity: 0;
}
.vignette:hover .vignette-img--hover {
  opacity: 1;
  transform: translateX(-50%);
}
.vignette-details {
  text-align: center;
}
.vignette-brand {
  margin-top: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  min-height: 22px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.vignette-nopharmacy {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 13px;
}
.vignette-promo {
  min-width: 100%;
  flex-wrap: wrap;
}
.vignette-content {
  overflow: hidden;
  width: 100%;
}
.vignette-name {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 40px;
  margin-bottom: 5px;
}
.vignette-name-link {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
@media (min-width: 768px) {
  .vignette-name-link {
    font-size: 16px;
  }
}
.vignette-wish {
  position: absolute;
  right: 9px;
  top: 181px;
  transform: translateY(-100%);
  margin: 0 !important;
}
@media (min-width: 768px) {
  .vignette-wish {
    top: 156px;
    right: 20px;
    top: 162px;
  }
}
.vignette-rating {
  display: flex;
  align-items: center;
  height: 22px;
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  margin-bottom: 25px;
}
.vignette-attrs {
  margin-top: 2px;
}
.vignette-button {
  opacity: 1;
  transition: 0.5s opacity;
}
@media (min-width: 768px) {
  .vignette-button {
    opacity: 0;
  }
}
.vignette:hover .vignette-button {
  opacity: 1;
}
.vignette-nostock {
  background: rgba(61, 61, 61, 0.5);
  z-index: 10;
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  transform: none;
  opacity: 1;
  font-size: 14px;
  text-transform: uppercase;
  transform: translateX(-50%) !important;
  line-height: 1;
  max-width: 100%;
}
.vignette-nostock-content {
  background: rgba(61, 61, 61, 0.5);
  border: 2px solid #ffffff;
  font-weight: bold;
  font-family: "Arial";
  color: #ffffff;
  padding: 12px;
}
.vignette-fidelity {
  margin-top: 4px;
  margin-left: 2px;
  margin-bottom: -14px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
}
.vignette-fidelity-image {
  margin-right: 3px;
  display: block;
}
.vignette-fidelity-text {
  font-weight: bold;
  font-size: 9px;
  color: #cd2367;
}
.vignette-price {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  font-size: 22px;
}
@media (min-width: 768px) {
  .vignette-price {
    font-size: 29px;
  }
}
.vignette-price .price {
  font-weight: bold;
  line-height: 22px;
  margin-bottom: -5px;
}
.vignette-action + .vignette-action {
  margin-left: 10px;
}
.vignette--const .vignette-action + .vignette-action {
  margin-left: 0;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .vignette-action + .vignette-action {
    margin-left: 0;
    margin-top: 10px;
  }
}
.vignette-actions {
  position: absolute;
  z-index: 100;
  right: 7px;
  top: 120px;
  display: flex;
}
.vignette--const .vignette-actions {
  display: block;
}
@media (min-width: 768px) {
  .vignette-actions {
    display: block;
  }
}
.vignette-qty {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
}

input[type=text]::-ms-clear {
  display: none;
}

.requiredfields {
  font-weight: 700;
  text-align: right;
  font-size: 10px;
}

html body.badaboum--custom.badaboum .form-container {
  padding: 30px 0;
  max-width: 728px;
  width: 100%;
  margin: auto;
  position: relative;
}
html body.badaboum--custom.badaboum .form-container--bordertop::before {
  content: "";
  position: absolute;
  height: 1px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid #e8e6e6;
  width: calc(100% - 40px);
  max-width: 728px;
}
html body.badaboum--custom.badaboum .form-separator {
  height: 1px;
  width: 100%;
  background: #e8e6e6;
  margin: 15px 0;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .form-separator {
    margin: 30px 0;
  }
}
html body.badaboum--custom.badaboum .form-item {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5px;
  position: relative;
}
html body.badaboum--custom.badaboum .form-image {
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
}
html body.badaboum--custom.badaboum .form-image img {
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  align-self: center;
}
html body.badaboum--custom.badaboum .form-image img + img {
  width: auto;
  left: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
}
html body.badaboum--custom.badaboum .form-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
html body.badaboum--custom.badaboum .form-title--big {
  font-size: 33px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .form-title--big {
    font-size: 15px;
  }
}
html body.badaboum--custom.badaboum .form-title--down::after {
  transform: rotate(180deg);
  transition: transform 330ms linear;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .form-title {
    margin-bottom: 10px;
  }
}
html body.badaboum--custom.badaboum .form-title::before {
  color: #cd2367;
  font-size: 36px;
  margin-right: 17px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .form-title::before {
    font-size: 25px;
    margin-right: 11px;
  }
}
html body.badaboum--custom.badaboum .form-subtitle {
  font-weight: 700;
  color: #000000;
  font-size: 16px;
  margin-bottom: 10px;
}
html body.badaboum--custom.badaboum .form-text {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 10px;
}
html body.badaboum--custom.badaboum .form-section + html body.badaboum--custom.badaboum .form-section {
  margin-top: 30px;
}
html body.badaboum--custom.badaboum .form-required-message {
  display: flex;
  justify-content: center;
  color: #B71918;
  font-size: 11px;
  margin-bottom: 20px;
  margin-top: -15px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .form-required-message {
    position: absolute;
    margin: 0;
    right: 20px;
    top: 0;
    width: 100%;
    justify-content: flex-end;
  }
}
html body.badaboum--custom.badaboum .form-actions-toolbar {
  margin-top: 30px;
  margin-left: 0;
}
@media (min-width: 0px) and (max-width: 575px) {
  html body.badaboum--custom.badaboum .form-actions-toolbar {
    margin-left: auto;
    margin-right: auto;
  }
}
html body.badaboum--custom.badaboum .form-actions-toolbar--center {
  margin-left: auto;
  margin-right: auto;
}
html body.badaboum--custom.badaboum .form-actions-toolbar--whislist {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 0px) and (max-width: 575px) {
  html body.badaboum--custom.badaboum .form-actions-toolbar--whislist button {
    width: 100%;
  }
}
html body.badaboum--custom.badaboum .form-actions-toolbar--whislist button:nth-child(even) {
  margin: 0 10px;
}
@media (min-width: 0px) and (max-width: 575px) {
  html body.badaboum--custom.badaboum .form-actions-toolbar--whislist button:nth-child(even) {
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
  }
}
html body.badaboum--custom.badaboum .form-actions-toolbar .primary {
  width: 100%;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .form-actions-toolbar .primary {
    margin-right: 30px;
    width: auto;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .form-actions-toolbar .primary button.btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (min-width: 0px) and (max-width: 575px) {
  html body.badaboum--custom.badaboum .form-actions-toolbar .secondary {
    width: 100%;
    text-align: right !important;
  }
  html body.badaboum--custom.badaboum .form-actions-toolbar .secondary .customer-showpassword,
html body.badaboum--custom.badaboum .form-actions-toolbar .secondary a.action {
    text-align: center !important;
  }
}

.field-error,
.message.warning,
.mage-error {
  font-size: 10px;
  color: #B71918;
  margin-top: 3px;
  font-style: italic;
}
.field-error--global,
.message.warning--global,
.mage-error--global {
  display: none;
  margin-top: 15px;
  font-size: 14px;
}
.form-error .field-error--global,
.form-error .message.warning--global,
.form-error .mage-error--global {
  display: inline-block !important;
}

div.mage-error {
  order: 10;
}

.password-strength-meter {
  font-size: 11px;
  padding-top: 3px;
}

.input-label,
.select-label {
  color: #2C2628;
  font-family: "Montserrat";
  display: block;
  font-size: 13px;
  text-align: left;
  margin-bottom: 7px;
  font-weight: 500;
  text-align: left;
}
@media (min-width: 768px) {
  .input-label,
.select-label {
    font-size: 14px;
  }
}
.input-label small,
.select-label small {
  text-transform: none;
}
.input-label::after,
.select-label::after {
  content: "*";
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px;
}
.input-label--no-required::after,
.select-label--no-required::after {
  content: none !important;
}

html body.badaboum--custom.badaboum .input-text,
.input-subject,
.input-file-box,
html body.badaboum--custom.badaboum ._has-datepicker,
html body.badaboum--custom.badaboum .select-box > select,
html body.badaboum--custom.badaboum .select {
  position: relative;
  display: block;
  width: 100%;
  background: #ffffff;
  font-size: 13px !important;
  font-weight: 300;
  z-index: 1;
  height: 50px;
  line-height: 50px;
  color: inherit;
  outline: none;
  border: 1px solid #a8a8a8 !important;
  font-family: "Montserrat";
  transition: border 0.5s, opacity 0.3s, box-shadow 0.3s;
  padding: 0 45px 0 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  border-radius: 0;
}
html body.badaboum--custom.badaboum .input-text--border,
.input-subject--border,
.input-file-box--border,
html body.badaboum--custom.badaboum ._has-datepicker--border,
html body.badaboum--custom.badaboum .select-box > select--border,
html body.badaboum--custom.badaboum .select--border {
  border-color: #cd2367;
}
html body.badaboum--custom.badaboum .input-text:not([disabled]):focus,
.input-subject:not([disabled]):focus,
.input-file-box:not([disabled]):focus,
html body.badaboum--custom.badaboum ._has-datepicker:not([disabled]):focus,
html body.badaboum--custom.badaboum .select-box > select:not([disabled]):focus,
html body.badaboum--custom.badaboum .select:not([disabled]):focus {
  box-shadow: 0 0 2px 1px #cd2367 inset;
  border: 1px solid rgba(223, 68, 130, 0.5) !important;
}
html body.badaboum--custom.badaboum .input-text[disabled],
.input-subject[disabled],
.input-file-box[disabled],
html body.badaboum--custom.badaboum ._has-datepicker[disabled],
html body.badaboum--custom.badaboum .select-box > select[disabled],
html body.badaboum--custom.badaboum .select[disabled] {
  opacity: 0.4;
}
html body.badaboum--custom.badaboum .input-text--secondary,
.input-subject--secondary,
.input-file-box--secondary,
html body.badaboum--custom.badaboum ._has-datepicker--secondary,
html body.badaboum--custom.badaboum .select-box > select--secondary,
html body.badaboum--custom.badaboum .select--secondary {
  border: none;
  background: #e9e5e5 !important;
}
._error html body.badaboum--custom.badaboum .input-text, html body.badaboum--custom.badaboum .input-text.mage-error,
._error .input-subject,
.input-subject.mage-error,
._error .input-file-box,
.input-file-box.mage-error,
._error html body.badaboum--custom.badaboum ._has-datepicker,
html body.badaboum--custom.badaboum ._has-datepicker.mage-error,
._error html body.badaboum--custom.badaboum .select-box > select,
html body.badaboum--custom.badaboum .select-box > select.mage-error,
._error html body.badaboum--custom.badaboum .select,
html body.badaboum--custom.badaboum .select.mage-error {
  border-color: #cd2367 !important;
  margin-top: 0;
  font-style: normal;
}
html body.badaboum--custom.badaboum .input-text::placeholder,
.input-subject::placeholder,
.input-file-box::placeholder,
html body.badaboum--custom.badaboum ._has-datepicker::placeholder,
html body.badaboum--custom.badaboum .select-box > select::placeholder,
html body.badaboum--custom.badaboum .select::placeholder {
  font-family: "Montserrat" !important;
  font-weight: 400;
  color: #2C2628;
  opacity: 1;
}

.select-box > select,
.select {
  line-height: normal;
  padding: 0 45px 0 10px !important;
  width: 100%;
  border: 1px solid #D8D8D8 !important;
  background: #ffffff;
}

.helper {
  cursor: pointer;
}

[type=tel] + .field-tooltip {
  right: 6px;
  top: 32px;
  z-index: 10;
}

.input-mandatory {
  font-size: 12px;
  font-style: normal;
  color: #cd2367;
  font-weight: bold;
  margin-bottom: 20px;
}
.input-mandatory::before {
  content: "* ";
}
.input-mandatory.nobefore::before {
  display: none;
}
.input-tooltip-toggle {
  right: 6px;
  top: 32px;
  z-index: 10;
}
.input-btn {
  height: 39px;
  line-height: 39px;
  font-size: 12px;
  padding: 0 15px;
  width: 100%;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .input-btn {
    width: auto;
  }
}
.input-btn--big {
  border-radius: 0 100px 100px 0;
  margin-left: -2px;
}
.input-box {
  position: relative;
  width: 100%;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  margin-bottom: 20px;
}
.input-box--flex {
  display: flex;
  align-items: center;
  margin-top: 11px;
}
.input-box--flex input {
  flex-grow: 3;
}
.search .input-box {
  margin-bottom: 0;
  margin-top: 0;
}
@media (min-width: 0px) and (max-width: 1279px) {
  .search .input-box::before {
    right: 30px;
  }
}
.input-box + .input-box {
  margin-top: 4px;
}
.input-box[class*=icon-] input {
  padding-right: 45px;
}
@media (min-width: 768px) {
  .input-box[class*=icon-] input {
    padding-right: 55px;
  }
}
.input-box::before {
  z-index: 3;
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 25px;
}
.input-box--btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-box-title {
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 7px;
  padding-left: 10px;
}
.input-box--long {
  width: 450px;
  max-width: 100%;
  margin: 0 auto;
}
.input-box--long input + .mage-error {
  position: absolute;
  bottom: -4px;
  width: 100%;
}
.input-box-label {
  margin-bottom: 0;
  margin-right: 20px;
}
@media (min-width: 1280px) {
  .input-box-label {
    margin-right: 38px;
  }
}
.input-box--btn {
  display: flex;
  margin-top: -5px;
  position: relative;
  width: 100%;
}
@media (min-width: 1024px) {
  .input-box--btn {
    width: auto;
  }
}
@media (min-width: 1024px) {
  .input-box--btn .input-text {
    width: auto;
  }
}
.input-box--btn .mage-error + .mage-error {
  position: absolute;
  left: 0;
  bottom: -18px;
}
.input-box-secondary {
  display: flex;
  align-items: center;
  margin-top: -5px;
  font-size: 11px;
  flex-wrap: wrap;
}
.input-box-secondary > * {
  margin-right: 10px;
  margin-bottom: 5px;
}
.input-box-button {
  margin-left: -4px;
  max-width: none;
  white-space: nowrap;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 50px;
  line-height: 50px;
  position: absolute;
  right: 0;
  top: 26px;
}
.input-box-button .btn.btn--primary {
  height: inherit;
}
.input-subject {
  background: #ffffff;
  text-align: left;
  padding: 0 20px;
}
.input-subject label {
  vertical-align: middle;
}
.input-subject label::after {
  content: " : ";
}
.input-subject .input-text {
  border: none;
  background: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  padding: 0;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.input-subject label + .input-text {
  width: calc(100% - 70px);
}
.input-text {
  padding: 0 45px 0 20px !important;
  background: #ffffff;
  text-overflow: ellipsis;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.input-text[type=password]::-ms-reveal {
  display: none;
}
.input-text:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
}
.input-text[readonly] {
  cursor: default;
}
.input-text:not([readonly]).error {
  border-color: #B71918;
}
.input-text:not([readonly]).valid {
  border-color: #6DB332;
}
.input-text--search {
  font-size: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 0;
  border-radius: 27px;
}
@media (min-width: 0px) and (max-width: 1279px) {
  .input-text--search {
    width: 100%;
  }
}
.input-text--search:focus {
  background: rgba(255, 255, 255, 0.3);
}
.input-text--box {
  align-items: flex-start;
  align-content: flex-start;
  height: auto;
  z-index: 15;
  overflow: hidden;
}
@media (min-width: 768px) {
  .input-text--box {
    border-radius: 30px;
  }
}
.input-text--simple {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  background: none;
  line-height: 1.2;
}
.input-text--big {
  height: 50px;
  line-height: 50px;
  padding: 0 50px 0 10px;
}
@media (min-width: 768px) {
  .input-text--big {
    padding: 0 50px;
  }
}
.input-submit--search {
  position: absolute;
  right: 7px;
  top: 50%;
  z-index: 5;
  background: #cd2367;
  border-radius: 4px;
  height: 38px;
  width: 44px;
  font-size: 0;
}
.input-submit--search::before {
  font-size: 30px;
  content: "\e8b6";
  font-family: "badaboum";
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
}
.input-qty {
  text-align: center;
  width: 53px;
  min-width: 53px;
  height: 38px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
  -moz-appearance: textfield;
  font-weight: 500;
  border-radius: 0;
}
.input-qty-box {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 0 17px;
  width: 100%;
}
.input-qty-box-container {
  text-align: center;
  justify-content: center;
  display: flex;
}
@media (min-width: 768px) {
  .input-qty-box-container {
    display: block;
    flex-wrap: wrap;
  }
}
.input-qty-box-container + .input-qty-box-container {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .input-qty-box-container + .input-qty-box-container {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .input-qty-box {
    justify-content: center;
  }
}
.input-qty-box + .input-qty-box {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .input-qty-box + .input-qty-box {
    margin-top: 0;
  }
}
.input-qty-boxes {
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .input-qty-boxes {
    display: flex;
  }
}
.input-qty.error {
  border-color: #B71918;
}
.input-qty-apply {
  font-size: 12px;
  margin-top: 2px;
  display: none;
}
.input-qty--small {
  height: 27px;
  width: 50px;
  line-height: 27px;
}
.input-qty-button {
  display: inline-block;
  width: 30px;
  height: 38px;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  vertical-align: middle;
  border: 1px #2C2628 solid !important;
  background: #2C2628;
  transition: 0.3s;
  color: #ffffff;
}
.input-qty-button:hover, .input-qty-button:active {
  color: #2C2628;
  background: #ffffff;
}
.input-qty-button--small {
  height: 27px;
  width: 27px;
  min-width: 27px;
}
.input-qty-button::before {
  position: absolute;
  top: 50%;
  left: 50%;
}
.input-qty-button--more {
  border-radius: 0 2px 2px 0;
}
.input-qty-button--more::before {
  content: "+";
}
.input-qty-button--less {
  border-radius: 2px 0 0 2px;
}
.input-qty-button--less::before {
  content: "-";
}
.input-qty[disabled] ~ .input-qty-button {
  background: #2f333a;
  cursor: default;
  outline: 0;
}
.input-qty-label {
  text-align: right;
  margin-right: 20px;
  color: #3d3d3d;
  display: block;
  font-size: 12px;
  margin-bottom: 7px;
  padding-top: 5px;
  font-weight: 700;
  width: 100%;
}
.input-qty-label span {
  font-size: 10px;
  font-weight: 400;
  display: block;
}
@media (min-width: 768px) {
  .input-qty-label span {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .input-qty-label {
    text-align: center;
    justify-content: center;
    margin-right: 0;
  }
}
.input-file {
  display: none;
}
.input-file-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media (min-width: 768px) {
  .input-file-box {
    padding: 5px;
    padding-left: 20px;
    flex-wrap: nowrap;
  }
}
.input-file-button {
  line-height: 1.1;
}
@media (min-width: 0px) and (max-width: 767px) {
  .input-file-button {
    line-height: 25px;
  }
}
.input-file-label {
  cursor: pointer;
  width: 100%;
}
@media (min-width: 768px) {
  .input-file-label {
    margin-right: 10px;
    width: auto;
  }
}
.input-file-image {
  max-width: 100%;
  height: auto;
}
.input-file-trigger::before {
  font-family: "badaboum";
  content: "\e944";
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 25px;
  cursor: pointer;
}
.input-label-required::after {
  content: "*";
  font-size: 100%;
  line-height: 0;
  position: relative;
  top: -0.2em;
}
.input-advice {
  font-size: 12px;
  font-style: italic;
  margin-top: 9px;
  color: #000000;
  text-shadow: 0 0 0 #000;
  text-align: left;
}
@media (min-width: 1024px) {
  .input-advice {
    margin-top: 5px;
    margin-left: 20px;
  }
}
.input-date {
  text-transform: uppercase;
}
.input-date::-webkit-inner-spin-button, .input-date::-webkit-calendar-picker-indicator, .input-date::-webkit-clear-button {
  display: none;
}
.input-date-label {
  text-transform: none;
  font-weight: normal;
  text-align: right;
  padding-top: 15px;
}
.input-date-label::after {
  content: " :";
}

textarea.input-text {
  padding: 10px 10px !important;
  line-height: 1.15;
  resize: vertical;
  min-height: 110px;
}

.select {
  background: #e9e5e5;
  padding: 10px 36px 10px 10px;
  border: none;
}
.select::-ms-expand {
  display: none;
}
.select.error {
  border-color: #B71918;
}
.select.valid {
  border-color: #6DB332;
}
.select-label {
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
  font-size: 14px;
  text-transform: none;
}
.select-label--side {
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 0;
  padding-top: 0;
}
.select-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 80px;
}
.select-box--alone {
  margin-bottom: 0;
}
.select-box::before {
  position: absolute;
  pointer-events: none;
  content: "\e90d";
  font-family: "badaboum";
  right: 18px;
  top: 50%;
  margin-top: -0.65em;
  font-size: 10px;
  z-index: 5;
  color: #cd2367;
  transition: 0.5s;
}
.select-box-small {
  display: inline-block;
  margin-right: 10px;
}
.select-box-small::before {
  font-size: 11px;
  right: 8px;
}
.select-box-small::after {
  right: 24px;
}
.select-box-small .select {
  padding: 0 33px 0 12px;
  height: 37px;
  line-height: 37px;
}
.select-box-smaller {
  display: inline-block;
}
@media (min-width: 0px) {
  .select-box-smaller {
    margin-top: 8px;
  }
}
.select-box-smaller::before {
  font-size: 11px;
  right: 8px;
}
.ie9 .select-box-smaller::before {
  line-height: 22px !important;
  width: 32px !important;
  right: 1px !important;
  padding-left: 8px;
}
.select-box-smaller::after {
  right: 24px;
}
.select-box-smaller .select {
  padding: 0 33px 0 12px;
  height: 24px;
  line-height: 24px;
}
.ie9 .select-box-smaller .select {
  padding-right: 13px;
}
.select-qty-box, .select-size-box {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background: #fff;
  border: 1px solid #2C2628;
}
.select-qty-box::before, .select-size-box::before {
  position: absolute;
  pointer-events: none;
  content: "\e900";
  color: #000000;
  font-family: "badaboum";
  right: 10px;
  top: 50%;
  margin-top: -0.5em;
  font-size: 8px;
}
.select-qty, .select-size {
  background: none;
  padding: 0 40px 0 10px;
  direction: rtl;
  height: 54px;
  line-height: 54px;
  font-size: 20px;
  border: none;
}
.select-qty.error, .select-size.error {
  border-color: #B71918;
}

html body.badaboum--custom.badaboum .radio,
html body.badaboum--custom.badaboum .checkbox {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.radio-title,
.checkbox-title {
  text-align: left;
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  cursor: pointer;
}
@media (min-width: 768px) {
  .radio-title,
.checkbox-title {
    margin-bottom: 15px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
  }
}
html body.badaboum--custom.badaboum .radio-box,
html body.badaboum--custom.badaboum .checkbox-box {
  text-align: left;
  font-size: 0;
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
  position: relative;
}
html body.badaboum--custom.badaboum .radio-box:before, html body.badaboum--custom.badaboum .radio-box:after,
html body.badaboum--custom.badaboum .checkbox-box:before,
html body.badaboum--custom.badaboum .checkbox-box:after {
  content: " ";
  display: table;
}
html body.badaboum--custom.badaboum .radio-box:after,
html body.badaboum--custom.badaboum .checkbox-box:after {
  clear: both;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .radio-box:nth-child(odd),
html body.badaboum--custom.badaboum .checkbox-box:nth-child(odd) {
    margin-bottom: 10px;
  }
}
html body.badaboum--custom.badaboum .radio-box div.field-tooltip,
html body.badaboum--custom.badaboum .checkbox-box div.field-tooltip {
  right: 0;
  top: -10px !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .radio-box div.field-tooltip,
html body.badaboum--custom.badaboum .checkbox-box div.field-tooltip {
    display: none;
  }
}
.input-label + html body.badaboum--custom.badaboum .radio-box,
.input-label + html body.badaboum--custom.badaboum .checkbox-box {
  margin-top: 15px;
}
html body.badaboum--custom.badaboum .radio-box input[type=checkbox].checkbox,
html body.badaboum--custom.badaboum .checkbox-box input[type=checkbox].checkbox {
  display: none;
}
@media (min-width: 768px) {
  .radio-title + .radio-box,
.radio-title + .checkbox-box,
.checkbox-title + .radio-box,
.checkbox-title + .checkbox-box {
    display: inline-block;
    vertical-align: top;
  }
}
.radio-label,
.checkbox-label {
  font-size: 13px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: default;
  user-select: none;
  transition: color 0.3s;
  min-height: 24px;
  font-weight: 300;
  color: #2C2628;
  padding-left: 35px;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .radio-label,
.checkbox-label {
    padding-left: 40px;
  }
}
.radio-label-price,
.checkbox-label-price {
  display: block;
  font-weight: bold;
  font-family: "Montserrat";
  font-size: 16px;
}
.radio-label ~ .radio-label,
.radio-label ~ .checkbox-label,
.checkbox-label ~ .radio-label,
.checkbox-label ~ .checkbox-label {
  margin-left: 40px;
}
@media (min-width: 768px) {
  .radio-label ~ .radio-label,
.radio-label ~ .checkbox-label,
.checkbox-label ~ .radio-label,
.checkbox-label ~ .checkbox-label {
    margin-left: 45px;
  }
}
html body.badaboum--custom.badaboum .radio-label::before, html body.badaboum--custom.badaboum .radio-label::after,
html body.badaboum--custom.badaboum .checkbox-label::before,
html body.badaboum--custom.badaboum .checkbox-label::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  left: 0;
  box-shadow: none;
}
html body.badaboum--custom.badaboum .radio-label::before,
html body.badaboum--custom.badaboum .checkbox-label::before {
  content: "";
  border: 1px solid;
  background: #ffffff !important;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-color: #a8a8a8;
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  margin: 0;
}
html body.badaboum--custom.badaboum .radio-label::after,
html body.badaboum--custom.badaboum .checkbox-label::after {
  content: "";
  left: 4px;
  font-family: "badaboum";
  background: none;
  color: #ffffff;
  height: 18px;
  width: 18px;
}
.radio-label--switch,
.checkbox-label--switch {
  min-height: 26px;
  padding-top: 5px;
  padding-left: 57px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.radio-label--switch::before,
.checkbox-label--switch::before {
  height: 26px;
  width: 50px;
  border-radius: 13px;
  background: #ffffff;
  border: 1px solid #707070;
}
.radio-label--switch::after,
.checkbox-label--switch::after {
  background: #e9e5e5;
  left: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}
.radio-label--top::after, .radio-label--top::before,
.checkbox-label--top::after,
.checkbox-label--top::before {
  top: 12px;
}
.radio-label-top, .radio-label-bottom,
.checkbox-label-top,
.checkbox-label-bottom {
  padding-left: 0;
}
.radio-label-top::before, .radio-label-bottom::before,
.checkbox-label-top::before,
.checkbox-label-bottom::before {
  left: 50%;
}
.radio-label-top::after, .radio-label-bottom::after,
.checkbox-label-top::after,
.checkbox-label-bottom::after {
  left: calc(50% - 4px);
}
.radio-label-top,
.checkbox-label-top {
  padding-top: 24px;
}
.radio-label-bottom,
.checkbox-label-bottom {
  padding-bottom: 24px;
}
.radio-label-bottom::before, .radio-label-bottom::after,
.checkbox-label-bottom::before,
.checkbox-label-bottom::after {
  top: auto;
}
.radio-label-bottom::before,
.checkbox-label-bottom::before {
  bottom: 0;
}
.radio-label-bottom::after,
.checkbox-label-bottom::after {
  bottom: 3px;
}
.radio.mage-error ~ .radio-label::before, .radio.validation-failed + .radio-label::before,
.radio.mage-error ~ .checkbox-label::before,
.radio.validation-failed + .checkbox-label::before,
.checkbox.mage-error ~ .radio-label::before,
.checkbox.validation-failed + .radio-label::before,
.checkbox.mage-error ~ .checkbox-label::before,
.checkbox.validation-failed + .checkbox-label::before {
  border-color: #B71918;
}
.radio ~ .radio-label,
.radio ~ .checkbox-label,
.checkbox ~ .radio-label,
.checkbox ~ .checkbox-label {
  cursor: pointer;
}
.radio ~ .radio-label::after,
.radio ~ .checkbox-label::after,
.checkbox ~ .radio-label::after,
.checkbox ~ .checkbox-label::after {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
html body.badaboum--custom.badaboum .radio:checked ~ .radio-label,
html body.badaboum--custom.badaboum .radio:checked ~ .checkbox-label,
html body.badaboum--custom.badaboum .checkbox:checked ~ .radio-label,
html body.badaboum--custom.badaboum .checkbox:checked ~ .checkbox-label {
  font-weight: 500;
}
html body.badaboum--custom.badaboum .radio:checked ~ .radio-label::after,
html body.badaboum--custom.badaboum .radio:checked ~ .checkbox-label::after,
html body.badaboum--custom.badaboum .checkbox:checked ~ .radio-label::after,
html body.badaboum--custom.badaboum .checkbox:checked ~ .checkbox-label::after {
  opacity: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background: #cd2367;
}
html body.badaboum--custom.badaboum .radio:checked ~ .radio-label--switch::before,
html body.badaboum--custom.badaboum .radio:checked ~ .checkbox-label--switch::before,
html body.badaboum--custom.badaboum .checkbox:checked ~ .radio-label--switch::before,
html body.badaboum--custom.badaboum .checkbox:checked ~ .checkbox-label--switch::before {
  background: #cd2367;
}
html body.badaboum--custom.badaboum .radio:checked ~ .radio-label--switch::after,
html body.badaboum--custom.badaboum .radio:checked ~ .checkbox-label--switch::after,
html body.badaboum--custom.badaboum .checkbox:checked ~ .radio-label--switch::after,
html body.badaboum--custom.badaboum .checkbox:checked ~ .checkbox-label--switch::after {
  left: 24px;
  background-color: #ffffff;
}
.radio-big,
.checkbox-big {
  padding-left: 35px;
  line-height: 25px;
  min-height: 25px;
}
.radio-big::before, .radio-big::after,
.checkbox-big::before,
.checkbox-big::after {
  width: 25px;
  height: 25px;
  border-radius: 2px;
}
.radio-big::after,
.checkbox-big::after {
  font-size: 11px;
  top: 0;
  left: 0;
  text-align: center;
}
.radio-inner,
.checkbox-inner {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  width: 100%;
}

.radio-label-title {
  font-size: 18px;
  margin-bottom: 4px;
  font-weight: bold;
  color: #2C2628;
  display: block;
}
html body.badaboum--custom.badaboum .radio-label::before, html body.badaboum--custom.badaboum .radio-label::after {
  border-radius: 100%;
}
html body.badaboum--custom.badaboum .radio-label::before {
  left: 1px;
  margin-top: 0px;
  width: 24px;
  height: 24px;
}
html body.badaboum--custom.badaboum .radio-label::after {
  left: 4px;
  margin-top: 0;
  font-size: 0;
  opacity: 1;
  background: transparent;
  border: 1px solid;
  transform: translateY(-50%) !important;
  width: 16px;
  height: 16px;
}
.radio-box.checked + .radio-label::after {
  background: #cd2367;
}

.price-range-min, .price-range-max {
  -webkit-transition: background 0.3s;
  -o-transition: background 0.3s;
  transition: background 0.3s;
  padding: 5px;
  margin: -5px;
}
.price-range-min::after, .price-range-max::after {
  content: attr(data-currency) !important;
}
.price-range-min:hover, .price-range-max:hover {
  background: #2C2628;
  cursor: pointer;
}
.price-range-min {
  float: left;
}
.price-range-max {
  float: right;
}
.price-range-current {
  font-size: 12px;
  font-weight: 500;
}
.price-range-current:before, .price-range-current:after {
  content: " ";
  display: table;
}
.price-range-current:after {
  clear: both;
}

.ui-slider {
  margin-top: 5px;
}

.field {
  min-width: 50%;
}
@media (min-width: 768px) {
  .field {
    min-width: unset;
  }
}
.field + .field {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .field + .field {
    margin-bottom: 0px;
    margin-left: 30px;
  }
}
.field-address {
  margin: 0 -15px -10px;
  width: calc(100% + 30px);
}

.opc .fieldset {
  margin-bottom: 10px !important;
}
.fieldset > .label {
  display: none;
}
.fieldset > .field {
  margin: 0 0 20px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .fieldset > .field {
    margin: 0 0 15px;
  }
}
.opc-wrapper .fieldset > .field {
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

html body.badaboum--custom.badaboum .qty {
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
}
html body.badaboum--custom.badaboum .qty:not(th):not(td) {
  display: flex;
}
.qty--small {
  justify-content: flex-start !important;
  margin: 0 !important;
}
html body.badaboum--custom.badaboum .qty-input {
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  width: 55px;
  padding: 0 5px;
  height: 40px;
  line-height: 40px;
}
.qty--small .qty-input {
  font-size: 10px;
  width: 70px;
  height: 50px;
  margin: 0 10px;
  border: 1px solid #D8D8D8;
}
.qty-btn {
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  transition: 0.5s;
  color: #2C2628;
  cursor: pointer;
  background-color: #f4f3e9;
}
.qty-btn:hover {
  background: #dfddcc;
}
.qty-btn--minus::before {
  content: "-";
}
.qty-btn--plus::before {
  content: "+";
}
.qty--small .qty-btn {
  font-size: 14px;
  width: 38px;
  height: 40px;
  min-width: 26px;
}

body .pac-container::after {
  display: none !important;
}
body .pac-item {
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.cluster {
  width: 50px !important;
  height: 50px !important;
}
.cluster img {
  clip: rect(0px, 55px, 55px, 0px) !important;
  width: 100% !important;
}
.cluster div {
  height: 100% !important;
  width: 100% !important;
}
.cluster span {
  font-weight: bold;
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.locator-header {
  background: #e9e5e5;
  border-radius: 4px;
}
.locator-header-search {
  margin-top: 0;
  margin-bottom: 20px;
  position: relative;
}
.locator-header-search::before, .locator-header-search-geoloc {
  z-index: 3;
  font-size: 21px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.locator-header-search::before {
  display: none;
  color: #cd2367;
  left: 20px;
  right: auto;
}
@media (min-width: 768px) {
  .locator-header-search::before {
    display: block;
  }
}
.locator-header-search-geoloc {
  right: 131px;
  left: auto;
}
@media (min-width: 768px) {
  .locator-header-search-geoloc {
    right: 155px;
  }
}
.locator-header-filters-content {
  margin-bottom: -13px;
}
.locator {
  padding: 35px 0 0;
  position: relative;
  z-index: 50;
  min-height: 312px;
}
@media (min-width: 768px) {
  .locator {
    padding: 35px 10px 0px;
    min-height: 377px;
  }
}
.locator::before {
  height: 132px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  max-height: 545px;
}
@media (min-width: 768px) {
  .locator::before {
    height: 455px;
    background-size: 60%;
    width: 2214px;
    border-radius: 50%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.locator--partial {
  margin-bottom: 376px;
}
.locator--popin {
  z-index: 500;
  position: fixed;
  left: 0;
  width: 100%;
  background: none;
  padding-top: 0;
  padding-bottom: 40px;
  overflow: auto;
  height: 100vh;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s, padding 0.5s;
}
@media (min-width: 768px) {
  .locator--popin {
    padding-top: 10px;
  }
}
@media (min-width: 1540px) {
  .locator--popin {
    padding-top: 90px;
  }
}
.locator--popin::before {
  content: none;
  display: none;
}
.locator--popin.active {
  padding-top: 50px;
  opacity: 1;
  pointer-events: initial;
}
@media (min-width: 768px) {
  .locator--popin.active {
    padding-top: 80px;
  }
}
@media (min-width: 1540px) {
  .locator--popin.active {
    padding-top: 190px;
  }
}
.locator--popin .locator-content {
  margin-left: 0;
  width: 100%;
}
.locator--popin .locator-list {
  margin-left: -15px;
  width: calc(100% + 30px);
  background: transparent;
}
@media (min-width: 0px) and (max-width: 767px) {
  .locator--popin .locator-selector {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
.locator-main {
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .locator-main {
    margin-bottom: 35px;
  }
}
.locator-overlay {
  position: fixed;
  background: #2C2628;
  opacity: 0.7;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  width: auto;
  max-height: none;
  height: 100vh;
  border-radius: 0;
  transform: none;
  z-index: -1;
}
.locator-cancel {
  position: fixed;
  bottom: 30px;
  width: 100%;
  left: 0;
  margin-top: 40px;
  z-index: -1;
}
@media (min-width: 768px) {
  .locator-cancel {
    bottom: initial;
    position: absolute;
  }
}
.locator-title {
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 55px;
}
@media (min-width: 768px) {
  .locator-title {
    font-size: 36px;
  }
}
.locator-map {
  width: 100%;
  height: 225px;
}
@media (min-width: 768px) {
  .locator-map {
    height: 500px;
  }
}
.locator-map-chosen {
  background: #e9e5e5;
}
.locator-map-chosen.active {
  padding: 20px;
}
.locator-content {
  width: calc(100% + 26px);
  margin-left: -13px;
  padding: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .locator-content {
    transition: margin 0.4s 0.1s;
  }
  .locator-content.active {
    margin-bottom: -200px;
  }
}
@media (min-width: 768px) {
  .locator-content {
    width: 100%;
    margin: 0;
    padding: 0 30px;
  }
}
@media (min-width: 1024px) {
  .locator-content {
    padding: 0 65px;
  }
}
.locator-content-section {
  display: none;
  width: 100%;
}
.locator-content-section-content {
  position: relative;
}
:checked + .locator-content-section {
  display: block;
}
.locator-content-section-map {
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s;
  max-height: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .locator-content-section-map .gmnoprint {
    display: none !important;
  }
  .locator-content-section-map .gm-fullscreen-control {
    display: none !important;
  }
}
.locator-content-section-map.active {
  max-height: 443px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .locator-content-section-map {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .locator-content-section-map, .locator-content-section-map.active {
    margin: 0;
    max-height: none;
  }
}
.locator-content-section-map-toggle {
  margin-top: -36px;
  z-index: 10;
  transform: translateY(76px);
}
.locator-content-section:not(.locator-content-section--partial) .locator-content-section-map-toggle, .locator-content-section-map-toggle.active {
  opacity: 0;
  pointer-events: none;
}
@media (min-width: 768px) {
  .locator-content-section-map-toggle {
    display: none;
  }
}
.locator-content-section-map-back {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
}
@media (min-width: 768px) {
  .locator-content-section-map-back {
    display: none;
  }
}
.locator-content-section:not(.locator-content-section--partial) .locator-content-section-main {
  width: 100%;
}
.locator-content-section:not(.locator-content-section--partial) .locator-content-section-map {
  display: none;
}
.locator-content-section:not(.locator-content-section--partial) .locator-content-section-title {
  display: block;
}
.locator-content-section-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 17px 10px;
}
@media (min-width: 768px) {
  .locator-content-section-title {
    display: none;
  }
}
.locator-input {
  width: 100%;
}
.locator-input, .locator-input:valid {
  border: 0;
}
@media (min-width: 768px) {
  .locator-input {
    height: 60px;
  }
}
.locator-input-container {
  position: relative;
  width: 100%;
  max-height: 50px;
  overflow: visible;
  transition: opacity 1s;
}
.locator-content-section:not(.locator-content-section--partial) .locator-input-container--home {
  display: block;
}
@media (min-width: 768px) {
  .locator-input-container--home {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .locator-input-container.active {
    opacity: 0;
    pointer-events: none;
  }
}
.locator-input-reset {
  position: absolute;
  z-index: 5;
  width: 37px;
  height: 37px;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  background: #e9e5e5;
  pointer-events: initial;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
}
@media (min-width: 768px) {
  .locator-input-reset {
    width: 48px;
    height: 48px;
    right: 7px;
    top: 6px;
  }
}
.locator-input-reset:hover {
  background: #e8e6e6;
  color: #ffffff;
}
:placeholder-shown ~ .locator-input-reset {
  opacity: 0;
  pointer-events: none;
}
.locator-input-reset::before {
  font-size: 13px;
  font-family: "badaboum";
  content: "\e90e";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .locator-input-reset::before {
    font-size: 18px;
  }
}
.locator-input-geoloc {
  position: absolute;
  top: 17px;
  right: 75px;
  line-height: 1.2;
  z-index: 10;
  transition: right 0.3s;
  display: none;
}
@media (min-width: 768px) {
  .locator-input-geoloc {
    top: 22px;
    display: flex;
  }
  :placeholder-shown ~ .locator-input-geoloc {
    right: 25px;
  }
}
.locator-input-geoloc--btn {
  display: flex;
  padding: 0;
  top: 5px;
  right: 5px;
  transition: 0.3s;
  pointer-events: none;
  opacity: 0;
}
:placeholder-shown ~ .locator-input-geoloc--btn {
  opacity: 1;
  pointer-events: initial;
}
@media (min-width: 768px) {
  .locator-input-geoloc--btn {
    display: none;
  }
}
.locator-input-results {
  display: none;
  margin-left: -20px;
  margin-right: -20px;
}
.locator-input-results-item {
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.5s;
}
.locator-input-results-item:hover {
  transition: 0s;
}
.locator-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 142px;
}
@media (min-width: 768px) {
  .locator-box {
    min-height: 231px;
  }
}
@media (min-width: 1280px) {
  .locator-box {
    min-height: 241px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .locator-box {
    padding: 30px 15px;
  }
}
.locator-selector {
  width: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff;
  border-radius: 33px;
  margin-top: -57px;
  margin-bottom: 2px;
  margin-left: auto;
  margin-right: auto;
  z-index: 20;
}
@media (min-width: 768px) {
  .locator-selector {
    width: 100%;
    margin-top: -73px;
    margin-bottom: 40px;
    margin-left: 0;
  }
}
.locator-selector-item {
  font-size: 14px;
  padding: 5px 23px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  cursor: pointer;
  transition: 0.5s;
  width: 50%;
}
@media (min-width: 768px) {
  .locator-selector-item {
    font-size: 14px;
    padding: 10px;
  }
}
@media (min-width: 1280px) {
  .locator-selector-item {
    padding: 10px 75px;
    font-size: 22px;
  }
}
.locator-selector-item ~ .locator-selector-item {
  margin-left: 20px;
}
.locator-selector-item::before {
  margin-right: 10px;
  line-height: 1;
  font-size: 26px;
}
@media (min-width: 768px) {
  .locator-selector-item::before {
    margin-right: 10px;
    font-size: 26px;
  }
}
@media (min-width: 1280px) {
  .locator-selector-item::before {
    margin-right: 22px;
    font-size: 36px;
  }
}
.locator-selector-item:hover {
  background: #e9e5e5;
}
:checked + .locator-selector-item {
  color: #ffffff;
  background: #cd2367;
}
.locator-list {
  height: 440px;
  overflow: auto;
  z-index: 10;
  width: 100%;
  padding: 0 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .locator-list {
    transition: margin 0.5s;
  }
}
.locator-list.active {
  background: transparent;
}
@media (min-width: 768px) {
  .locator-list.active {
    margin-top: 25px;
  }
}
.locator-list-header {
  padding: 20px;
  border-bottom: 2px solid #e8e6e6;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
}
.locator-list-header-count {
  color: #cd2367;
}
.locator-list-background {
  position: relative;
  background: #e9e5e5;
  width: 100%;
}
.locator-list-background::before {
  position: absolute;
  height: 30px;
  left: 0;
  top: 30px;
  z-index: -1;
  background: #e9e5e5;
  width: 100%;
}
.locator--popin .locator-list-background::before {
  display: none;
}
.locator-content-section--partial .locator-list-background::before {
  height: 150px;
}
@media (min-width: 768px) {
  .locator-list-background::before {
    display: none;
  }
}
@media (min-width: 768px) {
  .locator-list {
    width: 100%;
    position: initial;
    left: 0;
  }
}
.locator-list-item {
  cursor: pointer;
  width: 100%;
  transition: 0.3s;
  padding: 20px 10px;
  font-size: 12px;
  position: relative;
}
.locator-list-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: calc(100% - 20px);
  height: 1px;
  background: #e8e6e6;
}
.locator-list-item:first-child::before {
  display: none;
}
.locator-list-item[data-index] {
  cursor: pointer;
}
.locator-list-item + .locator-list-item {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .locator-list-item + .locator-list-item {
    margin-top: 0;
  }
}
.locator-list-item:hover {
  background: #ffffff;
  transition: 0.1s;
}
.locator-list-item--active {
  background: #ffffff !important;
}
.locator-list-item-img {
  border-radius: 4px;
  width: 30px;
  max-width: 30px;
  height: 30px;
  margin-right: 10px;
  align-self: flex-start;
}
@media (min-width: 768px) {
  .locator-list-item-img {
    align-self: center;
    border-radius: 50%;
    width: 65px;
    max-width: 65px;
    height: 65px;
    margin-right: 35px;
  }
}
.locator-list-item-image {
  margin-right: 20px;
  width: 75px;
  max-width: 75px;
  border-radius: 5px;
}
.locator-list-item-content {
  font-size: 12px;
  line-height: 19px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.locator-list-item-name {
  transition: 0.1s;
  font-weight: bold;
  font-size: 16px;
  color: #cd2367;
}
@media (min-width: 768px) {
  .locator-list-item-name {
    color: #2C2628;
  }
}
.locator-list-item-detail {
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  align-items: flex-start;
  margin-top: 4px;
  line-height: 1.3;
}
.locator-list-item-details {
  margin-bottom: 8px;
}
@media (min-width: 1024px) {
  .locator-list-item-detail--inline {
    display: inline-flex;
    margin-right: 15px;
  }
}
.locator-list-item-detail::before {
  margin-right: 5px;
  font-size: 12px;
  color: #cd2367;
}
.locator-list-item-slot {
  margin-top: 5px;
  font-weight: bold;
  padding-left: 17px;
}
.locator-list-item-slot--value {
  color: #cd2367;
  margin-top: 0;
}
.locator-list-item-distance {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.7;
  display: flex;
  align-items: center;
}
.locator-list-item-distance::after {
  font-size: 17px;
  margin-left: 5px;
  position: relative;
  top: 1px;
}
.locator-list-item-address--emphasis {
  opacity: 0.7;
}
@media (min-width: 768px) {
  .locator-list-item-address--emphasis {
    opacity: 1;
    font-weight: bold;
  }
}
.locator-list-item-schedule {
  font-weight: bold;
  line-height: 1.2;
  margin-top: 5px;
}
.locator-list-item-schedule::before {
  content: "•";
  margin-right: 3px;
}
.locator-list-item-schedule--open {
  color: #6DB332;
}
.locator-list-item-schedule--closed {
  color: #B71918;
}
.locator-list-item-schedule--big {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: -10px;
}
.locator-list-item-slot {
  font-weight: bold;
}
.locator-list-item-reinsurances {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.locator-list-item-reinsurance {
  margin: 0 10px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.locator-list-item-reinsurance::before {
  color: #ffffff;
  background: #cd2367;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  border-radius: 50%;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
}
.locator-band {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 1024px) {
  .locator-band {
    align-items: center;
    flex-direction: row;
  }
}
.locator-band-content {
  padding-right: 20px;
}
.locator-band-main {
  display: flex;
  align-items: flex-start;
}
.locator-band-btn {
  flex-shrink: 0;
  margin: 10px auto 0;
}
@media (min-width: 1024px) {
  .locator-band-btn {
    margin: 0 0 0 auto;
  }
}
@media (min-width: 1024px) {
  .locator-band-image {
    margin-right: 30px;
    width: 120px;
    max-width: 120px;
    height: 80px;
    flex-shrink: 0;
  }
}
.locator-home {
  display: none;
  margin: 20px -12px -50px;
  padding: 0 12px 20px;
  background: #e9e5e5;
}
.locator-content-section:not(.locator-content-section--partial) .locator-home {
  display: none !important;
}
@media (min-width: 768px) {
  .locator-home {
    margin: 0;
    padding: 0;
    background: none;
  }
}
.locator-home-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  top: 10px;
  position: relative;
}
@media (min-width: 768px) {
  .locator-home-title {
    font-weight: normal;
    font-size: 16px;
    margin-top: 0 !important;
    top: 0;
  }
}
.locator-home-title--rmg {
  top: 0;
}
.locator-home-back {
  justify-content: center;
}
.locator-home-head {
  padding: 15px 10px 20px;
  margin-bottom: 20px;
  background: #e9e5e5;
  max-width: 775px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .locator-home-head {
    padding: 15px 45px 20px;
  }
}
.locator-home-head-address {
  text-align: center;
}
.locator-home-head-available {
  font-weight: bold;
  text-align: center;
  color: #6DB332;
  margin-top: 7px;
  text-align: center;
  font-size: 14px;
}
.locator-home-head-available--not {
  color: #B71918;
}
.locator-home-head-availability {
  text-align: center;
  font-size: 16px;
}
.locator-home-head-availability span {
  font-weight: bold;
}
.locator-home-head-section + .locator-home-head-section {
  margin-top: 15px;
  padding: 10px 10px 20px 10px;
  border-radius: 4px;
  background: #ffffff;
}
@media (min-width: 768px) {
  .locator-home-head-section + .locator-home-head-section {
    border-top: 1px solid rgba(112, 112, 112, 0.5);
    padding: 20px 0 0 0;
    background: none;
  }
}
.locator-home-head-store {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .locator-home-head-store {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .locator-home-head-store {
    flex-wrap: wrap;
  }
  .locator-home-head-store > :last-child {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}
.locator-home-head-store-details {
  font-size: 12px;
  padding-right: 15px;
}
.locator-home-head-store-details-title {
  font-weight: bold;
}
.locator-home-head-store-details-text {
  opacity: 0.7;
  margin-top: 7px;
}
.locator-home-head-store-btn {
  margin-left: auto;
}
.locator-home-detail {
  width: 100%;
  background: #e9e5e5;
  padding: 10px 35px;
  margin-top: 10px;
}
.locator-home-detail + .locator-home-detail {
  margin-top: -10px;
  margin-bottom: 10px;
  padding-top: 0;
}
@media (min-width: 768px) {
  .locator-home-detail {
    padding: 0;
    margin: 10px 50px 30px 50px !important;
    flex-wrap: nowrap;
    width: 225px;
    background: none;
  }
}
.locator-home-details {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  width: calc(100% - 20px);
  margin-left: 10px;
  background: #ffffff;
  margin-top: -40px;
  padding: 0 15px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s;
}
.locator-home-details.active {
  max-height: 194px;
}
@media (min-width: 768px) {
  .locator-home-details {
    max-height: none;
    width: 100%;
    margin: 0;
    padding: 10px 35px;
    padding: 0;
    background: none;
  }
}
.locator-home-detail-title {
  font-weight: bold;
  color: #6DB332;
  margin-bottom: 15px;
  display: none;
}
@media (min-width: 768px) {
  .locator-home-detail-title {
    display: block;
  }
}
.locator-home-detail-value {
  font-size: 12px;
  padding: 5px 0;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .locator-home-detail-value {
    padding: 5px 9px;
  }
}
.locator-home-detail-value-amount {
  margin-left: auto;
}
@media (min-width: 768px) {
  .locator-home-detail-values {
    border-left: 1px solid #e8e6e6;
  }
}
.locator-home-first.active {
  display: none;
}
.locator-home-list {
  height: 300px;
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
  overflow: auto;
}
.locator-home-list-container {
  display: none;
}
.locator-content-section:not(.locator-content-section--partial) .locator-home-list-container--rmg {
  display: none !important;
}
.locator-home-list-container.active {
  display: block;
}
@media (min-width: 768px) {
  .locator-home-list {
    height: 275px;
  }
}
@media (min-width: 1280px) {
  .locator-home-list {
    padding: 0 100px;
  }
}

.location-map {
  height: 250px;
}
.location-header {
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 1024px) {
  .location-header {
    flex-wrap: nowrap;
  }
}
.location-header-image {
  margin-right: 40px;
  border-radius: 5px;
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .location-header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 125px;
    height: 105px;
  }
}
.location-header-details {
  width: 100%;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .location-header-details-main {
    width: 100%;
    min-height: 105px;
    margin-bottom: 40px;
    padding-left: 135px;
  }
}
.location-header-line {
  width: 100%;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .location-header-line {
    padding: 0 !important;
  }
}
.location-header-line + .location-header-line {
  margin-top: 20px;
}
.location-header-item {
  display: block !important;
  font-weight: 500;
  word-break: break-word;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .location-header-item {
    margin-top: 0;
  }
}
.location-header-item-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #cd2367;
  font-weight: bold;
  margin-bottom: 7px;
}
.location-header-item-label::before {
  font-size: 13px;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .location-header-schedule {
    columns: 2;
    column-gap: 40px;
  }
}
@media (min-width: 1280px) {
  .location-header-schedule {
    columns: 3;
    column-gap: 95px;
  }
}
.location-header-schedule-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  break-inside: avoid-column;
}
.location-header-schedule-item > :first-child {
  font-weight: bold;
}

html body.badaboum--custom.badaboum .cart-header-text {
  display: flex;
  margin-left: 30px;
  font-size: 24px;
  font-weight: bold;
}
html body.badaboum--custom.badaboum .cart-header-text::before {
  margin-right: 7px;
  font-size: 26px;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-header-text {
    margin-left: 0;
  }
}
html body.badaboum--custom.badaboum .cart-action {
  display: flex;
  width: auto;
  justify-content: flex-start;
  margin-top: 40px;
}
html body.badaboum--custom.badaboum .cart-action-empty {
  font-size: 11px;
}
html body.badaboum--custom.badaboum .cart-suggestions {
  margin-top: 60px;
  padding: 32px 0 40px 0;
  background-color: #ffffff;
  width: 100vw;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-suggestions {
    display: none;
  }
}
html body.badaboum--custom.badaboum .cart-suggestions-title {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 23px;
}
html body.badaboum--custom.badaboum .cart-suggestions-title::before {
  color: #cd2367;
  font-size: 33px;
  margin-right: 15px;
}
html body.badaboum--custom.badaboum .cart-suggestions-products {
  display: flex;
  flex-direction: row;
  max-width: 1137px;
  overflow: scroll;
}
html body.badaboum--custom.badaboum .cart-suggestions-products .item-box:last-child {
  padding-right: 0;
}
html body.badaboum--custom.badaboum .cart-msidebar-totals {
  display: flex;
  flex-direction: column;
  align-items: center;
}
html body.badaboum--custom.badaboum .cart-msidebar-total, html body.badaboum--custom.badaboum .cart-msidebar-discount {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
@media (min-width: 1280px) and (max-width: 1919px) {
  html body.badaboum--custom.badaboum .cart-msidebar-total, html body.badaboum--custom.badaboum .cart-msidebar-discount {
    display: none;
  }
}
html body.badaboum--custom.badaboum .cart-msidebar-total {
  background-color: #e9e5e5;
  font-size: 20px;
  font-weight: bold;
  padding: 7px 25px;
  margin: 40px 0 7px 0;
}
html body.badaboum--custom.badaboum .cart-msidebar-total--sticky {
  margin: 10px auto;
}
html body.badaboum--custom.badaboum .cart-msidebar-discount {
  font-size: 16px;
  font-weight: bold;
  color: #B71918;
  margin-bottom: 32px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-promo-container {
    padding: 20px;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar {
    align-items: center;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-sidebar .box {
    padding: 20px;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-summary {
  top: 80px;
  height: fit-content;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .cart-sidebar-summary {
    position: sticky;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-content, html body.badaboum--custom.badaboum .cart-sidebar-promotions {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 300px;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-content, html body.badaboum--custom.badaboum .cart-sidebar-promotions {
    width: 100%;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-content {
  margin-top: 26px;
  min-height: 427px;
  background-color: #ffffff;
}
html body.badaboum--custom.badaboum .cart-sidebar-content--checkout {
  margin-top: 0;
}
html body.badaboum--custom.badaboum .cart-sidebar-user {
  display: flex;
  justify-content: center;
  margin-top: 26px;
  font-size: 14px;
  font-weight: bold;
  color: #2C2628;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-user {
    display: none;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-user::before {
  margin-right: 12px;
  font-size: 18px;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-user--mobile {
    display: flex;
    justify-content: center;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-discount, html body.badaboum--custom.badaboum .cart-sidebar-promo {
  margin-top: 11px;
}
html body.badaboum--custom.badaboum .cart-sidebar-discount {
  color: #B71918;
}
html body.badaboum--custom.badaboum .cart-sidebar-promo {
  color: #cd2367;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-promo {
    display: none !important;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-promo-title {
  font-size: 12px;
}
html body.badaboum--custom.badaboum .cart-sidebar-promo-action {
  font-size: 10px;
  margin-top: 3px;
  width: max-content;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-promo-action {
    display: none;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-total, html body.badaboum--custom.badaboum .cart-sidebar-discount, html body.badaboum--custom.badaboum .cart-sidebar-promo, html body.badaboum--custom.badaboum .cart-sidebar-shipping {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-total, html body.badaboum--custom.badaboum .cart-sidebar-discount, html body.badaboum--custom.badaboum .cart-sidebar-promo, html body.badaboum--custom.badaboum .cart-sidebar-shipping {
    display: none;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-total-title, html body.badaboum--custom.badaboum .cart-sidebar-discount-title, html body.badaboum--custom.badaboum .cart-sidebar-promo-title, html body.badaboum--custom.badaboum .cart-sidebar-shipping-title {
  width: 50%;
}
html body.badaboum--custom.badaboum .cart-sidebar-total-price, html body.badaboum--custom.badaboum .cart-sidebar-discount-price, html body.badaboum--custom.badaboum .cart-sidebar-promo-price, html body.badaboum--custom.badaboum .cart-sidebar-shipping-price {
  margin-left: auto;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-total--mobile, html body.badaboum--custom.badaboum .cart-sidebar-discount--mobile, html body.badaboum--custom.badaboum .cart-sidebar-promo--mobile, html body.badaboum--custom.badaboum .cart-sidebar-shipping--mobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-shipping {
  font-size: 14px;
  margin-top: 10px;
}
html body.badaboum--custom.badaboum .cart-sidebar-totals {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  font-size: 19px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .cart-sidebar-totals {
    font-weight: 500;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-sidebar-totals {
    font-size: 16px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-sidebar-totals.shipping {
    font-size: 14px;
  }
  html body.badaboum--custom.badaboum .cart-sidebar-totals.shipping .value {
    font-weight: 400;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-totals td:before {
  content: none !important;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-totals--mobile {
    display: flex;
    flex-direction: column;
    margin: 20px auto 8px auto;
    height: 55px;
    width: 200px;
    border-radius: 4px;
    background-color: #e9e5e5;
    padding: 7px 25px;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-totals .mark {
  font-weight: 500;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-sidebar-totals .mark {
    font-size: 20px;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-totals.grand.totals {
  padding-top: 20px;
}
html body.badaboum--custom.badaboum .cart-sidebar-totals.grand.totals .amount .cart-sidebar-price {
  font-size: 16px;
  font-weight: 400;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-sidebar-totals.grand.totals .amount .cart-sidebar-price {
    font-size: 17px;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-totals .discount {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .cart-sidebar-totals .shipping.mark {
  padding: 0px 20px 0px 0px;
  font-weight: 400;
}
html body.badaboum--custom.badaboum .cart-sidebar-totals .shipping.mark .value {
  font-size: 12px;
}
html body.badaboum--custom.badaboum .cart-sidebar-subtotal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  font-size: 17px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .cart-sidebar-subtotal {
    font-size: 16px;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-subtotal .mark {
  font-weight: 400;
}
html body.badaboum--custom.badaboum .cart-sidebar-subtotal td::before {
  content: none !important;
}
html body.badaboum--custom.badaboum .cart-sidebar-price {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
html body.badaboum--custom.badaboum .cart-sidebar-including {
  font-size: 15px;
  font-style: italic;
  width: 100%;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .cart-sidebar-including {
    display: none;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-saving {
  font-size: 16px;
  font-weight: bold;
  color: #B71918;
  margin: 0 auto;
  padding-bottom: 25px;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .cart-sidebar-saving {
    display: none;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity {
  padding: 10px;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-content {
  background-color: #e9e5e5;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 17px 20px 0 0;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-header-img {
  width: 100px;
  height: 56px;
  object-fit: contain;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-header-text {
  font-weight: bold;
  max-width: 144px;
  font-size: 16px;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-header-text--big {
  font-size: 23px;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-header-text--green {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card {
  padding: 0 12px 20px 12px;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card-title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin: 17px 0 12px 0;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card .price {
  font-size: 20px;
  margin: 12px auto 16px auto;
  max-width: 215px;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card .price--fidelity {
  padding: 15px 14px;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card .price--big {
  margin: 12px auto 16px auto;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card-description {
  text-align: center;
  font-size: 12px;
  line-height: 15px;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card-description--bold, html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card-description--green {
  font-weight: bold;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card-description--green {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .cart-sidebar-fidelity-card-conditions {
  font-style: italic;
  font-size: 10px;
  text-align: center;
}
html body.badaboum--custom.badaboum .cart-sidebar-coupon-text {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}
html body.badaboum--custom.badaboum .cart-sidebar-coupon-input {
  position: relative;
  padding: 0 10px;
}
html body.badaboum--custom.badaboum .cart-sidebar-coupon-input::before {
  background: #2C2628;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  z-index: 15;
  cursor: pointer;
  content: "OK" !important;
  font-size: 16px;
  font-weight: bold;
}
html body.badaboum--custom.badaboum .cart-sidebar-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
html body.badaboum--custom.badaboum .cart-sidebar-actions .action {
  font-size: 15px;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-sidebar-actions {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-validate {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0 40px 20px 40px;
}
html body.badaboum--custom.badaboum .cart-sidebar-validate--sticky {
  margin-top: 0;
}
html body.badaboum--custom.badaboum .cart-sidebar-promotions-shipping {
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 15px;
  margin-top: -15px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-sidebar-promotions-shipping {
    margin-top: 0;
    font-size: 15px;
    line-height: 1.2;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-bottom {
  background: transparent !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-sidebar-bottom {
    width: 100%;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-bottom-text {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding-left: 15px;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-sidebar-bottom-text {
    font-weight: 500;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .cart-sidebar-bottom-text {
    font-size: 14px;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-bottom-text-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}
html body.badaboum--custom.badaboum .cart-sidebar-bottom-text-wrapper::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-sidebar-bottom-text-wrapper::before {
    top: 5px;
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-bottom-text span {
  color: #6DB332;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
html body.badaboum--custom.badaboum .cart-sidebar-bottom-partners--checkout {
  padding-top: 20px;
  background: #f8f8f8;
}
html body.badaboum--custom.badaboum .cart-sidebar-bottom-partners--checkout > div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px 20px;
  grid-template-rows: repeat(3, 50px);
  justify-items: center;
}
@media (min-width: 768px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-sidebar-bottom-partners--checkout > div {
    grid-template-rows: repeat(1, 50px);
    grid-template-columns: repeat(14, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .cart-sidebar-bottom-partners--checkout > div.cart {
    grid-template-rows: repeat(1, 50px);
    grid-template-columns: repeat(14, 1fr);
  }
}
html body.badaboum--custom.badaboum .cart-sidebar-bottom-partners--checkout > div .img-wrapper {
  height: 40px;
}
html body.badaboum--custom.badaboum .cart-body-item-name-container {
  display: flex;
  gap: 10px;
}
html body.badaboum--custom.badaboum .cart-product-name {
  font-size: 14px;
  color: #2C2628;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
}
html body.badaboum--custom.badaboum .cart-product-name-link {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.6;
  font-size: 14px;
}
html body.badaboum--custom.badaboum .cart-product-sku {
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
}
html body.badaboum--custom.badaboum .cart-product-dropshipping {
  background-color: #cd2367;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 0;
  padding: 7px;
}
html body.badaboum--custom.badaboum .cart-table-head {
  background: #f8f8f8;
  color: #3d3d3d;
  font-size: 14px;
}
html body.badaboum--custom.badaboum .cart-table-head-subtotal {
  width: 20%;
  padding-right: 20px;
  padding-left: 20px;
}
html body.badaboum--custom.badaboum .cart-table-head-qty, html body.badaboum--custom.badaboum .cart-table-head-price {
  width: 15%;
}
html body.badaboum--custom.badaboum .cart-table-head-price {
  font-weight: 500 !important;
  text-align: center !important;
}
html body.badaboum--custom.badaboum .cart-table-body {
  border-top: 10px solid #f8f8f8;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-table-body {
    border-top: 10px solid #f8f8f8;
    position: relative;
  }
}
html body.badaboum--custom.badaboum .cart-table-body td {
  padding: 0;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body td:before {
    content: attr(data-th) !important;
    font-size: 12px;
    font-weight: 500 !important;
    padding: 8px 0 6px !important;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body td:before {
    font-size: 14px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-table-body .item-actions {
    display: none !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-item {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: baseline !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-item-info {
    order: 1 !important;
    display: inline-flex !important;
    margin: 0 !important;
    margin-right: 0 !important;
    width: 82% !important;
    align-items: unset !important;
    padding-top: 10px !important;
  }
  html body.badaboum--custom.badaboum .cart-table-body-item-info::before {
    content: none !important;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .cart-table-body-item-info {
    width: 34% !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-qty {
    width: auto !important;
    order: 4 !important;
    height: 70px !important;
    text-align: center;
    flex: 1;
  }
  html body.badaboum--custom.badaboum .cart-table-body-qty::before {
    padding-bottom: 10px !important;
    margin-right: 0 !important;
  }
  html body.badaboum--custom.badaboum .cart-table-body-qty .qty-btn {
    height: 32px;
    font-size: 18px;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-qty {
    flex-direction: column;
    width: 33.3333333333%;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .cart-table-body-qty {
    width: 20% !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-subtotal {
    width: auto;
    order: 5 !important;
    height: 70px;
    text-align: center !important;
    flex: 1;
  }
  html body.badaboum--custom.badaboum .cart-table-body-subtotal::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  html body.badaboum--custom.badaboum .cart-table-body-subtotal .price {
    margin-top: 5px;
    display: block;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-subtotal {
    width: 33.3333333333%;
    flex-direction: column;
    height: auto;
  }
  html body.badaboum--custom.badaboum .cart-table-body-subtotal .price {
    margin-top: 5px;
    display: block;
  }
  html body.badaboum--custom.badaboum .cart-table-body-subtotal span.price-including-tax {
    margin-left: unset;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .cart-table-body-subtotal {
    width: 20% !important;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-table-body-price {
    text-align: center;
    width: auto !important;
    flex: 1;
  }
  html body.badaboum--custom.badaboum .cart-table-body-price::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  html body.badaboum--custom.badaboum .cart-table-body-price .table-body-price-wrapper {
    margin: 0;
  }
  html body.badaboum--custom.badaboum .cart-table-body-price .price {
    color: #cd2367;
    margin-top: 5px;
    display: block;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-price {
    flex-direction: column;
    width: 33.3333333333%;
    margin-top: 20px;
  }
  html body.badaboum--custom.badaboum .cart-table-body-price::before {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }
  html body.badaboum--custom.badaboum .cart-table-body-price .table-body-price-wrapper {
    margin: 0;
  }
  html body.badaboum--custom.badaboum .cart-table-body-price .price {
    color: #cd2367;
    margin-top: 5px;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .cart-table-body-price {
    width: 20% !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-remove {
    order: 2 !important;
    width: 40px;
    align-self: flex-start !important;
    margin-top: 15px !important;
    margin-left: auto !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) and (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-remove:before {
    content: none !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-table-body-remove .icon {
    font-size: 18px;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .cart-table-body-remove {
    width: 20% !important;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .cart-table-body-remove {
    padding-right: 20px !important;
  }
}
html body.badaboum--custom.badaboum .cart-table-body-remove .use-ajax.action.towishlist.action-towishlist, html body.badaboum--custom.badaboum .cart-table-body-remove .gift-options-cart-item {
  display: none !important;
}
html body.badaboum--custom.badaboum .cart-form-table {
  border: none;
}
html body.badaboum--custom.badaboum .cart-form .product-image-photo {
  width: 110px;
  height: 110px;
  object-fit: cover;
}
@media (min-width: 576px) {
  html body.badaboum--custom.badaboum .cart-form .product-image-photo {
    width: 120px !important;
    height: 120px !important;
  }
}
html body.badaboum--custom.badaboum .cart-form .product-item-details {
  vertical-align: middle !important;
  text-align: left;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-form .product-item-details {
    display: inline-flex !important;
    flex-direction: column !important;
    padding: 0 12px !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-form .product-item-photo {
    display: block !important;
    width: fit-content !important;
    position: unset !important;
    max-width: 120px !important;
    max-height: 120px !important;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .cart-coupon .input-box-button {
    top: 43px;
  }
  html body.badaboum--custom.badaboum .cart-coupon-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
html body.badaboum--custom.badaboum .cart-item-qty {
  font-size: 16px;
  font-weight: 500;
  margin: 0 5px;
  padding: 0;
  width: 60px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .cart-item-qty {
    height: 30px !important;
  }
}
html body.badaboum--custom.badaboum .cart-button {
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 999;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .cart-button {
    display: none;
  }
}

body.checkout-index-index,
body.checkout-cart-index {
  background: #f8f8f8;
}
@media (min-width: 0px) and (max-width: 767px) {
  body.checkout-index-index .columns,
body.checkout-cart-index .columns {
    width: 100%;
  }
  body.checkout-index-index .page-title,
body.checkout-cart-index .page-title {
    margin: 7px 0;
  }
  body.checkout-index-index .page-title-wrapper,
body.checkout-cart-index .page-title-wrapper {
    text-align: center;
  }
  body.checkout-index-index .admin__data-grid-outer-wrap,
body.checkout-cart-index .admin__data-grid-outer-wrap {
    order: 2;
  }
}
body.checkout-index-index .column.main,
body.checkout-cart-index .column.main {
  padding-bottom: 0;
}
body.checkout-index-index .admin__data-grid-outer-wrap,
body.checkout-cart-index .admin__data-grid-outer-wrap {
  padding-right: 0;
  padding-left: 0;
}
body.checkout-index-index .page-title-wrapper,
body.checkout-cart-index .page-title-wrapper {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  body.checkout-index-index .page-title-wrapper .page-title,
body.checkout-cart-index .page-title-wrapper .page-title {
    margin-left: 30px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  body.checkout-index-index .page-title-wrapper,
body.checkout-cart-index .page-title-wrapper {
    justify-content: center;
  }
}
body.checkout-index-index .page-title-wrapper .page-title,
body.checkout-cart-index .page-title-wrapper .page-title {
  order: 2;
}
body.checkout-index-index .page-title-wrapper .btn.action.back,
body.checkout-cart-index .page-title-wrapper .btn.action.back {
  order: 1;
  display: none;
}
@media (min-width: 768px) {
  body.checkout-index-index .page-title-wrapper .btn.action.back,
body.checkout-cart-index .page-title-wrapper .btn.action.back {
    display: flex;
  }
}

html body.badaboum--custom.badaboum .minicart {
  background-color: #ffffff;
  right: -15px;
  padding: 20px;
  width: 360px;
  border: none;
}
html body.badaboum--custom.badaboum .minicart:after {
  content: none;
}
html body.badaboum--custom.badaboum .minicart-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e6e6;
}
html body.badaboum--custom.badaboum .minicart-header-count {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
html body.badaboum--custom.badaboum .minicart-btn {
  display: inline-block;
  margin-left: auto;
}
.showcart .minicart-wrapper.active:before {
  font-size: inherit !important;
  color: #cd2367;
}
.minicart-empty {
  font-weight: 500;
  text-align: center;
  display: block;
  padding: 10px 0;
}
html body.badaboum--custom.badaboum .minicart-widgets {
  margin: 0;
}
html body.badaboum--custom.badaboum .minicart-item {
  position: relative;
  border-top: 1px solid #e8e6e6;
  padding: 20px 0;
  display: flex;
  margin-bottom: 0;
}
.minicart-item:first-child {
  border-top: none;
}
.minicart-item:last-child {
  border-bottom: 1px solid #e8e6e6;
}
html body.badaboum--custom.badaboum .minicart-items-wrapper {
  max-height: 380px;
  overflow: auto;
  padding: 0 20px;
  border: 0;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .minicart-items-wrapper {
    max-height: 380px;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .minicart-items-wrapper {
    max-height: 200px;
  }
}
@media (min-width: 1540px) {
  html body.badaboum--custom.badaboum .minicart-items-wrapper {
    max-height: 380px;
  }
}
html body.badaboum--custom.badaboum .minicart-items-wrapper--big {
  max-height: 380px;
  padding-top: 10px;
}
html body.badaboum--custom.badaboum .minicart-item-img, html body.badaboum--custom.badaboum .minicart-item img {
  width: 55px;
  max-width: 55px;
  height: 55px;
  margin: 0;
  object-fit: cover;
  position: static;
}
.minicart-item-name {
  font-size: 14px;
  font-weight: 500;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  padding-right: 70px;
}
@media (min-width: 768px) {
  .minicart-item-name {
    padding-right: 110px;
  }
}
.minicart-item-options {
  padding-bottom: 10px;
  font-weight: 500 !important;
}
html body.badaboum--custom.badaboum .minicart-item-options.active .minicart-item-options-toggle:after {
  transform: rotate(180deg);
  content: "\e90d";
}
html body.badaboum--custom.badaboum .minicart-item-options-toggle:after {
  font-family: "badaboum";
  content: "\e90d";
  position: absolute;
  font-size: 10px;
  transition: transform 0.5s ease;
}
.minicart-item-price {
  font-size: 17px;
  position: absolute;
  bottom: 22px;
  right: 0;
}
.minicart-item-price--gap {
  bottom: 9px;
}
html body.badaboum--custom.badaboum .minicart-item-details {
  padding-left: 15px;
}
html body.badaboum--custom.badaboum .minicart-item-remove::before {
  font-family: "badaboum";
  font-size: 20px;
  line-height: 1;
}
html body.badaboum--custom.badaboum .minicart-item-remove:hover::before {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .minicart-item-update {
  display: block !important;
  position: absolute;
  top: -10vh;
  opacity: 0;
}
html body.badaboum--custom.badaboum .minicart-subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
}
html body.badaboum--custom.badaboum .minicart-subtotal .price {
  font-size: 25px;
}

html body.badaboum--custom.badaboum .popup-title {
  background: #cd2367;
  padding: 10px 0;
  text-align: center;
  text-transform: none;
  height: 35px;
  color: #ffffff;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .popup-title {
    height: auto;
  }
}
html body.badaboum--custom.badaboum .popup-title p {
  font-weight: bold;
  font-size: 10px;
  display: none;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .popup-title p {
    font-size: 1.4rem;
    display: block;
  }
}
html body.badaboum--custom.badaboum .popup-wrapper {
  border-radius: 4px;
}
html body.badaboum--custom.badaboum .popup-image {
  height: 200px !important;
  width: 200px !important;
  order: 1;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .popup-image {
    margin-top: 10px;
    display: none;
  }
}
html body.badaboum--custom.badaboum .popup-content {
  height: auto !important;
  display: flex;
  padding: 20px 20px 0 !important;
  align-items: flex-start;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .popup-content {
    padding: 20px 10px 0 !important;
    flex-direction: column;
    align-items: center;
  }
}
html body.badaboum--custom.badaboum .popup-content .price-box.price-final_price {
  padding-left: 0;
}
html body.badaboum--custom.badaboum .popup-content span.price {
  font-size: 16px;
  font-weight: 500;
}
html body.badaboum--custom.badaboum .popup-content .product-add-form {
  order: 2;
}
html body.badaboum--custom.badaboum .popup-content div[data-element=main] {
  display: none;
}
html body.badaboum--custom.badaboum .popup-name {
  text-align: left;
  font-weight: 600;
  margin: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .popup-name {
    font-size: 20px;
    text-align: center;
  }
}
html body.badaboum--custom.badaboum .popup-qty > p {
  margin-bottom: 0;
}
html body.badaboum--custom.badaboum .popup-qty .qty-btn {
  width: 30px;
  height: 30px;
}
html body.badaboum--custom.badaboum .popup-qty .qty-input {
  height: 30px;
  width: 50px;
}
html body.badaboum--custom.badaboum .popup-info {
  order: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .popup-info {
    align-items: center;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .popup-info {
    margin-left: 20px;
  }
}
html body.badaboum--custom.badaboum .popup-cartinfo {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 20px;
}
html body.badaboum--custom.badaboum .popup-cartinfo .text-colored {
  font-weight: bold;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .popup-cartinfo .text-colored .price {
    font-size: 16px;
  }
}
html body.badaboum--custom.badaboum .popup-cartinfo p {
  font-size: 16px !important;
  text-align: left;
}
html body.badaboum--custom.badaboum .popup-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .popup-btn-wrapper {
    flex-direction: column;
  }
  html body.badaboum--custom.badaboum .popup-btn-wrapper span {
    font-size: 14px !important;
  }
}
html body.badaboum--custom.badaboum .popup-btn-wrapper .btn--secondary {
  margin-top: 20px;
}
@media (min-width: 576px) {
  html body.badaboum--custom.badaboum .popup-btn-wrapper .btn--secondary {
    margin-left: 20px;
    margin-top: 0;
  }
}
html body.badaboum--custom.badaboum .popup-btn--close {
  background: none !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .popup-btn--close {
    top: 5px;
    right: 0px;
    display: flex;
    align-items: center;
  }
}
html body.badaboum--custom.badaboum .popup-btn--close.icon.icon-close:before {
  color: #ffffff;
  font-weight: bold;
}
html body.badaboum--custom.badaboum .popup-wishlist-wraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
html body.badaboum--custom.badaboum .popup-wishlist-name {
  text-align: center;
  padding: 0 20px;
}
html body.badaboum--custom.badaboum .popup-wishlist-name-wrapper {
  width: 100% !important;
  height: 100px !important;
  margin-top: 0 !important;
}
html body.badaboum--custom.badaboum .popup-wishlist-image {
  display: none;
}
html body.badaboum--custom.badaboum .popup-wishlist-form-wrapper {
  width: 100% !important;
  padding-bottom: 15px;
}
html body.badaboum--custom.badaboum .popup-wishlist-form {
  width: 100%;
}
html body.badaboum--custom.badaboum .popup-wishlist-error-box {
  padding: 15px;
}

#mb-ajaxwishlist-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

html body.badaboum--custom.badaboum .footer-container {
  padding: 0;
  background: #F4F3E9;
  padding: 0 10px 40px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .footer-container .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-container {
    padding: 60px 0 40px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .footer-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
html body.badaboum--custom.badaboum .footer-container .footer-payment .row-flex--start {
  justify-content: space-between;
}
@media (min-width: 768px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .footer-container .footer-payment .row-flex--start .img-wrapper {
    max-width: 35px;
  }
}
html body.badaboum--custom.badaboum .footer-row {
  display: flex;
  padding: 15px 0px;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-row {
    padding: 20px 0px;
  }
}
html body.badaboum--custom.badaboum .footer-row--nogap {
  padding: 0px;
}
html body.badaboum--custom.badaboum .footer-newsletter {
  padding: 15px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-newsletter {
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .footer-newsletter .footer-col-section {
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
  }
  html body.badaboum--custom.badaboum .footer-newsletter .footer-col-section .newsletter {
    margin-right: 25px;
  }
  html body.badaboum--custom.badaboum .footer-newsletter .footer-col-section .footer-devis {
    margin-left: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter {
    width: 50%;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter {
    width: 100%;
    margin-bottom: 20px;
  }
  html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .input-box .input-label {
    font-size: 18px;
    text-align: center;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .input-box .input-label {
    font-size: 16px;
    text-align: left;
  }
}
html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .btn--primary.subscribe {
  margin-top: -2px;
  margin-left: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .btn--primary.subscribe {
    margin-top: 3px;
  }
}
html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .input-label {
  margin-bottom: 8px;
}
html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .input-text {
  height: 50px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .input-text {
    height: 39px;
  }
}
html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .input-box-button {
  height: 50px;
  top: unset;
  bottom: 3px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-newsletter .block.newsletter .input-box-button {
    height: 39px;
    top: 28px;
    bottom: unset;
  }
}
html body.badaboum--custom.badaboum .footer-newsletter .field.newsletter {
  width: 100%;
}
html body.badaboum--custom.badaboum .footer-col {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .footer-col-last {
    margin-top: 30px;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .footer-col-last {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-col-last .footer-col-title {
    font-size: 13px;
  }
}
html body.badaboum--custom.badaboum .footer-col-title {
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 34px;
  text-align: left;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-col-title {
    display: inline;
    padding: 0;
    margin-bottom: 13px;
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .footer-col-title {
    pointer-events: none;
  }
}
html body.badaboum--custom.badaboum .footer-col-title::after {
  font-size: 12px;
  line-height: 14px;
  margin-left: auto;
  transition: 0.5s;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-col-title::after {
    display: none;
  }
}
html body.badaboum--custom.badaboum .footer-col-title.active::after {
  transform: rotate(180deg);
}
html body.badaboum--custom.badaboum .footer-col-section {
  position: relative;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-col-section {
    margin-bottom: 0;
  }
}
html body.badaboum--custom.badaboum .footer-col-section:last-of-type html body.badaboum--custom.badaboum .footer-col-section::before {
  content: none;
}
html body.badaboum--custom.badaboum .footer-col-section::before {
  content: "";
  bottom: 0;
  left: 50%;
  background: #D8D8D8;
  transform: translateX(-50%);
  position: absolute;
  width: 95vw;
  height: 1px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-col-section {
    padding: 0;
  }
  html body.badaboum--custom.badaboum .footer-col-section::before {
    display: none;
  }
  html body.badaboum--custom.badaboum .footer-col-section + html body.badaboum--custom.badaboum .footer-col-section {
    margin-top: 17px;
  }
}
html body.badaboum--custom.badaboum .footer-col-section .btn--primary.subscribe {
  margin-top: -1px;
  margin-left: 0;
}
html body.badaboum--custom.badaboum .footer-content {
  padding: 0px 20px;
  padding-top: 50px;
  position: relative;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-content {
    padding-top: 40px;
  }
}
html body.badaboum--custom.badaboum .footer-content-nav {
  padding: 30px 0px 10px;
  position: relative;
  max-width: 1210px;
  margin: auto;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-content-nav {
    padding: 20px 0px 10px 200px;
    padding-top: 20px;
    min-height: 255px;
  }
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .footer-content-nav {
    justify-content: flex-start;
  }
}
html body.badaboum--custom.badaboum .footer-content-nav:before {
  position: absolute;
  top: 50%;
  left: -7%;
  transform: translateY(-50%);
  font-size: 245px;
  opacity: 0.3;
  display: none;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-content-nav:before {
    display: block;
  }
}
html body.badaboum--custom.badaboum .footer-content-nav:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translateX(-50%);
  background: #e8e6e6;
  height: 1px;
  width: 100%;
}
html body.badaboum--custom.badaboum .footer-content-title {
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html body.badaboum--custom.badaboum .footer-content-title::after {
  font-family: "badaboum";
  content: "\e921";
  transition: 0.5s;
  margin-left: 10px;
  font-size: 24px;
  color: #2C2628;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-content-title::after {
    display: none;
  }
}
html body.badaboum--custom.badaboum .footer-content-title.active::after {
  transform: rotate(180deg);
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-content-title {
    font-size: 16px;
    margin-bottom: 0;
  }
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .footer-content-title {
    font-size: 18px;
    margin-bottom: 14px;
  }
}
html body.badaboum--custom.badaboum .footer-content-link {
  margin-top: 3px;
}
html body.badaboum--custom.badaboum .footer-content-links {
  line-height: 22px;
  font-size: 14px;
  overflow: hidden;
  max-height: 0;
  transition: 0.5s;
}
html body.badaboum--custom.badaboum .footer-content-links.open {
  max-height: none;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-content-links {
    max-height: none;
  }
}
@media (min-width: 0px) {
  html body.badaboum--custom.badaboum .footer-content-link:first-child {
    margin-top: 8px;
  }
}
html body.badaboum--custom.badaboum .footer-content-link:before {
  content: "-";
  margin-right: 5px;
}
html body.badaboum--custom.badaboum .footer-legals {
  background: #cd2367;
  line-height: 24px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
html body.badaboum--custom.badaboum .footer-legals-content {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
html body.badaboum--custom.badaboum .footer-legals-item {
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-legals-item {
    text-align: initial;
    width: auto;
  }
}
html body.badaboum--custom.badaboum .footer-legals-item + html body.badaboum--custom.badaboum .footer-legals-item {
  margin-top: 6px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-legals-item + html body.badaboum--custom.badaboum .footer-legals-item {
    margin-top: 0;
    margin-left: auto;
  }
}
html body.badaboum--custom.badaboum .footer-legals-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 20px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-legals-links {
    padding: 0;
  }
}
html body.badaboum--custom.badaboum .footer-legals-link {
  white-space: nowrap;
}
html body.badaboum--custom.badaboum .footer-legals-link + html body.badaboum--custom.badaboum .footer-legals-link {
  padding-left: 4px;
  margin-left: 8px;
  position: relative;
}
html body.badaboum--custom.badaboum .footer-legals-link + html body.badaboum--custom.badaboum .footer-legals-link:before {
  content: "|";
  position: absolute;
  left: -4px;
  top: 50%;
  transform: translateY(-50%);
}
html body.badaboum--custom.badaboum .footer-text, html body.badaboum--custom.badaboum .footer-link {
  position: relative;
  color: #3d3d3d;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 6px !important;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-texts, html body.badaboum--custom.badaboum .footer-links {
    margin-top: 10px;
  }
}
html body.badaboum--custom.badaboum .footer-text:last-child, html body.badaboum--custom.badaboum .footer-link:last-child {
  margin-bottom: 20px !important;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-text:last-child, html body.badaboum--custom.badaboum .footer-link:last-child {
    margin-bottom: 0px !important;
  }
}
html body.badaboum--custom.badaboum .footer-text span.link, html body.badaboum--custom.badaboum .footer-link span.link {
  font-size: 13px;
}
html body.badaboum--custom.badaboum .footer-text + html body.badaboum--custom.badaboum .footer-text, html body.badaboum--custom.badaboum .footer-text + html body.badaboum--custom.badaboum .footer-link, html body.badaboum--custom.badaboum .footer-link + html body.badaboum--custom.badaboum .footer-text, html body.badaboum--custom.badaboum .footer-link + html body.badaboum--custom.badaboum .footer-link {
  margin-top: 11px;
}
html body.badaboum--custom.badaboum .footer-text:after, html body.badaboum--custom.badaboum .footer-link:after {
  position: absolute;
  bottom: -3px;
  left: 0px;
  right: 0px;
  height: 1px;
  background: #3d3d3d;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease;
}
html body.badaboum--custom.badaboum .footer-text:hover:after, html body.badaboum--custom.badaboum .footer-link:hover:after {
  transform: scaleX(1);
}
html body.badaboum--custom.badaboum .footer-text--light, html body.badaboum--custom.badaboum .footer-link--light {
  color: #ffffff;
}
html body.badaboum--custom.badaboum .footer-text--light:after, html body.badaboum--custom.badaboum .footer-link--light:after {
  background: #ffffff;
}
html body.badaboum--custom.badaboum .footer-other-link {
  margin-bottom: 40px;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .footer-other-link {
    margin-bottom: 15px;
    flex-wrap: nowrap;
  }
  html body.badaboum--custom.badaboum .footer-other-link .img-wrapper:nth-child(even) {
    margin: 0 10px;
  }
}
html body.badaboum--custom.badaboum .footer-other-link .img-wrapper {
  max-width: unset;
  max-height: unset;
  height: auto;
  width: 100%;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-other-link .img-wrapper {
    max-width: 100px;
    max-height: 36px;
  }
  html body.badaboum--custom.badaboum .footer-other-link .img-wrapper img {
    object-fit: fill;
  }
}
html body.badaboum--custom.badaboum .footer-socials {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-socials {
    justify-content: space-between;
  }
}
html body.badaboum--custom.badaboum .footer-socials--mobile {
  justify-content: unset;
  margin: 10px auto 35px;
}
html body.badaboum--custom.badaboum .footer-socials--mobile span:nth-child(even) {
  margin: 0 10px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-socials--mobile {
    display: none;
  }
  html body.badaboum--custom.badaboum .footer-socials--mobile a:nth-child(even) {
    margin: 0 20px;
  }
}
html body.badaboum--custom.badaboum .footer-socials--desktop {
  display: none;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-socials--desktop {
    display: flex;
  }
}
html body.badaboum--custom.badaboum .footer-social {
  font-size: 25px;
  color: #ffffff !important;
  position: relative;
}
html body.badaboum--custom.badaboum .footer-social:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46%, -48%);
  content: none !important;
}
html body.badaboum--custom.badaboum .footer-social-icon {
  font-size: 40px;
  color: #cd2367;
  opacity: 1;
  transition: opacity 0.5s;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-social-icon {
    font-size: 35px;
  }
}
html body.badaboum--custom.badaboum .footer-social-icon:hover {
  opacity: 0.5;
}
html body.badaboum--custom.badaboum .footer-social-icon--svg {
  margin-top: -2px;
}
html body.badaboum--custom.badaboum .footer-logo {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: 30px 0;
  justify-content: center;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-logo {
    justify-content: flex-start;
    margin-bottom: 40px;
    margin-top: 0;
  }
}
html body.badaboum--custom.badaboum .footer-logo .logo-wrapper {
  width: 250px !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .footer-logo a.logo {
    margin: auto;
    float: unset;
    max-width: 200px;
    display: block;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-logo a.logo {
    margin: 0 !important;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .footer-logo a.logo {
    max-width: unset;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-line--top {
    padding-top: 30px !important;
    border-top: 1px solid #D8D8D8;
  }
}
html body.badaboum--custom.badaboum .footer-payment {
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .footer-payment {
    margin-top: 30px;
    padding: 0 0 0 0;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .footer-payment .row-flex--start {
    justify-content: space-between !important;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .footer-payment-icons {
    align-items: center;
  }
  html body.badaboum--custom.badaboum .footer-payment-icons img {
    width: auto;
    height: auto;
    max-height: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .footer-devis {
    width: 50%;
  }
  html body.badaboum--custom.badaboum .footer-devis .footer-col-title {
    margin-bottom: 0;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .footer-devis .footer-col-title {
    font-size: 16px;
  }
}

html body.badaboum--custom.badaboum .page-bottom {
  margin-left: auto;
  margin-right: auto;
  max-width: 1335px;
  width: 100%;
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .page-bottom {
    padding: 0 20px;
  }
}

html body.badaboum--custom.badaboum .icon-chevron-up-scroll::before {
  transform: rotate(90deg);
}

.pager-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pager-label {
  font-size: 12px;
  font-weight: 700;
  margin-right: 8px;
}
.pager-items {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.pager-item {
  background: #e9e5e5;
  color: #786855;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.pager-item a {
  color: inherit !important;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  padding: 10px;
}
.pager-item + .pager-item {
  margin-left: 1px;
}
.pager-item:hover, .pager-item--current {
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  background: #cd2367;
  color: #ffffff;
}
.pager-item--current {
  padding: 10px;
}

.relateds {
  margin-bottom: 30px;
}

.promo {
  position: relative;
  left: -10px;
  top: -10px;
}
.promo-product {
  background-color: #cd2367;
  height: max-content;
}
.promo-content {
  background-color: #cd2367;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  margin-bottom: 0;
  padding: 7px;
}
@media (min-width: 768px) {
  .promo-content {
    font-size: 12px;
  }
}
.promo-content + .promo-content {
  margin-top: 5px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .promo-percent {
    width: 60px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .promo-wide {
    display: none;
  }
}

.breadcrumb-container {
  padding: 10px 10px;
  color: #2C2628;
  background-size: cover;
  font-size: 12px;
  display: none;
  z-index: 1;
  position: relative;
}
@media (min-width: 768px) {
  .breadcrumb-container {
    padding: 10px 0;
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .breadcrumb-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
.breadcrumb-product {
  font-size: 16px;
  color: #2C2628;
  margin-bottom: 30px;
}
@media (min-width: 0px) and (max-width: 1279px) {
  .breadcrumb-product {
    display: none;
  }
}
.breadcrumb-product-section--first {
  text-transform: uppercase;
}
.breadcrumb-product-divider {
  margin: 0 5px;
}
.breadcrumb-item {
  line-height: 1;
  position: relative;
  display: none;
}
@media (min-width: 768px) {
  .breadcrumb-item {
    line-height: 19px;
    display: flex;
    margin-right: 4px;
  }
}
.breadcrumb-item::first-letter {
  text-transform: uppercase;
}
.breadcrumb-item-link {
  position: relative;
}
.breadcrumb-item-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 1px;
  background: currentColor;
  transition: 0.5s;
}
.breadcrumb-item-link:hover::after {
  width: 100%;
}
.breadcrumb-item--current {
  display: block;
  font-size: 24px;
  font-weight: bold;
}
.breadcrumb-item:last-child {
  font-weight: bold;
}
@media (min-width: 768px) {
  .breadcrumb-item + .breadcrumb-item::before {
    font-size: 16px;
    font-weight: normal;
    content: ">";
    margin-right: 4px;
    position: relative;
    font-family: Arial;
  }
}
.breadcrumb-subs {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -33px;
  padding: 0 20px 30px;
  overflow: auto;
  width: auto;
}
@media (min-width: 768px) {
  .breadcrumb-subs {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0;
    padding: 0px;
    overflow: initial;
    width: 100%;
  }
}
.breadcrumb-sub {
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .breadcrumb-sub {
    margin-bottom: 0 !important;
  }
}

.category-view .amslider {
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-view .amslider {
    width: 100vw;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.category-cover-background {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 200px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-cover-background {
    width: 100vw;
    height: auto;
  }
}
.category-cover-background h1 {
  margin-bottom: 0;
  text-transform: none;
  margin-top: 0;
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 50px;
  text-align: center;
  color: #cd2367;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-cover-background h1 {
    font-size: 25px;
    line-height: 34px;
  }
}
.category-cover .customer-search-desc-btn {
  margin-top: 0;
  display: none !important;
  width: 100%;
}
.category-cover .customer-search-desc-btn .pagebuilder-button-link {
  font-size: 14px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-cover .customer-search-desc-btn {
    display: inline-block !important;
  }
}
.category-cover .customer-search-desc-btn a.pagebuilder-button-link {
  color: #cd2367;
}
.category-cover-content {
  margin: 15px auto;
  width: auto;
  max-width: 1100px;
}
@media (min-width: 768px) {
  .category-cover-content {
    margin: 40px auto;
  }
}
.category-cover-content p {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-cover-content-main p, .category-cover-content-main a {
    font-size: 13px;
    font-weight: 300;
  }
  .category-cover-content-main strong {
    font-weight: 500;
  }
  .category-cover-content-main a {
    text-decoration: underline;
    color: #cd2367;
  }
  .category-cover-content-main .amslider-container {
    width: 100vw;
    margin-left: -20px;
    padding-left: 10px;
  }
  .category-cover-content-main .cms {
    margin: 0;
  }
}
.category-description {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .category-description {
    max-height: 100% !important;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-description {
    min-height: 47px !important;
  }
  .category-description:not(.active) p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    transition: 0.5s;
  }
}
@media (min-width: 768px) {
  .category-description-bottom {
    border-bottom: 1px solid #D8D8D8;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-description-bottom .view-more {
    padding: 0;
  }
}
.category-description-bottom-title {
  color: #2C2628;
  font-family: "Montserrat - Semi Bold";
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-description-bottom-title {
    margin: 0;
    font-size: 20px;
  }
}
@media (min-width: 1024px) {
  .category-description-bottom-title {
    text-align: left;
  }
}
.category-description-bottom-content {
  color: #2C2628;
  font-family: "Montserrat - Regular";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.6;
  margin: 30px 0 50px 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-description-bottom-content {
    margin: 10px 0 10px 0;
    font-size: 12px;
    text-align: center;
  }
}
.category-header-container {
  position: relative;
  margin-top: -25px;
  padding: 120px 0 20px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .category-header-container {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .category-header-container {
    padding: 30px 0 20px;
  }
}
.category-header--1 {
  color: #d4007a;
}
.category-header--2 {
  color: #0E90BD;
}
.category-header--3 {
  color: #A2C621;
}
.category-header--4 {
  color: #EC6611;
}
.category-header--5 {
  color: #EC6611;
}
.category-header--6 {
  color: #EC6611;
}
.category-header--7 {
  color: #EC6611;
}
.category-header--8 {
  color: #EC6611;
}
.category-header--9 {
  color: #EC6611;
}
.category-header--10 {
  color: #FE7676;
}
.category-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background: white;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, white 155px);
  z-index: 0;
}
@media (min-width: 768px) {
  .category-header::before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, white 100%);
  }
}
.category-header-background {
  background-position: center top;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  z-index: -1;
}
.category-header-content {
  position: relative;
}
.category-header-slider {
  width: 100%;
}
.category-title {
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .category-title {
    font-size: 26px;
  }
}
.category-description {
  color: #2C2628;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
.category-description-content {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .category-description-content {
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .category-description-content {
    text-align: left;
  }
}
.category-item-title {
  font-weight: 500;
  font-size: 12px;
  padding: 0 3px;
}
@media (min-width: 768px) {
  .category-item-title {
    padding: 0;
    font-size: 14px;
  }
}
.category-subcategory {
  display: flex;
  flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .category-subcategory {
    flex-direction: column;
    padding: 0 300px 0 20px;
    position: relative;
  }
}
.category-subcategory .category-title {
  text-align: left;
  margin-bottom: 15px;
  flex: 1;
}
@media (min-width: 768px) {
  .category-subcategory .category-title {
    flex: none;
    padding-right: 25px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-subcategory .category-title {
    text-transform: inherit;
    display: flex;
    align-items: center;
  }
}
.category-subcategory-image {
  height: 100px;
  width: 100px;
  margin-left: auto;
}
@media (min-width: 768px) {
  .category-subcategory-image {
    width: 300px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.category-subcategory-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.category-subcategory-content {
  width: 100%;
}
@media (min-width: 768px) {
  .category-subcategory-content {
    padding-right: 25px !important;
    padding-left: 0 !important;
  }
}
.category-subcategory-content .category-title {
  margin-bottom: 25px;
  text-transform: full-size-kana;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .category-subcategory-content .category-title {
    text-align: left;
  }
}
.category-subcategory .view-more-btn {
  margin: 20px auto;
}
@media (min-width: 0px) and (max-width: 767px) {
  .category-subcategory .view-more[data-elevator]:not(.active) {
    height: 100px;
  }
}

.subcategories {
  display: flex;
  flex-wrap: wrap;
}
.subcategories .subcategory {
  margin-right: 10px;
}
.subcategories .subcategory:last-child {
  margin-right: 0;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .subcategories {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.subcategory {
  width: calc(25% - 10px);
  min-width: 178px;
  height: 50px;
  border: 1px solid #D8D8D8;
  margin-bottom: 10px;
  transition: border 0.5s ease-in-out;
}
@media (min-width: 0px) and (max-width: 767px) {
  .subcategory:last-child {
    margin-right: 20px;
  }
}
.subcategory:hover {
  border-color: #cd2367 !important;
}
.subcategory:hover .subcategory-link--text.category-item-title {
  color: #cd2367;
}
.subcategory-content {
  height: 100%;
  max-width: 100%;
  width: 100%;
}
.subcategory-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 100%;
  width: 100%;
  padding-left: 10px;
}
.subcategory-link--text {
  min-width: 50%;
  height: 100%;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 50px;
  transition: color 0.5s ease-in-out;
}
.subcategory-link--img {
  height: 100%;
  object-fit: cover;
  max-width: 30%;
}
@media (min-width: 0px) and (max-width: 767px) {
  .subcategory-link--img {
    width: 100%;
    max-width: 100%;
  }
}

.swiper {
  margin: 0;
}
.swiper-container {
  margin: 40px 0 20px;
  overflow: visible;
}
@media (min-width: 768px) {
  .swiper-container {
    overflow: hidden;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .swiper-slide {
    padding: 0;
  }
}
.swiper-category-item {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  color: #2C2628;
}
.swiper-category-item-img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
}
.swiper-category-item-img--hover {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  opacity: 0;
  transition: opacity 0.5s;
}
@media (min-width: 768px) {
  .swiper-category-item-img--hover {
    top: 10px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .swiper-slide-active .swiper-category-item-img--hover {
    opacity: 1 !important;
  }
}
@media (min-width: 1280px) {
  .swiper-category-item:hover .swiper-category-item-img--hover {
    opacity: 1;
    transition: 0.25s;
  }
}
.swiper-button-prev, .swiper-button-next {
  opacity: 1;
  height: 20px;
  width: 20px;
  background: currentColor;
  border-radius: 50%;
  display: none;
}
@media (min-width: 768px) {
  .swiper-button-prev, .swiper-button-next {
    display: block;
  }
}
.swiper-button-prev::before, .swiper-button-next::before {
  font-family: "badaboum";
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
.swiper-button-prev::before {
  content: "\e910";
}
.swiper-button-next::before {
  content: "\e90d";
}
@media (min-width: 0px) and (max-width: 767px) {
  .swiper-slide {
    padding: 0;
  }
}
.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 12px auto;
  width: 100%;
}
.swiper-pagination-bullet {
  width: 25px;
  height: 5px;
  border-radius: 2px;
  background: #e9e5e5;
  margin: 3px;
  opacity: 1;
  transition: 0.3s;
}
.swiper-pagination-bullet-active {
  background: currentColor;
}

.rating-1::before {
  content: "\e90b";
}
.rating-2::before {
  content: "\e90b\e90b";
}
.rating-3::before {
  content: "\e90b\e90b\e90b";
}
.rating-4::before {
  content: "\e90b\e90b\e90b\e90b";
}
.rating-5::before {
  content: "\e90b\e90b\e90b\e90b\e90b";
}
.rating-result {
  margin-left: 0;
  height: 30px;
  width: 87px;
  margin-right: 13px;
}
.rating-result label {
  top: 0;
}
.rating-result::before, .rating-result span::before {
  content: "\e90b\e90b\e90b\e90b\e90b";
}
.rating-result::before, .rating-result span::before, .rating-result label::before {
  font-size: 15px;
  height: auto;
  font-family: "badaboum";
  letter-spacing: 3px;
  left: 0;
  height: 30px;
  line-height: 30px;
  color: #cd2367;
}
.rating-result::before {
  content: "\e90e\e90e\e90e\e90e\e90e";
}
.rating-result--small {
  margin-right: 0;
  height: 22px;
  width: 65px;
}
.rating-result--small::before, .rating-result--small span::before, .rating-result--small label::before {
  font-size: 11px;
  letter-spacing: 2px;
  height: 22px;
  line-height: 22px;
}

body.catalog-category-view section.section.view-more-section {
  margin-top: 0;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .catalog-product-view .columns .column.main {
    display: flex;
    flex-direction: column;
  }
  .catalog-product-view .columns .column.main .product.media {
    order: 1;
  }
  .catalog-product-view .columns .column.main .product-info-main {
    order: 2;
  }
  .catalog-product-view .columns .column.main .product-details {
    order: 3;
  }
}

html body.badaboum--custom.badaboum .page-main-details .review-item {
  list-style: none;
}
html body.badaboum--custom.badaboum .page-main-details .review-title {
  color: #cd2367;
  font-weight: bold;
}
html body.badaboum--custom.badaboum .page-main-details .review-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
html body.badaboum--custom.badaboum .page-main-details .review-details-value {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .page-main-details .review-details-label {
  font-style: italic;
  margin-right: 7px;
  font-size: small;
}
html body.badaboum--custom.badaboum .page-main-details .review-ratings {
  width: 25%;
}
html body.badaboum--custom.badaboum .page-main-details .review-content {
  min-height: 90px;
  width: 73.5%;
  display: inline-block;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .page-main-details .review-content {
    width: 100%;
    margin-top: 15px;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .page-main-details .review-content {
    width: 66%;
  }
}
html body.badaboum--custom.badaboum .page-main-details .review-fieldset {
  margin: 0 0 20px;
}
html body.badaboum--custom.badaboum .page-main-details .review-legend span {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
}
html body.badaboum--custom.badaboum .page-main-details .review-legend strong {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .page-main-details .review-field-ratings, html body.badaboum--custom.badaboum .page-main-details .review-field-nickname, html body.badaboum--custom.badaboum .page-main-details .review-field-summary, html body.badaboum--custom.badaboum .page-main-details .review-field-text {
  display: flex;
  flex-direction: column;
}
html body.badaboum--custom.badaboum .page-main-details .review-field-ratings .label, html body.badaboum--custom.badaboum .page-main-details .review-field-nickname .label, html body.badaboum--custom.badaboum .page-main-details .review-field-summary .label, html body.badaboum--custom.badaboum .page-main-details .review-field-text .label {
  text-align: left;
  width: 100%;
}
html body.badaboum--custom.badaboum .page-main-details .review-field-ratings .control, html body.badaboum--custom.badaboum .page-main-details .review-field-nickname .control, html body.badaboum--custom.badaboum .page-main-details .review-field-summary .control, html body.badaboum--custom.badaboum .page-main-details .review-field-text .control {
  width: 100%;
}
html body.badaboum--custom.badaboum .page-main-details .review-form {
  margin: auto;
}
html body.badaboum--custom.badaboum .page-main-details .review-form-actions {
  display: flex;
  align-items: flex-start;
  margin-left: 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .page-main-details .review-form-actions button.btn {
    width: 100% !important;
  }
}
html body.badaboum--custom.badaboum .admin__data-grid-outer-wrap {
  max-width: 1240px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .admin__data-grid-outer-wrap {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .admin__data-grid-outer-wrap {
    order: 3;
  }
}
html body.badaboum--custom.badaboum .admin__data-grid-outer-wrap .slick-list.draggable {
  min-height: 290px;
}
html body.badaboum--custom.badaboum .admin__data-grid-outer-wrap .section-viewed-products {
  margin-top: 0;
}
html body.badaboum--custom.badaboum .product.media {
  width: 47%;
  position: relative;
  margin-bottom: 30px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .product.media {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .product.media {
    margin-bottom: 80px;
    margin-top: 20px;
  }
}
html body.badaboum--custom.badaboum .product-tags {
  max-width: max-content;
  position: absolute;
  top: 25px;
  left: 15px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-tags {
    top: 15px;
    left: 115px;
  }
}
html body.badaboum--custom.badaboum .product-wishlist {
  max-width: max-content;
  position: absolute;
  top: 15px;
  right: 15px;
}
html body.badaboum--custom.badaboum .product-wishlist em.icon::before {
  font-size: 35px !important;
}
html body.badaboum--custom.badaboum .product-info-seo-title {
  font-size: 14px;
  font-weight: lighter;
  font-style: normal;
  text-align: left;
  letter-spacing: normal;
  line-height: normal;
  color: #2C2628;
  margin: 15px 0;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-info-seo-title {
    font-size: 18px;
  }
}
html body.badaboum--custom.badaboum .product-info-stock {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
html body.badaboum--custom.badaboum .product-info-stock span {
  font-size: 1.6rem;
  text-transform: none;
  font-weight: 500;
  display: flex;
  align-items: center;
}
html body.badaboum--custom.badaboum .product-info-stock span::before {
  content: "●";
  font-size: 1.4rem;
  margin-right: 5px;
}
html body.badaboum--custom.badaboum .product-info-stock .available {
  display: flex;
  align-items: center;
  color: #6DB332;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-info-stock .available {
    margin-bottom: 10px;
    margin-right: 0px;
    align-items: flex-start;
  }
  html body.badaboum--custom.badaboum .product-info-stock .available span {
    white-space: nowrap;
  }
}
html body.badaboum--custom.badaboum .product-info-stock .available p {
  margin: 0 0 0 5px;
  font-size: 14px;
  font-weight: 400;
  color: #2c2628;
  text-transform: none;
  line-height: 22.4px;
}
html body.badaboum--custom.badaboum .product-info-stock .unavailable {
  color: #CE0A0A;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-info-stock .unavailable {
    margin-bottom: 10px;
  }
}
html body.badaboum--custom.badaboum .product-info-socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: 10px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-info-socials {
    width: auto;
    margin-top: 5px;
    justify-content: space-between;
  }
}
html body.badaboum--custom.badaboum .product-info-socials .footer-social:nth-child(even) {
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .product-info-socials .footer-social--mail {
    padding-top: 7px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .product-info-socials .footer-social--mail {
    padding-top: 0px;
  }
}
html body.badaboum--custom.badaboum .product-info-socials .footer-social-icon {
  font-size: 25px;
}
html body.badaboum--custom.badaboum .product-info-wrapper .product.attribute.sku > div.value, html body.badaboum--custom.badaboum .product-info-wrapper .product.attribute.sku > span.type {
  color: #2C2628;
  font-weight: lighter;
  font-size: 12px;
}
html body.badaboum--custom.badaboum .product-info-price {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}
html body.badaboum--custom.badaboum .product-info-price .special-price {
  color: #cd2367;
  order: 1;
}
html body.badaboum--custom.badaboum .product-info-price .special-price .price {
  font-size: 35px;
  line-height: 30px;
  color: #cd2367;
}
html body.badaboum--custom.badaboum .product-info-price .old-price {
  order: 2;
  color: #000000;
}
html body.badaboum--custom.badaboum .product-info-price .old-price .price {
  display: inline-block;
  position: relative;
  font-weight: 100;
  line-height: 10px;
  color: #2C2628 !important;
  font-size: 1.8rem;
}
html body.badaboum--custom.badaboum .product-info-price .old-price .price::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 1px;
  background: #2C2628;
}
html body.badaboum--custom.badaboum .product-info-price .old-price .price-wrapper {
  line-height: 0;
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price {
  display: flex;
  margin: 35px 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-info-price .price-final_price {
    padding-left: 0;
    margin: 15px 0;
  }
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price.price-container {
  align-items: center;
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price span {
  margin: 0;
  margin-right: 5px;
  font-size: 35px;
  color: #cd2367;
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price span .price-label {
  display: none;
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price span.normal-price .price-label {
  display: block;
  font-size: 18px;
  line-height: 0;
  color: #2C2628;
  margin-right: 10px;
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price span.normal-price .price-label:after {
  content: ": ";
  padding-left: 10px;
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price span.normal-price:has(.special-price) .price-label {
  display: none;
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price span.normal-price span[id^=product-price-] {
  line-height: initial;
}
html body.badaboum--custom.badaboum .product-info-price .price-final_price .promo-content {
  line-height: 10px;
}
html body.badaboum--custom.badaboum .product-info-price .promo-product {
  order: 3;
  margin-top: -10px;
}
html body.badaboum--custom.badaboum .product-info-main {
  width: 100%;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-info-main .pagebuilder-column img {
    width: 36px;
  }
  html body.badaboum--custom.badaboum .product-info-main .pagebuilder-column span {
    line-height: 14px;
  }
  html body.badaboum--custom.badaboum .product-info-main .pagebuilder-column strong {
    font-size: 12px;
  }
  html body.badaboum--custom.badaboum .product-info-main .pagebuilder-column p {
    font-size: 10px;
  }
  html body.badaboum--custom.badaboum .product-info-main .pagebuilder-column:nth-child(odd) {
    max-width: max-content;
  }
  html body.badaboum--custom.badaboum .product-info-main .pagebuilder-column-group {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1024px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .product-info-main {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .product-info-main {
    max-width: 700px;
    margin-top: 24px;
  }
}
html body.badaboum--custom.badaboum .product-info-main .page-title {
  text-align: left;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-info-main .page-title {
    margin-top: 0;
  }
}
html body.badaboum--custom.badaboum .product-info-main .actions.full {
  padding-bottom: 33px;
}
html body.badaboum--custom.badaboum .product-info-main-stock-wrapper {
  display: flex;
  margin: 20px 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-info-main-stock-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
}
html body.badaboum--custom.badaboum .product-info-main-stock-wrapper .product-view--disabled .qty-btn--minus, html body.badaboum--custom.badaboum .product-info-main-stock-wrapper .product-view--disabled .qty-btn--plus {
  cursor: not-allowed;
  opacity: 0.5;
}
html body.badaboum--custom.badaboum .product-info-main-stock-wrapper .product-view--disabled .qty-btn--minus:hover, html body.badaboum--custom.badaboum .product-info-main-stock-wrapper .product-view--disabled .qty-btn--plus:hover {
  background: #f4f3e9;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-info-main-stock-wrapper .actions {
    margin-top: 30px;
  }
}
html body.badaboum--custom.badaboum .product-info-main-stock-wrapper .actions p {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
}
html body.badaboum--custom.badaboum .product-info-main-stock-wrapper .actions .action.alert.btn {
  margin-top: 10px;
}
html body.badaboum--custom.badaboum .product-info-main .box-tocart {
  width: 100%;
}
@media (min-width: 576px) and (max-width: 2559px) {
  html body.badaboum--custom.badaboum .product-info-main .box-tocart {
    margin: 20px 0 0 0;
  }
}
html body.badaboum--custom.badaboum .product-info-main .box-tocart .fieldset {
  display: flex;
  align-items: center;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .product-info-main .box-tocart .fieldset {
    flex-direction: column;
  }
}
html body.badaboum--custom.badaboum .product-info-main .box-tocart .fieldset .qty-input {
  font-size: 16px;
  font-weight: 400;
}
html body.badaboum--custom.badaboum .product-info-main .box-tocart .fieldset .actions {
  padding-top: 0;
  width: 100%;
  padding-bottom: 30px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .product-info-main .box-tocart .fieldset .actions {
    margin-top: 30px;
  }
}
html body.badaboum--custom.badaboum .product-info-main .box-tocart .fieldset .actions .tocart {
  height: 60px;
  width: 100%;
  margin-top: 10px;
}
html body.badaboum--custom.badaboum .product-info-main .box-tocart .fieldset .actions .tocart span {
  font-weight: 200;
}
html body.badaboum--custom.badaboum .product-details-qty {
  margin-right: 35px;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-details-qty {
    margin-right: 0px;
  }
}
@media (min-width: 576px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .product-details-qty {
    margin-right: 5px;
  }
}
html body.badaboum--custom.badaboum .product-details-qty-input {
  width: 70px;
}
html body.badaboum--custom.badaboum .product-details-qty #qty-error {
  margin-top: 15px;
  position: absolute;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-details-qty #qty-error {
    margin-top: 5px;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    width: 100%;
  }
}
html body.badaboum--custom.badaboum .product-shipping_countdown {
  font-size: 12px;
  font-weight: 500;
}
html body.badaboum--custom.badaboum .product-shipping_countdown-timer, html body.badaboum--custom.badaboum .product-shipping_countdown-delay {
  color: #6DB332;
  line-height: 1;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-shipping-reassurance {
    margin-bottom: 30px;
  }
}
html body.badaboum--custom.badaboum .product-shipping-reassurance p {
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-shipping-reassurance p {
    font-size: 14px;
  }
}
html body.badaboum--custom.badaboum .product-shipping-reassurance p strong {
  font-size: 12px;
  font-weight: 600;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-shipping-reassurance p strong {
    font-size: 18px;
  }
}
html body.badaboum--custom.badaboum .product-reviews-summary .view {
  text-decoration: underline;
  font-weight: 400;
}
html body.badaboum--custom.badaboum .products-list {
  margin: 15px 0;
}
html body.badaboum--custom.badaboum .product-items {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  font-size: unset !important;
  margin: 0 -12px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-items {
    margin: 0 -15px;
  }
}
@media (min-width: 1540px) {
  html body.badaboum--custom.badaboum .product-items {
    margin: 0 -22px;
  }
}
html body.badaboum--custom.badaboum .product-card {
  height: auto;
  background: #ffffff;
  margin-bottom: 15px;
  max-width: none;
  padding: 0 3px;
  width: 50%;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card {
    width: 33.3333333333%;
    padding: 0 15px;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .product-card {
    width: 25%;
    height: auto;
  }
}
@media (min-width: 1540px) {
  html body.badaboum--custom.badaboum .product-card {
    padding: 0 22px;
  }
}
html body.badaboum--custom.badaboum .product-card-header {
  height: auto;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card-header {
    height: auto;
  }
}
html body.badaboum--custom.badaboum .product-card-header:hover .product-card-action, html body.badaboum--custom.badaboum .product-card-header:hover .product-card-action--wishlist {
  bottom: 0;
}
html body.badaboum--custom.badaboum .product-card-header-top {
  position: absolute;
  top: 0;
  display: flex;
  z-index: 2;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  height: auto;
}
html body.badaboum--custom.badaboum .product-card-img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
html body.badaboum--custom.badaboum .product-card-action {
  position: absolute;
  bottom: -100px;
  width: 100%;
  transition: bottom 0.3s ease-in-out;
  z-index: 1;
}
html body.badaboum--custom.badaboum .product-card-action .actions.full p {
  display: none;
}
html body.badaboum--custom.badaboum .product-card-action a.btn, html body.badaboum--custom.badaboum .product-card-action button.btn, html body.badaboum--custom.badaboum .product-card-action div.btn {
  text-transform: capitalize;
  height: 40px;
  margin-top: 10px;
}
html body.badaboum--custom.badaboum .product-card-action a.btn.btn--quaternary {
  margin: 0;
  background-color: #b0b3b2;
  color: #ffffff;
}
html body.badaboum--custom.badaboum .product-card-action a.btn.btn--quaternary:hover {
  background-color: #b0b3b2;
  color: #ffffff;
}
html body.badaboum--custom.badaboum .product-card-action a.btn.btn--quaternary:active {
  color: #ffffff;
}
html body.badaboum--custom.badaboum .product-card-action a.btn.btn--quaternary:active::after {
  background: #707070;
}
html body.badaboum--custom.badaboum .product-card-action a.btn.btn--quaternary::after {
  background: #cacccb;
}
html body.badaboum--custom.badaboum .product-card-action .product-info-main-stock-wrapper {
  margin: 0;
}
html body.badaboum--custom.badaboum .product-card-action .product-view--disabled {
  display: none !important;
}
html body.badaboum--custom.badaboum .product-card-action .qty--small {
  justify-content: space-between !important;
}
html body.badaboum--custom.badaboum .product-card-action .qty-input {
  width: 100px !important;
}
html body.badaboum--custom.badaboum .product-card-wishlist {
  cursor: pointer;
  margin-left: auto;
}
html body.badaboum--custom.badaboum .product-card-wishlist a.action.towishlist::before {
  content: none !important;
}
html body.badaboum--custom.badaboum .product-card-wishlist .tocompare {
  display: none;
}
html body.badaboum--custom.badaboum .product-card-wishlist em.icon:before {
  color: #818c9e;
  font-size: 20px;
  line-height: 33px;
  transition: color 0.3s ease-in-out;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card-wishlist em.icon:before {
    font-size: 24px;
  }
}
html body.badaboum--custom.badaboum .product-card-wishlist:hover em.icon:before {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .product-card-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card-content {
    padding: 15px;
  }
}
html body.badaboum--custom.badaboum .product-card-content p {
  margin-bottom: 0;
}
html body.badaboum--custom.badaboum .product-card-content-whislist {
  text-align: center;
}
html body.badaboum--custom.badaboum .product-card-content-whislist a {
  font-size: 14px;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .product-card-content-whislist a {
    font-size: 16px;
  }
}
html body.badaboum--custom.badaboum .product-card-content-whislist a::before {
  margin-right: 10px;
}
html body.badaboum--custom.badaboum .product-card-name {
  color: #2C2628;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: center;
  height: 48px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 6px 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-card-name {
    font-size: 13px;
    font-weight: 300;
    line-height: 1.5;
    height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card-name {
    font-size: 14px;
  }
}
html body.badaboum--custom.badaboum .product-card-name:hover {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .product-card-price--old-price {
  order: 1;
}
html body.badaboum--custom.badaboum .product-card-price--old-price .price {
  text-decoration: line-through !important;
  color: #2C2628 !important;
}
html body.badaboum--custom.badaboum .product-card-price--new-price {
  order: 2 !important;
}
html body.badaboum--custom.badaboum .product-card-price--new-price .price {
  font-size: 18px !important;
  color: #cd2367 !important;
  padding-left: 10px !important;
  text-decoration: none !important;
  order: 2;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card-price--new-price .price {
    font-size: 25px !important;
  }
}
html body.badaboum--custom.badaboum .product-card-price-wrapper {
  font-size: unset !important;
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .price {
  color: #cd2367;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 33px;
  text-align: center;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .price {
    font-size: 24px;
  }
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .price-as-configured {
  display: flex;
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .special-price {
  order: 2 !important;
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .special-price .price {
  font-size: 18px !important;
  color: #cd2367 !important;
  padding-left: 10px !important;
  text-decoration: none !important;
  order: 2;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .special-price .price {
    font-size: 24px !important;
  }
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .old-price {
  order: 1;
  color: #2C2628 !important;
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .old-price .price {
  display: inline-block;
  position: relative;
  color: #2C2628 !important;
  font-size: 16px;
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .old-price .price::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 1px;
  background: #000000;
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .price-label {
  display: none !important;
}
html body.badaboum--custom.badaboum .product-card-price-wrapper .price-box .promo-product {
  display: none !important;
}
html body.badaboum--custom.badaboum .product-card-status {
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .product-card-status {
    font-size: 12px;
  }
}
html body.badaboum--custom.badaboum .product-card-status--stock {
  color: #6DB332;
}
html body.badaboum--custom.badaboum .product-card-status--out-of-stock {
  color: #cd2367;
}
html body.badaboum--custom.badaboum .product-details .block.upsell {
  margin-top: 2.7rem;
}
html body.badaboum--custom.badaboum .product-details .tabs {
  order: 2;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-details .tabs {
    width: auto;
    position: unset;
    transform: none;
    margin: 0 -20px;
    font-weight: 300;
    font-size: 13px;
    line-height: 1.6;
  }
}
html body.badaboum--custom.badaboum .product-details .tabs-header {
  order: 1;
  justify-content: center;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-details .tabs-header {
    width: auto;
    margin: 0 -20px;
    position: unset;
    transform: none;
  }
}
html body.badaboum--custom.badaboum .product-details .tabs-item {
  max-width: 200px;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-details .tabs-item {
    color: #2c262877;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-details .tabs-item::after {
    display: none;
  }
  html body.badaboum--custom.badaboum .product-details .tabs-item:hover, html body.badaboum--custom.badaboum .product-details .tabs-item.active {
    color: #2C2628;
    background: #F4F3E9;
  }
}
html body.badaboum--custom.badaboum .product-details .tabs-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
html body.badaboum--custom.badaboum .product-details .tab--active {
  padding: 40px 30px 40px;
  max-width: 1015px;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-details .tab--active {
    padding: 10px 15px 10px;
  }
}
html body.badaboum--custom.badaboum .product-details .tab#additional {
  display: none;
}
html body.badaboum--custom.badaboum .product-details .delivery-tab--content {
  width: 100%;
}
html body.badaboum--custom.badaboum .product-details .delivery-tab--content .pagebuilder-column-group {
  justify-content: space-between;
}
html body.badaboum--custom.badaboum .product-details .delivery-tab--content .pagebuilder-column-group .pagebuilder-column:nth-child(odd) {
  width: 3% !important;
}
html body.badaboum--custom.badaboum .product-details .delivery-tab--content .pagebuilder-column-group .pagebuilder-column:nth-child(even) {
  width: 30% !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .product-details .delivery-tab--content .pagebuilder-column-group .pagebuilder-column {
    flex-basis: unset;
  }
  html body.badaboum--custom.badaboum .product-details .delivery-tab--content .pagebuilder-column-group .pagebuilder-column:nth-child(odd) {
    width: 7% !important;
  }
  html body.badaboum--custom.badaboum .product-details .delivery-tab--content .pagebuilder-column-group .pagebuilder-column:nth-child(even) {
    width: 91% !important;
  }
  html body.badaboum--custom.badaboum .product-details .delivery-tab--content .pagebuilder-column-group .pagebuilder-column p:last-child {
    margin-bottom: 15px;
  }
}
html body.badaboum--custom.badaboum .product-details .delivery-tab--content .pagebuilder-column-group .pagebuilder-column p {
  margin-bottom: 0;
  padding-left: 10px;
}
html body.badaboum--custom.badaboum .product-details .delivery-tab--content .shipping-delivery--img {
  width: 25px;
}
html body.badaboum--custom.badaboum .product-full-width-section {
  padding-top: 0;
}

.fotorama-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #D8D8D8;
  opacity: 0;
  display: none;
  z-index: 99;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__stage {
    padding-top: 30% !important;
    min-height: 798px !important;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__stage__shaft {
    margin-top: 0;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__stage__frame {
    top: 15%;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__nav-wrap {
    margin-top: 0;
    margin-bottom: 0;
    bottom: 250px;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .fotorama--fullscreen {
    height: 90vh !important;
    width: 90vw !important;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__stage {
    height: 921px !important;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__img--full {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .fotorama--fullscreen {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__stage {
    margin-left: 0;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__nav {
    display: flex;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__nav-wrap {
    position: absolute;
    bottom: 0;
  }
  html body.badaboum--custom.badaboum .fotorama--fullscreen .fotorama__nav__shaft {
    margin: auto;
    padding: 0;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .fotorama--fullscreen {
    width: 995px !important;
    height: 906px !important;
  }
}
html body.badaboum--custom.badaboum .fotorama__nav__shaft {
  background: none;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .fotorama__nav__shaft {
    padding: 40px 0;
  }
}
html body.badaboum--custom.badaboum .fotorama__thumb {
  border: 0;
  border: 1px solid transparent;
  transition: border 0.4s;
  cursor: pointer;
}
html body.badaboum--custom.badaboum .fotorama__thumb--icon {
  display: none;
}
html body.badaboum--custom.badaboum .fotorama__thumb__arr::before {
  font-family: "badaboum";
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 10px;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .fotorama__thumb__arr--left::before {
    content: "\e90e";
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .fotorama__thumb__arr--right {
    bottom: -3px;
    height: 20px;
  }
  html body.badaboum--custom.badaboum .fotorama__thumb__arr--right::before {
    top: auto;
    bottom: 8px;
    transform: translate(-50%, 0);
    content: "\e90d";
  }
}
html body.badaboum--custom.badaboum .fotorama__thumb-border {
  display: none;
}
html body.badaboum--custom.badaboum .fotorama__arr {
  background: none;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .fotorama__arr {
    display: block !important;
    width: 50px;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .fotorama__arr {
    width: 100px;
  }
}
html body.badaboum--custom.badaboum .fotorama__arr__arr {
  background: none;
  font-family: "badaboum";
  width: 30px;
  height: 30px;
  position: relative;
  background: #cd2367;
  border-radius: 50%;
}
html body.badaboum--custom.badaboum .fotorama__arr__arr::before {
  color: #ffffff;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "\e911";
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .fotorama__arr__arr {
    display: none;
  }
}
html body.badaboum--custom.badaboum .fotorama__arr--prev > * {
  transform: translate(-50%, -50%) rotate(180deg);
}
@media (min-width: 0px) and (max-width: 1023px) {
  .fotorama__nav-wrap {
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .fotorama__nav__frame {
    width: 29px;
    height: 2px;
  }
  html body.badaboum--custom.badaboum .fotorama__nav__frame--dot {
    text-align: center;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .fotorama__nav__frame {
    margin-bottom: 0;
    padding-bottom: 10px !important;
  }
}
html body.badaboum--custom.badaboum .fotorama__dot {
  width: 26px;
  background: #2C2628;
  transition: 0.5s;
  border: 0;
  top: 0;
  left: 0;
  display: inline-block;
  opacity: 0.4;
}
html body.badaboum--custom.badaboum .fotorama__active .fotorama__dot {
  background: #2C2628;
  opacity: 1;
}
@media (min-width: 0px) and (max-width: 767px) {
  .fotorama__stage {
    height: auto !important;
    padding-top: 100%;
  }
  .fotorama__stage__shaft {
    margin-top: -100%;
  }
}
@media (min-width: 1024px) {
  .fotorama__stage {
    margin-left: 20px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .fotorama__stage__frame {
    height: 100vw !important;
  }
}
html body.badaboum--custom.badaboum .fotorama__stage__frame .fotorama__img {
  cursor: -moz-zoom-in !important;
  cursor: -webkit-zoom-in !important;
  cursor: zoom-in !important;
  top: 0 !important;
  transform: translateX(-50%) !important;
  -webkit-transform: none !important;
  position: static;
  object-fit: initial;
}
html body.badaboum--custom.badaboum .fotorama__active > .fotorama__thumb {
  border-color: #cd2367 !important;
}

html body.badaboum--custom.badaboum .slick-slide .product-card {
  padding: 0;
}

@media (min-width: 0px) {
  .tabs {
    background: #F4F3E9;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 0px) and (min-width: 0px) and (max-width: 767px) {
  .tabs .common-slider {
    margin-left: -20px;
  }
}
@media (min-width: 0px) {
  .tabs-container {
    width: 100%;
  }
}
@media (min-width: 0px) {
  .tabs--gap {
    padding: 0 10px;
    margin-bottom: -45px;
  }
}
@media (min-width: 0px) and (min-width: 768px) {
  .tabs--gap {
    margin-top: 0;
  }
}
@media (min-width: 0px) {
  .tabs--gap--mobile {
    margin-bottom: 30px;
  }
}
@media (min-width: 0px) and (min-width: 768px) {
  .tabs--gap--mobile {
    margin: 0;
  }
}
@media (min-width: 0px) {
  .tabs-header {
    display: flex;
    flex-wrap: nowrap;
    width: 100vw;
    margin: 0 -15px;
    position: relative;
    overflow: auto;
  }
}
@media (min-width: 0px) and (min-width: 768px) {
  .tabs-header {
    width: 100%;
    justify-content: start;
    margin: 0;
  }
}
@media (min-width: 0px) and (min-width: 1280px) {
  .tabs-header.visible-lg {
    display: flex !important;
  }
}
@media (min-width: 0px) and (min-width: 1540px) {
  .tabs-header.visible-xl {
    display: flex !important;
  }
}
@media (min-width: 0px) {
  .tabs-header-row {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 0px) {
  .tabs-header--icon {
    margin: 0 -6px;
  }
}
@media (min-width: 0px) {
  .tabs-header--icon .tabs-header-item {
    font-size: 0;
    padding: 10px 5px 20px;
    margin: 0 6px;
    border-radius: 3px 3px 0 0;
  }
  .tabs-header--icon .tabs-header-item::after {
    width: 40px;
  }
  .tabs-header--icon .tabs-header-item::before {
    font-size: 36px;
    display: block;
  }
}
@media (min-width: 0px) {
  .tabs-header--small {
    align-items: center;
  }
}
@media (min-width: 0px) and (min-width: 1024px) {
  .tabs-header--small {
    white-space: nowrap;
  }
}
@media (min-width: 0px) {
  .tabs-header--small .tabs-header-item {
    font-size: 13px;
    padding: 8px 8px;
    background: none;
    margin: 0;
  }
}
@media (min-width: 0px) and (min-width: 768px) {
  .tabs-header--small .tabs-header-item {
    padding: 8px 8px;
  }
}
@media (min-width: 0px) and (min-width: 1024px) {
  .tabs-header--small .tabs-header-item {
    padding: 18px 8px;
    font-size: 16px;
  }
}
@media (min-width: 0px) {
  .tabs-header--medium {
    margin: 0 -7px;
  }
}
@media (min-width: 0px) {
  .tabs-header--medium .tabs-header-item {
    margin: 0 7px;
    font-size: 16px;
    padding: 19px 10px;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (min-width: 0px) {
  .tabs-header-item {
    padding: 18px 11px;
    width: 100%;
    flex: 1;
    cursor: pointer;
    -webkit-transition: background 0.3s, border-color 0.3s;
    -o-transition: background 0.3s, border-color 0.3s;
    transition: background 0.3s, border-color 0.3s;
    color: #2C2628;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: center;
    background: #F4F3E9;
    display: block;
    border: 0px;
    position: relative;
    transition: 0.5s;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: capitalize;
    white-space: nowrap;
    max-width: 220px;
  }
  .tabs-header-item::before {
    display: none;
  }
  .tabs-header-item > a {
    color: #2C2628;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: center;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
  }
  .tabs-header-item:nth-child(even) {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (min-width: 0px) and (min-width: 768px) {
  .tabs-header-item .tabs-header-item + .tabs-header-item {
    display: inline-block;
    text-align-last: auto;
  }
}
@media (min-width: 0px) {
  .tabs-header-item.active:hover, .tabs-header-item:hover {
    background: #df4482;
  }
  .tabs-header-item.active:hover > a, .tabs-header-item:hover > a {
    color: #ffffff;
  }
}
@media (min-width: 0px) {
  .tabs-header-item::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 67px;
    bottom: 0;
    left: 50%;
    background: #cd2367;
    transform: translateX(-50%) scaleX(0);
    opacity: 0;
    transition: 0.75s;
  }
}
@media (min-width: 0px) and (min-width: 1024px) {
  .tabs-header-item::after {
    width: 100px;
  }
}
@media (min-width: 0px) {
  .tabs-header-item.active::after {
    opacity: 1;
    transform: translateX(-50%);
  }
}
@media (min-width: 0px) {
  .tabs-header-item.active {
    color: #ffffff;
    background-color: #cd2367;
  }
  .tabs-header-item.active > a {
    color: #ffffff;
  }
}
@media (min-width: 0px) and (min-width: 0px) and (max-width: 1023px) {
  .tabs-header-item--secondary {
    color: #2c262880;
  }
  .tabs-header-item--secondary.active::after, .tabs-header-item--secondary.active:hover::after {
    display: none;
  }
}
@media (min-width: 0px) {
  .tab {
    opacity: 0;
    max-height: 0;
    pointer-events: none;
    display: none;
  }
  .tab .tab--active {
    padding: 0;
    overflow: visible;
  }
}
@media (min-width: 0px) {
  .tab--active {
    opacity: 1;
    pointer-events: initial;
    max-height: none;
    display: block;
    padding: 20px 20px 40px;
    max-width: 1540px;
    width: 100%;
    margin: auto;
  }
}
@media (min-width: 0px) and (min-width: 1280px) {
  .tab--active {
    padding: 20px 20px 40px;
  }
}
@media (min-width: 0px) {
  .tab-description {
    margin: 0 auto 20px;
    color: #2C2628;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }
}
@media (min-width: 0px) and (min-width: 768px) {
  .tab-description {
    padding: 0 10px;
    margin: 20px auto 20px;
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs {
    background: #F4F3E9;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs .common-slider {
    margin-left: -20px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-container {
    width: 100%;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs--gap {
    padding: 0 10px;
    margin-bottom: -45px;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 768px) {
  .mtabs .tabs--gap {
    margin-top: 0;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs--gap--mobile {
    margin-bottom: 30px;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 768px) {
  .mtabs .tabs--gap--mobile {
    margin: 0;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header {
    display: flex;
    flex-wrap: nowrap;
    width: 100vw;
    margin: 0 -15px;
    position: relative;
    overflow: auto;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 768px) {
  .mtabs .tabs-header {
    width: 100%;
    justify-content: start;
    margin: 0;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 1280px) {
  .mtabs .tabs-header.visible-lg {
    display: flex !important;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 1540px) {
  .mtabs .tabs-header.visible-xl {
    display: flex !important;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header-row {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header--icon {
    margin: 0 -6px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header--icon .mtabs .tabs-header-item {
    font-size: 0;
    padding: 10px 5px 20px;
    margin: 0 6px;
    border-radius: 3px 3px 0 0;
  }
  .mtabs .tabs-header--icon .mtabs .tabs-header-item::after {
    width: 40px;
  }
  .mtabs .tabs-header--icon .mtabs .tabs-header-item::before {
    font-size: 36px;
    display: block;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header--small {
    align-items: center;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 1024px) {
  .mtabs .tabs-header--small {
    white-space: nowrap;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header--small .tabs-header-item {
    font-size: 13px;
    padding: 8px 8px;
    background: none;
    margin: 0;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 768px) {
  .mtabs .tabs-header--small .tabs-header-item {
    padding: 8px 8px;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 1024px) {
  .mtabs .tabs-header--small .tabs-header-item {
    padding: 18px 8px;
    font-size: 16px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header--medium {
    margin: 0 -7px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header--medium .mtabs .tabs-header-item {
    margin: 0 7px;
    font-size: 16px;
    padding: 19px 10px;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header-item {
    padding: 18px 11px;
    width: 100%;
    flex: 1;
    cursor: pointer;
    -webkit-transition: background 0.3s, border-color 0.3s;
    -o-transition: background 0.3s, border-color 0.3s;
    transition: background 0.3s, border-color 0.3s;
    color: #2C2628;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: center;
    background: #F4F3E9;
    display: block;
    border: 0px;
    position: relative;
    transition: 0.5s;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: capitalize;
    white-space: nowrap;
    max-width: 220px;
  }
  .mtabs .tabs-header-item::before {
    display: none;
  }
  .mtabs .tabs-header-item > a {
    color: #2C2628;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: center;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
  }
  .mtabs .tabs-header-item:nth-child(even) {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 768px) {
  .mtabs .tabs-header-item .mtabs .tabs-header-item + .mtabs .tabs-header-item {
    display: inline-block;
    text-align-last: auto;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header-item.active:hover, .mtabs .tabs-header-item:hover {
    background: #df4482;
  }
  .mtabs .tabs-header-item.active:hover > a, .mtabs .tabs-header-item:hover > a {
    color: #ffffff;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header-item::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 67px;
    bottom: 0;
    left: 50%;
    background: #cd2367;
    transform: translateX(-50%) scaleX(0);
    opacity: 0;
    transition: 0.75s;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 1024px) {
  .mtabs .tabs-header-item::after {
    width: 100px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header-item.active::after {
    opacity: 1;
    transform: translateX(-50%);
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tabs-header-item.active {
    color: #ffffff;
    background-color: #cd2367;
  }
  .mtabs .tabs-header-item.active > a {
    color: #ffffff;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 0px) and (max-width: 1023px) {
  .mtabs .tabs-header-item--secondary {
    color: #2c262880;
  }
  .mtabs .tabs-header-item--secondary.active::after, .mtabs .tabs-header-item--secondary.active:hover::after {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tab {
    opacity: 0;
    max-height: 0;
    pointer-events: none;
    display: none;
  }
  .mtabs .tab .mtabs .tab--active {
    padding: 0;
    overflow: visible;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tab--active {
    opacity: 1;
    pointer-events: initial;
    max-height: none;
    display: block;
    padding: 20px 20px 40px;
    max-width: 1540px;
    width: 100%;
    margin: auto;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 1280px) {
  .mtabs .tab--active {
    padding: 20px 20px 40px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .mtabs .tab-description {
    margin: 0 auto 20px;
    color: #2C2628;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
  }
}
@media (min-width: 0px) and (max-width: 767px) and (min-width: 768px) {
  .mtabs .tab-description {
    padding: 0 10px;
    margin: 20px auto 20px;
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 0px) {
  .mtabs .tab {
    opacity: 1;
    max-height: none;
    pointer-events: all;
    padding: 0;
  }
}

.page-header {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.header {
  order: 1;
  padding: 20px;
  width: 100% !important;
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  .header {
    order: 2;
  }
}
.header-content {
  padding: 20px 0;
  display: flex !important;
  align-items: center;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .header-content {
    flex-wrap: wrap;
    padding-bottom: 7px;
    justify-content: space-between;
    align-items: baseline;
  }
}
.header-top-banner {
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 !important;
}
@media (min-width: 768px) {
  .header-top-banner {
    font-size: 14px;
    line-height: 1.4;
  }
}
.header-top-banner-wrapper {
  padding: 8px;
  background: #cd2367;
  order: 2;
}
@media (min-width: 768px) {
  .header-top-banner-wrapper {
    order: 1;
    padding: 8px 15px;
  }
}
.header-burger-button {
  display: inline-flex !important;
  order: 3;
  margin: 0 !important;
}
@media (min-width: 768px) {
  .header-burger-button {
    display: none;
  }
}
.header-link {
  position: relative;
}
.header-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  order: 2;
  width: 48%;
  margin-right: 8px;
}
@media (min-width: 768px) and (max-width: 1279px) {
  .header-links {
    margin-bottom: 0;
    margin-right: 10px;
  }
}
@media (min-width: 1024px) {
  .header-links {
    margin-top: 5px;
  }
}
@media (min-width: 1024px) {
  .header-links {
    order: 3;
    width: 155px;
  }
}
.header-link:nth-child(even) {
  margin: 0 20px;
}
.header-link:hover .header-icon-hover-overlay {
  opacity: 0.5;
}
.header-link-sticker {
  position: absolute;
  top: -8px;
  right: -15px;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #cd2367;
  padding: 10px;
  color: #ffffff;
  font-size: 12px;
  display: none;
}
@media (min-width: 0px) and (max-width: 767px) {
  .header-link-sticker {
    top: -5px;
    right: -10px;
    font-size: 10px;
  }
}
.header-link-sticker--visible {
  display: flex;
}
.header .logo {
  margin: 0;
  padding: 0;
  max-width: unset;
  min-width: 100%;
}
.header .logo-wrapper {
  width: 49%;
  order: 1;
  height: fit-content;
  margin: 0 auto 0 0;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .header .logo-wrapper img {
    max-width: 180px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .header .logo-wrapper {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .header .logo-wrapper {
    margin-right: auto !important;
  }
}
@media (min-width: 1024px) {
  .header .logo-wrapper {
    width: 300px;
  }
}
.header-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-btn {
  height: 43px;
  line-height: 43px;
  margin-right: 25px;
}
.header-icon {
  color: #2C2628;
  transition: 0.5s;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .header-icon {
    font-size: 85px;
    line-height: 0px;
  }
}
.header-icon:hover::before, .header-icon:hover::after {
  color: #980C3D;
}
.header-icon::after, .header-icon::before {
  transition: 0.5s;
  font-size: 20px;
}
@media (min-width: 768px) {
  .header-icon::after, .header-icon::before {
    font-size: 24px;
  }
}
.header-icon-hover {
  display: block;
  box-shadow: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s, margin 0.3s;
  color: #2C2628;
  position: relative;
  padding-top: 15px;
  position: absolute;
  right: -10px;
  min-width: 300px;
  background: none;
  border: 0;
  margin-top: -10px;
  z-index: 99;
}
@media (min-width: 0px) and (max-width: 767px) {
  .header-icon-hover {
    display: none !important;
  }
}
.header-icon-hover--account {
  right: -23px;
}
.header-icon-hover--wishlist {
  right: -20px;
}
.header-icon-hover .minicart {
  display: block !important;
}
:hover > .header-icon-hover, :hover + .header-icon-hover {
  margin-top: 0;
  opacity: 1;
  pointer-events: initial;
}
.header-icon-hover-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #D8D8D8;
  opacity: 0;
  z-index: 99;
  pointer-events: none;
  transition: opacity 0.5s ease;
}
@media (min-width: 0px) and (max-width: 767px) {
  .header-icon-hover-overlay {
    display: none !important;
  }
}
html body.badaboum--custom.badaboum .header-icon-hover-content {
  background-color: #ffffff;
  padding: 20px;
  display: block !important;
  position: relative;
  width: 400px;
}
html body.badaboum--custom.badaboum .header-icon-hover-content::before {
  content: "";
  position: absolute;
}
html body.badaboum--custom.badaboum .header-icon-hover-content::before {
  z-index: 151;
  border: 6px solid;
  border-color: transparent transparent #ffffff transparent;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .header-icon-hover-content::before {
    right: 26px;
    top: -12px;
  }
}
html body.badaboum--custom.badaboum .header-icon-hover-content--account {
  width: auto;
}
html body.badaboum--custom.badaboum .header-icon-hover-content--account .btns .btn:not([style*="display: none"]) + .btn {
  margin-left: 0;
}
.header-minicart {
  position: relative;
  margin: 0 !important;
  color: #2C2628;
  z-index: 100;
}
.header-search {
  position: relative;
  width: calc(100% - 35px);
  order: 4 !important;
  padding: 0;
  margin-left: auto;
  margin-top: 0;
}
@media (min-width: 768px) {
  .header-search {
    width: calc(100% - 300px);
    margin: 0 40px;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .header-search {
    margin: 15px 0 0 auto;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .header-search {
    width: 95%;
  }
}
@media (min-width: 1024px) {
  .header-search {
    order: 2 !important;
  }
}
@media (min-width: 1280px) {
  .header-search {
    order: initial;
    margin-top: 0;
  }
}
html body.badaboum--custom.badaboum .header-search-input {
  color: #2C2628 !important;
  border-radius: 20px !important;
  padding: 0 20px !important;
  line-height: 40px !important;
  height: 40px;
  margin-right: 5px !important;
  width: 100% !important;
  border: 1px solid #cd2367 !important;
  transition: border 0.5s, opacity 0.3s, box-shadow 0.3s !important;
}
@media (min-width: 1024px) and (max-width: 1539px) {
  html body.badaboum--custom.badaboum .header-search-input {
    margin: 0 15px;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .header-search-input {
    position: relative;
    left: 0 !important;
    margin: 0 !important;
    position: relative !important;
  }
}
html body.badaboum--custom.badaboum .header-search-input:not([disabled]):focus {
  box-shadow: 0 0 2px 1px #cd2367;
}
html body.badaboum--custom.badaboum .header-search-input::placeholder {
  opacity: 1;
  font-family: "Montserrat";
  color: #000000;
  font-weight: 300;
  font-size: 13px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .header-search .label {
    position: absolute;
    z-index: 2;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.header-search .label::before {
  color: #000000;
  font-size: 22px;
  display: none;
}
.header-search-submit {
  padding: 0;
  border: 0 !important;
  font-size: 21px !important;
  position: absolute !important;
  top: 50% !important;
  right: 17px !important;
  opacity: 0.5 !important;
  transform: translate(0, -40%) !important;
  background: none !important;
}
@media (min-width: 1024px) {
  .header-search-submit {
    right: 0 !important;
  }
}
@media (min-width: 1540px) {
  .header-search-submit {
    right: 17px !important;
  }
}
.header-search-submit:before {
  content: "\e907" !important;
  font-family: "badaboum" !important;
}

.menu {
  position: relative;
  background-color: #ffffff !important;
}
.menu-hamburger-button {
  order: 3;
  margin: 0 !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .menu-hamburger-button {
    transform: translateY(-5px);
  }
}
.menu-list {
  justify-content: space-between;
  max-width: 1520px !important;
  position: unset !important;
}
.menu-item:nth-last-child(-n+2) .menu-item-content {
  color: #cd2367 !important;
}
.menu-item:nth-last-child(-n+2):hover {
  background: #cd2367 !important;
}
.menu-item:nth-last-child(-n+2):hover, .menu-item:nth-last-child(-n+2):hover .menu-item-content {
  color: #ffffff !important;
}
.menu-link {
  position: relative;
  padding: 20px 10px !important;
  transition: 0.5s;
}
.menu-link:hover {
  background: #cd2367 !important;
}
.menu-link:hover, .menu-link:hover p {
  color: #ffffff !important;
}
.menu-link::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 95%;
  bottom: 0;
  left: 50%;
  background: #cd2367;
  transform: translateX(-50%) scaleX(0);
  opacity: 0;
  transition: 0.75s;
}
.menu-link.-current:hover {
  background: #cd2367 !important;
}
.menu-link.-current::after {
  opacity: 1;
  transform: translateX(-50%);
}
.menu-link.-current p {
  color: #cd2367;
}
.menu-link p {
  color: #2C2628;
  font-family: "Montserrat - Medium";
  font-size: 15px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  font-weight: 500 !important;
}
.menu_submenu {
  background: #f8f8f8 !important;
}
.menu_submenu-category-section {
  column-count: 4;
  display: block;
  max-width: 1520px;
  margin: auto;
}
.menu_submenu-column {
  -webkit-column-break-inside: avoid;
  break-inside: avoid-column;
  width: 100% !important;
  padding-right: 0 !important;
  max-width: 100% !important;
  display: block !important;
}
.menu_submenu-wrapper {
  max-width: 1540px;
  margin: 0 auto;
}
.menu_submenu-wrapper .pagebuilder-column-group {
  gap: 0 1.3em;
}
html body.badaboum--custom.badaboum .menu_submenu-title {
  color: #2C2628 !important;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  cursor: pointer !important;
  pointer-events: auto !important;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
html body.badaboum--custom.badaboum .menu_submenu-title:hover {
  color: #cd2367 !important;
}
.menu_submenu-list {
  padding-left: 15px !important;
}
.menu_submenu-list .menu_submenu-item-link p + .menu_submenu-icon {
  display: none;
}
.menu_submenu-item {
  position: relative;
  padding-left: 10px;
}
.menu_submenu-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background: #cd2367;
}
@media (min-width: 768px) {
  .menu_submenu-item:first-child {
    display: none;
  }
}
.menu_submenu-item-link {
  pointer-events: auto;
  cursor: pointer;
  padding: 0 0 10px 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  .menu_submenu-item-link {
    padding: 0 0 0 0 !important;
  }
}
.menu_submenu-item-link:hover p {
  color: #cd2367;
}
.menu_submenu-item-link.-current p {
  color: #cd2367 !important;
}
.menu_submenu-item-link p {
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 200;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
.menu_submenu-link a {
  cursor: pointer;
  pointer-events: auto;
  color: #cd2367;
  text-decoration: underline;
}
.menu_submenu-icon {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid rgba(61, 61, 61, 0.1);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1280px) {
  .menu_submenu-icon figure {
    transform: none !important;
  }
}
@media (min-width: 0px) and (max-width: 1279px) {
  .menu_submenu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu_submenu-icon .ammenu-icon-block {
    width: 18px;
    height: 18px;
    min-width: 18px;
  }
  .menu_submenu-icon.-toggle {
    margin-left: auto !important;
  }
}
.menu_submenu-icon .ammenu-icon-block {
  width: 100% !important;
  height: 100% !important;
  margin-right: 0 !important;
}
.menu_submenu-icon-wrapper .menu_submenu-category-section {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  gap: 1.2em;
}
.menu_submenu-icon-wrapper .menu_submenu-column {
  position: relative;
  width: calc(33.3333333333% - 1.2em) !important;
  padding-bottom: 0;
  margin-bottom: 20px;
}
.menu_submenu-icon-wrapper .menu_submenu-column::after, .menu_submenu-icon-wrapper .menu_submenu-column::before {
  content: "";
  position: absolute;
  right: 75px;
  bottom: 0;
  width: 50%;
  height: 2px;
  border-bottom: 1px solid rgba(61, 61, 61, 0.1);
}
.menu_submenu-icon-wrapper .menu_submenu-column::before {
  width: 0;
  border-bottom: 1px solid #cd2367;
  transition: width 0.5s ease-in-out;
}
.menu_submenu-icon-wrapper .menu_submenu-column:hover::before {
  width: 50%;
}
.menu_submenu-icon-right .menu_submenu-right-single-image {
  max-width: 335px;
  max-height: 170px;
}
.menu_submenu-icon-right .menu_submenu-right-single-image:last-child {
  margin-top: 30px;
}
.menu_submenu-right {
  border-left: 1px solid rgba(61, 61, 61, 0.1);
  padding-left: 25px;
  height: fit-content !important;
}
.menu_submenu-right-title {
  color: #cd2367;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 0;
  text-align: left;
  text-decoration: underline;
  text-transform: uppercase;
}
.menu_submenu-right-color-list {
  margin-bottom: 40px;
  width: 345px;
}
.menu_submenu-right-color-list ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2em;
}
.menu_submenu-right-color-list ul li {
  display: flex;
  align-items: center;
  gap: 1.2em;
  width: calc(50% - 1.2em);
  position: relative;
}
.menu_submenu-right-color-list ul li::after, .menu_submenu-right-color-list ul li::before {
  content: "";
  position: absolute;
  right: 25px;
  bottom: 0;
  width: 50%;
  height: 2px;
  border-bottom: 1px solid rgba(61, 61, 61, 0.1);
}
.menu_submenu-right-color-list ul li::before {
  width: 0;
  border-bottom: 1px solid #cd2367;
  transition: width 0.5s ease-in-out;
}
.menu_submenu-right-color-list ul li:hover::before {
  width: 50%;
}
.menu_submenu-right-color-list ul li:hover img {
  border-color: #cd2367;
}
.menu_submenu-right-color-list ul li img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #3d3d3d;
  padding: 2px;
  transition: border-color 0.5s ease-in-out;
}
.menu_submenu-right-color-list ul li a {
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
.menu_submenu-right-image-wrapper {
  max-width: 335px;
  max-height: 171px;
  height: 100%;
  width: 100%;
}
.menu_submenu-right-single-image {
  max-width: 600px;
  max-height: 305px;
  height: 100%;
  width: 100%;
}
.menu_submenu-right-double-image-list ul {
  display: flex;
  gap: 0 1.2em;
}
.menu_submenu-right-multiple-image-list ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2em;
}
.menu_submenu-right-multiple-image-list ul li {
  width: calc(50% - 1.2em);
  max-height: 135px;
}
.menu_submenu-right-multiple-image-list ul li img {
  width: 100%;
  height: auto !important;
}
.menu_submenu-right-double-image-list img {
  height: auto !important;
}
.menu_sidebar {
  display: flex;
  flex-direction: column;
  width: 90vw !important;
  height: 100vh !important;
}
.menu_sidebar.ammenu-nav-sections.-sidebar.-mobile.-drill.-animation-undefined.-opened #axeptio_main_button {
  display: none !important;
}
.menu_sidebar-title-wrapper {
  padding: 25px 20px;
  display: flex;
  align-items: center;
}
.menu_sidebar-title-wrapper .logo {
  max-width: 50%;
}
.menu_sidebar-title-wrapper .logo-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}
.menu_sidebar-close {
  position: absolute;
}
html body.badaboum--custom.badaboum .menu_sidebar-close::after, html body.badaboum--custom.badaboum .menu_sidebar-close::before {
  height: 30px;
  width: 2px;
}
.menu_sidebar-section, .menu_sidebar-wrapper {
  height: fit-content !important;
}
.menu_sidebar-section-account, .menu_sidebar-wrapper-account {
  margin-top: 20px;
  margin-bottom: 20px;
}
.menu_sidebar-section-account .menu_sidebar-active-level, .menu_sidebar-wrapper-account .menu_sidebar-active-level {
  display: none !important;
}
.menu_sidebar-section-account .menu_sidebar-list, .menu_sidebar-wrapper-account .menu_sidebar-list {
  display: flex !important;
  gap: 15px;
}
.menu_sidebar-section-account .menu_sidebar-item, .menu_sidebar-wrapper-account .menu_sidebar-item {
  width: calc(50% - 7.5px);
}
.menu_sidebar-section-account .menu_sidebar-item:first-child, .menu_sidebar-wrapper-account .menu_sidebar-item:first-child {
  border-bottom: 0;
  border-top: 0;
}
.menu_sidebar-section-account .menu_sidebar-item:last-child, .menu_sidebar-wrapper-account .menu_sidebar-item:last-child {
  border: 0;
}
.menu_sidebar-section-account .menu_sidebar-item:not(:first-child) .menu_submenu-item-link, .menu_sidebar-wrapper-account .menu_sidebar-item:not(:first-child) .menu_submenu-item-link {
  border-bottom: 1px solid #D8D8D8 !important;
}
.menu_sidebar-section-account .menu_sidebar-item .menu_submenu-item-link, .menu_sidebar-wrapper-account .menu_sidebar-item .menu_submenu-item-link {
  border: 1px solid #D8D8D8 !important;
  padding: 0 10px !important;
}
.menu_sidebar-section-account .menu_sidebar-item .menu_submenu-item-link .ammenu-text, .menu_sidebar-wrapper-account .menu_sidebar-item .menu_submenu-item-link .ammenu-text {
  text-align: center;
  word-break: inherit;
}
.menu_sidebar-section-account .menu_sidebar-item .menu_submenu-item-link .ammenu-text-block, .menu_sidebar-wrapper-account .menu_sidebar-item .menu_submenu-item-link .ammenu-text-block {
  width: 100%;
  display: unset !important;
  text-align: center !important;
  padding: 0 15px;
}
.menu_sidebar-section-account .menu_sidebar-item .menu_submenu-item-link .ammenu-icon-block.-icon, .menu_sidebar-wrapper-account .menu_sidebar-item .menu_submenu-item-link .ammenu-icon-block.-icon {
  margin: 0;
  width: 22px;
  height: 22px;
}
.menu_sidebar-section-account .menu_sidebar-item:nth-last-child(-n+2) .menu_submenu-item-link .ammenu-text-block, .menu_sidebar-wrapper-account .menu_sidebar-item:nth-last-child(-n+2) .menu_submenu-item-link .ammenu-text-block {
  color: #2C2628;
}
.menu_sidebar-section-account .menu_submenu-icon svg, .menu_sidebar-wrapper-account .menu_submenu-icon svg {
  width: 22px;
  height: 22px;
}
.menu_sidebar-section {
  overflow: visible !important;
}
html body.badaboum--custom.badaboum .menu_sidebar-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}
.menu_sidebar-item {
  width: 100%;
}
.menu_sidebar-item:first-child {
  color: #980C3D;
  border-top: 1px solid #a8a8a878;
}
.menu_sidebar-item:last-child {
  border-bottom: 1px solid #a8a8a878;
}
.menu_sidebar-item .menu_submenu-item-link {
  border-color: #a8a8a878 !important;
  justify-content: flex-start;
  gap: 20px;
}
.menu_sidebar-item .menu_submenu-icon {
  width: 30px !important;
  height: 30px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  border: none;
  margin-right: 0 !important;
}
.menu_sidebar-item .menu_submenu-icon svg {
  color: #2C2628;
}
.menu_sidebar-navigation {
  margin: 0;
  padding: 0 15px;
  background-color: rgba(206, 10, 78, 0.1);
  border-bottom: 0;
}
.menu_sidebar-navigation button {
  padding: 20px 0;
}
.menu_sidebar-navigation button.-prev + .-go-main {
  display: none;
}
html body.badaboum--custom.badaboum .menu_sidebar-navigation-content {
  font-weight: bold;
  font-size: 14px;
  color: #2C2628;
  margin-left: -10px;
}
.menu_sidebar-navigation .menu_submenu-icon {
  width: 20px !important;
  height: 20px !important;
  padding-right: 20px;
  padding-left: 11px;
  border: none !important;
  margin-left: 0 !important;
}
.menu_sidebar-navigation .menu_submenu-icon:before {
  content: "\e912";
  font-family: "badaboum";
  font-size: 18px;
  color: #cd2367 !important;
  font-weight: 700;
}
.menu_sidebar-navigation .menu_submenu-icon .ammenu-icon-block {
  display: none;
}
.menu_sidebar-navigation .menu_submenu-icon svg {
  color: #cd2367;
}
.menu_sidebar-active-level {
  margin-top: -74px;
  z-index: 10;
  position: relative;
  background: white;
}
.menu_sidebar-active-level .menu_sidebar-item:first-child {
  border-top: 0;
}
.menu_sidebar-active-level .menu_sidebar-item:first-child .ammenu-text-block {
  color: #cd2367;
}

.ammenu-menu-wrapper {
  margin-bottom: 0;
}

.recap {
  padding: 20px 10px;
}
.recap-title {
  font-size: 16px;
  font-weight: bold;
  color: #2C2628;
}
.recap-content {
  margin-top: 17px;
  padding: 5px 15px;
  border-radius: 4px;
  background-color: #e9e5e5;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.recap-content-icon {
  font-size: 45px;
  color: #cd2367;
  margin-right: 15px;
  margin-top: 5px;
}
.recap-content-image {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 14px;
}
.recap-content-text {
  color: #2C2628;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 0;
}
.recap-content-top {
  font-size: 16px;
  font-weight: bold;
}
.recap-content-middle {
  font-size: 14px;
  letter-spacing: 0.7px;
  padding: 5px 0;
}
.recap-content-bottom {
  font-size: 12px;
  opacity: 0.7;
}
.recap-content-link {
  color: #2C2628;
  text-decoration: underline;
  font-size: 12px;
}

.address {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 10px;
  transition: 0.5s;
  border: 2px solid transparent;
  width: 100%;
  transition: 0.5s ease-in-out;
  font-size: 14px;
}
.addresses {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
@media (min-width: 768px) {
  .addresses {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.address--selected {
  border-color: #cd2367;
}
.address:hover {
  border-color: #cd2367;
}
.address-box {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .address-box {
    max-width: 300px;
  }
}
.address:not(:first-child)::before {
  width: calc(100% + 40px);
  height: 1px;
  background-color: #e9e5e5;
  position: absolute;
  top: 0;
}
@media (min-width: 768px) {
  .address:not(:first-child)::before::before {
    display: none;
  }
}
@media (min-width: 768px) {
  .address:nth-child(2n)::before {
    width: 1px;
    height: 200px;
    background-color: #e9e5e5;
    position: absolute;
    left: -45px;
    top: 40px;
  }
}
@media (min-width: 768px) and (min-width: 1024px) {
  .address:nth-child(2n)::before {
    left: -30px;
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .address:nth-child(2n)::before {
    left: -50px;
  }
}
.address-recap {
  display: flex;
  flex-direction: row;
  background-color: #e9e5e5;
  border-radius: 4px;
  padding: 0 15px;
  margin: 10px;
}
.address-recap-title {
  font-size: 16px;
  font-weight: bold;
  padding: 26px 12px 5px 12px;
}
.address-recap-icon {
  height: 100%;
  margin-top: 10px;
  margin-right: 15px;
}
.address-recap-icon::before {
  color: #cd2367;
  font-size: 36px;
}
.address-title {
  color: #2C2628;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.address-title-icon {
  margin-right: 15px;
}
.address-title-icon::before {
  color: #cd2367;
  font-size: 30px;
}
.address-title-highlight {
  color: #cd2367;
}
.address-select {
  width: 240px;
}
.address-select > select {
  cursor: pointer;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.address-detail {
  padding-bottom: 20px;
}
.address-details {
  padding: 15px 0;
  color: #2C2628;
  font-size: 15px;
}
.address-detail-name {
  font-size: 20px;
  font-weight: bold;
}
.address-detail-link {
  color: #cd2367;
  text-decoration: underline;
  font-size: 12px;
  font-weight: bold;
}
.address-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .checkout-container .opc-estimated-wrapper {
    margin: 0 0 20px;
    padding: 20px 0 12px;
  }
}
.checkout-shipping-method {
  border-radius: 8px;
  cursor: pointer;
}
.checkout-shipping-methods {
  border-collapse: separate;
  border-spacing: 0 20px;
}
.checkout-shipping-method-col {
  background: #e9e5e5;
  border-top: none;
  vertical-align: middle !important;
}
.checkout-shipping-method-col:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 20px;
}
.checkout-shipping-method-col:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-right: 20px;
}
.checkout-shipping-method-price {
  font-size: 18px;
}
html body.badaboum--custom.badaboum .checkout-header {
  padding: 20px 0;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .checkout-header {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  html body.badaboum--custom.badaboum .checkout-header .page-description {
    margin: 0;
    font-weight: 100;
  }
}
html body.badaboum--custom.badaboum .checkout-header::before {
  background: #2C2628 !important;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header {
    margin-bottom: 0;
  }
}
html body.badaboum--custom.badaboum .checkout-header-back {
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.5s;
  font-size: 0;
  margin-right: 20px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-back {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-back:hover {
    padding-right: 3px;
  }
}
html body.badaboum--custom.badaboum .checkout-header-back::before {
  display: inline-block;
  color: #ffffff;
  padding: 5px 6px 5px 4px;
  line-height: 1;
  font-size: 12px;
  border: 2px solid;
  border-radius: 50%;
  margin-right: 10px;
  transition: 0.5s;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-back::before {
    padding: 7px 8px 7px 6px;
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-back:hover::before {
    transform: translateX(2px);
    margin-right: 7px;
  }
}
html body.badaboum--custom.badaboum .checkout-header-logo {
  max-width: 150px;
  /*color: $color-black;

  & .logo{
      font-size: 0;
      &::before{
          font-family: $icon;
          content:'\e913';
          font-size: 56px;

          @include bp(md){
              margin-right: 50px;
          }

          @include bp(lg){
              font-size: 85px;
              margin-right: 127px;
          }
      }
  }*/
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-logo {
    max-width: none;
  }
}
html body.badaboum--custom.badaboum .checkout-header-logo-img {
  display: block;
}
html body.badaboum--custom.badaboum .checkout-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-content {
    justify-content: space-between;
    margin: 0;
  }
}
html body.badaboum--custom.badaboum .checkout-header-secure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  width: 175px;
  font-weight: bold;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-secure {
    justify-content: flex-start;
    text-align: left;
    width: auto;
  }
}
html body.badaboum--custom.badaboum .checkout-header-secure::before {
  font-size: 20px;
  margin-right: 5px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-secure::before {
    font-size: 30px;
    margin-right: 10px;
  }
}
html body.badaboum--custom.badaboum .checkout-header-secure-text {
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  max-width: 82px;
  text-align: left;
  line-height: 12px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-secure-text {
    max-width: 115px;
    font-size: 14px;
    line-height: 18px;
  }
}
html body.badaboum--custom.badaboum .checkout-header-help {
  display: flex;
  text-align: center;
  white-space: none;
  align-items: center;
  text-align: left;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-help {
    margin-left: auto;
  }
}
html body.badaboum--custom.badaboum .checkout-header-help::before {
  font-size: 0;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-help::before {
    font-size: 32px;
    padding-right: 13px;
  }
}
html body.badaboum--custom.badaboum .checkout-header-help-text {
  display: block;
  font-weight: 700;
  font-size: 0;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .checkout-header-help-text {
    font-size: 12px;
  }
}
html body.badaboum--custom.badaboum .checkout-header-help-text::before {
  font-size: 30px;
}
.checkout-footer {
  margin-top: 80px;
}
.checkout-footer-content {
  width: 100%;
  max-width: 1150px;
  padding: 0 25px;
  margin: 0 auto;
}
.checkout-footer-items {
  border-top: 1px solid;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding: 20px;
}
@media (min-width: 768px) {
  .checkout-footer-items {
    padding: 30px 25px;
    flex-wrap: nowrap;
    margin-bottom: 0;
  }
}
.checkout-footer-item {
  font-size: 14px;
  line-height: 1.8;
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) {
  .checkout-footer-item {
    line-height: 32px;
    width: auto;
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 768px) {
  .checkout-footer-item + .checkout-footer-item::before {
    content: "|";
    padding: 0 4px;
  }
}
.checkout-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #2C2628;
  padding: 5px 0 27px 15px;
}
@media (min-width: 768px) {
  .checkout-title {
    padding: 5px 0 45px 15px;
  }
}
.checkout-title::before {
  font-size: 30px;
  margin-right: 15px;
}
.checkout-title--center {
  width: 100%;
  justify-content: center;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .checkout-title--center {
    justify-content: left;
    padding-left: 0;
    font-size: 18px;
    padding-bottom: 20px;
  }
}
.checkout-title--noicon {
  padding-top: 10px;
}
.checkout-subtitle {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 25px;
}
.checkout-text {
  padding: 5px;
}
.checkout-same {
  margin: 25px 0;
}
.checkout-btn {
  margin: 0 5px;
}
.checkout-btns {
  margin: 0 -5px 10px;
  display: flex;
  flex-wrap: wrap;
}
.checkout-step-button {
  margin-top: 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .checkout-step-button {
    margin-top: 40px;
  }
}
.checkout-step-buttons {
  width: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .checkout-step-button {
    width: auto;
    margin: 40px auto 0;
  }
}
.checkout-steps {
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  position: absolute;
  max-height: 80px;
  top: 90px;
  left: 0;
  border-top: 1px solid #e8e6e6;
}
@media (min-width: 768px) {
  .checkout-steps {
    border: none;
    position: static;
    width: 800px;
  }
}
.checkout-step {
  width: 100%;
  color: #e8e6e6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  font-family: "Arial";
  font-size: 14px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  /*&:not(:first-child) {
      &::before {
          content: '';
          height: 1px;
          max-width: 80px;
          background-color: $color-primary;
      }
  }*/
}
@media (min-width: 768px) {
  .checkout-step {
    justify-content: center;
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .checkout-step:first-child {
    width: auto;
  }
}
.checkout-step-link {
  transition: 0.5s;
}
.checkout-step-link:hover {
  color: #000000;
}
.checkout-step + .checkout-step {
  position: relative;
}
.checkout-step + .checkout-step::before {
  /*padding: 0 5px;
  order:-1;*/
  content: "";
  width: calc(100% - 75px);
  height: 1px;
  background-color: #e9e5e5;
  position: absolute;
  left: -25%;
  top: 50%;
  /*@include bp(md){
      padding: 0 20px;
  }*/
}
@media (min-width: 576px) {
  .checkout-step + .checkout-step::before {
    width: calc(100% - 70px);
    left: -33%;
  }
}
@media (min-width: 768px) {
  .checkout-step + .checkout-step::before {
    position: static;
    max-width: 80px;
  }
}
.checkout-step.active::before {
  background-color: #cd2367;
}
.checkout-step-text {
  position: relative;
  padding: 0 10px 17px 10px;
  font-size: 14px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .checkout-step-text {
    padding: 35px 15px;
  }
}
.checkout-step-text.active {
  color: #2C2628;
  /*&::after{
      content:'';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: 0.5s;
      background: currentColor;

      @include bp(md){
          height: 2px;
      }
  }*/
}
@media (min-width: 768px) {
  .checkout-step-icon {
    padding-left: 15px;
  }
}
.checkout-step-icon::before {
  font-size: 30px;
  color: #e8e6e6;
  /*position: absolute;
  top: -50%;
  left: 50%;*/
}
@media (min-width: 768px) {
  .checkout-step-icon::before {
    font-size: 40px;
    position: static;
  }
}
.checkout-step-icon.active::before {
  color: #cd2367;
}
.checkout-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 35px 0;
}
@media (min-width: 768px) {
  .checkout-controls {
    padding: 40px 0;
  }
}
.checkout-controls--sticky {
  background: #ffffff;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
}
@media (min-width: 768px) {
  .checkout-controls--sticky {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .checkout-controls-btn {
    display: inline-block;
  }
}
.checkout-controls--footer {
  border-top: 1px solid #3d3d3d;
  margin-top: 35px;
  padding-top: 30px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .checkout-controls--footer {
    display: flex;
    flex-direction: row-reverse;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
.checkout-controls-content {
  text-align: center;
  padding: 0px 40px;
  width: 100%;
}
@media (min-width: 768px) {
  .checkout-controls-content {
    width: auto;
  }
}
.checkout-controls-title {
  padding: 0 15px;
  font-size: 16px;
  font-family: "Arial";
}
@media (min-width: 768px) {
  .checkout-controls-title {
    font-size: 22px;
  }
}
.checkout-controls-title-detail {
  font-family: "Montserrat";
  font-size: 12px;
  white-space: nowrap;
  margin-top: 4px;
  display: block;
}
@media (min-width: 768px) {
  .checkout-controls-title-detail {
    margin-top: 0;
    display: inline-block;
  }
}
.checkout-controls-subtitle {
  max-width: 400px;
  margin: 7px auto 0;
  font-size: 12px;
  line-height: 20px;
}
.checkout-delivery-method {
  background: #ffffff;
  padding: 30px;
  position: relative;
  cursor: pointer;
}
.checkout-delivery-method-title {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  padding-top: 50px;
}
@media (min-width: 768px) {
  .checkout-delivery-method-title {
    padding: 0 100px;
  }
}
.checkout-delivery-method-price {
  position: absolute;
  top: 50%;
  right: 30px;
  font-weight: 700;
  color: #cd2367;
  text-transform: uppercase;
  font-size: 28px;
  top: 22px;
  right: 45px;
}
@media (min-width: 768px) {
  .checkout-delivery-method-price {
    top: 40px;
    right: 45px;
  }
}
.checkout-delivery-method-price.price {
  font-size: 28px;
}
.checkout-delivery-method-delay {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 20px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
  background-color: #93c3ce;
  padding: 25px;
  margin-bottom: 30px;
}
.checkout-option {
  background: #ffffff;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .checkout-option {
    flex-wrap: nowrap;
  }
}
.checkout-option + .checkout-option {
  margin-top: 15px;
}
.checkout-option-text {
  padding-left: 20px;
  margin-bottom: 10px;
  width: calc(100% - 30px);
  cursor: pointer;
}
@media (min-width: 768px) {
  .checkout-option-text {
    width: auto;
    margin-bottom: 0;
  }
}
.checkout-option-text-primary {
  font-weight: 700;
  font-size: 18px;
}
.checkout-option-text-secondary {
  font-size: 13px;
  color: #000000;
}
.checkout-option-price {
  padding-left: 20px;
  margin-left: auto;
}
.checkout-option-price .price {
  font-size: 18px;
  font-weight: 700;
}
.checkout-blocks {
  margin: 40px -45px -30px;
  padding-top: 40px;
  background: #ffffff;
}
.checkout-block + .checkout-block {
  margin-top: 20px;
}
.checkout-block {
  display: flex;
  color: #000000;
  flex-wrap: wrap;
  padding: 0 30px;
}
@media (min-width: 768px) {
  .checkout-block {
    flex-wrap: nowrap;
    padding: 0;
  }
}
.checkout-block img {
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .checkout-block img {
    width: auto;
    height: auto;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .checkout-block-content {
    padding-left: 15px;
    padding-top: 5px;
  }
}
.checkout-block-title {
  font-size: 14px;
  font-weight: 700;
}
.checkout-block-text {
  font-size: 13px;
}
.checkout-fixed {
  position: fixed;
  bottom: 0;
  padding: 10px;
  background: #ffffff;
  width: 100vw;
  left: 0;
  z-index: 100;
}
.checkout-fixed-button {
  margin: 0;
}
.checkout-total {
  padding: 28px 20px 15px;
}
.checkout-totals-wrapper {
  margin-top: 30px;
  background: #e9e5e5;
  padding: 15px 30px;
  text-align: center;
  margin-bottom: 20px;
}
.checkout-totals-label {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.checkout-totals-amount {
  font-weight: 700;
  font-size: 30px;
  color: #cd2367;
}
.checkout-sticky-footer {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 88px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 15;
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .checkout-sticky-footer {
    display: none;
  }
}
.checkout-success .payment-method:hover {
  box-shadow: unset !important;
}

html body.badaboum--custom.badaboum .payment-method {
  border: 0 !important;
}
html body.badaboum--custom.badaboum .payment-method-content {
  padding: 0 20px 20px !important;
}
html body.badaboum--custom.badaboum .payment-method-billing-address {
  margin-bottom: 20px !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .payment-header .btn {
    display: none;
  }
}
.payment-fidelity {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 30px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .payment-fidelity {
    flex-direction: column-reverse;
  }
}
.payment-fidelity-left, .payment-fidelity-right {
  width: 50%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .payment-fidelity-left, .payment-fidelity-right {
    width: 100%;
  }
}
.payment-fidelity-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .payment-fidelity-left {
    height: 115px;
  }
}
.payment-fidelity-right {
  background-size: 300%;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .payment-fidelity-right {
    height: 300px;
    background-color: rgba(205, 35, 103, 0.25);
    background-image: none !important;
  }
}
.payment-fidelity-subtitle {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 9px;
}
.payment-fidelity-title {
  font-size: 28px;
  letter-spacing: 0;
  line-height: 34px;
  font-weight: bold;
  text-shadow: 1px 1px 2px 0 #980C3D;
  margin-bottom: 24px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .payment-fidelity-title {
    display: none;
  }
}
.payment-fidelity-title--mobile {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
}
@media (min-width: 1024px) {
  .payment-fidelity-title--mobile {
    display: none;
  }
}
.payment-fidelity-input {
  border: 1px solid #e8e6e6;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.06);
  max-width: 140px;
}
.payment-fidelity-input-sign {
  position: relative;
  right: 30px;
  top: 4px;
  font-size: 22px;
  font-weight: bold;
  color: #2C2628;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .payment-fidelity-input-sign {
    font-size: 12px;
    right: -5px;
    top: 0;
  }
}
.payment-fidelity-img {
  margin-bottom: 11px;
}
.payment-fidelity-amount {
  width: 200px;
  background-color: #ffffff;
  text-align: center;
  padding: 9.7px 0;
  color: #cd2367;
  font-size: 28px;
  font-weight: bold;
  border-radius: 4px;
}
.payment-choice {
  margin-bottom: 20px;
}
.payment-choice-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.payment-choice-title::before {
  color: #cd2367;
  font-size: 30px;
  margin-right: 15px;
}
.payment-choice-remaining {
  text-align: center;
  font-size: 18px;
  color: #2C2628;
  font-weight: bold;
  margin-bottom: 25px;
}
.payment-choice-remaining--amount {
  color: #6DB332;
}
.payment-choice-mean {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  border: 1px solid #e8e6e6;
  padding: 10px;
  margin-bottom: 25px;
  border-radius: 4px;
  justify-content: space-between;
}
.payment-choice-means {
  padding: 0 45px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .payment-choice-means {
    padding: 0 10px;
  }
}

.amcheckout-step-container .amcheckout-form-login .amcheckout-password, .amcheckout-step-container .form-login .field.amcheckout-password {
  width: 100%;
}

@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .amcheckout-step-container,
html body.badaboum--custom.badaboum .payment-step-container {
    margin-top: 0 !important;
    color: black;
    font-weight: 300;
  }
  html body.badaboum--custom.badaboum .amcheckout-step-container hr,
html body.badaboum--custom.badaboum .payment-step-container hr {
    margin-top: 0;
  }
  html body.badaboum--custom.badaboum .amcheckout-step-container .items-in-cart strong,
html body.badaboum--custom.badaboum .payment-step-container .items-in-cart strong {
    font-weight: 500;
  }
  html body.badaboum--custom.badaboum .amcheckout-step-container .items-in-cart .product-item,
html body.badaboum--custom.badaboum .payment-step-container .items-in-cart .product-item {
    padding-bottom: 10px;
  }
  html body.badaboum--custom.badaboum .amcheckout-step-container .items-in-cart .product-item-details,
html body.badaboum--custom.badaboum .payment-step-container .items-in-cart .product-item-details {
    padding-left: 65px;
  }
  html body.badaboum--custom.badaboum .amcheckout-step-container .items-in-cart .product-item-details .price,
html body.badaboum--custom.badaboum .payment-step-container .items-in-cart .product-item-details .price {
    font-weight: 500;
    font-size: 16px;
  }
  html body.badaboum--custom.badaboum .amcheckout-step-container .items-in-cart .product-item-inner,
html body.badaboum--custom.badaboum .payment-step-container .items-in-cart .product-item-inner {
    font-size: 13px;
  }
}
html body.badaboum--custom.badaboum .amcheckout-block,
html body.badaboum--custom.badaboum .payment-block {
  border: 0 !important;
}
html body.badaboum--custom.badaboum .amcheckout-title,
html body.badaboum--custom.badaboum .payment-title {
  color: #ffffff;
  background: #cd2367;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
html body.badaboum--custom.badaboum .amcheckout-method,
html body.badaboum--custom.badaboum .payment-method {
  border: 0;
  border-radius: 0;
  box-shadow: 0 0px 0px 1px transparent;
  transition: 0.5s;
  background: #ffffff !important;
  font-weight: 300;
}
html body.badaboum--custom.badaboum .amcheckout-method .col,
html body.badaboum--custom.badaboum .payment-method .col {
  font-size: 13px;
  vertical-align: middle;
}
html body.badaboum--custom.badaboum .amcheckout-method .col-price,
html body.badaboum--custom.badaboum .payment-method .col-price {
  padding-left: 0;
}
html body.badaboum--custom.badaboum .amcheckout-method:hover,
html body.badaboum--custom.badaboum .payment-method:hover {
  transition: 0s;
  box-shadow: 0 0px 0px 1px #cd2367 !important;
}
html body.badaboum--custom.badaboum .amcheckout-method.-selected, html body.badaboum--custom.badaboum .amcheckout-method._active,
html body.badaboum--custom.badaboum .payment-method.-selected,
html body.badaboum--custom.badaboum .payment-method._active {
  box-shadow: 0 0px 0px 1px transparent !important;
  background: #e9e5e5 !important;
  font-weight: 500;
}
html body.badaboum--custom.badaboum .amcheckout-method.checkmemo,
html body.badaboum--custom.badaboum .payment-method.checkmemo {
  pointer-events: none;
}
html body.badaboum--custom.badaboum .amcheckout-method img,
html body.badaboum--custom.badaboum .payment-method img {
  min-width: 60px;
  width: 60px;
  max-width: 60px;
}
html body.badaboum--custom.badaboum .amcheckout-dropshipping-wrapper,
html body.badaboum--custom.badaboum .payment-dropshipping-wrapper {
  margin-bottom: 20px;
}
html body.badaboum--custom.badaboum .amcheckout-dropshipping-wrapper::before,
html body.badaboum--custom.badaboum .payment-dropshipping-wrapper::before {
  content: "*";
  background: none;
  border-radius: unset;
  font-size: 20px;
}
html body.badaboum--custom.badaboum .amcheckout-additional-options,
html body.badaboum--custom.badaboum .payment-additional-options {
  border: none !important;
}

body.checkout-index-index .page-main {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  body.checkout-index-index .logo-wrapper {
    margin: auto;
  }
}

[data-sidebar-toggle] {
  cursor: pointer;
}

#amasty-shopby-product-list {
  width: 100%;
  height: fit-content;
  position: sticky;
  top: 80px;
  width: 100%;
}

@media (min-width: 576px) and (max-width: 1023px) {
  .catalog-topnav.amasty-catalog-topnav {
    display: none !important;
  }
}

@media (min-width: 576px) and (max-width: 1023px) {
  .am_shopby_apply_filters {
    position: fixed;
    z-index: 999;
    width: 100%;
    display: block;
  }
  .am_shopby_apply_filters.visible {
    bottom: 0;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  .am_shopby_apply_filters .am-show-button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 15px 0;
    width: 100%;
    border-top: 1px solid #cccccc;
    background: #ffffff;
    text-align: center;
  }
  .am_shopby_apply_filters .am-show-button:before {
    content: unset !important;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  .am_shopby_apply_filters .am-show-button > .am-items {
    margin: 0 !important;
  }
}

@media (min-width: 1024px) {
  .sidebar-filter {
    position: sticky;
    top: 70px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .sidebar-filter {
    margin-bottom: 100px;
  }
}
html body.badaboum--custom.badaboum .sidebar-filter-item--category {
  border-bottom: none;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .sidebar-filter-item--category {
    display: none;
  }
}
html body.badaboum--custom.badaboum .sidebar-filter-item--category > .sidebar-filter-title {
  pointer-events: none;
}
html body.badaboum--custom.badaboum .sidebar-filter-item--category > .sidebar-filter-title:after {
  display: none;
}
html body.badaboum--custom.badaboum .sidebar-filter-item--category > .icon-chevron-down-after::after,
html body.badaboum--custom.badaboum .sidebar-filter-item--category .am-collapse-icon:after {
  color: #afa2a6;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .sidebar-filter-item--category .checkbox-label {
    padding-left: 0;
  }
}
html body.badaboum--custom.badaboum .sidebar-filter-item--category .checkbox-label::before, html body.badaboum--custom.badaboum .sidebar-filter-item--category .checkbox-label::after {
  display: none;
}
html body.badaboum--custom.badaboum.catalogsearch-result-index .sidebar-filter-item--category {
  display: none;
}
.sidebar-filter-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #D8D8D8;
  opacity: 0;
  z-index: 99;
  pointer-events: none;
  transition: opacity 0.5s ease;
}
html body.badaboum--custom.badaboum .sidebar-filter-wrapper {
  position: fixed;
  left: -1000px;
  top: 0;
  bottom: 0;
  z-index: 150;
  background-color: #ffffff;
  transition: left 0.7s ease-in-out;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .sidebar-filter-wrapper {
    width: 100vw;
    overflow: auto;
    max-height: 100vh;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .sidebar-filter-wrapper {
    width: 350px;
    overflow: auto;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .sidebar-filter-wrapper {
    position: unset;
    height: auto;
    background-color: transparent;
    z-index: 9;
    width: 20%;
  }
}
html body.badaboum--custom.badaboum .sidebar-filter-wrapper.open {
  left: 0;
}
html body.badaboum--custom.badaboum .sidebar-filter-wrapper.open ~ .sidebar-filter-overlay {
  opacity: 0.5;
  pointer-events: inherit;
}
html body.badaboum--custom.badaboum .sidebar-filter-wrapper.open .page-title-wrapper {
  display: none;
}
html body.badaboum--custom.badaboum .sidebar-filter-wrapper .page-title-wrapper {
  display: none;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .sidebar-filter-wrapper .page-title-wrapper {
    display: block;
  }
}
.sidebar-filter-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 20px 0;
  color: #cd2367;
  visibility: visible;
  opacity: 1;
}
.sidebar-filter-header p {
  font-size: 18px;
  font-weight: 700;
}
@media (min-width: 1024px) {
  .sidebar-filter-header {
    margin: 0;
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
.sidebar-filter-button {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  visibility: visible;
  opacity: 1;
  z-index: 999;
}
@media (min-width: 1024px) {
  .sidebar-filter-button {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
.sidebar-filter-close {
  position: absolute;
  right: 0;
  display: block;
  visibility: visible;
  opacity: 1;
}
.sidebar-filter-close .icon-close::before {
  font-weight: bold;
}
@media (min-width: 1024px) {
  .sidebar-filter-close {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
.sidebar-filter-title {
  padding: 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  color: #2C2628;
  text-transform: uppercase;
}
.sidebar-filter-title::after {
  font-size: 8px;
  color: #cd2367;
  transition: transform 0.5s ease;
}
@media (min-width: 0px) and (max-width: 767px) {
  .sidebar-filter-title::after {
    font-size: 8px;
  }
}
.sidebar-filter-title.active::after {
  transform: rotate(180deg);
}
.sidebar-filter-content {
  border-top: 1px solid #D8D8D8;
  padding-bottom: 0;
  padding: 0 10px;
}
.sidebar-filter-content-form .checkbox:checked ~ .checkbox-label {
  color: #cd2367 !important;
  font-size: 12px;
}
.sidebar-filter-content-form .checkbox-label {
  padding-left: 30px;
  padding-right: 25px;
}
@media (min-width: 768px) {
  .sidebar-filter-content-form .checkbox-label {
    line-height: unset;
  }
}
.sidebar-filter-content-form .checkbox-label::before, .sidebar-filter-content-form .checkbox-label::after {
  display: block;
}
.sidebar-filter-content-form .checkbox-label::before {
  height: 16px !important;
  width: 16px !important;
}
.sidebar-filter-content-form .checkbox-label::after {
  height: 8px !important;
  width: 8px !important;
}
.sidebar-filter-content-form .am-filter-items-color input.input {
  display: none;
}
.sidebar-filter-result-remove::before, .sidebar-filter-result-remove::after {
  background-color: #cd2367 !important;
}
.sidebar-filter-category-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 10px;
  margin: 0 -10px !important;
  border-bottom: 1px solid #D8D8D8;
}
.sidebar-filter-category-wrapper:last-child {
  border-bottom: none;
}
.sidebar-filter-category-wrapper .checkbox-box {
  margin-bottom: 0 !important;
}
.sidebar-filter-category-wrapper .am-collapse-icon {
  right: 10px;
  top: 15px;
  left: unset;
  margin: 0;
  width: auto;
}
.sidebar-filter-category-wrapper .am-collapse-icon::after {
  font-family: "badaboum";
  content: "\e90d";
  background: unset;
  color: #cd2367;
  width: auto;
  height: auto;
  font-size: 8px;
  transition: transform 0.5s ease;
}
.sidebar-filter-category-wrapper .am-collapse-icon.-active::after {
  font-family: "badaboum";
  content: "\e90d";
  background: unset;
  transition: all 0.5s ease-in-out;
}
.sidebar-filter-category-content-wrapper {
  transition: display 0.5s ease-in-out;
  margin-left: 1em;
  width: 100%;
}
.sidebar-filter-category-content-wrapper span.label {
  position: relative;
  padding-left: 10px;
}
.sidebar-filter-category-content-wrapper span.label:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "-";
  transform: translateY(-50%);
  display: flex;
  align-items: baseline;
  height: 100%;
}
.sidebar-filter-category-content-wrapper.level-1 .sidebar-filter-category-wrapper {
  margin-left: -24px !important;
  padding-left: 24px;
}
.sidebar-filter-category-content-wrapper.level-2 .sidebar-filter-category-wrapper {
  margin-left: -37px !important;
  padding-left: 37px;
}

html body.badaboum--custom.badaboum .am-swatch-wrapper .swatch-option.color, html body.badaboum--custom.badaboum .am-swatch-wrapper .swatch-option.image {
  border-radius: 50%;
  min-width: 20px;
  border: 1px solid #bbbbbb;
}
html body.badaboum--custom.badaboum .am-swatch-wrapper .swatch-option.color:not(.disabled):hover {
  border: 1px solid #bbbbbb;
}

@media (min-width: 0px) and (max-width: 767px) {
  body[class*=appoint] .breadcrumb-container {
    display: none;
  }
}

.footstep {
  /*	
  &-button{
  	margin-top: 20px;
  	width: 100%;
  	@include bp(md){
  		margin-top: 40px;			
  	}

  	&s{
  		width: 100%;
  		display: flex;
  		align-items: center;
  	}

  	@include bp(md){
  		width: auto;	
  		margin: 40px auto 0;		
  	}
  }
  */
  display: flex;
  align-items: center;
  font-weight: 500;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  width: 100%;
  margin-bottom: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.footsteps {
  counter-reset: number 0;
  display: flex;
  width: calc(100% + 20px);
  background: #cd2367;
  padding: 20px;
  color: #ffffff;
  margin-left: -10px;
  margin-top: -20px;
  margin-bottom: 20px;
}
.footsteps::after {
  content: " /" attr(data-count);
  font-size: 16px;
  font-weight: bold;
  margin-left: 1px;
}
@media (min-width: 768px) {
  .footsteps::after {
    content: none;
  }
}
@media (min-width: 768px) {
  .footsteps {
    padding: 0;
    background: none;
    justify-content: center;
    margin-bottom: 60px;
    margin-top: 40px;
    margin-left: 0;
    width: 100%;
    color: #2C2628;
  }
}
@media (min-width: 768px) {
  .footstep {
    display: flex;
    font-size: 20px;
    margin-top: -10px;
    width: auto;
    pointer-events: initial;
    position: relative;
    opacity: 1;
  }
}
.footstep--active {
  position: relative;
  pointer-events: initial;
  opacity: 1;
  display: flex;
}
.footstep-content {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .footstep-content {
    justify-content: center;
    flex-direction: column;
  }
}
.footstep-content::before {
  counter-increment: number 1;
  content: counter(number);
  margin-left: auto;
  font-size: 16px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .footstep-content::before {
    font-size: 20px;
    margin-left: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 40px;
    text-align: center;
    margin-bottom: 7px;
    font-family: "badaboum";
    content: "\e913";
    color: #ffffff;
    background: #cd2367;
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .footstep + .footstep::after {
    content: "";
    height: 2px;
    width: 75px;
    order: -1;
    background: #786855;
    margin: 0 15px;
    position: relative;
    top: -5px;
  }
}
@media (min-width: 1280px) {
  .footstep + .footstep::after {
    width: 115px;
  }
}
@media (min-width: 768px) {
  .footstep--active .footstep-content::before {
    content: counter(number);
    color: #ffffff;
    background: #cd2367;
    font-family: "Montserrat";
    font-weight: 700;
  }
}
@media (min-width: 768px) {
  .footstep--active ~ .footstep .footstep-content::before {
    content: counter(number);
    background: #e9e5e5;
    background: #e9e5e5;
    color: #2C2628;
    font-family: "Montserrat";
    font-weight: 700;
  }
}
.footstep-text {
  display: block;
  font-size: 14px;
  order: -1;
}
@media (min-width: 768px) {
  .footstep-text {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    text-align: center;
    font-size: 15px;
  }
}
@media (min-width: 1280px) {
  .footstep-text {
    font-size: 20px;
  }
}

.recap-container {
  width: 100%;
  margin: 40px 0 0;
}
@media (min-width: 768px) {
  .recap-container {
    margin: 0;
  }
}
@media (min-width: 1280px) {
  .recap-container {
    padding-left: 70px;
  }
}
.recap-section {
  background: #e9e5e5;
}

.schedule {
  width: 100%;
  background: #e9e5e5;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  position: relative;
  color: #cd2367;
}
.schedule-col {
  align-self: flex-start;
}
.schedule .slick-next, .schedule .slick-prev {
  position: absolute;
  top: -8px;
  z-index: 10;
}
.schedule .slick-prev {
  left: 40px;
}
.schedule .slick-next {
  right: 40px;
}
.schedule-columns {
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  position: relative;
  overflow: hidden;
  min-height: 230px;
  padding-left: 0;
  padding-right: 0;
  opacity: 0;
  transition: 1s;
}
.schedule-columns.slick-initialized {
  opacity: 1;
}
.schedule-columns::before, .schedule-columns::after {
  content: "";
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 45px;
  z-index: 5;
}
.schedule-columns::after {
  left: auto;
  right: 0;
}
@media (min-width: 768px) {
  .schedule-columns {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.schedule-column {
  color: #2C2628;
  padding: 0 10px;
}
@media (min-width: 768px) {
  .schedule-column {
    padding: 0 7px;
  }
}
.schedule-column-content {
  display: flex;
  flex-wrap: wrap;
  padding-top: 57px;
  position: relative;
  margin-left: -7px;
  margin-right: -7px;
}
@media (min-width: 768px) {
  .schedule-column-content {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-top: 0;
  }
}
.schedule-column-head {
  margin-bottom: 20px;
  font-size: 15px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .schedule-column-head {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }
}
.schedule-column-head :first-child {
  margin-bottom: 2px;
  font-weight: bold;
}
.schedule-cell {
  width: calc(33.333% - 14px);
  background: #df4482;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-weight: bold;
  font-size: 15px;
  padding: 12px 21px;
  margin-bottom: 15px;
  transition: color 0.5s, background 0.5s;
  cursor: pointer;
  margin: 0 7px 15px 7px;
}
.schedule-cell:hover {
  background: #e9e5e5;
}
.schedule-cell.active {
  background: #cd2367;
  color: #ffffff;
}
@media (min-width: 768px) {
  .schedule-cell {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

section.header_custom {
  margin-top: 15px !important;
  margin-bottom: 25px;
}
@media (min-width: 1024px) {
  section.header_custom {
    height: 100%;
    max-height: 620px;
  }
}

html body.badaboum--custom.badaboum .home-category {
  /*** HOME HEADER - SEO ***/
  /*** END - HOME HEADER - SEO - END***/
  /*** HOME HEADER - SLIDER ***/
  /*** END -- HOME HEADER - SLIDER -- END ***/
}
html body.badaboum--custom.badaboum .home-category-img-wrapper {
  height: 100%;
  width: 100%;
}
html body.badaboum--custom.badaboum .home-category-seo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}
html body.badaboum--custom.badaboum .home-category-seo-card {
  position: relative;
  max-height: 130px;
  height: 130px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .home-category-seo-card {
    height: 250px;
    max-height: calc(50% - 10px);
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card--left {
  width: 100%;
  order: 1;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--left {
    height: 130px;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--left {
    height: 250px;
    max-height: 250px;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--left {
    width: calc(50% - 10px);
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card--right {
  width: 100%;
  order: 6;
  height: 100%;
}
html body.badaboum--custom.badaboum .home-category-seo-card--right .home-category-seo-card-content--left p, html body.badaboum--custom.badaboum .home-category-seo-card--right .home-category-seo-card-content--left span {
  color: #1d888c;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--right {
    height: 130px;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--right {
    max-height: 250px;
    height: 250px;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--right {
    order: 2;
    width: calc(50% - 10px);
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card--small {
  width: calc(50% - 5px);
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--small {
    width: calc(25% - 15px);
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card--small-1 {
  order: 3;
}
html body.badaboum--custom.badaboum .home-category-seo-card--small-2 {
  order: 5;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--small-2 {
    order: 4;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card--small-3 {
  order: 1;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--small-3 {
    order: 5;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card--small-4 {
  display: block;
  order: 2;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .home-category-seo-card--small-4 {
    display: none;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
html body.badaboum--custom.badaboum .home-category-seo-card-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 130px;
  padding-left: 10px;
}
@media (min-width: 576px) {
  html body.badaboum--custom.badaboum .home-category-seo-card-content {
    width: 215px;
    padding-left: 20px;
  }
}
@media (min-width: 576px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .home-category-seo-card-content {
    width: 270px;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card-content--left {
  left: 0;
}
html body.badaboum--custom.badaboum .home-category-seo-card-content--right {
  right: 0;
}
html body.badaboum--custom.badaboum .home-category-seo-card-content--bottom {
  bottom: 0;
  top: unset;
  transform: none;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 100%);
}
html body.badaboum--custom.badaboum .home-category-seo-card-title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .home-category-seo-card-title {
    font-size: 20px;
    line-height: 25px;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card-desc {
  color: #ffffff;
  font-weight: 400;
}
html body.badaboum--custom.badaboum .home-category-seo-card-subtitle {
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .home-category-seo-card-subtitle {
    font-size: 12px;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-card-price {
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .home-category-seo-card-price {
    font-size: 18px;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-content {
  color: #2C2628;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  order: 6;
  height: 100%;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .home-category-seo-content {
    width: 100%;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-content-title {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .home-category-seo-content-title {
    text-align: left;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-content p {
  text-align: center;
  line-height: 23px;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .home-category-seo-content p {
    text-align: justify;
  }
}
html body.badaboum--custom.badaboum .home-category-seo-content p + html body.badaboum--custom.badaboum .home-category-seo-content p {
  margin-bottom: 0;
}
html body.badaboum--custom.badaboum .home-category-slider {
  height: 100%;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .home-category-slider {
    width: 100vw;
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (min-width: 768px) and (max-width: 1539px) {
  html body.badaboum--custom.badaboum .home-category-slider {
    margin-left: -20px;
    margin-right: -20px;
  }
}
html body.badaboum--custom.badaboum .home-category-slider img {
  max-height: 620px;
  height: 100%;
  width: 100%;
}
html body.badaboum--custom.badaboum .home-category-slider .slick-list {
  height: 100%;
}
html body.badaboum--custom.badaboum .home-category-slider .slick-track {
  height: 100%;
}
html body.badaboum--custom.badaboum .home-category-slider .slick-slide {
  margin: 0 !important;
  height: 100% !important;
  max-width: 100%;
}
html body.badaboum--custom.badaboum .home-category-slider .slick-dots {
  position: relative;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .home-category-slider .slick-dots {
    position: absolute;
    bottom: 25px;
  }
}
html body.badaboum--custom.badaboum .home-category-slider .slick-dots li {
  width: 40px;
  height: 2px;
}
html body.badaboum--custom.badaboum .home-category-slider .slick-dots li button {
  width: 40px;
  height: 2px;
  background: #707070;
  padding: 0;
}
html body.badaboum--custom.badaboum .home-category-slider .slick-dots li button:before {
  content: none;
}
html body.badaboum--custom.badaboum .home-category-slider .slick-dots li.slick-active button {
  background-color: #000000;
}
@media (min-width: 0px) and (max-width: 1279px) {
  html body.badaboum--custom.badaboum .home-category-slider .slick-arrow {
    display: none !important;
  }
}
html body.badaboum--custom.badaboum .home-category-slide {
  position: relative;
  height: 100%;
}
html body.badaboum--custom.badaboum .home-category-slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .home-category-slide-content {
    width: 50%;
  }
}
html body.badaboum--custom.badaboum .home-category-slide-content-title {
  font-size: 20px;
  color: #cd2367;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 10px;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .home-category-slide-content-title {
    font-size: 50px;
  }
}
html body.badaboum--custom.badaboum .home-category-slide-content-text {
  text-align: center;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0 15px;
  margin-bottom: 15px;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .home-category-slide-content-text {
    font-size: 14px;
  }
}
html body.badaboum--custom.badaboum .home-category-slide-content a.btn {
  width: fit-content;
  font-size: 16px;
  padding: 16px 60px !important;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .home-category-slide-content a.btn {
    padding: 23px 69px !important;
    font-size: 20px;
  }
}
html body.badaboum--custom.badaboum .home-description {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .home-description {
    padding: 0 15px;
  }
}
html body.badaboum--custom.badaboum .home-description[data-elevator]:not(.active) {
  max-height: 100%;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .home-description[data-elevator]:not(.active) {
    max-height: 150px !important;
  }
}
html body.badaboum--custom.badaboum .home-description[data-elevator]:not(.active):after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffffff 100%);
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .home-description[data-elevator]:not(.active):after {
    content: none;
    display: none;
  }
}
html body.badaboum--custom.badaboum .home-description.active:after {
  height: 0;
  transform: all;
  transition: transform 0.5s ease;
}
html body.badaboum--custom.badaboum .home-description-btn {
  text-align: center;
  text-decoration: underline;
  margin-top: 20px;
  display: none;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .home-description-btn {
    display: block;
  }
}
html body.badaboum--custom.badaboum .home-description-section {
  border-bottom: 1px solid #D8D8D8;
  margin: 0;
  padding-bottom: 60px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .home-description-section {
    margin: 0 -15px;
  }
}

html body.badaboum--custom.badaboum .pagebuilder-button-primary {
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  visibility: visible !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  transition: 0.6s;
  border: 0;
  box-shadow: 0 5px 9px transparent;
  white-space: nowrap;
  -webkit-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  transition: opacity 0.3s, top 0.6s, transform 0.8s;
  font-weight: 400;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  height: 50px;
  padding: 0 15px !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  color: #ffffff !important;
  background-color: #cd2367 !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .pagebuilder-button-primary {
    font-size: 16px;
  }
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary-text,
html body.badaboum--custom.badaboum .pagebuilder-button-primary em,
html body.badaboum--custom.badaboum .pagebuilder-button-primary span {
  z-index: 15;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  color: inherit;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary-text::before, html body.badaboum--custom.badaboum .pagebuilder-button-primary-text::after,
html body.badaboum--custom.badaboum .pagebuilder-button-primary em::before,
html body.badaboum--custom.badaboum .pagebuilder-button-primary em::after,
html body.badaboum--custom.badaboum .pagebuilder-button-primary span::before,
html body.badaboum--custom.badaboum .pagebuilder-button-primary span::after {
  font-size: 1.6666666667em;
  line-height: 1.6666666667;
  vertical-align: middle;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary-text::before,
html body.badaboum--custom.badaboum .pagebuilder-button-primary em::before,
html body.badaboum--custom.badaboum .pagebuilder-button-primary span::before {
  margin-right: 10px;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary-text::after,
html body.badaboum--custom.badaboum .pagebuilder-button-primary em::after,
html body.badaboum--custom.badaboum .pagebuilder-button-primary span::after {
  margin-left: 10px;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  width: calc(800% + 8px);
  height: 150%;
  padding-top: calc(800% + 8px);
  opacity: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  transform: translate(-32%, 0) rotate(-17deg) translate3d(0, 0, 0);
  -webkit-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  -o-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary:hover::after,
html body.badaboum--custom.badaboum .pagebuilder-button-primary a:not(.func):hover ::after {
  opacity: 1;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
  transform: translate(-50%, 0) rotate(0) translate3d(0, 0, 0);
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary:active::after, html body.badaboum--custom.badaboum .pagebuilder-button-primary.active::after, a:active html body.badaboum--custom.badaboum .pagebuilder-button-primary:not(.button-alone)::after {
  opacity: 1;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary:hover {
  color: #ffffff;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary:active, html body.badaboum--custom.badaboum .pagebuilder-button-primary.active {
  color: #ffffff;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary:active::after, html body.badaboum--custom.badaboum .pagebuilder-button-primary.active::after {
  background: #df4482 !important;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
}
html body.badaboum--custom.badaboum .pagebuilder-button-primary::after {
  background: #df4482 !important;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: inherit;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  transition: 0.6s;
  border: 0;
  box-shadow: 0 5px 9px transparent;
  white-space: nowrap;
  -webkit-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0.3s, top 0.6s, transform 0.8s;
  transition: opacity 0.3s, top 0.6s, transform 0.8s;
  font-weight: 400;
  text-transform: initial;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  height: 50px;
  padding: 0 15px !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  background-color: #F4F3E9 !important;
  color: #2C2628 !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .pagebuilder-button-secondary {
    font-size: 16px;
  }
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary-text,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary em,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary span {
  z-index: 15;
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  color: inherit;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary-text::before, html body.badaboum--custom.badaboum .pagebuilder-button-secondary-text::after,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary em::before,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary em::after,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary span::before,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary span::after {
  font-size: 1.6666666667em;
  line-height: 1.6666666667;
  vertical-align: middle;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary-text::before,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary em::before,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary span::before {
  margin-right: 10px;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary-text::after,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary em::after,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary span::after {
  margin-left: 10px;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  width: calc(800% + 8px);
  height: 150%;
  padding-top: calc(800% + 8px);
  opacity: 0;
  z-index: -1;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  transform: translate(-32%, 0) rotate(-17deg) translate3d(0, 0, 0);
  -webkit-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  -o-transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
  transition: opacity 0.6s, top 0s 0.3s, transform 0s 0.3s;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary:hover::after,
html body.badaboum--custom.badaboum .pagebuilder-button-secondary a:not(.func):hover ::after {
  opacity: 1;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
  transform: translate(-50%, 0) rotate(0) translate3d(0, 0, 0);
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary:active::after, html body.badaboum--custom.badaboum .pagebuilder-button-secondary.active::after, a:active html body.badaboum--custom.badaboum .pagebuilder-button-secondary:not(.button-alone)::after {
  opacity: 1;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary:hover {
  background-color: #F4F3E9 !important;
  color: #2C2628 !important;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary span {
  color: #2C2628 !important;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary:active, html body.badaboum--custom.badaboum .pagebuilder-button-secondary.active {
  color: #dfddcc !important;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary:active::after, html body.badaboum--custom.badaboum .pagebuilder-button-secondary.active::after {
  background: #dfddcc !important;
  top: 0;
  -webkit-transition: opacity 0s, top 0.6s, transform 0.8s;
  -o-transition: opacity 0s, top 0.6s, transform 0.8s;
  transition: opacity 0s, top 0.6s, transform 0.8s;
}
html body.badaboum--custom.badaboum .pagebuilder-button-secondary::after {
  background: #dfddcc !important;
}
html body.badaboum--custom.badaboum .pagebuilder-poster-content {
  color: #2C2628;
}
html body.badaboum--custom.badaboum .pagebuilder-poster-content h2 {
  color: #2C2628;
  font-weight: 900 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
html body.badaboum--custom.badaboum .pagebuilder-poster-content h2 span {
  font-size: 25px !important;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .pagebuilder-poster-content h2 span {
    font-size: 50px !important;
  }
}
html body.badaboum--custom.badaboum .pagebuilder-poster-content p {
  color: #2C2628;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 22px;
  text-align: center;
  margin-bottom: 20px;
  max-width: 500px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1280px) {
  html body.badaboum--custom.badaboum .pagebuilder-poster-content p {
    font-size: 20px;
    line-height: 28px;
  }
}

.articles {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  opacity: 0;
  transition: opacity 1s;
}
.articles .slick-list {
  overflow: visible;
}
.articles.slick-initialized {
  opacity: 1;
}
.article-box {
  padding: 5px 10px 35px;
}
@media (min-width: 1280px) {
  .article-box {
    padding: 5px 35px 40px;
  }
}
.article {
  min-height: 100%;
  overflow: hidden;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(50, 51, 53, 0.2);
}
@media (min-width: 1280px) {
  .article {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  }
}
.article-content {
  padding: 10px 20px;
}
.article-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.article-title {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  font-weight: bold;
}
.article-excerpt {
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 10px;
}

.cms {
  margin-bottom: 20px;
}
.cms--nogap {
  margin-bottom: 0;
}
.cms a {
  color: #cd2367;
  color: #2C2628;
  font-weight: 300;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.cms a:hover, .cms a:active {
  color: #cd2367;
  text-decoration: none;
}
.cms a:visited {
  color: #2C2628;
  text-decoration: none;
}
.cms a:visited:hover {
  color: #cd2367;
}
.cms a:hover {
  color: #df4482;
}
.cms table {
  width: 100% !important;
}
.cms td {
  width: auto !important;
}
.cms h1, .cms h2, .cms h3, .cms h4 {
  margin-top: 10px;
  margin-bottom: 20px;
}
.cms div + h2, .cms div + h3, .cms div + h4 {
  margin-top: 40px;
}
.cms h1 {
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 26px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 10px;
  position: relative;
}
@media (min-width: 768px) {
  .cms h1 {
    margin-bottom: 40px;
  }
}
.cms h2 {
  font-family: "Arial";
  font-size: 20px;
  font-weight: 500;
  color: #2C2628;
  width: 100%;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.cms h3 {
  font-family: "Arial";
  font-size: 18px;
  font-weight: normal;
  color: #2C2628;
  width: 100%;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
}
.cms h4 {
  font-family: "Arial";
  font-size: 16px;
  font-weight: normal;
  color: #786855;
  width: 100%;
}
.cms p, .cms p span, .cms h2 {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.7;
  letter-spacing: normal;
  color: #2C2628;
  letter-spacing: normal;
}
.cms p a {
  color: #cd2367 !important;
  cursor: pointer !important;
  text-decoration: underline !important;
  font-size: 11px;
}
.cms p a:hover, .cms p a:active {
  color: #980C3D !important;
}
.cms ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.cms ul {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 30px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  text-align: justify;
  margin-bottom: 40px;
}
.cms ul li {
  list-style: none;
}
.cms ul li:before {
  content: "-";
  padding-right: 3px;
}
.cms ul li a {
  color: #786855;
  text-decoration: revert;
}
.cms ul li a:hover, .cms ul li a:active {
  color: #980C3D;
}
.cms strong {
  font-weight: 500;
  color: #cd2367;
}
.cms em {
  font-weight: 400;
}
.cms em {
  font-style: italic;
}
@media (min-width: 0px) and (max-width: 767px) {
  .cms p, .cms h1, .cms h2, .cms h3, .cms p, .cms img, .cms ul {
    width: 100% !important;
    display: block;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.cms .elevator-target {
  margin-bottom: 15px;
}
.cms .section {
  margin: 15px 0 20px;
}
.cms .section-title {
  cursor: pointer;
  padding-right: 30px;
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e6e6;
}
.cms .section-title::after {
  position: absolute;
  top: 0;
  right: 2px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 1.3em;
}
.cms iframe {
  max-width: 100%;
}

.cms-myprivilege .breadcrumb-container {
  display: none;
}
.cms-myprivilege .main {
  min-height: 0;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.mp-header {
  max-width: 1920px;
  margin: -20px auto 0 -10px;
  width: calc(100% + 20px);
  margin-left: -10px;
}
@media (min-width: 768px) {
  .mp-header {
    width: auto;
    margin: -25px auto 0;
  }
}
.mp-header::before, .mp-header::after {
  content: "";
  background: #f0cccc;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  z-index: -1;
  width: 50%;
}
@media (min-width: 0px) and (max-width: 767px) {
  .mp-header::before, .mp-header::after {
    display: none;
  }
}
@media (min-width: 768px) {
  .mp-header::before, .mp-header::after {
    box-shadow: inset 1px -15px 16px -22px #978888e6;
  }
}
@media (min-width: 1024px) {
  .mp-header::before, .mp-header::after {
    box-shadow: inset 1px -17px 18px -24px #978888;
  }
}
@media (min-width: 1280px) {
  .mp-header::before, .mp-header::after {
    box-shadow: inset 1px -18px 15px -20px #978888e6;
  }
}
.mp-header::before {
  right: 0;
}
@media (min-width: 768px) {
  .mp-header::before {
    height: 244px;
  }
}
@media (min-width: 1024px) {
  .mp-header::before {
    height: 342px;
  }
}
@media (min-width: 1280px) {
  .mp-header::before {
    height: 332px;
  }
}
.mp-header::after {
  background: #f4d0d0;
  left: 0;
}
@media (min-width: 768px) {
  .mp-header::after {
    height: 265px;
  }
}
@media (min-width: 1024px) {
  .mp-header::after {
    height: 369px;
  }
}
@media (min-width: 1280px) {
  .mp-header::after {
    height: 366px;
  }
}
.mp-connect {
  overflow: hidden;
  border-radius: 20px;
}
@media (min-width: 768px) {
  .mp-connect {
    border-radius: 50px;
  }
}
.mp-text {
  text-align: center;
  max-width: 815px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .mp-text {
    text-align: left;
  }
}
.mp-golds {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mp-gold + .mp-gold {
  margin-left: 10px;
}
.mp-slides {
  position: absolute;
  padding: 0;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  max-width: 1220px;
}
.mp-slides-container {
  position: relative;
  padding-top: 24%;
  width: calc(100% + 20px);
  margin-left: -10px;
}
@media (min-width: 768px) {
  .mp-slides-container {
    padding-top: 27%;
  }
}
@media (min-width: 1280px) {
  .mp-slides-container {
    width: 100%;
    padding-top: 0;
    height: 300px;
  }
}
.reinsurance {
  margin: 12px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  position: relative;
  text-align: left;
  width: 100%;
}
@media (min-width: 768px) {
  .reinsurance {
    margin: 20px 0;
    width: calc(33.3333333333% - 50px);
  }
}
.reinsurances {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.reinsurance-content {
  margin-left: 12px;
}
@media (min-width: 768px) {
  .reinsurance-content {
    margin-left: 20px;
  }
}
.reinsurance-title {
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;
}
.reinsurance-details {
  color: #2C2628;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 300;
}
.reinsurance-logo {
  max-width: 32px;
  max-height: 32px;
}
@media (min-width: 768px) {
  .reinsurance-logo {
    max-width: 45px;
    max-height: 45px;
  }
}

html body.badaboum--custom.badaboum .newsletter {
  width: 100%;
  max-width: 100% !important;
}
html body.badaboum--custom.badaboum .newsletter .input-label {
  font-size: 13px;
}

html body.badaboum--custom.badaboum .tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
html body.badaboum--custom.badaboum .tag {
  background: #F4F3E9;
  text-transform: inherit;
  margin-right: 15px;
  margin-bottom: 15px;
}
html body.badaboum--custom.badaboum .tag:last-child {
  margin-right: 0;
}
html body.badaboum--custom.badaboum .tag h2 {
  margin: 0;
}
html body.badaboum--custom.badaboum .tag-content {
  color: #2C2628;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin: 0;
}

html body.badaboum--custom.badaboum .bytheme {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
html body.badaboum--custom.badaboum .bytheme-header {
  order: 1;
  width: 100%;
  justify-content: center;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .bytheme-header {
    width: 850px;
    margin: 0 auto 40px 350px;
  }
}
html body.badaboum--custom.badaboum .bytheme-button {
  align-items: center;
  order: 3;
  width: 100%;
  margin-top: 0;
  align-self: baseline;
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .bytheme-button {
    order: 2;
    margin-left: auto;
    margin-top: 0;
    width: fit-content;
  }
}
html body.badaboum--custom.badaboum .bytheme-button span {
  color: #2C2628;
}
html body.badaboum--custom.badaboum .bytheme-button em.icon {
  margin-left: 15px;
  font-size: 10px;
  color: #2C2628;
  width: auto !important;
}
html body.badaboum--custom.badaboum .bytheme-body {
  order: 2;
  padding: 0;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .bytheme-body {
    min-height: auto !important;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .bytheme-body {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  html body.badaboum--custom.badaboum .bytheme-body {
    order: 3;
  }
}
html body.badaboum--custom.badaboum .bytheme-wrapper {
  height: inherit;
  width: inherit;
  position: relative;
  min-height: 270px;
  height: 270px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .bytheme-wrapper {
    max-height: 350px;
    height: 350px;
  }
}
html body.badaboum--custom.badaboum .bytheme-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0%;
}
html body.badaboum--custom.badaboum .bytheme-content {
  position: absolute;
  bottom: 0;
  height: 130px;
  width: 100%;
  z-index: 10;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 100%);
  display: flex;
  align-items: flex-end;
}
html body.badaboum--custom.badaboum .bytheme-content h2 {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  margin: 0 !important;
  width: 100%;
  line-height: 1.3;
  padding: 13px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .bytheme-content h2 {
    font-size: 14px;
    padding: 16px;
  }
}
html body.badaboum--custom.badaboum .bytheme-content span {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .bytheme-content span {
    font-size: 22px;
  }
}

@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .best-sellers-slider {
    min-height: auto !important;
  }
  html body.badaboum--custom.badaboum .best-sellers-slider .slick-slide {
    height: auto;
  }
}

.byblocks {
  display: grid;
  grid-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
  grid-template-areas: "block-big" "block-small-top" "block-small-bottom";
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
}
@media (min-width: 1024px) {
  .byblocks {
    grid-template-areas: "block-big block-small-top" "block-big block-small-bottom";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 290px);
  }
}
.byblock-item {
  width: 100%;
  height: 100%;
  position: relative;
}
.byblock-item--big {
  grid-area: block-big;
}
.byblock-item--small .byblock-item-content {
  left: 180px;
  width: 150px;
}
@media (min-width: 768px) and (max-width: 1279px) {
  .byblock-item--small .byblock-item-content {
    left: 335px;
    width: fit-content;
  }
}
@media (min-width: 1024px) {
  .byblock-item--small .byblock-item-content {
    left: 250px;
    width: fit-content;
  }
}
.byblock-item--small .byblock-item-title {
  font-size: 14px;
}
@media (min-width: 768px) {
  .byblock-item--small .byblock-item-title {
    font-size: 20px;
  }
}
.byblock-item--small .byblock-item-subtitle {
  font-size: 12px;
}
@media (min-width: 768px) {
  .byblock-item--small .byblock-item-subtitle {
    font-size: 14px;
  }
}
.byblock-item--small .byblock-item-desc {
  font-size: 12px;
}
@media (min-width: 768px) {
  .byblock-item--small .byblock-item-desc {
    font-size: 16px;
  }
}
.byblock-item--small-top {
  grid-area: block-small-top;
}
.byblock-item--small-bottom {
  grid-area: block-small-bottom;
}
.byblock-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 1024px) {
  .byblock-item img {
    object-fit: fill;
  }
}
.byblock-item-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
}
@media (min-width: 768px) and (max-width: 1279px) {
  .byblock-item-content {
    left: 335px;
  }
}
@media (min-width: 1024px) {
  .byblock-item-content {
    left: 230px;
  }
}
.byblock-item-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
@media (min-width: 768px) {
  .byblock-item-title {
    font-size: 40px;
  }
}
.byblock-item-subtitle {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
@media (min-width: 768px) {
  .byblock-item-subtitle {
    font-size: 20px;
  }
}
.byblock-item-desc {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
@media (min-width: 768px) {
  .byblock-item-desc {
    font-size: 22px;
  }
}

.customer-account-login .secondary {
  text-align: left;
}
.customer-account-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 1023px) {
  .customer-account-column {
    width: 100%;
  }
  .customer-account-column .page-subtitle {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .customer-account-column {
    margin-bottom: 100px;
  }
  .customer-account-column .page-subtitle {
    font-size: 16px;
    margin-bottom: 15px;
    padding: 25px 20px;
    background: rgba(206, 10, 78, 0.1);
    border-left: 4px solid #cd2367;
    width: 100vw;
    margin-left: -15px;
  }
  .customer-account-column .form-actions-toolbar {
    top: calc(100% + 15px);
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0 !important;
  }
}
.customer-account-column .form.wishlist.share .legend {
  margin-left: 0;
}
.customer-account-column .form.wishlist.share .field {
  display: flex;
  flex-direction: column;
}
.customer-account-column .form.wishlist.share .field .control {
  width: 100%;
}
.customer-account-column .form.wishlist.share .field .control textarea {
  width: 100%;
  resize: none;
}
.customer-account-column .form.wishlist.share .field .control textarea#email_address {
  height: 42px;
}
.customer-account-column .form.wishlist.share .field .control textarea#message {
  height: 200px;
}
.customer-account-nav .nav.item {
  margin: 0;
}
.customer-account-nav .nav.item a {
  color: #2C2628;
  font-size: 14px;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  padding: 20px;
  display: flex;
  align-items: center;
}
.customer-account-nav .nav.item a:before {
  margin-right: 15px;
  font-size: 20px;
  width: 22px;
  text-align: center;
}
.customer-account-nav .nav.item a:hover {
  background: #980C3D;
  color: #ffffff;
}
.customer-account-nav .nav.item a.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-account-nav .nav.item.current {
  background: rgba(206, 10, 78, 0.1);
}
.customer-account-nav .nav.item.current a {
  border-left: 3px solid #cd2367;
  padding: 0;
  padding-left: 20px;
}
.customer-account-nav .nav.item.current a:before {
  color: #cd2367;
}
.customer-account-nav .nav.item.current a strong {
  padding-left: 0;
}
.customer-account-nav .nav.item.current strong {
  padding: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
  border-color: #cd2367;
}
.customer-account-nav .nav.item.current strong:before {
  margin-right: 15px;
  font-size: 20px;
  color: #cd2367;
}
.customer-account-nav .nav.item:last-child {
  padding: 20px;
}
.customer-account-nav .nav.item:last-child .btn--primary {
  height: 50px;
}
.customer-account-address {
  margin: 0 0 20px;
}
.customer-account-dob {
  display: flex !important;
  align-items: center;
}
.customer-login .fieldset.login:after {
  margin: 10px 0 !important;
}
.customer-login-btn {
  margin-top: 35px;
}
.customer-showpassword {
  cursor: pointer;
}
.customer-form-create {
  width: 100% !important;
}
.customer-form-create .form-actions-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-form-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.customer-form-section-title {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .customer-form-section-title {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.customer-form input[type=checkbox].checkbox {
  position: unset;
  margin: 0;
  top: unset;
}
.customer-captcha {
  width: 100%;
}
@media (min-width: 768px) {
  .customer-captcha {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .customer-captcha .field.captcha {
    padding-right: 15px;
  }
}
.customer-captcha .field.captcha .control.captcha-image .captcha-img {
  height: 100px;
}
.customer-captcha .field.captcha button.captcha-reload.btn--secondary > span {
  color: #2C2628 !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .customer-block--new-customer {
    margin-top: 20px;
  }
}
.customer-order-history {
  margin-bottom: 20px;
}
.customer-order-details-view {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.customer-order-details-view-content {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.customer-order-details-view-content .box {
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  .customer-order-details-view-content .box {
    width: calc(50% - 10px);
  }
  .customer-order-details-view-content .box:nth-child(odd) {
    margin-right: 10px;
  }
  .customer-order-details-view-content .box:nth-child(even) {
    margin-left: 10px;
  }
}
.customer-order-details-view-content address {
  line-height: 22px;
}
.customer-wishlist-select {
  width: 40%;
}
.customer-wishlist-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}
.customer-wishlist-actions::before, .customer-wishlist-actions:after {
  content: unset !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .customer-wishlist-actions button.btn {
    width: 100%;
  }
  .customer-wishlist-actions button.btn:nth-child(even) {
    margin: 20px 0;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  body.customer-account-login .login-container p {
    font-size: 13px;
    line-height: 1.8;
  }
}
body.customer-account-login .login-container .fieldset {
  margin-bottom: 0 !important;
}
body.customer-account-login .login-container .fieldset::after {
  content: none !important;
}

body.customer-account-forgotpassword .customer-captcha {
  width: 100%;
}
@media (min-width: 768px) {
  body.customer-account-forgotpassword .customer-captcha .field.captcha {
    padding-right: 0;
  }
}
body.customer-account-forgotpassword .customer-captcha .field.captcha .control.captcha-image .captcha-img {
  height: 100px;
}

body.customer-account-create ._has-datepicker ~ .ui-datepicker-trigger {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}
body.customer-account-create #remember-me-box {
  display: none;
}

body.account ._has-datepicker ~ .ui-datepicker-trigger {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  body.account .sidebar {
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  body.account .sidebar-main {
    width: 100% !important;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  body.account fieldset.fieldset.password {
    margin-left: 0;
    margin-right: 0;
  }
}
body.account .block-collapsible-nav {
  width: 100% !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  body.account .block-collapsible-nav {
    position: initial !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 5 !important;
    flex-direction: column !important;
    background: #fff !important;
    margin-bottom: 15px;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  body.account .block-collapsible-nav-title {
    padding: 20px 40px 20px 15px !important;
    border: 0 !important;
  }
}
body.account .block-collapsible-nav-title:after {
  color: #fff;
  font-size: 50px !important;
  background: #6DB332;
  height: 30px;
  border-radius: 50%;
  width: 30px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
}
body.account .block-collapsible-nav .content {
  background-color: #ffffff;
  padding: 0;
  width: 100%;
  height: fit-content;
}
body.account .page-title-wrapper {
  width: 100%;
}
@media (min-width: 0px) and (max-width: 575px) {
  body.account .page-title-wrapper {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  body.account .page-main {
    padding-top: 15px;
  }
}

@media (min-width: 0px) and (max-width: 1023px) {
  .pager {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.pager .pages {
  text-align: center;
  padding: 0;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .pages {
    margin-bottom: 0;
  }
}
.pager .pages .item {
  font-size: 14px;
  margin: 0 3px;
  font-weight: 700;
  min-width: 15px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .pages .item {
    margin: 0 2px;
  }
}
.pager .pages .items {
  display: flex;
  align-items: center;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .pages .items {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}
.pager .pages .item .page {
  font-size: 14px;
  font-weight: 400;
  color: #2C2628;
}
.pager .pages .item.current {
  font-size: 16px;
}
.pager .pages .action {
  border: 1px solid #D8D8D8;
  background: #ffffff;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .pages .action {
    height: 40px;
    width: 28px;
    display: block;
    margin-left: 5px;
  }
  .pager .pages .action span {
    display: none;
  }
}
.pager .pages .action.next, .pager .pages .action.previous {
  height: 37px;
  width: 25px;
}
.pager .pages .action.next::before, .pager .pages .action.previous::before {
  margin: 0 0 0 -11px;
}
.pager .pages .action.next {
  margin-left: 3px;
}
.pager .pages .action.previous {
  margin-right: 3px;
}
.pager .pages .action.next::before, .pager .pages .action.previous::before {
  color: #cd2367;
  line-height: 35px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .pages .action.next::before, .pager .pages .action.previous::before {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}
.pager .limiter {
  display: flex !important;
  align-items: center;
  margin-bottom: 0 !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .limiter {
    display: inline-flex !important;
    justify-content: center;
  }
}
.pager .limiter select {
  margin: 0 10px;
  border: 1px solid #D8D8D8;
  background: #ffffff;
  padding-right: 0 !important;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .limiter-label {
    font-weight: 300;
    font-size: 15px;
  }
}
.pager .limiter-text {
  white-space: nowrap;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .limiter-text {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 1023px) {
  .pager .limiter-options {
    height: 40px !important;
    line-height: 40px !important;
    font-size: 14px !important;
  }
}

html body.badaboum--custom.badaboum .toolbar-product-qty {
  color: #2C2628;
  font-size: 16px;
  font-weight: 700;
}
@media (min-width: 0px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .toolbar-product-qty {
    text-align: center;
  }
}
html body.badaboum--custom.badaboum .toolbar-products {
  background-color: #F4F3E9 !important;
  padding: 10px 15px !important;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .toolbar-products {
    padding: 10px 5px !important;
  }
}
html body.badaboum--custom.badaboum .toolbar .pager {
  justify-content: space-between;
}
html body.badaboum--custom.badaboum .toolbar .pager .limiter, html body.badaboum--custom.badaboum .toolbar .pager .pages {
  display: block;
}
html body.badaboum--custom.badaboum .toolbar .pager .pages {
  order: 2;
}
html body.badaboum--custom.badaboum .toolbar .pager .pages-items {
  margin-top: 4px;
}
html body.badaboum--custom.badaboum .toolbar .pager .limiter {
  order: 3;
}
html body.badaboum--custom.badaboum .toolbar .pager .limiter #limiter {
  height: 40px;
  line-height: 40px;
}
html body.badaboum--custom.badaboum .toolbar .pager .toolbar-amount {
  order: 1;
  line-height: 29px;
}
@media (min-width: 576px) and (max-width: 1023px) {
  html body.badaboum--custom.badaboum .toolbar .pager .toolbar-amount {
    padding: 0;
  }
}
html body.badaboum--custom.badaboum .toolbar .pager .toolbar-sorter {
  order: 4;
}
html body.badaboum--custom.badaboum .toolbar .pager .toolbar-sorter.sorter .action {
  display: none;
}
html body.badaboum--custom.badaboum .toolbar .pager .toolbar-sorter.sorter #sorter {
  height: 40px;
  line-height: 40px;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .toolbar:not(.toolbar-bottom) {
    display: none;
  }
}
html body.badaboum--custom.badaboum .products.wrapper ~ .toolbar .toolbar-amount {
  display: block;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .products.wrapper ~ .toolbar .toolbar-amount {
    display: none;
  }
}
html body.badaboum--custom.badaboum .products.wrapper ~ .toolbar .sorter {
  display: flex;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .products.wrapper ~ .toolbar .sorter {
    padding: 20px 10px 0;
  }
}

html body.badaboum--custom.badaboum .sorter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html body.badaboum--custom.badaboum .sorter-label {
  white-space: nowrap;
}
@media (min-width: 0px) and (max-width: 767px) {
  html body.badaboum--custom.badaboum .sorter-label {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .sorter {
    width: 230px;
  }
}
html body.badaboum--custom.badaboum .sorter-select {
  width: 45%;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .sorter-select {
    width: 100%;
  }
}
html body.badaboum--custom.badaboum .sorter-options {
  background: #ffffff;
}
html body.badaboum--custom.badaboum .sorter-action::before {
  color: #cd2367;
}

.breadcrumbs {
  padding: 10px;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .breadcrumbs {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.breadcrumbs-wrapper {
  background-color: #F4F3E9;
  margin-bottom: 30px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .breadcrumbs-wrapper {
    margin-bottom: 10px;
  }
}
.breadcrumbs-wrapper .breadcrumbs-wrapper {
  margin-bottom: 0;
}
.breadcrumbs-item {
  display: inline;
}
@media (min-width: 0px) and (max-width: 767px) {
  .breadcrumbs-item {
    font-size: 11px;
  }
}
.breadcrumbs-items {
  width: 100%;
  font-size: 12px;
}
.breadcrumbs-item a {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #2C2628;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  line-height: 1;
}
@media (min-width: 0px) and (max-width: 767px) {
  .breadcrumbs-item a {
    font-size: 11px;
  }
}
.breadcrumbs-item a:hover {
  color: #980C3D;
  text-decoration: none;
}
.breadcrumbs-item:last-child {
  color: #cd2367;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.breadcrumbs-item:last-child span {
  font-weight: 700;
}
.breadcrumbs-item + .breadcrumbs-item::before {
  padding: 0 2px;
  content: "\e91e";
  font-family: "badaboum";
  font-size: 16px;
  line-height: 17px;
  color: #2C2628 !important;
  position: relative;
  top: 3px;
}
@media (min-width: 0px) and (max-width: 767px) {
  .breadcrumbs-item + .breadcrumbs-item::before {
    font-size: 15px;
  }
}
.breadcrumbs-link {
  display: flex;
  align-items: center;
}
.breadcrumbs-link::after {
  content: " > ";
  color: #786855;
  padding: 0 8px;
}

html body.badaboum--custom.badaboum .faq-category {
  width: 100% !important;
}
html body.badaboum--custom.badaboum .faq-category-title {
  text-transform: capitalize;
}
html body.badaboum--custom.badaboum .faq-item:last-child {
  margin-bottom: 4rem;
}
html body.badaboum--custom.badaboum .faq-item-title {
  order: 1;
}
html body.badaboum--custom.badaboum .faq-item-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html body.badaboum--custom.badaboum .faq-item-title-wrapper .icon-chevron-down {
  order: 2;
}
html body.badaboum--custom.badaboum .faq-item-title-wrapper .icon-chevron-down::before {
  transition: transform 0.5s ease !important;
  color: #2C2628 !important;
  font-size: 10px;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .faq-item-title-wrapper .icon-chevron-down::before {
    font-size: 14px;
  }
}
html body.badaboum--custom.badaboum .faq-item-title-wrapper .icon-chevron-down.active::before {
  transform: rotate(180deg);
}
html body.badaboum--custom.badaboum .faq-item-content {
  display: flex;
  flex-direction: column;
}
html body.badaboum--custom.badaboum .faq-item-links {
  align-self: flex-end;
}
html body.badaboum--custom.badaboum .faq-search-box-input {
  width: 100%;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .faq-search-box-input {
    width: 40%;
  }
}
html body.badaboum--custom.badaboum .faq-search-autocomplete ul {
  padding-left: 0;
  margin-bottom: 0;
}
html body.badaboum--custom.badaboum .faq-search-autocomplete ul li:before {
  content: none;
}
html body.badaboum--custom.badaboum .faq-question-column {
  margin: 0 auto;
  padding-left: 0;
  float: unset;
}
html body.badaboum--custom.badaboum .faq-question_view-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
html body.badaboum--custom.badaboum .faq-question_view-footer::before, html body.badaboum--custom.badaboum .faq-question_view-footer::after {
  content: unset;
}
html body.badaboum--custom.badaboum .faq-ask-form-container {
  max-width: 100%;
  margin: 20px auto 0;
}
@media (min-width: 768px) {
  html body.badaboum--custom.badaboum .faq-ask-form-container {
    max-width: 60%;
  }
}
@media (min-width: 0px) and (max-width: 575px) {
  html body.badaboum--custom.badaboum .faq-ask-form-btn {
    width: 100%;
  }
}
html body.badaboum--custom.badaboum .faq-rating {
  border: 1px solid #cd2367;
}
html body.badaboum--custom.badaboum .faq-rating-helpful {
  margin-bottom: 0;
}
html body.badaboum--custom.badaboum .faq-rating-vote {
  cursor: pointer;
  pointer-events: auto;
}
html body.badaboum--custom.badaboum .faq-rating-vote:last-child::before {
  content: "|";
  padding: 0 10px;
}
html body.badaboum--custom.badaboum .faq-rating-vote-wrapper {
  margin: 0 20px;
}
html body.badaboum--custom.badaboum .faq-rating-data-wrapper {
  margin-bottom: 0;
}

body.cms-amasty-faq-home-page {
  background-color: #f8f8f8 !important;
}

.container.cms .page-title-wrapper {
  text-align: center;
}

.view-more {
  position: relative;
}
@media (min-width: 0px) and (max-width: 1023px) {
  .view-more {
    padding: 0 15px;
  }
}
.view-more[data-elevator]:not(.active) {
  max-height: 100%;
}
@media (min-width: 0px) and (max-width: 767px) {
  .view-more[data-elevator]:not(.active) {
    max-height: 150px !important;
  }
}
.view-more[data-elevator]:not(.active):after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffffff 100%);
}
@media (min-width: 768px) {
  .view-more[data-elevator]:not(.active):after {
    content: none;
    display: none;
  }
}
.view-more.active:after {
  height: 0;
  transform: all;
  transition: transform 0.5s ease;
}
.view-more-btn {
  text-align: center;
  text-decoration: underline;
  margin-top: 20px;
  display: none;
  color: #cd2367;
}
@media (min-width: 0px) and (max-width: 767px) {
  .view-more-btn {
    display: block;
  }
}

html body.badaboum--custom.badaboum .ticket {
  margin-bottom: 20px;
}
html body.badaboum--custom.badaboum .ticket-primary {
  background: #F4F3E9;
  border-right-color: #D8D8D8;
}
html body.badaboum--custom.badaboum .ticket-warning {
  background: rgba(206, 10, 78, 0.1);
  border-left-color: #980C3D;
}
html body.badaboum--custom.badaboum .ticket-status {
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 400;
}
html body.badaboum--custom.badaboum .ticket-status--open {
  background: rgba(206, 10, 78, 0.1);
}
html body.badaboum--custom.badaboum .ticket-status--close {
  background: #B71918;
  color: #ffffff;
}
html body.badaboum--custom.badaboum .ticket-chat-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
html body.badaboum--custom.badaboum .ticket-chat-header {
  padding: 20px 0;
}

html body.badaboum--custom.badaboum .ambrands-brand-item {
  transition: 0.5s ease-in-out;
  border: 2px solid transparent;
  border-radius: 10px;
}
html body.badaboum--custom.badaboum .ambrands-brand-item::before {
  content: unset;
}
html body.badaboum--custom.badaboum .ambrands-brand-item:hover {
  border-color: #cd2367;
}
html body.badaboum--custom.badaboum .ambrands-inner {
  border: none;
  text-decoration: none;
}
html body.badaboum--custom.badaboum .ambrands-image-block {
  width: 100%;
}
html body.badaboum--custom.badaboum .ambrands-empty {
  width: auto;
  margin: auto;
}

body.ambrand-index-index .category-cover-background {
  background-repeat: no-repeat;
  background-size: contain;
}