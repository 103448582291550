@include font-face(
    $fp,
    '../fonts/montserrat-regular-webfont',
    300,
    normal,
    woff woff2
);

@include font-face(
    $fp,
    '../fonts/montserrat-medium-webfont',
    400,
    normal,
    woff woff2
);

@include font-face(
    $fp,
    '../fonts/montserrat-semibold-webfont',
    500,
    normal,
    woff woff2
);

@include font-face(
    $fp,
    '../fonts/montserrat-bold-webfont',
    700,
    normal, 
    woff woff2
);

@include font-face(
    $fp,
    '../fonts/montserrat-black-webfont',
    900,
    normal,
    woff woff2
);

 
 /* FONTICON ICOMOON, USE IF NEEDED */
 
/* 
@include font-face(
    $icon,
    '../../fonts/leader',
    400,
    normal,
    woff woff2
);*/


@include font-face(
    $icon,
    '../fonts/badaboum',
    400,
    normal,
    woff woff2
);