$tab : '.tab';
$mtabs : '.mtabs';

@mixin tabs() {

    &s {
        background: $color-background;
        width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        .common-slider {
            @include bp(xs, sm) {
                margin-left: -20px;
            }
        }

        &-container {
            width: 100%;
        }

        &--gap {
            padding: 0 10px;
            margin-bottom: -45px;

            @include bp(md) {
                margin-top: 0;
            }

            &--mobile {
                margin-bottom: 30px;

                @include bp(md) {
                    margin: 0;
                }
            }
        }

        &-header {
            display: flex;
            flex-wrap: nowrap;
            width: 100vw;
            margin: 0 -15px;
            position: relative;
            overflow: auto;

            @include bp(md) {
                width: 100%;
                justify-content: start;
                margin: 0;
            }

            &.visible-lg {
                @include bp(lg) {
                    display: flex !important;
                }
            }

            &.visible-xl {
                @include bp(xl) {
                    display: flex !important;
                }
            }

            &-row {
                display: flex;
                justify-content: center;
            }

            &--icon {
                margin: 0 -6px;
            }

            $icon: #{&}--icon;

            #{$icon} &-item {
                font-size: 0;
                padding: 10px 5px 20px;
                margin: 0 6px;
                border-radius: 3px 3px 0 0;

                &::after {
                    width: 40px;
                }

                &::before {
                    font-size: 36px;
                    display: block;
                }
            }

            &--small {
                align-items: center;

                @include bp(ml) {
                    white-space: nowrap;
                }
            }


            &--small #{$tab}s-header-item {

                font-size: 13px;
                padding: 8px 8px;
                background: none;
                margin: 0;

                @include bp(md) {
                    padding: 8px 8px;
                }

                @include bp(ml) {
                    padding: 18px 8px;
                    font-size: 16px;
                }
            }

            &--medium {
                margin: 0 -7px;
            }

            $medium: #{&}--medium;

            #{$medium} &-item {
                margin: 0 7px;
                font-size: 16px;
                padding: 19px 10px;
                align-content: center;
                align-items: center;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }

            &-item {

                $headeritem: &;

                &::before {
                    display: none;
                }

                padding: 18px 11px;
                width: 100%;
                flex: 1;
                cursor: pointer;
                @include transition(background $duration, border-color $duration);
                color: $color-main;
                font-size: 18px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                line-height: 24px;
                text-align: center;
                background: $color-background;
                display: block;
                border: 0px;
                position: relative;
                transition: 0.5s;
                margin-bottom: 0;
                margin-top: 0;
                text-transform: capitalize;
                white-space: nowrap;
                max-width: 220px;

                &>a {
                    color: $color-main;
                    font-size: 18px;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: 24px;
                    text-align: center;
                    @include transition(color $duration);
                }

                &:nth-child(even){
                    margin-left: 15px;
                    margin-right: 15px;
                }


                #{$headeritem} + #{$headeritem} {

                    @include bp(md) {
                        display: inline-block;
                        text-align-last: auto;
                    }
                }

                &.active:hover,
                &:hover {
                    background: $color-primary-lighter;
                    
                    > a {
                        color: $color-white;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 67px;
                    bottom: 0;
                    left: 50%;
                    background: $color-primary;
                    transform: translateX(-50%) scaleX(0);
                    opacity: 0;
                    transition: 0.75s;

                    @include bp(ml) {
                        width: 100px;
                    }
                }

                &.active::after {
                    opacity: 1;
                    transform: translateX(-50%);
                }

                &.active {
                    color: $color-white;
                    background-color: $color-primary;
                    > a {
                        color: $color-white;
                    }
                }


                &--secondary {
                    @include bp(xs, md) {
                        color: #2c262880;

                        &.active,
                        &.active:hover {
                            // color: $color-black;
                            // background: $color-background;

                            &::after {
                                display: none;
                            }
                        }

                        &:hover {
                            // color: $color-black;
                            // background: $color-background;
                        }
                    }
                }
            }
        }
    }

    & {
        opacity: 0;
        max-height: 0;
        pointer-events: none;
        display: none;

        & &--active {
            padding: 0;
            overflow: visible;
        }
    }

    &--active {
        opacity: 1;
        pointer-events: initial;
        max-height: none;
        display: block;
        padding: 20px 20px 40px;
        max-width: get-breakpoint-value($breakpoints, 6);
        width: 100%;
        margin: auto;

        @include bp(lg) {
            padding: 20px 20px 40px;
        }
    }

    &-description {
        margin: 0 auto 20px;
        color: $color-main;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;

        @include bp(md) {
            padding: 0 10px;
            margin: 20px auto 20px;
            font-size: 14px;
            line-height: 26px;
        }
    }
}

@include bp(xs) {
    #{$tab} {
        @include tabs;
    }
}

#{$mtabs} #{$tab} {

    @include bp(xs, sm) {
        @include tabs;
    }

    @include bp(xs) {
        & {
            opacity: 1;
            max-height: none;
            pointer-events: all;
            padding: 0;
        }
    }
}
