.menu {
    position: relative;
    background-color: $color-white !important;

    // hamburger-button
    &-hamburger-button {
        order: 3;
        margin: 0 !important;

        @include bp(xs, md) {
            transform: translateY(-5px);
        }
    }

    &-list {
        justify-content: space-between;
        max-width: calc(1540px - 20px) !important;
        position: unset !important;
    }

    &-item {

        &:nth-last-child(-n+2) {
            .menu-item-content {
                color: $color-primary !important;
            }

            &:hover {
                background: $color-primary !important;
    
                &, .menu-item-content {
                    color: $color-white !important;
                }
            }
        }
    }

    &-link {
        position: relative;
        padding: 20px 10px !important;
        transition: 0.5s;

        &:hover {
            background: $color-primary !important;

            &, p {
                color: $color-white !important;
            }
        }

        &::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 95%;
            bottom: 0;
            left: 50%;
            background: $color-primary;
            transform: translateX(-50%) scaleX(0);
            opacity: 0;
            transition: 0.75s;
        }

        &.-current {

            &:hover{
                background: $color-primary !important;
            }
            
            &::after {
                opacity: 1;
                transform: translateX(-50%);
            }

            p {
                color: $color-primary;
            }
        }

        p {
            color: $color-main;
            font-family: "Montserrat - Medium";
            font-size: 15px;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            font-weight: 500 !important;
        }
    }


    // submenu
    &_submenu {
        background: $color-background-body !important;

        &-category-section {
            column-count: 4;
            display: block;
            max-width: calc(1540px - 20px);
            margin: auto;
        }

        &-column {
            -webkit-column-break-inside: avoid;
            break-inside: avoid-column;
            width: 100% !important;
            padding-right: 0 !important;
            max-width: 100% !important;
            display: block !important;
        }

        &-wrapper {
            max-width: get-breakpoint-value($breakpoints, 6);
            margin: 0 auto;

            .pagebuilder-column {

                &-group {
                    gap: 0 1.3em;
                }
            }
        }

        #{$prefix} &-title {
            color: $color-main !important;
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            cursor: pointer !important;
            pointer-events: auto !important;
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            &:hover {
                color: $color-primary !important;
            }
        }

        &-list {
            padding-left: 15px !important;
            
           .menu_submenu-item-link p + .menu_submenu-icon {
                display: none;
            }
        }

        &-item {
            position: relative;
            padding-left: 10px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 4px;
                width: 3px;
                height: 3px;
                border-radius: 100%;
                background: $color-primary;
            }

            &:first-child {
                @include bp(md) {
                    display: none;
                }
            }

            &-link {
                pointer-events: auto;
                cursor: pointer;
                padding: 0 0 10px 0;

                @include bp(xs, sm) {
                    padding: 0 0 0 0 !important;
                }

                &:hover {
                    p {
                        color: $color-primary;
                    }
                }

                &.-current {
                    p {
                        color: $color-primary !important;
                    }
                }

                p {
                    color: $color-main;
                    font-family: "Montserrat";
                    font-size: 13px;
                    font-weight: 200;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    text-align: left;
                }
            }
        }

        &-link {

            a {
                cursor: pointer;
                pointer-events: auto;
                color: $color-primary;
                text-decoration: underline;
            }
        }

        &-icon {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            border: 1px solid $color-border-submenu;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include bp(lg) {
                figure {
                    transform: none !important;
                }
            }

            @include bp(xs, ml) {
                display: flex;
                justify-content: center;
                align-items: center;

                .ammenu-icon-block {
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                } 
                
                &.-toggle {
                    margin-left: auto !important;
                }
            }
            
            .ammenu-icon-block {
                width: 100% !important;
                height: 100% !important;
                margin-right: 0 !important;
            }

            &-wrapper {
                .menu_submenu {
                    &-category-section {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: baseline;
                        justify-content: space-between;
                        gap: 1.2em;
                    }

                    &-column {
                        position: relative;
                        width: calc((100% / 3) - 1.2em) !important;
                        padding-bottom: 0;
                        margin-bottom: 20px;

                        &::after,
                        &::before {
                            content: "";
                            position: absolute;
                            right: 75px;
                            bottom: 0;
                            width: 50%;
                            height: 2px;
                            border-bottom: 1px solid $color-border-submenu;
                        }

                        &::before {
                            width: 0;
                            border-bottom: 1px solid $color-primary;
                            transition: width .5s ease-in-out;
                        }

                        &:hover {
                            &::before {
                                width: 50%;
                            }
                        }

                    }
                }
            }

            &-right {

                .menu_submenu-right-single-image {
                    max-width: 335px;
                    max-height: 170px;

                    &:last-child {
                        margin-top: 30px;
                    }
                }
            }
        }

        &-right {
            border-left: 1px solid $color-border-submenu;
            padding-left: 25px;
            height: fit-content !important;

            &-title {
                color: $color-primary;
                font-family: "Montserrat";
                font-size: 16px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                margin-top: 0;
                text-align: left;
                text-decoration: underline;
                text-transform: uppercase;
            }

            &-color-list {
                margin-bottom: 40px;
                width: 345px;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1.2em;

                    li {
                        display: flex;
                        align-items: center;
                        gap: 1.2em;
                        width: calc((100% / 2) - 1.2em);
                        position: relative;

                        &::after,
                        &::before {
                            content: "";
                            position: absolute;
                            right: 25px;
                            bottom: 0;
                            width: 50%;
                            height: 2px;
                            border-bottom: 1px solid $color-border-submenu;
                        }

                        &::before {
                            width: 0;
                            border-bottom: 1px solid $color-primary;
                            transition: width .5s ease-in-out;
                        }

                        &:hover {
                            &::before {
                                width: 50%;
                            }

                            img {
                                border-color: $color-primary;
                            }
                        }

                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            border: 1px solid $color-grey-darker;
                            padding: 2px;
                            transition: border-color .5s ease-in-out;
                        }

                        a {
                            color: $color-main;
                            font-family: "Montserrat";
                            font-size: 14px;
                            font-weight: 500;
                            font-style: normal;
                            letter-spacing: normal;
                            line-height: normal;
                            text-align: left;
                        }
                    }
                }
            }

            &-image {
                &-wrapper {
                    max-width: 335px;
                    max-height: 171px;
                    height: 100%;
                    width: 100%;
                }
            }

            &-single-image {
                max-width: 600px;
                max-height: 305px;
                height: 100%;
                width: 100%;
            }

            &-double-image-list {
                ul {
                    display: flex;
                    gap: 0 1.2em;
                }
            }

            &-multiple-image-list {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1.2em;

                    li {
                        width: calc((100% / 2) - 1.2em);
                        max-height: 135px;

                        img {
                            width: 100%;
                            // height: 135px;
                            height: auto !important;
                        }
                    }
                }
            }
            
            &-double-image-list{
                img{
                    height: auto !important;
                }
            }
        }
    }


    //mobile sidemenu
    &_sidebar {
        display: flex;
        flex-direction: column;
        width: 90vw !important;
        height: 100vh !important;
        
        &.ammenu-nav-sections.-sidebar.-mobile.-drill.-animation-undefined.-opened {
            #axeptio_main_button {
                display: none !important;
            }
        }

        &-title {
            &-wrapper {
                padding: 25px 20px;
                display: flex;
                align-items: center;

                .logo {
                    max-width: 50%;

                    &-wrapper {
                        display: flex;
                        justify-content: center;
                        margin: auto;
                    }
                }
            }
        }

        &-close {
            position: absolute;

            #{$prefix} &::after,
            #{$prefix} &::before {
                height: 30px;
                width: 2px;
            }
        }

        &-section,
        &-wrapper {
            height: fit-content !important;

            &-account {
                margin-top: 20px;
                margin-bottom: 20px;

                .menu {
                    &_sidebar {
                        &-active-level {
                            display: none !important;
                        }

                        &-list {
                            display: flex !important;
                            gap: 15px;
                        }

                        &-item {
                            width: calc((100% / 2) - (15px / 2));
        
                            &:first-child {
                                border-bottom: 0;
                                border-top: 0;
                            }
        
                            &:last-child {
                                border: 0;
                            }
        
                            &:not(:first-child) {
                                .menu_submenu-item-link {
                                    border-bottom: 1px solid $color-border !important;
                                }
                            }
        
                            .menu_submenu-item-link {
                                border: 1px solid $color-border !important;
                                padding: 0 10px !important;
        
        
                                .ammenu-text {
                                    text-align: center;
                                    word-break: inherit;
        
                                    &-block {
                                        width: 100%;
                                        display: unset !important;
                                        text-align: center !important;
                                        padding: 0 15px;
                                    }
                                }
        
                                .ammenu-icon {
                                    &-block.-icon {
                                        margin: 0;
                                        width: 22px;
                                        height: 22px;
                                    }
                                }
                            }
        
                            &:nth-last-child(-n+2) {
                                .menu_submenu-item-link .ammenu-text-block {
                                    color: $color-main;
                                }
                            }
                        }
                    }

                    &_submenu {
                        &-icon {
                            svg {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }
        }

        &-section {
            overflow: visible !important;
        }

        #{$prefix} &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-left: 15px;
            padding-right: 15px;
        }

        &-item {
            width: 100%;

            &:first-child {
                color: $color-primary-darker;
                border-top: 1px solid #a8a8a878;
            }

            &:last-child {
                border-bottom: 1px solid #a8a8a878;
            }

            .menu_submenu-item-link {
                border-color: #a8a8a878 !important;
                justify-content: flex-start;
                gap: 20px;
            }

            .menu_submenu-icon {
                width: 30px !important;
                height: 30px !important;
                padding: 0 !important;
                display: flex !important;
                align-items: center !important;
                border: none;
                margin-right: 0 !important;
                

                svg {
                    color: $color-grey;
                }
            }
        }

        &-navigation {
            margin: 0;
            padding: 0 15px;
            background-color: $color-primary-lighter-1;
            border-bottom: 0;

            button {
                padding: 20px 0;

                &.-prev + .-go-main {
                    display: none;
                }
            }

            #{$prefix} &-content {
                font-weight: bold;
                font-size: 14px;
                color: $color-main;
                margin-left: -10px;
            }

            .menu_submenu-icon {
                width: 20px !important;
                height: 20px !important;
                padding-right: 20px;
                padding-left: 11px;
                border: none !important;
                margin-left: 0 !important;
                
                &:before {
                    content: '\e912';
                    font-family: 'badaboum';
                    font-size: 18px;
                    color: $color-primary !important;
                    font-weight: 700;
                }
                
                .ammenu-icon-block {
                  display: none;
                }

                svg {
                    color: $color-primary
                }
            }
        }

        &-active {
            &-level {
                margin-top: -74px;
                z-index: 10;
                position: relative;
                background: white;

                .menu {
                    &_sidebar {
                        &-item {
                            &:first-child {
                                border-top: 0;

                                .ammenu {
                                    &-text {
                                        &-block {
                                            color: $color-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ammenu {
    &-menu-wrapper {
        margin-bottom: 0;
    }
}