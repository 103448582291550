#{$prefix} .tag {
    &s {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    & {
        background: $color-background;
        text-transform: inherit;
        margin-right: 15px;
        margin-bottom: 15px;

        &:last-child {
            margin-right: 0;
        }

        h2 {
            margin: 0;
        }
    }

    &-content {
        color: $color-grey;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        margin: 0;
    } 
}