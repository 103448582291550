#{$prefix} .footer{
	
	&-container{
		padding: 0;
		background: $color-background;
		padding: 0 10px 40px;

		@include bp(xs, md) {
			.container {
				padding-left: 0;
				padding-right: 0;
			}
		}

		@include bp(md) {
			padding: 60px 0 40px;
		}
		
		@include bp(md, ml) {
			padding-left: 15px;
			padding-right: 15px;
		}


		.footer-payment{
			.row-flex--start{
				justify-content: space-between;
				.img-wrapper{
					@include bp(md, ml) {
						max-width: 35px;
					}
				}
			}
		}
	}
	
	&-row {
		display: flex;
		padding:15px 0px;
		justify-content: center;
		flex-wrap: wrap;

		@include bp(md){
			padding:20px 0px;
		}

		&--nogap {
			padding: 0px;
		}
	}
	
	&-newsletter{
		padding: 15px;

		@include bp(md){
			padding: 0;
		}
		
		@include bp(md, ml){
			.footer-col-section {
				display: flex;
				align-items: baseline;
				margin-bottom: 0;
				.newsletter{
					margin-right: 25px;
				}
				.footer-devis{
					margin-left: 25px;
				}
			}
		}

		.block.newsletter {
			@include bp(md, ml){
				width: 50%;
			}

			@include bp(xs, sm){
				width: 100%;
                margin-bottom: 20px;

				.input-box{
					.input-label{
						font-size: 18px;
							text-align: center;

							@include bp(md){
											font-size: 16px;
											text-align: left;
							}
					}
				}
			}

			.btn--primary.subscribe {
				margin-top: -2px;
				margin-left: 0;
				@include bp(xs, sm){
					margin-top: 3px;
				}
			}
			
			.input-label {
				margin-bottom: 8px;
			}
            
			.input-text {
				height: 50px;

				@include bp(md) {
					height: 39px;
				}
			}
			
			.input-box-button {
				height: 50px;
				top: unset;
                bottom: 3px;

				@include bp(md) {
					height: 39px;
					top: 28px;
                    bottom: unset;
				}
			}
		}
			
		.field.newsletter {
			width: 100%;
		}
	}
	
	
	&-col {
		
		&-last {

			@include bp(md, ml) {
				margin-top: 30px;
				width: 100%;
			}

			@include bp(ml) {
				margin-left: auto !important;
			}

			.footer-col-title {
				@include bp(md) {
					font-size: 13px;
				}
			}
		}
		
		&-title{
			color: $color-main;
			font-family: $fp;
			font-size: 18px;
			font-weight: 500;
			font-style: normal;
			letter-spacing: normal;
			line-height: 34px;
			text-align: left;
			display: flex;
			align-items: center;

			@include bp(md){
				display: inline;
				padding: 0;
				margin-bottom: 13px;
				font-size: 16px;
			}

			@include bp(ml) {
				pointer-events: none;
			}
			
			&::after{
				font-size: 12px;
				line-height: 14px;
				margin-left: auto;
				transition: 0.5s;
				
				@include bp(md){
					display: none;
				}
			}
			
			&.active{
				&::after{
					transform: rotate(180deg);
				}
			}
		}
		
		width: 100%;
		
		&-section{
			
			position: relative;
			margin-bottom: 20px;
			
			@include bp(md){
				margin-bottom: 0;
			}
			
			&:last-of-type &::before {
					content: none
			}
			
			&::before{
				content:'';
				bottom: 0;
				left: 50%;
				background: #D8D8D8;
				transform: translateX(-50%);
				position: absolute;
				width: 95vw;
				height: 1px;
			}			
			
			@include bp(md){
				padding: 0;
				
				&::before{
					display: none;
				}
				
				& + &{
					margin-top: 17px;
				}
			}
			.btn--primary{
				&.subscribe{
					margin-top: -1px;
					margin-left: 0;
				}
			}
		}
	}
	&-content {
		padding:0px 20px;
		padding-top:50px;
		position: relative;

		@include bp(md){
			padding-top:40px;
		}

		&-nav {
			padding:30px 0px 10px;
			position: relative;
			max-width: 1210px;
			margin:auto;
			
			@include bp(md){
				padding:20px 0px 10px 200px;
				padding-top:20px;
				min-height:255px;
			}

			@include bp(lg){
				justify-content: flex-start;
			}

			//ROSE DES VENTS
			&:before {
				position: absolute;
				top:50%;
				left:-7%;
				transform: translateY(-50%);
				font-size: 245px;
				opacity: 0.3;
				display: none;

				@include bp(md){
					display: block;
				}
			}
			
			//BORDER-TOP
			&:after {
				content:'';
				position: absolute;
				left:50%;
				top:0%;
				transform: translateX(-50%);
				background: $color-grey-light;
				height:1px;
				width: 100%;
			}
		}

		&-title {
			font-family: $fp;
			font-size: 18px;
			line-height: 27px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			&::after{
				font-family: $icon;
				content:'\e921';
				transition: 0.5s;
				margin-left: 10px;
				font-size: 24px;
				color: $color-grey;
				@include bp(md){
					display: none;
				}
			}
			
			&.active{
				&::after{
					transform: rotate(180deg);
				}
			}
			
			
			
			@include bp(md){
				font-size: 16px;
				margin-bottom: 0;
			}
			
			@include bp(lg){
				font-size: 18px;
				margin-bottom: 14px;
			}
		}

		&-link {
			
			& {
				margin-top: 3px;
			}
			
			&s {
				line-height: 22px;
				font-size: 14px;
				overflow: hidden;
				max-height: 0;
				transition: 0.5s;
				
				&.open{
					max-height:none;
				}
				
				@include bp(md){
					max-height:none;
				}
			}
			
			@include bp(xs){
				&:first-child{
					margin-top: 8px;
				}
			}
			
			
			&:before {
				content:'-';
				margin-right:5px;
			}
		}
	}
	
	&-legal {
		
		&s {
			background: $color-primary;
			line-height: 24px;
			color: $color-white;
			padding-top: 10px;
			padding-bottom: 10px;
			font-weight: bold;
			font-size: 12px;
			line-height: 16px;
			
			&-content{
				display: flex;
				align-items: center;
				justify-content: center;
				align-content: center;
				flex-wrap: wrap;
			}
			
			&-item{
				
				width: 100%;
				text-align: center;
				@include bp(md){
					text-align: initial;
					width: auto;
				}
				
				& + &{
					margin-top: 6px;
				
					@include bp(md){
						margin-top: 0;
						margin-left: auto;
					}
				}
			}
		
			&-link{
				
				&s{
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;
					padding: 0 20px;
					@include bp(md){
						padding: 0;
					}
				}
				
				&{
					white-space: nowrap;
				}

				& + & {
					padding-left: 4px;
					margin-left: 8px;
					position: relative;
					
					
					&:before {
						content:'|';
						position: absolute;
						left:-4px;
						top:50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
	
	&-text,
	&-link {
		&s{
			@include bp(md){
				margin-top: 10px;
			}
		}
		position: relative;
		color: $color-grey-darker;
		font-size: 14px;
		cursor:pointer;
		margin-bottom: 6px !important;
		
		&:last-child{
			margin-bottom: 20px !important;
			@include bp(md){
				margin-bottom: 0px !important;
			}
		}
		
		span.link {
            font-size: 13px;
		}
		
		& + &{
			margin-top: 11px
		}
		
		&:after {
			// content:'';
			position: absolute;
			bottom:-3px;
			left:0px;
			right:0px;
			height:1px;
			background: $color-grey-darker;
			transform: scaleX(0);
			transform-origin: left;
			transition: transform 0.5s ease;
		}

		&:hover:after {
			transform:scaleX(1);
		}

		&--light {
			color: $color-white;
			
			&:after {
				background: $color-white;
			}
		}
	}

	&-other-link{
        margin-bottom: 40px;

        @include bp(xs, sm) {
            margin-bottom: 15px;
            flex-wrap: nowrap;
			.img-wrapper{
				&:nth-child(even){
					margin: 0 10px;
				}
			}
        }

		.img-wrapper {
			max-width: unset;
			max-height: unset;
			height: auto;
			width: 100%;

			@include bp(md){
                max-width: 100px;
                max-height: 36px;

                img {
                    object-fit: fill;
                }
			}

		}
	}

	
	&-social{
		&s{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;

			@include bp(md){
				justify-content: space-between;
			}

			&--mobile {
				justify-content: unset;
                margin: 10px auto 35px;
				
				span:nth-child(even) {
					margin: 0 10px;
				}
	
				@include bp(md) {
					display: none;
                    a:nth-child(even) {
						margin: 0 20px;
					}
				}
			}
	
			&--desktop {
				display: none;
	
				@include bp(md) {
					display: flex;
				}
			}
		}
		
		&{
			font-size: 25px;
			color: $color-white !important;
			position: relative;
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-46%, -48%);
			content: none !important;
		}

		&-icon {
			font-size: 40px;
			color: $color-primary;
			opacity: 1;
			transition: opacity 0.5s;
	
            @include bp(md) {
                font-size: 35px;
            }

			&:hover {
				opacity: .5;
			}

            &--svg {
                margin-top: -2px;
            }
		}
	}
	
	&-logo {
		width: 100%;
		height: auto;
		object-fit: contain;
		margin: 30px 0;
		justify-content: center;

		@include bp(md) {
			justify-content: flex-start;
			margin-bottom: 40px;
			margin-top: 0;
		}

		.logo-wrapper {
			width: 250px !important;
		}

		a.logo {
			@include bp(xs, md) {
                margin: auto;
				float: unset;
                max-width: 200px;
                display: block;
			}

			@include bp(md) {
				margin: 0 !important;
			}
			@include bp(ml) {
				max-width: unset;
			}
		}
	}

	&-line {

		&--top {
			@include bp(md) {
				padding-top: 30px !important;
				border-top: 1px solid $color-border;
			}
		}
	}

	&-payment {
		padding-right: 15px;
		padding-left: 15px;

		@include bp(md){
            margin-top: 30px;
			padding: 0 0 0 0;
		}
		
		@include bp(ml) {
			.row-flex--start {
				justify-content: space-between !important;
			}
		}

        &-icons {
            @include bp(xs, sm) {
                align-items: center;

                img {
                    width: auto;
                    height: auto;
                    max-height: 100%;
                }
            }
        }
	}
	
	&-devis {
		@include bp(md, ml) {
			width: 50%;
			
			.footer-col-title {
				margin-bottom: 0;
			}
		}
		@include bp(xs, sm){
			.footer-col-title {
				font-size: 16px;
			}
		}
	}
}	


#{$prefix} .page-bottom {
	margin-left: auto;
	margin-right: auto;
	max-width: 1335px;
	width: 100%;

	@include bp(sm, md) {
		padding: 0 20px;
	}
}

#{$prefix} .icon-chevron-up-scroll::before{
	transform: rotate(90deg);
}