.breadcrumb {
    &s {
        padding: 10px;

        @include bp(xs, md) {
            padding-left: 15px;
            padding-right: 15px;
        }

        &-wrapper {
            background-color: $color-background;
            margin-bottom: 30px;
            
            @include bp(xs, sm) {
                margin-bottom: 10px;
            }

            & & {
                margin-bottom: 0;
            }
        }

        &-item {
            display: inline;
                
            @include bp(xs, sm) {
                font-size: 11px;
            }

            &s {
                width: 100%;
                font-size: 12px;
            }

            & a {
                @include transition($duration);
                color: $color-grey;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                line-height: 1;

                @include bp(xs, sm) {
                    font-size: 11px;
                }

                &:hover {
                    color: $color-primary-darker;
                    text-decoration: none;
                }
            }

            &:last-child {
                color: $color-primary;
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                
                span {
                    font-weight: 700;
                }
            }

            &+& {
                &::before {
                    padding: 0 2px;
                    content: '\e91e';
                    font-family: 'badaboum';
                    font-size: 16px;
                    line-height: 17px;
                    color: $color-grey !important;
                    position: relative;
                    top: 3px;

                    @include bp(xs, sm) {
                        font-size: 15px;
                    }
                }
            }
            
        }

        &-link {
            display: flex;
            align-items: center;
    
            &::after {
                content: ' > ';
                color: $color-secondary;
                padding: 0 8px;
            }
        }
    }
}
