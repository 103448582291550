#{$prefix} .popup {    
    &-title {
        background: $color-primary;
        padding: 10px 0;
        text-align: center;
        text-transform: none;
        height: 35px;
        color: $color-white;
        
        @include bp(md) {
            height: auto;
        }
         
        p {
            font-weight: bold;
            font-size:10px;
            display:  none;
             
            
            @include bp(md) {
                font-size: 1.4rem;
                display: block;
            }
        }
    }

    &-wrapper {
        border-radius: 4px;
    }
    
    &-image {
        height: 200px !important;
        width: 200px !important;
        order: 1;

        @include bp(xs, sm) {
            margin-top: 10px;
            display: none;
        }
    }

    &-content {
        height: auto !important;
        display: flex;
        padding: 20px 20px 0 !important;
        align-items: flex-start;

        @include bp(xs, sm) {
            padding: 20px 10px 0 !important;
            flex-direction: column;
            align-items: center;
        }
        
        .price-box.price-final_price {
            padding-left: 0;
        }
        
        span.price {
            font-size: 16px;
            font-weight: 500;           
        }
        
        .product-add-form {
            order: 2;
        }
        
        div[data-element="main"] {
            display: none;
        }
    }
    
    &-name {
        text-align: left;
        font-weight: 600;
        margin: 0;

        @include bp(xs, sm) {
            font-size: 20px;
            text-align: center;
        }
    }
    
    &-qty {
        // gap: 0 20px;
        
        > p {
            margin-bottom: 0;
        }
        
        .qty {
            &-btn {
                width: 30px;
                height: 30px;
            }
              
            &-input {
                height: 30px;
                width: 50px;
            }
        } 
    }
    
    &-info {
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include bp(xs, sm) {
            align-items: center;
        }
        
        @include bp(md) {
            margin-left: 20px;
        }
    }

    &-cartinfo {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-top: 20px;

        .text-colored {
            font-weight: bold;

            .price {
                @include bp(xs, sm) {
                    font-size: 16px;
                }
            }
        }

        p {
            font-size: 16px !important;
            text-align: left;
        }
    }

    &-btn {

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
           padding: 0 20px 0 !important;

            @include bp(xs, sm) {
                flex-direction: column;
                
                span {
                    font-size: 14px !important;
                }
            }

            .btn {
                &--secondary {
                    margin-top: 20px;

                    @include bp(sm) {
                        margin-left: 20px;
                        margin-top: 0;
                    }
                }
            }
        }

         &--close {
            background: none !important;
            
            @include bp(xs, md) {
                top: 5px;
                right: 0px;
                display: flex;
                align-items: center;
            }

            &.icon.icon-close {
                &:before {
                    color: $color-white;
                    font-weight: bold;
                }
            }
        }
    }

    // WISHLIST POPUP
    &-wishlist{

        &-wraper {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
        }

        &-name {
            text-align: center;
            padding: 0 20px;

            &-wrapper {
                width: 100% !important;
                height: 100px !important;
                margin-top: 0 !important;
            }
        }
        
        &-image {
            display: none;
        }
         
        &-form-wrapper {
            width: 100% !important;
            padding-bottom: 15px;
        }
        
        &-form {
            width: 100%;
        }
        
        &-error {
            &-box {
                padding: 15px;
            }
        }
    }
}

#mb-ajaxwishlist-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

// #mb-ajaxcart-wrapper img.image {
//     object-fit: contain;
    
//     @include bp(md) {   
//         height: 200px !important;
//         width: 200px !important;
//         order: 1;
//     }

//     @include bp(xs, sm) {
//         margin-top: 10px;
//         display: none;
//     }
// }