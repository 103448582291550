#{$prefix} .faq {
    &-category {
        width: 100% !important;

        &-title {
            text-transform: capitalize;
        }
    }

    &-item {

        &:last-child {
            margin-bottom: 4rem;
        }

        &-title {
            order: 1;

            &-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            
            .icon-chevron-down {
                order: 2;    

                &::before {
                    transition: transform 0.5s ease !important;
                    color: $color-grey !important;
                    font-size: 10px;
                    
                    @include bp(md) {
                        font-size: 14px;
                    }
                }

                &.active {
                    &::before {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        }

        &-content {
            display: flex;
            flex-direction: column;
        }

        &-links {
            align-self: flex-end;
        }
    }

    &-search-box {
        &-input {
            width: 100%;

            @include bp(md) {
                width: 40%;
            }
        }
    }

    &-search-autocomplete {
        ul {
            padding-left: 0;
            margin-bottom: 0;
        
            li {
                &:before {
                    content: none;
                }
            }
        }
    }
    
    &-question {
        &-column {
            margin: 0 auto;
            padding-left: 0;
            float: unset;
        }
        
        // faq question detail page
        &_view {
            &-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                &::before, &::after {
                    content: unset;
                }
            }
        }
    }
    
    &-ask {
        &-form {
            &-container {
                max-width: 100%;
                margin: 20px auto 0;
                
                @include bp(md) {
                    max-width: 60%;
                }
            }
            
            &-btn {
                @include bp(xs, xs) {
                    width: 100%;
                }
            }
        }
    }
    
    &-rating {
        border: 1px solid $color-primary;
        
        &-helpful {
            margin-bottom: 0;
        }
        
        &-vote {
            cursor: pointer;
            pointer-events: auto;
            
            &:last-child {
                
                &::before {
                    content: '|';
                    padding: 0 10px;
                }
            }
            
            &-wrapper{
                margin: 0 20px;
            }
        }
        
        &-data {
            &-wrapper {
                margin-bottom: 0;
            }
        }
    }
}

body.cms-amasty-faq-home-page {
    background-color: $color-background-body !important;
}

.container.cms {
    .page-title-wrapper {
        text-align: center;
    }
}
