.byblock {
    &s {
        display: grid;
        grid-gap: 20px;
        justify-items: stretch;
        align-items: stretch;
        grid-template-areas:
            "block-big"
            "block-small-top"
            "block-small-bottom";
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);

        @include bp(ml) {
            grid-template-areas:
                "block-big block-small-top"
                "block-big block-small-bottom";
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, calc(310px - 20px));
        }
    }

   &-item {
      width: 100%;
      height: 100%;
      position: relative;

      &--big {
         grid-area: block-big;
      }

      &--small {
         .byblock-item {
         
           &-content {
               left: 180px;
               width: 150px;

               @include bp(md, ml) {
                  left: 335px;
                  width: fit-content;
               }

               @include bp(ml) {
                  left: 250px;
                  width: fit-content;
               }
            }
            
            &-title {
               font-size: 14px;
      
               @include bp(md) {
                  font-size: 20px;
               }
            }
            
            &-subtitle {
               font-size: 12px;
      
               @include bp(md) {
                  font-size: 14px
               }
            }
            
            &-desc {
               font-size: 12px;
               @include bp(md) {
                  font-size: 16px
                }
            }
         }
      }

      &--small-top {
         grid-area: block-small-top;
      }

      &--small-bottom {
         grid-area: block-small-bottom;
      }

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;

         @include bp(ml) {
            object-fit: fill;
         }
      }


      &-content {
         display: flex;
         flex-direction: column;
         position: absolute;
         top: 50%;
         left: 120px;
         transform: translateY(-50%);

         @include bp(md, ml) {
            left: 335px;
         }


         @include bp(ml) {
            left: 230px;
         }
      }
      
      &-title {
         color: $color-white;      
         font-size: 20px;
         font-weight: 600;
         font-style: normal;
         letter-spacing: normal;
         line-height: normal;

         @include bp(md) {
            font-size: 40px
         }
      }
      
      &-subtitle {
         font-size: 16px;
         font-weight: 600;
         font-style: normal;
         letter-spacing: normal;
         line-height: normal;

          @include bp(md) {
            font-size: 20px
          }
      }
      
      &-desc {
         color: $color-white;
         font-size: 18px;
         font-weight: 500;
         font-style: normal;
         letter-spacing: normal;
         line-height: normal;

         @include bp(md) {
            font-size: 22px;
         }
      }
   }
}