@use 'sass:math';

html,
input,
textarea,
select,
// 	{
// 	// font: normal normal 14px/1 $font-primary;
// 	// color: $color-main;
// }

body {
    line-height: 1.4;
    color: $color-main !important;
    background: $color-white;
    font-family: $fp;
    max-width: 100vw;

    @include bp(xs, md) {
        overflow-x: hidden;
    }

    &.nav-open,
    &.sidebar-open {
        overflow: hidden;
    }

    &.cms-home.cms-index-index.page-layout-home_new {
        overflow-x: hidden;
    }
}


#{$prefix} * {
    box-sizing: border-box;
    outline: none !important;
    font-family: $fp;
}



/*********** ELEMENTS GENERAL STYLES ************/

span.data-link {
    cursor: pointer
}

a,span.data-link {

    @include transition($duration);
    border: 0;
    color: inherit;
    text-decoration: none;
    outline: none;
    text-decoration: none;
    color: $color-main;
    font-family: $fp;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.45;
    text-align: left;

    @include bp(md) {
        font-size: 14px;
        line-height: 24px;
    }

    &:hover {
        color: inherit;
        text-decoration: none;
    }

    &:active {
        @include transition(0s);
    }

    &:visited {
        color: $color-main;
    }
}

p {
    font-family: $fp;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.45;

    @include bp(md) {
        font-size: 14px;
        line-height: 26px;
    }
}

ul {
    list-style: none;
    padding: 0;
}

.img {
    &-wrapper {
        max-width: 60px;
        max-height: 60px;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include bp(md) {
            margin: 0;
            height: 60px;
        }

        &--small {
            max-width: 45px;
            max-height: 36px;

            @include bp(md) {
                max-height: unset;
            }
        }

        & > img,
        & > svg {
            width: 100%;
            height: 100%;
            object-fit: contain;  
        }
    }
}

em{
    font-style: normal;;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul>li {
    margin: 0;
    padding: 0;
}

button {
    border: none;
    outline: none;
    padding: 15px 20px;
    color: $color-main;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;

    &:hover {
        background: $color-btn-hover-background;
    }
}

.stroke {
    text-decoration: line-through;
}

.small {
    font-size: (math.div(11, 14) * 1em);
    line-height: (math.div(13, 14) * 1em);
}

.center {
    display: flex;
    justify-content: center;
    width: 100%;

    &--vertical {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.text {
    &-left {
        text-align: left !important;
        justify-content: flex-start;
    }

    &-right {
        text-align: right;
        justify-content: flex-end;
    }

    &-justify {
        text-align: justify;
    }

    &-center {
        text-align: center !important;
        justify-content: center;

        &--mobile {
            @include bp(xs, sm) {
                text-align: center;
                justify-content: center;
            }
        }
        
        p{
            text-align: inherit;
        }
    }

    &-underline {
        text-decoration: underline;
    }

    &-colored {
        color: $color-primary !important;
        
        &--green {
            color: $color-green !important;
        }
    }

    &-bold {
        font-weight: bold !important;
    }
    
    &-semibold {
        font-weight: 500 !important;
    }
    
    &-uppercase {
        text-transform: uppercase;
    }
}

.d{
    &-flex{
        display: flex;
    }    
    
    &-block{
        display: block;
    }
}

.align {
    &-top {
        align-self: flex-start;
        margin-bottom: 0;
    }

    &-bottom {
        align-self: flex-end;
        margin-bottom: 0;
    }
}

.page-main {
    max-width: get-breakpoint-value($breakpoints, 6) !important;
    
    @include bp(xs, lg) {
        padding-right: 10px;
        padding-left: 10px;

        .columns .column.main{
            padding-bottom: 0;
        }
    }

    @include bp(xl) {
        padding-left: 0;
        padding-right: 0;
    }
}

#{$prefix} .container {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: get-breakpoint-value($breakpoints, 6) !important;
    width: 100%;

    @include bp(xs, lg) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @include bp(xl) {
        padding-left: 0;
        padding-right: 0;
    }

    &--nogap {
        padding: 0 !important;
    }

    &::before {
        display: none;
    }

    & & {
        width: auto !important;
        padding: 0;
    }
    &+& {
        margin-top: 30px;
    }

    &--verysmall {
        max-width: 768px !important;
    }

    &--small {
        max-width: 1024px !important;
    }

    &--medium {
        max-width: 1240px !important;
    }

    &--big {
        width: 100%;
        max-width: 1980px;
        margin: 0 auto !important;

        @include bp(ml) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}


/****************** LINKS *******************/

@mixin link {
    color: $color-grey;
    font-weight: 300;

    &:hover,
    &:active {
        color: $color-primary;
        text-decoration: none;
    }
    &:visited {
        color: $color-grey;
        text-decoration: none;
        &:hover{
            color: $color-primary;
        }
    }
    

}

@mixin link-underline {
    text-decoration: underline;
    cursor: pointer;
}

.link, span.data-link {

    &s {
        margin: 20px auto;
    }

    @include link;

    &--hover {
        position: relative;

        &::before {
            content: '';
            width: 0;
            background: currentColor;
            height: 1px;
            left: 0;
            bottom: -1px;
            position: absolute;
            transition: 0.5s;
        }

        &:hover::before {
            width: 100%;
        }
    }

    &>em {
        font-weight: 700;
    }

    &--underline {
        @include link-underline;
    }

    &--important {
        border-bottom: 1px solid;
        font-weight: 700;
    }

    &--revert {
        color: $color-primary;

        &:hover,
        &:visited,
        &:active {
            color: $color-primary;
        }
    }

    &--normalize {
        font-size: 14px;
    }

    &--active {
        color: $color-primary !important;
    }

    &--alt {
        color: $color-grey-light;
        font-weight: bold;
    }

    &--small {
        font-size: 0.80em;
    }
}


/****************** JS HELPERS *******************/



[data-toggle] {
    cursor: pointer;
}

[data-atc] {
    cursor: pointer;
}


[data-lazy] {
    opacity: 0;
    height: 0;
}

/****************** PAGE LAYOUT *******************/

.full {
    width: 100% !important;

    &--mobile {
        @include bp(xs, sm) {
            width: 100% !important;

            &.row {
                width: calc(100% + 30px) !important;
            }
        }
    }
}


.page {
    &-wrapper {
        overflow-x: hidden;
    }

    &-title {
        margin-bottom: 30px;
        margin-top: 30px;
        position: relative;
        display: inline-block;
        line-height: 1.25;
        color: $color-main;
        font-family: $fp;
        font-size: 30px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;

        @include bp(xs, xs) {
            font-size: 20px;
            text-align: center;
        }

        @include bp(md) {
            margin-bottom: 30px;
        }
    }


    &-subtitle {
        font-size: 20px;
        margin-bottom: 20px;
        margin-top: 0;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;

        @include bp(md) {
            font-size: 20px;
        }
    }

    &-title+&-subtitle,
    &-title+&-description,
    &-subtitle+&-description {
        margin-top: 15px;

        @include bp(md) {
            margin-top: 20px;
        }
    }

    &-description {
        color: $color-main;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 30px;

        @include bp(md) {
            font-size: 16px;
            line-height: 26px;
        }
    }


}

.row {

    &--small {
        margin-left: -5px;
        margin-right: -5px;

        &>[class^=col] {
            padding-left: 5px;
            padding-right: 5px;
        }

        &--mobile {
            @include bp(xs, sm) {
                margin-left: -5px;
                margin-right: -5px;

                &.full {
                    width: calc(100% + 10px) !important;
                }

                &>[class^=col] {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }

    &-flex {
        & {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            &::before,
            &::after {
                display: none;
            }
        }

        &--start {
            align-items: flex-start;
        }

        &--center {
            align-items: center;
            align-content: center;
            justify-content: center;
        }

        &--baseline {
            align-items: baseline;
        }

        &--between {
            justify-content: space-between;
        }

        &--justify {
            justify-content: center;
        }

        &>[class^=col-] {
            float: none;
            display: flex;

            & .block {
                display: flex;
            }
        }
    }

}

.col-main {
    margin-top: 0;
}

.col {
    &-flex {
        display: flex;
        flex-direction: column;

        &--center {
            justify-content: center;
        }
    }
}

.flex-start {
    align-content: flex-start;
    align-items: flex-start;
}


.bg-gray {
	background-color: $color-background-body !important;
}

#{$prefix} .no {
    &-padding {
        padding: 0 !important;
        
        &--top  {
            padding-top: 0 !important;  
        }

        &--left {
            padding-left: 0 !important;
        }

        &--right {
            padding-right: 0 !important;
        }
        &--bottom {
            padding-bottom: 0 !important;
        }
    }

    &-margin {
        margin: 0 !important;

        &--top {
            margin-top: 0 !important;
        }
        
        &--bottom {
            margin-bottom: 0 !important;
        }
        &--left {
            margin-left: 0 !important;
        }
        &--right {
            margin-right: 0 !important;
        }
    }

    &-border {
        border: none !important;
    }

    &-background {
        background: unset !important;
    }
    
    &-before {
        
        &::before {
            content: none !important;
        }
    }
}

.hidden {
    display: none !important;
    @include bp(xs, sm) {
        &--mobile{
            display: none;
        }
    }
    @include bp(md) {
        &--desktop{
            display: none;
        }

    }
}

.section {
    position: relative;
    clear: both;
    margin: 40px 0;

    &--first {
        margin-top: 0;
    }

    @include bp(md) {
        margin: 60px 0;

        &--gap {
            margin-bottom: 80px;
        }
    }
    &.view{
        &-more{
            &-section{
                margin-bottom: 0;
                padding-bottom: 30px;
                border-bottom: 1px solid #d8d8d8;

                @include bp(xs, sm) {
                    .view-more {
                        padding: 0;
                    }

                    p {
                        font-size: 11px;
                    }
                }

                @include bp(md) {
                    padding-bottom: 60px;
                }
            }
        }
    }

    &:first-child {
        margin-top: 0;
    }

    &-header {
        @include bp(xs, sm) {
            margin-bottom: 20px;
        }
    }

    &-title {
        &,
        &:first-child {
            color: $color-main;
            font-family: $fp;
            font-size: 20px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 15px;
            position: relative;


            &::before {
                margin-right: 15px;
                color: $color-primary;
                font-size: 25px;
            }

            @include bp(md) {
                margin-top: 10px;
                margin-bottom: 30px;
                font-size: 25px;

                &::before {
                    font-size: 40px;
                }
            }
        }

        &-link {
            position: absolute !important;
            top: 50%;
            right: 0;
            @include transform(translate(0, -50%) !important);
        }

        &--small {
            max-width: 695px;
            margin-left: auto;
            margin-right: auto;
            font-size: 34px;
            display: block;
            line-height: 14px;
            padding-top: 10px;
            font-weight: normal;
            font-family: $fp;

            @include bp(md) {
                font-size: 40px;
                line-height: 34px;
            }
        }

        &--big {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            font-size: 20px;
            display: block;
            position: relative;
            margin-bottom: 28px;

            &::before {
                content: '';
                height: 4px;
                border-radius: 2px;
                width: 50px;
                position: absolute;
                bottom: -12px;
                left: 50%;
                transform: translate(-50%, 0);
                background: $color-primary;
            }

            & em {
                font-weight: bold;
                color: $color-primary;
            }

            @include bp(md) {
                font-size: 30px;
                line-height: 38px;
                margin-bottom: 48px;
            }
        }

    }

    &-subtitle {
        color: $color-main;
        font-family: $fp;
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: 24px;
        text-align: center;
        margin-bottom: 25px;
        @include bp(xs, sm){
            margin-bottom: 15px;
        }

        @include bp(md) {
            font-size: 21px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            line-height: 34px;
            text-align: left;
        }
    }

    &--grey {
        margin-top: 0;
        padding-top: 25px;
        padding-bottom: 55px;
        background: $color-grey-lighter;

        @include bp(md) {
            padding-top: 55px;
        }

        &+.footer {
            margin-top: -35px;

            @include bp(md) {
                margin-top: -55px;
            }
        }
    }
}

.box {
    padding: 15px;
    background: $color-white;
    border-radius: 4px;
    text-align: left;

    @include bp(md) {
        padding: 30px;
    }

    &+& {
        margin-top: 20px;
    }

    &--shadow {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    }

    &--fill {
        background: rgba($color-grey-lighter, .3);
    }
    
    &--neutral{
        background: transparent;
    }

    &--nogap {
        padding: 0;
    }

    &--alt {
        background: $color-grey-light;
    }

    &-content {
        &+& {
            margin-top: 45px;
        }
    }

    &--medium {
        padding: 25px;
    }

    $boxsmall: #{&}--small;

    &--small {
        padding: 12px;

        @include bp(md) {
            padding: 20px;
        }
    }

    &-title {
        font-family: $fp;
        margin-bottom: 20px !important;
        font-size: 18px;
        font-weight: normal;
        font-weight: 500;

        @include bp(md) {
            font-size: 16px;
        }

        &::before {
            display: block;
            margin-bottom: 20px;
            text-align: center;
            font-size: 65px;
            line-height: 1;
        }
    }

    &-subtitle {
        font-size: 16px;
        font-family: $fp;
        font-weight: 500;
        margin-bottom: 25px;
    }

    &-strong {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;

    }

    &-text {
        margin-bottom: 25px;
        font-weight: normal;
    }

    &-title+&-subtitle {
        margin-top: -25px;
    }

    &-list {
        &-item {
            &:nth-child(n+3) {
                margin-top: 15px;
            }

        }
    }

    &::before {
        font-size: 60px;
        line-height: 1;
        text-align: center;
        display: block;
        margin: 0 auto 25px;
    }

    &-valign {
        display: flex;
        flex-wrap: wrap;
        height: 100%;

        &-top,
        &-bottom {
            width: 100%;
        }

        &-top {
            align-self: flex-start;
        }

        &-bottom {
            align-self: flex-end;
        }
    }

    &-section {
        &+& {
            margin-top: 20px;
            margin-left: -20px;
            margin-right: -20px;
            padding: 20px 20px 0;
            border-top: 1px solid $color-grey-light;

            @include bp(ml) {
                margin-top: 40px;
                margin-left: -40px;
                margin-right: -40px;
                padding: 40px 40px 0;

                #{$boxsmall} & {
                    margin-top: 20px;
                    margin-left: -20px;
                    margin-right: -20px;
                    padding: 20px 20px 0;
                }
            }
        }

        &--elevator {
            &:not(.open) {
                display: none;
            }
        }

        &-title {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 20px;
            color: $color-primary;
        }
    }

    &-smallgap {
        &+& {
            margin-top: 20px;

            @include bp(ml) {
                margin-top: 0;
            }
        }
    }

    &-col {

        &s {
            margin: -20px -20px 0;

            @include bp(ml) {
                margin: 0 -40px;
            }
        }

        margin-bottom: -20px;
        padding: 35px 20px;

        @include bp(md) {
            padding: 40px;

        }

        @include bp(ml) {
            margin-top: -40px;
            margin-bottom: -40px;
        }



        &--small {
            padding: 20px !important;

            @include bp(md) {
                padding: 30px !important;
            }
        }

        @include bp(lg) {
            padding: 40px 75px;
        }

        &+& {
            border-top: 1px solid $color-grey-light;
            margin-top: 20px;

            @include bp(md) {
                margin-top: 0;
                border-top: 0;
                border-left: 1px solid $color-grey-light;
            }

            @include bp(ml) {
                margin-top: -40px;
            }
        }
    }

    &-mobilegap {

        &+& {
            margin-top: 35px;

            @include bp(md) {
                margin-top: 0;
            }
        }
    }

    &--login {
        margin: 100px auto;
    }
}

/****************** MESSAGES *******************/

.message {

    &s {
        font-weight: 500;
        padding: 0;
    }

    background: $color-grey-lighter;
    text-align: center;
    padding: 5px 10px;
    margin: 15px 0;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    border-radius: 3px;

    &--error {
        color: $color-white;
        background: rgba($color-error, 0.5);
    }

    &--ok {
        color: $color-white;
        background: rgba($color-ok, 0.5);
    }

    &--info {
        color: #4d586c;
        background: $color-grey-lighter;
    }

    &-content {
        font-size: 13px;
        font-weight: bold;
        display: flex;
        align-items: center;

        &::before {
            margin-right: 8px;
        }
    }
    
    &s &s{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    &s &s &{
        min-width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        
        & > div::before{
            margin-top: -3px;
            position: initial;
            line-height: 13px;
            width: 28px;
        }
    }

}


/****************** ELEVATORS *******************/


$elevator: 'data-elevator';

[#{$elevator}] {
    overflow: hidden;
    transition: 0.5s;

    &:not(.active) {
        max-height: 0;
    }
}

[#{$elevator}--mobile] {
    @include bp(md) {
        overflow: initial;

        &:not(.active) {
            max-height: none;
        }
    }
}

[#{$elevator}-toggle] {
    cursor: pointer;
}

.elevator {
    &-toggle {

        &--mobile {
            @include bp(md) {
                display: none !important;
            }
        }

        &,
        &--mobile {

            & em {
                position: relative;
                z-index: 5;
                display: block;
                font-style: normal !important;
            }

            &.active::after {
                @include transform(rotate(180deg));
            }

            &::after {
                content: '\e5c5';
                font-size: 24px;
                @include transition($duration);
                margin-left: 5px;
                z-index: 6;
                font-family: $icon;
            }

            &::before {
                content: '';
                background-image: linear-gradient(180deg, rgba($color-grey-lighter, 0) 0%, $color-grey-lighter 50%);
                position: absolute;
                bottom: 0;
                left: 0;
                height: calc(100% + 85px);
                width: 100%;
                opacity: 1;
                @include transition($duration);
            }

            &--white {
                &::before {
                    background-image: linear-gradient(180deg, rgba($color-white, 0) 0%, $color-white 50%);
                }
            }

            &--whitemobile {
                &::before {
                    @include bp(xs, sm) {
                        display: block !important;
                        height: 75px;
                    }
                }
            }

            &.active::before {
                opacity: 0;
                @include transition($duration);
            }


            &--nogap {
                margin-top: 0;
            }
        }

        &--simple {
            &::before {
                display: none;
            }
        }
    }

    &-target {
        max-height: 0;
        overflow: hidden;

        &--nogap {
            max-height: 0;
        }

        overflow: hidden;
        @include transition($duration);

        &--mobile {
            @include bp(xs, sm) {
                max-height: 0;
                overflow: hidden;
                @include transition($duration);
            }

            @include bp(md) {
                max-height: none !important;
            }
        }

        &--mobile.active,
        &.active {
            max-height: none;
        }
    }

}

/****************** ARRANGE ORDER OF ELEMENTS *******************/

.order {

    &-begin {
        order: -1;
    }

    &-end {
        order: 99999;
    }

    &-2 {
        order: 2;
    }

    &-mobile {
        @include bp(xs, sm) {

            &-begin {
                order: -1;
            }

            &-1 {
                order: 1;
            }

            &-2 {
                order: 2;
            }

            &-3 {
                order: 3;
            }

            &-4 {
                order: 4;
            }

            &-5 {
                order: 5;
            }

            &-6 {
                order: 6;
            }

            &-end {
                order: 99999;
            }

        }
    }

    &-md {
        @include bp(md) {

            &-begin {
                order: -1;
            }

            &-1 {
                order: 1;
            }

            &-2 {
                order: 2;
            }

            &-3 {
                order: 3;
            }

            &-4 {
                order: 4;
            }

            &-5 {
                order: 5;
            }

            &-6 {
                order: 6;
            }

            &-end {
                order: 99999;
            }
        }
    }

    &-ml {
        @include bp(ml) {
            &-begin {
                order: -1;
            }

            &-1 {
                order: 1;
            }

            &-2 {
                order: 2;
            }

            &-3 {
                order: 3;
            }

            &-4 {
                order: 4;
            }

            &-5 {
                order: 5;
            }

            &-6 {
                order: 6;
            }

            &-end {
                order: 99999;
            }
        }
    }

    &-lg {
        @include bp(lg) {
            &-begin {
                order: -1;
            }

            &-1 {
                order: 1;
            }

            &-2 {
                order: 2;
            }

            &-3 {
                order: 3;
            }

            &-4 {
                order: 4;
            }

            &-5 {
                order: 5;
            }

            &-6 {
                order: 6;
            }

            &-end {
                order: 99999;
            }
        }
    }
}

.ok {
    color: $color-primary;
}

.ko {
    color: $color-error;
}

/****************** LOADER *******************/



@keyframes rotating {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
      transform: translate(-50%, -50%) rotate(360deg);
    }
}

.loader {

    pointer-events: none;

    &-overlay {
        background: $color-white;
        position: fixed;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        transition: 1s;
        z-index: 99999;

        .loaded & {
            opacity: 0;
            pointer-events: none
        }

        .loading & {
            opacity: 1;
            pointer-events: all;
        }
    }

    position:fixed;
    top:50% !important;
    left:50% !important;
    @include transform(translate(-50%, -50%) !important);
    z-index: 9999;

    & img {
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        display: none !important;
    }

    & p,
    &-logo {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        margin: 60px auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba($color-primary, 0.2);
        border-right: 1.1em solid rgba($color-primary, 0.2);
        border-bottom: 1.1em solid rgba($color-primary, 0.2);
        border-left: 1.1em solid $color-primary;
        transform: translateZ(0);
        animation: rotating 1.1s infinite linear;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%) translateZ(0);

    }

}



/****************** STATUS *******************/



.status {
    font-weight: normal;

    &--ok {
        color: $color-ok;
    }

    &--warning {
        color: $color-warning;
    }

    &--error {
        color: $color-error;
    }
}



.products-main-sidebar{
	.page-title-wrapper{
		background-color: #f4f3e9;
		display: flex;
		align-items: center;
		padding: 15px 0;
		.page-title{
			margin-left: 10px;
			margin-top: 0;
			margin-bottom: 0;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
			color: #2c2628;
			line-height: 17px;
		}
	}
}

.rating-summary{
    .rating-result{
        width: 148px;
        margin-left: -1px;
        &::before{
            font-family: 'badaboum';
            content: '\e91f' '\e91f' '\e91f' '\e91f' '\e91f';
            letter-spacing: 2px;
            font-size: 28px;
            color: #d9d8cf;
        }
        & > span{
            &::before{
                font-family: 'badaboum';
                content: '\e91f' '\e91f' '\e91f' '\e91f' '\e91f';
                letter-spacing: 2px;
                color: #eec426;
            }
        }
    }
}

.review-control-vote{
    letter-spacing: 2px;
    color: #d9d7cb;
    &::before{
        content: '\e91f' '\e91f' '\e91f' '\e91f' '\e91f';
        font-family: 'badaboum';
        color: #d9d8cf;
        letter-spacing: 2px;
    }
    label::before{
        font-family: 'badaboum';
        color: #eec426;
        letter-spacing: 2px;
    }
    .rating-{
        &1{
            z-index: 6;
            &::before{
                content: '\e91f';
            }
        }
        &2{
            z-index: 5;
            &::before{
                content: '\e91f' '\e91f';
            }
        }
        &3{
            z-index: 4;
            &::before{
                content: '\e91f' '\e91f' '\e91f';
            }
        }
        &4{
            z-index: 3;
            &::before{
                content: '\e91f' '\e91f' '\e91f' '\e91f';
            }
        }
        &5{
            z-index: 2;
            &::before{
                content: '\e91f' '\e91f' '\e91f' '\e91f' '\e91f';
            }
        }
    }
}
textarea:not([disabled]){
    transition: 0.4s;
    &:focus {
        box-shadow: 0 0 2px 1px $color-primary inset;
        border: 1px solid rgba(244, 23, 100, 0.5) !important;
    }
}

.faq-ask-form-container{
    #amfaq-ask-form-inline{
        .fieldset{
            .field.required.choice{
                &::before{
                    display: none;
                }
            }
        }
    }
}

.am-search-box-wrapper .am-widget-center{
    width: 100% !important;
    
    @include bp(md){
        width: 40% !important;
    }
}

.amfaq_questions {
    .am-no-items{
        font-weight: normal;
    }
    &~.amfaq_questions {
        display: none;
    }
}

.grecaptcha-badge {
    z-index: 1000 !important;
    display: none !important;
    visibility: hidden !important;


    @include bp(ml) {
        display: block !important;
        visibility: visible !important
    }
}