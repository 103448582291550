#{$prefix}{
    .page-main-details{
        
        .review {
            
            &-item {
                list-style: none;
            }
            
            &-title {
                color: $color-primary;
                font-weight: bold;
            }
            
            &-details{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                
                &-value {
                    color: $color-primary;   
                }
                
                &-label{
                    font-style: italic;
                    margin-right: 7px;
                    font-size: small;
                }
            }
            
            &-ratings {
                width: 25%;
            }
            
            &-content {
                min-height: 90px;
                width: 73.5%;
                display: inline-block;
                
                @include bp(xs, sm){
                    width: 100%;
                    margin-top: 15px;
                }
                
                @include bp(sm, md){
                    width: 66%;
                }
            }
            
            &-fieldset {
                margin: 0 0 20px;
            }
            
            &-legend{
                span{
                    margin-bottom: 10px;
                    display: block;
                    font-size: 16px;
                }
                strong{
                    color: $color-primary;
                }
            }
            
            &-field {
                &-ratings, &-nickname, &-summary, &-text{
                    display: flex;
                    flex-direction: column;
                    
                    .label{
                        text-align: left;
                        width: 100%;
                    }
                    
                    .control{
                        width: 100%;
                    }
                }
            }
            
            &-form{
                margin: auto;
                
                &-actions{
                    display: flex;
                    align-items: flex-start;
                    margin-left: 0;
                    
                    @include bp(xs, sm) {
                        button.btn {
                            width: 100% !important;
                        }                        
                    }
                    
                }
            }
        }
    }
    
    .admin__data-grid-outer-wrap{
        max-width: 1240px;
        padding-left: 20px;
        padding-right: 20px;

        @include bp(xs, sm){
            padding-left: 0px;
            padding-right: 0px;
        }

        @include bp(sm, md) {
            order: 3;
        }

        .slick-list.draggable{
            min-height: 290px;
        }
        .section-viewed-products {
            margin-top: 0;
        }
    }
    
    .product {
    
        &.media{
            width: 47%;
            position: relative;
            margin-bottom: 30px;
            
            @include bp(xs, md){
                width: 100%;
            }
            
            @include bp(ml) {
                margin-bottom: 80px;
                margin-top: 20px;
            }
        }


        &-tags {
            max-width: max-content;
            position: absolute;    
            top: 25px;
            left: 15px;

            @include bp(md) {
                top: 15px;
                left: 115px;
            }
        }
        
        &-wishlist {
            max-width: max-content;
            position: absolute;
            top: 15px;
            right: 15px;
       
            em.icon::before{
                font-size: 35px !important;
            }
        }
        
        &-info{
            
            &-seo-title {
                font-size: 14px;
                font-weight: lighter;
                font-style: normal;
                text-align: left;
                letter-spacing: normal;
                line-height: normal;
                color: $color-grey;
                margin: 15px 0;
                
                @include bp(md) {
                    font-size: 18px;
                }
            }
            
            &-stock{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
        
                span{
                    font-size: 1.6rem;
                    text-transform: none;
                    font-weight: 500;
                    display: flex;
                    align-items: center;

                    &::before{
                        content: '●';
                        font-size: 1.4rem;
                        margin-right: 5px;
                    }
                }
                    
                // TODO DEV FRONT: refacto - plus générique
                .available{
                    display: flex;
                    align-items: center;
                    color: #6DB332;
                    @include bp(xs, sm){
                        margin-bottom: 10px;
                        margin-right: 0px;
                        align-items: flex-start;
                        span{
                            white-space: nowrap;
                        }
                    }
                    p{
                        margin: 0 0 0 5px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #2c2628;
                        text-transform: none;
                        line-height: 22.4px;
                    }
                }
                .unavailable{
                    color: #CE0A0A;

                    @include bp(xs, sm){
                        margin-bottom: 10px;
                    }
                }
            }
            
            &-socials{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 30px;
                margin-top: 10px;
                
                @include bp(md) {
                    width: auto;
                    margin-top: 5px;
                    justify-content: space-between;
                }

                .footer-social {
                    &:nth-child(even) {
                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    &--mail {

                        @include bp(xs, md) {
                            padding-top: 7px;
                        }
                        @include bp(md, md) {
                            padding-top: 0px;
                        }
                    }

                    &-icon {
                        font-size: 25px;
                    }
                }
            }
            
            &-wrapper{
                .product.attribute.sku {
                    
                  & > div.value, & > span.type {
                        color: $color-grey;
                        font-weight: lighter;
                        font-size: 12px;
                    }
                }
            }
            
            &-price{
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                flex-wrap: wrap;
                
                .special-price{
                    color: $color-primary;
                    order: 1;
                    
                    .price{
                        font-size: 35px;
                        line-height: 30px;
                        color: $color-primary;
                    }
                }
                
                .old-price{
                    order: 2;
                    color: $color-black;
                    
                    .price{                    
                        display:inline-block;
                        position: relative;
                        font-weight: 100;
                        line-height: 10px;
                        color: $color-main !important;
                        font-size: 1.8rem;
                        
                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 100%;
                            height: 1px;
                            background: $color-main;
                        }

                        &-wrapper {
                            line-height: 0;
                        }
                    }
                }
                
                .price-final_price{
                    display: flex;
                    margin: 35px 0;

                    @include bp(xs, sm){
                        padding-left: 0;
                        margin: 15px 0;
                    }
                                        
                    &.price-container {
                        align-items: center;
                    }
                    
                    span{
                        margin: 0;
                        margin-right: 5px;
                        font-size: 35px;
                        color: $color-primary;
                        
                        .price-label{
                            display: none;
                        }
                    }

                    span.normal-price {

                        .price-label {
                            display: block;
                            font-size: 18px;
                            line-height: 0;
                            color: $color-main;
                            margin-right: 10px;

                            &:after {
                                content: ': ';
                                padding-left: 10px;
                            }
                        }

                        &:has(.special-price) {
                            .price-label {
                                display: none;
                            }
                        }

                        span[id^="product-price-"] {
                            line-height: initial;
                        }
                    }
                    
                    .promo-content {
                        line-height: 10px;
                    } 
                }
                
                .promo-product {
                    order: 3;
                    margin-top: -10px;
                }
            }
            
            &-main{
                width: 100%;
                
                @include bp(xs, sm){
                    
                    .pagebuilder {
                        
                        &-column {
                            
                            img{
                                width: 36px;
                            }
                            span{
                                line-height: 14px;
                            }
                            strong{
                                font-size: 12px;
                            }
                            p{
                                font-size: 10px;
                            }
                            
                            &:nth-child(odd) {
                                max-width: max-content;
                            }
                            
                            &-group {
                                flex-wrap: nowrap;
                                
                            }
                        }
                    }
                }

                @include bp(ml, ml) {
                    width: 50%;
                }

                @include bp(ml) {
                    max-width: 700px;
                    margin-top: 24px;
                }
            
                .page-title{
                    text-align: left;
                    
                    @include bp(xs, sm) {
                        margin-top: 0;
                    }
                }
                
                // out of stock action wrapper
                .actions.full{
                    padding-bottom: 33px;
                }
                
                &-stock-wrapper{
                    display: flex;
                    margin: 20px 0;

                    @include bp(xs, sm){
                        flex-wrap: wrap;
                        justify-content: center;
                        margin-top: 20px;
                    }

                    .product-view--disabled{
                        
                        .qty {
                            
                            &-btn--minus, &-btn--plus {
                                cursor: not-allowed;
                                opacity: 0.5;
                                    
                                    &:hover{
                                        background: #f4f3e9;
                                    }
                            }
                        }
                    }
                    
                    .actions {
                        @include bp(xs, sm) {
                            margin-top: 30px;
                        }
                        
                        p  {
                            font-size: 12px;
                            font-weight: 600;
                            text-align: center;
                            margin-bottom: 0;
                        }
                        
                        .action.alert.btn {
                            margin-top: 10px;
                        }
                    }
                }

                // add to cart button product page
                .box-tocart{
                    width: 100%;
                    
                    @include bp(sm, xxl){
                        margin: 20px 0 0 0;
                    }
                    
                    .fieldset{
                        display: flex;
                        align-items: center;
                        
                        @include bp(xs, md){
                            flex-direction: column;
                        }
                        .qty-input{
                            font-size: 16px;
                            font-weight: 400;
                        }
                        
                        .actions{
                            padding-top: 0;
                            width: 100%;
                            padding-bottom: 30px;

                            @include bp(xs, md){
                                margin-top: 30px;
                            }
                            
                            .tocart{
                                height: 60px;
                                width: 100%;
                                margin-top: 10px;
                                
                                span {
                                    font-weight: 200;
                                }
                            }    
                        }
                    }
                }
                
            }
        }
        
        &-details-qty{
            margin-right: 35px;
            
            @include bp(xs, sm){
                margin-right: 0px;
            }
            @include bp(sm, ml){
                margin-right: 5px;
            }
            
            &-input {
                width: 70px;
            }
            
            #qty-error{
                margin-top: 15px;
                position: absolute;
                
                @include bp(xs, sm){
                    margin-top: 5px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    text-align: center;
                    width: 100%;
                }
            }
        }
        &-shipping{
        
            &_countdown{
                font-size: 12px;
                font-weight: 500;

                &-timer, &-delay{
                    color: $color-green;
                    line-height: 1;
                }
            }
            
            &-reassurance {
                
                @include bp(xs, sm) {
                    margin-bottom: 30px;
                }
                
               p {
                    font-size: 10px;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    
                    @include bp(md) {
                        font-size: 14px;
                    }
                
                    strong {
                        font-size: 12px;
                        font-weight: 600;
                        
                        @include bp(md) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        
        // stars review
        &-reviews-summary{
            .view{
                text-decoration: underline;
                font-weight: 400;
            }
        }
        
        // product list in category page
        
        &s-list {
            margin: 15px 0;
        }
        
        &-items{
            min-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            font-size:  unset !important;
            margin: 0 -12px;
            
            @include bp(md){
                margin: 0 -15px; 
            }
            
            @include bp(xl){
                margin: 0 -22px;
            }
        }
    
        // product card item
        &-card {
            height: auto;
            background: $color-white;
            margin-bottom: 15px;
            max-width: none;
            padding: 0 3px;
            width: calc((100% / 2));
            
            @include bp(md){
                width: calc((100% / 3));
                padding: 0 15px;
            }
            
            @include bp(ml) {
                width: calc((100% / 4));
                height: auto;
            }
            
            @include bp(xl){
                padding: 0 22px;
            }


            &-header {
                height: auto;
                position: relative;
                overflow: hidden;
                
                @include bp(md) {
                    height: auto;
                }
    
                &:hover {
    
                    .product-card-action, .product-card-action--wishlist {
                        bottom: 0;
                    }
                } 
    
                &-top {
                    position: absolute;
                    top: 0;
                    display: flex;
                    z-index: 2;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 10px;
                    width: 100%;
                    height: auto;    
                }
            }
    
            &-img{
                width: 100%;
                height: auto;
                object-fit: contain;
            }
    
            &-action {
                position: absolute;
                bottom: -100px;
                width: 100%;
                transition: bottom .3s ease-in-out;
                z-index: 1;
                
                .actions.full {
                    p {
                        display: none;
                    }
                }
    
                a.btn, button.btn, div.btn {
                    text-transform: capitalize;
                    height: 40px;
                    margin-top: 10px;
                }
                
                // override add to alert button style for product cart item
                a.btn.btn--quaternary {
                    margin: 0;
                    background-color: $color-btn-out-of-stock;
                    color: $color-white;
                
                    &:hover {
                        background-color: $color-btn-out-of-stock;
                        color: $color-white;
                    }
                
                    &:active {
                        color: $color-white;
                
                        &::after {
                            background: $color-grey-dark;
                        }
                    }
                
                    &::after {
                        background: $color-btn-out-of-stock-lighter;
                    }
                }
                
                .product-info-main-stock-wrapper {
                    margin: 0;
                }
                
                .product-view--disabled{
                    display: none!important;
                }
                
                .qty{
                    
                    &--small {
                        justify-content: space-between !important;
                    }
                    
                    &-input {
                        width: 100px !important;
                    }
                }
            }
            
            &-wishlist {
                cursor: pointer;
                margin-left: auto;
    
                a.action.towishlist::before {
                    content: none !important;
                }
                
                .tocompare{
                    display: none;
                }
                
                em.icon:before {
                    color: $color-wishlist-icon;
                    font-size: 20px;
                    line-height: 33px;
                    transition: color .3s ease-in-out;
                    
                    @include bp(md) {
                        font-size: 24px;
                    }
                }
    
                &:hover {
                    em.icon:before{
                        color: $color-primary;
                    }
                }
            }
    
            &-content {
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                overflow: hidden;
                
                @include bp(md) {
                    padding: 15px;
                }
    
                p {
                    margin-bottom: 0;
                }
    
                &-whislist {
                    text-align: center;
    
                    a {
                        font-size: 14px;
                        
                        @include bp(lg) {
                            font-size: 16px;
                        }
                        &::before {
                            margin-right: 10px;
                        }
                    }
                }
            }
    
            &-name {
                color: $color-main;
                font-size: 12px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                line-height: 24px;
                text-align: center;
                height: 48px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                margin: 6px 0;

                @include bp(xs, sm) {
                    font-size: 13px;
                    font-weight: 300;
                    line-height: 1.5;
                    height: 60px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                }

                
                @include bp(md){
                    font-size: 14px;
                }

                &:hover {
                    color: $color-primary;
                }
            }
    
            &-price {

                &--old-price {
                    order: 1;
                    
                    .price {
                        text-decoration: line-through !important;
                        color: $color-main !important;
                    }
                }
    
                &--new-price {
                    order: 2 !important;
                    
                    .price {
                        font-size: 18px !important;
                        color: $color-primary !important;
                        padding-left: 10px !important;
                        text-decoration: none !important;
                        order: 2;
                                                
                        @include bp(md) {
                            font-size: 25px !important;
                        }
                    }
                    
                }
    
                &-wrapper {
                    font-size: unset !important;
                    
                    .price-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        
                        .price {
                            color: $color-primary;
                            font-size: 24px;
                            font-weight: 400;
                            font-style: normal;
                            letter-spacing: normal;
                            line-height: 33px;
                            text-align: center;
                            
                            @include bp(md) {
                                font-size: 24px;
                            }
                        }
                        
                        .price-as-configured {
                            display: flex;
                        }
                        
                        .special-price {
                            order: 2 !important;
                    
                            .price {
                                font-size: 18px !important;
                                color: $color-primary !important;
                                padding-left: 10px !important;
                                text-decoration: none !important;
                                order: 2;
                                                        
                                @include bp(md) {
                                    font-size: 24px !important;
                                }
                            }
                        }
                        
                        .old-price {
                            order: 1;
                            color: $color-main !important;
                    
                            .price {
                                // text-decoration: line-through !important;
                                display:inline-block;
                                position: relative;
                                color: $color-main !important;
                                font-size: 16px;
                                
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 100%;
                                    height: 1px;
                                    background: $color-black;
                                }
                            }
                        }
                        
                        .price-label {
                            display: none !important;
                        }
                        
                        
                        .promo-product {
                            display: none !important;
                        }
                    }
                }
            }

            &-status {
                font-size: 10px;
                font-weight: 500;
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                
                @include bp(md) {
                    font-size: 12px;
                }
    
                &--stock {
                    color: $color-green;
                }
    
                &--out-of-stock {
                    color: $color-primary;
                }
            }
        }

        &-details {

            .block.upsell{
                margin-top: 2.7rem;
            }
           
            .tab{
            
                &s{
                    order: 2;
                    
                    @include bp(xs, sm) {
                        width: auto;
                        position: unset;
                        transform: none;
                        margin: 0 -20px;
                        font-weight: 300;
                        font-size: 13px;
                        line-height: 1.6;
                    }
                    
                    &-header{
                        order: 1;
                        justify-content: center;
                        
                        @include bp(xs, sm) {
                            width: auto;
                            margin: 0 -20px;
                            position: unset;
                            transform: none;
                        }
                    }
                    &-item{
                        max-width: 200px;
                        @include bp(xs, sm){
                            color: #2c262877;
    
                        }
                        @include bp(xs, sm){
                            &::after {
                                display: none;
                            }
        
                            &:hover, &.active {
                                color: $color-grey;
                                background: $color-background;
                            }
                        }
                    }
                    &-container{
                        justify-content: center;
                        display: flex;
                        flex-direction: column;
                    }
                }
                
                &--active {
                    padding: 40px 30px 40px;
                    max-width: 1015px;
        
                    @include bp(xs, sm) {
                        padding: 10px 15px 10px;
                    }
                }
                
                &#additional{
                    display: none;
                }
            }
            
            .delivery-tab--content{
                width: 100%;
                
                .pagebuilder-column-group{
                    justify-content: space-between;
                    .pagebuilder-column{
                        &:nth-child(odd){
                            width: 3%!important;
                        }
                        &:nth-child(even){
                            width: 30%!important;
                        }
    
                        @include bp(xs, sm) {
                            flex-basis: unset;
    
                            &:nth-child(odd){
                                width: 7%!important;
                            }
                            &:nth-child(even){
                                width: 91%!important;
                            }
    
                            p:last-child {
                                margin-bottom: 15px;
                            }
                        }
    
                        p{
                            margin-bottom: 0;
                            padding-left: 10px;
                        }
                    }
                }
    
                .shipping-delivery--img{
                    width: 25px;
                }
            }
        }

        &-full {
            &-width {
                &-section {
                    padding-top: 0;
                }
            }
        }
    }
}

$fotorama : '.fotorama';

#{$fotorama}{
    
    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: $color-border;
        opacity: 0;
        display: none;
        z-index: 99;
        // pointer-events: none;
    }
    
   #{$prefix} &--fullscreen {
        @include bp(xs, sm) {
            .fotorama {
                &__stage {
                    padding-top: 30% !important;
                    min-height: 798px !important;
                    
                    &__shaft {
                        margin-top: 0;
                    }
                    &__frame {
                        top: 15%;
                    }
                }
                
                &__nav {
                    &-wrap {
                        margin-top: 0;
                        margin-bottom: 0;
                        bottom: 250px;
                    }
                }
            }
        }

        @include bp(sm, md) {
            height: 90vh !important;
            width: 90vw !important;

            .fotorama {
                
                &__stage {
                    height: 921px !important;
                }

                &__img--full {
                    max-width: 100% !important;
                    max-height: 100% !important;
                }
            }
        }
    
        @include bp(md) {   
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            
            
            .fotorama {
                
                &__stage {
                    margin-left: 0;
                }
                
                &__nav{
                    display: flex;
                   
                    &-wrap {
                        position: absolute;
                        bottom: 0;
                    }
                    
                    &__shaft {
                        margin: auto;
                        padding: 0;
                    }
                }
            }
        }

        @include bp (ml) {
            width: 995px !important;
            height: 906px !important;
        } 
    }
    
    #{$prefix} &__nav{
        
        &__shaft{
            background: none;

            @include bp(ml){
                padding: 40px 0;
            }
        }
    }

    #{$prefix} &__thumb{
        border: 0;
        border: 1px solid transparent;
        transition: border 0.4s;
        cursor: pointer;
        
        &--icon{
            display: none;
        }
        
        &__arr{
            
            
            &::before{
                font-family: $icon;
                position:absolute;
                top: 5px;
                left: 50%;
                transform: translate(-50%,0);
                font-size: 10px;
            }
            
            &--left{
   
                &::before{

                    @include bp(ml){
                        content: '\e90e';
                    }
                }
            }
            
            &--right{

                @include bp(ml){
                    bottom: -3px;
                    height: 20px;

                    &::before{
                        top: auto;
                        bottom: 8px;
                        transform: translate(-50%,0);
                        content: '\e90d';
                    }
                }
            }
            
        }
        
        &-border{
            display: none;
        }
    }
    
    #{$prefix} &__arr{
        background: none;
        
        @include bp(xs,sm){
            display: block !important;
            width: 50px;
        }
        
        @include bp(md){
            width: 100px;
        }
        
        &__arr{
            
            background: none;
            font-family: $icon;
            width: 30px;
            height: 30px;
            position: relative;
            background: $color-primary;
            border-radius: 50%;
            
            &::before{
                color: $color-white;
                font-size: 16px;
                position:absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                content: '\e911';
            }
            
            @include bp(xs, sm) {
                display: none;
            }
        }
        
        &--prev > *{
            transform:translate(-50%,-50%) rotate(180deg);
        }
    }
    
    &__nav{
        
        
        &-wrap{
            
            @include bp(xs,md){
                position: relative;
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }
        
        #{$prefix} &__frame{
            
            @include bp(xs,md){
                width: 29px;
                height: 2px;
                
                &--dot{
                    text-align: center;
                }
            }
            
            @include bp(ml){
                margin-bottom: 0;
                padding-bottom: 10px !important;
                
            }
        }
    }
    
    #{$prefix} &__dot{
        width: 26px;
        background: $color-main;
        transition: 0.5s;
        border: 0;
        top: 0;
        left: 0;
        display: inline-block;
        opacity: 0.4;
    }
    
    #{$prefix} &__active &__dot{
        background: $color-main;
        opacity: 1;
    }

    &__stage{
        
        @include bp(xs,sm){
            height: auto !important;
            padding-top: 100%;
            
            &__shaft{
                margin-top: -100%;
            }
        }
        
        @include bp(ml){
            margin-left: 20px;
        }
        
        
        &__frame{
            @include bp(xs,sm){
                height: 100vw !important;
            }
           
            #{$prefix} & #{$fotorama}__img {
                cursor: -moz-zoom-in !important; 
                cursor: -webkit-zoom-in !important;
                cursor: zoom-in !important;      
                top: 0 !important;
                transform: translateX(-50%) !important;
                -webkit-transform: none !important;
                position: static;
                object-fit: initial;
            }
        }
    }

    #{$prefix} &__active > &__thumb{
        border-color: $color-primary !important;
    }
}

#{$prefix} .slick-slide .product-card{
    padding: 0;
}