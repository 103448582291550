$vignette: '.vignette';
$vignetteConst: #{$vignette}--const;

#{$vignette} {
	
	&s{
		
		@include bp(lg){
			padding: 0 35px;
		}

		&-slider{
			 
			display: flex;
			flex-wrap: wrap;
			opacity: 0;
			transform: translateY(25px);
			transition: opacity 1s, transform 1s;
			@include bp(md){
				padding-bottom: 20px;
			}
			
			&.slick-initialized{
				opacity: 1;
				transform: none;
			}
			
			.slick-list{
				overflow: visible;
				@include bp(md){
					overflow: hidden;
				}
			}
			
			.slick-prev,
			.slick-next{
				background: $color-white !important;
				&::before{
					color: $color-primary !important	;
				}
			}
			
			.slick-prev{
				left: 0;
				@include bp(xl){
					left: -70px;
				}
			}
			
			.slick-next{
				right: 0;
				@include bp(xl){
					right: -70px;
				}
			}

		}
	}

	&-box{
		
		padding-bottom: 15px;
		width: 100%;
		
		@include bp(md){
			padding-bottom: 40px;
		}
		
		position: relative;
		min-width:0;
		transition: opacity 0.6s, transform 0.6s;
		
		.slick-slide &{
			width: 100%;
			padding-bottom: 0;
		}
		
		&--slide{
			padding: 0 10px;
			@include bp(md){
				padding: 0 14px;
			}
		}
		
		/*
		@include bp(md){
			width: 33.333%;
			padding-bottom: 20px;
		}

		@include bp(lg){
			width: 25%;
		}

		@include bp(xl){
			width: 20%;
		}

		@include bp(xxl){
			width: 14.285%;
		}

		@include bp(xxxl){
			width: 11.111%;
		}
		
		&--loading{
			opacity: 0;
			transform: translateY(-20px) scale(0.7);
		}
		
		&--image{
			height: 100%;
		}
		*/
		
		&-image{
			
			position:relative;
			display: block;
			width: 100%;
			height: 100%;
			display: block;
			min-height: 222px;
			
			&-source{
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				display: block;
				width: 100%;
				height: 100%;
				object-fit:cover;
			}
		}

	}

	& {

		position: relative;
		cursor: pointer;
		transition: opacity 0.75s, transform 0.75s;
		transform: perspective(1200px);
		width: 100%;
		max-width:100%;
		background: $color-white;
		border: 1px solid $color-grey-light;
		border-radius: 4px;
		padding: 45px 5px 5px;
		display: flex;
		align-items: flex-start;
		cursor: pointer;
		flex-wrap: wrap;
		align-content: flex-start;
		margin: 0;
		border: 0;
		border-radius: 20px;
		box-shadow: 0 2px 4px 0 rgba(50,51,53,0.2);
		transition: box-shadow 0.5s;
		
		@include bp(md){
			padding: 20px 20px 10px;
			border-radius: 40px;
		}
		
		&:hover{
			box-shadow: 0 2px 10px 0 rgba(50,51,53,0.5);
		}
		

		&#{$vignetteConst}{
			min-height: min-content;
			flex-direction: column;
			flex-wrap: nowrap;
			align-content: initial;
		}

		@include bp(md){
			min-height: min-content;
			flex-direction: column;
			flex-wrap: nowrap;
			align-content: initial;
		}
	}

	&--loading {
		pointer-events: none;
	}

	&s-list &--loading{
		pointer-events:initial;
	}


	&-tags{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 10px;
		margin-bottom: 8px;

		#{$vignetteConst} &{
			margin-bottom: 10px;
		}

		@include bp(md){
			margin-bottom: 10px;
		}

	}

	&-img{
		
		&-container{
			position: relative;
		}
		
		display: block;
		margin: 0 auto;
		width: 130px;
		height: 130px;
		margin-bottom: 10px;
		object-fit: cover;
		transition: opacity 0.5s, transform 0.5s;
		
		@include bp(md){
			width: 140px;
			height: 140px;
		}
		
		&--hover{
			opacity: 0;
			pointer-events: none;
			position: absolute;
			left: 50%;
			top: 45px;
			transform: translateX(-20%);
			
			@include bp(md){
				top: 20px;
			}
		}
	}
	
	&:hover #{$vignette}-img{
		
		&{
			opacity: 0;	
		}
		
		&--hover{
			opacity: 1;
			transform: translateX(-50%);
		}	
	
	}
	
	&-details{
		text-align: center;
	}
	
	&-brand{
		margin-top: 5px;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		min-height:22px;
		-webkit-line-clamp: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: uppercase;
	}
	
	&-nopharmacy{
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 13px;
	}

	&-promo {
		min-width: 100%;
		flex-wrap: wrap;
	}


	&-content {
		overflow: hidden;
		width: 100%;
	}

	&-name{
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		min-height: 40px;
		margin-bottom: 5px;
		
		&-link{
			font-size: 14px;
			line-height: 20px;
			color: $color-black;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			
			@include bp(md){
				font-size: 16px;
			}
			
		}
	}
	
	&-wish{
		position: absolute;
		right: 9px;
		top: 181px;
		transform: translateY(-100%);
		margin: 0 !important;
		
		@include bp(md){
			top: 156px;
			right: 20px;
			top: 162px;
		}
	}
	
	&-rating{
		display: flex;
		align-items: center;
		height: 22px;
		align-items: center;
		align-content: center;
		justify-content: center;
		display: flex;
		margin-top: 20px;
		margin-bottom: 25px;
	}

	&-attrs{
		margin-top: 2px;
	}
	
	&-button{
		opacity: 1;
		transition: 0.5s opacity;
		
		@include bp(md){
			opacity: 0;
		}
	}
	
	&:hover #{$vignette}-button{
		opacity: 1;
	}
	
	&-nostock{
		background: rgba($color-grey-darker,0.5);
		z-index: 10;
		position: absolute;
		left: 50%;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		transform: none;
		opacity: 1;
		font-size: 14px;
		text-transform: uppercase;
		transform: translateX(-50%) !important;
		line-height: 1;
		max-width: 100%;
		
		&-content{
			background: rgba($color-grey-darker,0.5);
			border: 2px solid $color-white;
			font-weight: bold;
			font-family: $fs;
			color: $color-white;
			padding: 12px;
		}
	}

	&-fidelity{
		margin-top: 4px;
		margin-left: 2px;
		margin-bottom: -14px;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 10;

		&-image{
			margin-right: 3px;
			display: block;
		}

		&-text{
			font-weight: bold;
			font-size: 9px;
			color: $color-primary;
		}
	}


	&-price {
		width: 100%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 42px;
		font-size: 22px;
		
		@include bp(md){
			font-size: 29px;
		}
		
		& .price{
			font-weight: bold;
			line-height: 22px;
			margin-bottom: -5px;
		}
		
		& .old-price .price{
			
		}
	}

	&-action{

		& + &{

			margin-left: 10px;

			#{$vignetteConst} &{
				margin-left: 0;
				margin-top: 10px;
			}

			@include bp(md){
				margin-left: 0;
				margin-top: 10px;
			}
		}

		&s{
			position: absolute;
			z-index: 100;
			right: 7px;
			top: 120px;
			display: flex;

			#{$vignetteConst} &{
				display: block;
			}

			@include bp(md){
				display: block;
			}
		}
	}

	&-qty{
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 100;
	}


}
