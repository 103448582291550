.minicart {

	#{$prefix} & {
		background-color: $color-white;
		right: -15px;
		padding: 20px;
		width: 360px;
		border: none;
		
		&:after {
			content: none;
		}

		&-header {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-bottom: 10px;
			border-bottom: 1px solid $color-grey-light;

			&-count {
				font-size: 16px;
				font-weight: bold;
				margin: 0;
			}
		}

		&-btn {
			display: inline-block;
			margin-left: auto;
		}
	}

	&-wrapper {
		.showcart &.active {

			&:before {
				font-size: inherit !important;
				color: $color-primary;
			}
		}
	}

	&-empty {
		font-weight: 500;
		text-align: center;
		display: block;
		padding: 10px 0;
	}

	#{$prefix} &-widgets {
		margin: 0;
	}

	&-item {
		#{$prefix} & {
			position: relative;
			border-top: 1px solid $color-grey-light;
			padding: 20px 0;
			display: flex;
			margin-bottom: 0;
		}

		&:first-child {
			border-top: none;
		}

		&:last-child {
			border-bottom: 1px solid $color-grey-light;
		}

		&s {
			#{$prefix} &-wrapper {
				max-height: 380px;
				overflow: auto;
				padding: 0 20px;
				border: 0;

				@include bp(md) {
					max-height: 380px;
				}

				@include bp(ml) {
					max-height: 200px;
				}

				@include bp(xl) {
					max-height: 380px;
				}

				&--big {
					max-height: 380px;
					padding-top: 10px;
				}
			}
		}

		#{$prefix} &-img,
		#{$prefix} & img {
			width: 55px;
			max-width: 55px;
			height: 55px;
			margin: 0;
			object-fit: cover;
			position: static;
		}

		&-name {
			font-size: 14px;
			font-weight: 500;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box;
			padding-right: 70px;

			@include bp(md) {
				padding-right: 110px;
			}

		}

		&-options {
			padding-bottom: 10px;
			font-weight: 500 !important;

			#{$prefix} &.active {
				.minicart-item-options-toggle:after {
					transform: rotate(180deg);
					content: '\e90d';
				}
			}

			#{$prefix} &-toggle {
				&:after {
					font-family: 'badaboum';
					content: '\e90d';
					position: absolute;
					font-size: 10px;
					transition: transform 0.5s ease;
				}
			}
		}

		&-price {
			font-size: 17px;
			position: absolute;
			bottom: 22px;
			right: 0;

			&--gap {
				bottom: 9px;
			}
		}

		#{$prefix} &-details {
			padding-left: 15px;
		}

		#{$prefix} &-remove {
			&::before {
				font-family: $icon;
				font-size: 20px;
				line-height: 1;
			}

			&:hover {
				&::before {
					color: $color-primary;
				}
			}
		}

		#{$prefix} &-update {
			display: block !important;
			position: absolute;
			top: -10vh;
			opacity: 0;
		}
	}

	#{$prefix} &-subtotal {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: bold;
		font-size: 16px;
		margin-top: 30px;
		margin-bottom: 30px;

		.price {
				font-size: 25px;
		}
	}
}
