
//
//  Colors
//  ----------------------------------------------

$color-grey-lighter: #e9e5e5;
$color-grey-light: #e8e6e6;
$color-grey: #2C2628;
$color-grey-dark: #707070;
$color-grey-darker: #3d3d3d;
$color-wishlist-icon: #818c9e;

$color-main: $color-grey;
$color-white: #ffffff;
$color-black: #000000;
$color-red: #B71918;
$color-green: #6DB332;
$color-orange: #EC6611;
$color-cream :#E9E5E5;
$color-horaires :#E9E5E5;
$color-puce-green: #089E42;
$color-puce-orange: #D18E06;
$color-home-right-section: #1d888c;



//for category colors
$colors-universe: #d4007a, #0E90BD, #A2C621, #EC6611, #EC6611, #EC6611, #EC6611, #EC6611, #EC6611, #FE7676, #3bf491, #f4943b, #0a4069, #0e5911, #590e1e, #11423c, #c8bf70, $color-grey-lighter;

$color-primary: #cd2367;
$color-primary-lighter: lighten($color-primary, 10%);
$color-primary-lighter-1: rgba(206, 10, 78, .1);

$color-primary-darker: #980C3D;

$color-secondary: #786855;
$color-secondary-darker: #695b4a;

$color-tertiary: $color-red;
$color-tertiary-darker: darken($color-tertiary, 10%);
$color-tertiary-lighter: lighten($color-tertiary, 10%);

$color-quaternary: #756a6e;
$color-quaternary-darker: darken($color-quaternary, 10%);
$color-quaternary-lighter: lighten($color-quaternary, 20%);

$color-error: $color-red;
$color-warning: $color-orange;
$color-ok: $color-green;
$color-success: $color-ok;

$color-promo: $color-ok;
$color-btn-out-of-stock: #b0b3b2;
$color-btn-out-of-stock-lighter: lighten(#b0b3b2, 10%);

$color-border: #D8D8D8;
$color-border-submenu: rgba(61,61,61,0.1);
$color-background: #F4F3E9;
$color-background-body: #f8f8f8;
$color-btn-hover-background: #dfddcc;
$corner-radius: 4px;

//
//  Typography
//  ---------------------------------------------

$font-montserrat : 'Montserrat';
$font-sans: Arial,sans-serif;
$font-primary : $font-montserrat;
$font-secondary : 'Arial';
$fp : $font-primary;
$fs : $font-secondary;
$icon : 'badaboum';

//
//  Breakpoints
//  ---------------------------------------------

$breakpoints: (
	(xs, 0),
	(sm, 576),
	(md, 768),
	(ml, 1024),
	(lg, 1280),
	(xl, 1540),
	(xxl, 1920),
	(xxxl, 2560),
);

//
//  Transition
//  ---------------------------------------------
$duration: .3s;