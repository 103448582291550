@use 'sass:math';

.pager {

    &-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-label {
        font-size: 12px;
        font-weight: 700;
        margin-right: 8px;
    }

    &-item {
        &s {
            display: flex;
            justify-content: center;
            align-items: stretch;
        }

        & {
            background: $color-grey-lighter;
            color: $color-secondary;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            align-items: center;
            @include transition($duration);
        }

        & a {
            color: inherit !important;
            @include transition(0s);
            padding: 10px;
        }

        &+& {
            margin-left: 1px;
        }

        &:hover,
        &--current {
            @include transition(0s);
            background: $color-primary;
            color: $color-white;
        }

        &--current {
            padding: 10px;
        }
    }
}

.relateds {
    margin-bottom: 30px;
}

.promo {
    position: relative;
    left: -10px;
    top: -10px;

    &-product{
        background-color: $color-primary;
        height: max-content;
    }

    &-content {
        background-color: $color-primary;
        color: $color-white;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
        margin-bottom: 0;
        padding: 7px;

        @include bp(md) {
            font-size: 12px;
        }

        & + & {
            margin-top: 5px;
        }
    }

    &-percent {
        @include bp(xs, sm) {
            width: 60px;
        }
    }

    &-wide {
        @include bp(xs, sm) {
            display:none;
        }
    }
}

.breadcrumb {

    &-container {
        padding: 10px 10px;
        color: $color-main;
        background-size: cover;
        font-size: 12px;
        display: none;
        z-index: 1;
        position: relative;


        @include bp(md) {
            padding: 10px 0;
            display: block;
        }

        @include bp(md, md) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    & {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;

        &-product {
            font-size: 16px;
            color: $color-grey;
            margin-bottom: 30px;

            @include bp(xs, ml) {
                display: none;
            }

            &-section {
                &--first {
                    text-transform: uppercase;
                }
            }

            &-divider {
                margin: 0 5px;
            }
        }
    }

    &-item {
        line-height: 1;
        position: relative;
        display: none;

        @include bp(md) {
            line-height: 19px;
            display: flex;
            margin-right: 4px;
        }

        &::first-letter {
            text-transform: uppercase;
        }


        &-link {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 0;
                height: 1px;
                background: currentColor;
                transition: 0.5s;

            }

            &:hover::after {
                width: 100%;
            }
        }

        &--current {
            display: block;
            font-size: 24px;
            font-weight: bold;
        }

        &:last-child {
            font-weight: bold;
        }

        &+& {

            &::before {
                @include bp(md) {
                    font-size: 16px;
                    font-weight: normal;
                    content: '>';
                    margin-right: 4px;
                    position: relative;
                    font-family: Arial;
                }
            }
        }

    }

    &-sub {

        &s {

            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: -33px;
            padding: 0 20px 30px;
            overflow: auto;
            width: auto;

            @include bp(md) {
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 0;
                padding: 0px;
                overflow: initial;
                width: 100%;
            }
        }

        & {
            flex-shrink: 0;

            @include bp(xs, sm) {
                margin-bottom: 0 !important;
            }
        }
    }

}

.category {
    &-main {

    }

    &-view {
        .amslider {
            margin-top: 0;
            margin-bottom: 0;

            @include bp(xs, sm) {
                width: 100vw;
            }
        }
    }

    &-cover {
        @include bp(xs, sm) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-background {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 200px;
            background-size: 100%;
            background-repeat: no-repeat;
            position: relative;

            @include bp(xs, sm) {
                width: 100vw;
                // margin-bottom: 20px;
                height: auto;
            }

            h1 {
                margin-bottom: 0;
                text-transform: none;
                margin-top: 0;
                font-size: 50px;
                font-weight: 500;
                font-style: normal;
                letter-spacing: normal;
                line-height: 50px;
                text-align: center;
                color: $color-primary;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                @include bp(xs, sm) {
                    font-size: 25px;
                    line-height: 34px;
                }
            }
        }

        .customer-search-desc-btn {
            margin-top: 0;
            display: none !important;
            width: 100%;

            .pagebuilder-button-link {
                font-size: 14px;
            }

            @include bp(xs, sm) {
                display: inline-block !important;
            }

            a.pagebuilder-button-link {
                color: $color-primary;
            }
        }

        &-content {
            margin: 15px auto;
            width: auto;
            max-width: 1100px;

            @include bp(md) {
                margin: 40px auto;
            }

            p {
                text-align: center
            };

            &-main {
                @include bp(xs, sm) {
                    p, a {
                        font-size: 13px;
                        font-weight: 300;
                    }

                    strong {
                        font-weight: 500;
                    }

                    a {
                        text-decoration: underline;
                        color: $color-primary;
                    }

                    .amslider {
                        &-container {
                            width: 100vw;
                            margin-left: -20px;
                            padding-left: 10px;
                        }
                    }

                    .cms {
                        margin: 0;
                    }
                }
            }
        }
    }

    &-description {
        margin-bottom: 0;

        @include bp(md) {
            max-height: 100% !important;
        }

        @include bp(xs, sm) {
            min-height: 47px !important;

            &:not(.active) p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                transition: 0.5s;
            }
        }

        &-bottom {
            @include bp(md){
                border-bottom: 1px solid $color-border;
            }

            .view-more {
                @include bp(xs, sm) {
                    padding: 0;
                }
            }

            &-title {
                color: $color-main;
                font-family: "Montserrat - Semi Bold";
                font-size: 24px;
                font-weight: 500;
                line-height: 34px;
                text-align: center;

                @include bp(xs, sm) {
                    margin: 0;
                    font-size: 20px;
                }

                @include bp(ml) {
                    text-align: left;
                }
            }

            &-content {
                color: $color-main;
                font-family: "Montserrat - Regular";
                font-size: 14px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: normal;
                line-height: 1.6;
                margin: 30px 0 50px 0;

                @include bp(xs, sm){
                    margin: 10px 0 10px 0;
                    font-size: 12px;
                    text-align: center;
                }
            }
        }
    }

    &-header {

        &-container {
            position: relative;
            margin-top: -25px;
            padding: 120px 0 20px;

            @include bp(md, md) {
                margin-top: 0;
            }

            @include bp(md) {
                padding: 30px 0 20px;
            }
        }

        @for $i from 1 through 10 {
            &--#{$i} {
                color: nth($colors-universe, $i);
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: 100%;
            background: rgb(255, 255, 255);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 155px);
            z-index: 0;

            @include bp(md) {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 1) 100%);
            }
        }

        &-background {
            background-position: center top;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: 100%;
            z-index: -1;
        }

        &-content {
            position: relative;
        }

        &-slider {
            width: 100%;
        }


    }

    &-title {
        text-transform: uppercase;
        margin-bottom: 0;

        @include bp(ml) {
            font-size: 26px;
        }
    }

    &-description {
        color: $color-main;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;

        &-content {
            text-align: center;

            @include bp(xs, md) {
                margin-top: 15px;
            }

            @include bp(md) {
                text-align: left;
            }
        }
    }

    &-item {
        &-title {
            font-weight: 500;
            font-size: 12px;
            padding: 0 3px;

            @include bp(md) {
                padding: 0;
                font-size: 14px;
            }
        }
    }

    &-subcategory {
        display: flex;
        flex-direction: row;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 30px;
        width: 100%;

        @include bp(md) {
            flex-direction: column;
            padding: 0 300px 0 20px;
            position: relative;
        }

        .category-title {
            text-align: left;
            margin-bottom: 15px;
            flex: 1;

            @include bp(md) {
                flex: none;
                padding-right: 25px;
            }

            @include bp(xs, sm) {
                text-transform: inherit;
                display: flex;
                align-items: center;
            }
        }

        &-image {
            height: 100px;
            width: 100px;
            margin-left: auto;

            @include bp(md) {
                width: 300px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }

        &-content {
            width: 100%;

            @include bp(md) {
                padding-right: 25px !important;
                padding-left: 0 !important;
            }

            .category-title {
                margin-bottom: 25px;
                text-transform: full-size-kana;

                @include bp(xs, md) {
                    text-align: left;
                }
            }
        }

        .view-more-btn {
            margin: 20px auto;
        }

        @include bp(xs, sm) {
            .view-more[data-elevator]:not(.active) {
                height: 100px;
            }
        }

    }
}

// subcategory section
.subcategor{
	&ies{
		display: flex;
		flex-wrap: wrap;
        & .subcategory{
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
        }

		@include bp(xs, md){
			flex-wrap: nowrap;
			overflow-x: auto;
        }
	}

	&y{
		width: calc(25% - 10px);
		min-width: 178px;
		height: 50px;
		border: 1px solid $color-border;
		margin-bottom: 10px;
		transition: border .5s ease-in-out;

        @include bp(xs, sm) {
            &:last-child {
                margin-right: 20px;
            }
        }

		&:hover {
			border-color: $color-primary !important;

            .subcategory-link--text.category-item-title {
                color: $color-primary;
            }
		}

        &-content {
			height: 100%;
			max-width: 100%;
			width: 100%;
        }

		&-link{
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 100%;
			max-width: 100%;
			width: 100%;
			padding-left: 10px;


			&--text{
				min-width: 50%;
				height: 100%;
				margin-bottom: 0;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
				line-height: 50px;
                transition: color .5s ease-in-out;
			}

			&--img{
				height: 100%;
				object-fit: cover;
				max-width: 30%;

                @include bp(xs, sm) {
                    width: 100%;
                    max-width: 100%;
                }
			}
		}
	}
}

$swiper: '.swiper';

.swiper {

    margin: 0;

    &-container {
        margin: 40px 0 20px;
        overflow: visible;

        @include bp(md) {
            overflow: hidden;
        }
    }

    $slideActive: #{&}-slide-active;

    &-slide {
        @include bp(xs, sm) {
            padding: 0;
        }
    }


    &-category {

        &-item {

            & {
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                color: $color-main;
            }

            &-img {
                & {
                    border-radius: 50%;
                    width: 60px;
                    height: 60px;
                    margin-bottom: 5px;
                }

                &--hover {
                    & {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 0);
                        top: 0;
                        opacity: 0;
                        transition: opacity 0.5s;

                        @include bp(md) {
                            top: 10px;
                        }
                    }

                    @include bp(xs, sm) {
                        #{$slideActive} & {
                            opacity: 1 !important;
                        }
                    }


                }


            }

            &:hover & {
                @include bp(lg) {
                    &-img {
                        &--hover {
                            opacity: 1;
                            transition: 0.25s;
                        }
                    }
                }
            }
        }
    }

    &-button {

        &-prev,
        &-next {
            opacity: 1;
            height: 20px;
            width: 20px;
            background: currentColor;
            border-radius: 50%;
            display: none;

            @include bp(md) {
                display: block;
            }

            &::before {
                font-family: $icon;
                color: $color-white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 12px;
            }
        }

        &-prev {
            &::before {
                content: '\e910';
            }
        }

        &-next {
            &::before {
                content: '\e90d';
            }
        }
    }

    &-slide {
        @include bp(xs, sm) {
            padding: 0;
        }
    }

    &-pagination {

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 12px auto;
        width: 100%;

        &-bullet {

            width: 25px;
            height: 5px;
            border-radius: 2px;
            background: $color-grey-lighter;
            margin: 3px;
            opacity: 1;
            transition: 0.3s;

            &-active {
                background: currentColor;
            }
        }
    }
}

.rating {

    &-1::before {
        content: '\e90b';
    }

    &-2::before {
        content: '\e90b \e90b';
    }

    &-3::before {
        content: '\e90b \e90b \e90b';
    }

    &-4::before {
        content: '\e90b \e90b \e90b \e90b';
    }

    &-5::before {
        content: '\e90b \e90b \e90b \e90b \e90b';
    }


    &-result {

        & label {
            top: 0;
        }

        margin-left: 0;
        height: 30px;
        width: 87px;
        margin-right: 13px;


        &::before,
        & span::before {
            content: '\e90b \e90b \e90b \e90b \e90b';
        }

        &::before,
        & span::before,
        & label::before {
            font-size: 15px;
            height: auto;
            font-family: $icon;
            letter-spacing: 3px;
            left: 0;
            height: 30px;
            line-height: 30px;
            color: $color-primary;
        }

        &::before {
            content: '\e90e \e90e \e90e \e90e \e90e';
        }

        &--small {
            margin-right: 0;
            height: 22px;
            width: 65px;

            &::before,
            & span::before,
            & label::before {
                font-size: 11px;
                letter-spacing: 2px;
                height: 22px;
                line-height: 22px;
            }
        }
    }
}

body.catalog-category-view {
    section.section.view-more-section {
        margin-top: 0;
    }
}

.catalog-product-view{
    .columns{
        .column.main{
            @include bp(md, md) {
                display: flex;
                flex-direction: column;
                .product{
                    &.media{
                        order: 1;
                    }
                    &-info-main{
                        order: 2;
                    }
                    &-details{
                        order: 3;
                    }
                }
            }
        }
    }
}
