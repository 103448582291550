@use 'sass:math';

.grid {
	display: grid;	
	grid-gap: 20px;
	margin-top: 20px;
	margin-bottom: 100px;
	grid-template-areas:
		'item1'
		'item2'
		'item3'
		'item4'
		'item5'
		'item6'
		'item7'
		'item8';
	grid-template-rows: 266px 380px 266px 266px 266px 380px 380px 129px;
	@include bp(md){
		grid-template-areas:
			'item1 item2'
			'item1 item2'
			'item1 item2'
			'item3 item4'
			'item3 item4'
			'item3 item4'
			'item5 item6'
			'item5 item6'
			'item5 item6'
			'item7 item8'
			'item7 item8'
			'item7 item8';
		grid-template-rows: repeat(12, 76px);
	}
	
	@include bp(ml){
		grid-template-areas:
			'item1 item2 item3'
			'item1 item2 item3'
			'item1 item2 item3'
			'item4 item2 item5'
			'item4 item7 item5'
			'item4 item7 item5'
			'item6 item7 item5'
			'item6 item8 item8'
			'item6 item8 item8'
			'item6 item8 item8';
		padding: 0 25px;
		margin-top: 40px;
		margin-bottom: 151px;
		grid-template-rows: repeat(10, 76px);
	}
	&-item {
		position: relative;
		border-radius: math.div($corner-radius, 2);
		
		opacity: 0;
		transform: translateX(-20%);
		//Animation des grilles plus complexes sur deux axes
		// transform: translateY(-20%);
		
		&::before{
			content:'';
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			background: rgba($color-black,0.1);
			height: 100%;
			width: 100%;
			@include transition(0.75s);
			border-radius: 3px;
		}
		
		&:hover::before{
			@include transform(perspective(1px) translateZ(0) translate(8px,5px));
		}
		
		&-link {
			
			display: block;
			height: 100%;
			background: linear-gradient(45deg, $color-black 0%,$color-grey-lighter 100%);
			transition: transform 0.5s;
			border-radius: math.div($corner-radius, 2);
			
			&:hover{
				@include transform(perspective(1px) translateZ(0) translate(0px,-4px) scale(1.01));
			}
			
			&::before,
			&::after {
				position: absolute;
				top: 10px;
				right: 10px;
				bottom: 10px;
				left: 10px;
				content: '';
				opacity: 0;
				-webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
				transition: opacity 0.5s, transform 0.5s;
			}

			 &::before {
				border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
				-webkit-transform: scaleX(0);
				transform: scaleX(0);
			}

			 &::after {
				border-right: 1px solid #fff;
				border-left: 1px solid #fff;
				-webkit-transform: scaleY(0);
				transform: scaleY(0);
			}



			&:hover::before,
			&:hover::after {
				opacity: 0.6;
				-webkit-transform: scale(1);
				transform: scale(1);
			}

			
			&-image {
				width: 100%;
				height: 100%;
				object-fit: cover;
				@include transition(0.5s);
				position: relative;
				-webkit-filter: grayscale(25%);
				filter: grayscale(25%);	
				
			}
			
			
			&:hover &-image {
				opacity: 0.6;
				-webkit-filter: grayscale(0%);
				filter: grayscale(0%);	
			}
			
			&-title {
				font-family: $fs;
				font-size: 24px;
				font-weight: normal;
				line-height: 1.33;
				color: $color-white;
				position: absolute;
				margin: 0 auto;
				left: 19px;
				bottom: 18px;
				z-index: 1;
				transition: 0.5s;
			}
			&:hover &-title {
				left: 26px;
			}
		}
		
		
		body.loaded &{
			transform: none;
			opacity: 1;
		}
		
		@for $i from 1 through 8 {
			&--#{$i} {
				grid-area: item#{$i};
				transition: 0.75s #{$i*0.25}s;
			    
		    	@if $i % 2 == 0 {
		    	    transform: translateX(20%);
		        }
		    	
		    	@if $i % 3 == 0 {
		    	    transform: translateX(-20%);
		        }
		    	
		    	@if $i % 4 == 0 {
		    	    transform: translateX(20%);
		        }
				
				//Animation des grilles plus complexes sur deux axes
				/*
				@if $i % 2 == 0 {
				    transform: translateX(-20%);
			    }
				
				@if $i % 3 == 0 {
				    transform: translateY(20%);
			    }
				
				@if $i % 4 == 0 {
				    transform: translateY(20%);
			    }
			    */
		    	

				
				
			}
		}
		
	}

	&--5 {
		margin-bottom: 30px;
		grid-template-areas:
			'item1'
			'item2'
			'item3'
			'item4'
			'item5';
		grid-template-rows: 266px 266px 0px 0px 0px;
		@include bp(md){
			grid-template-areas:
				'item1 item2'
				'item1 item2'
				'item3 item4'
				'item3 item4'
				'item5 item5'
				'item5 item5';
			grid-template-rows: repeat(6, 76px);
		}
		
		@include bp(ml){
			grid-template-areas:
				'item1 item2 item3'
				'item1 item2 item3'
				'item1 item2 item3'
				'item4 item2 item3'
				'item4 item5 item5'
				'item4 item5 item5';
			padding: 0 25px;
			margin-top: 32px;
			margin-bottom: 40px;
			grid-template-rows: repeat(6, 76px);
		}
		@for $i from 1 through 5 {
			.gridlayout-item--#{$i} {
				grid-area: item#{$i};
			}
		}
		.gridlayout-item--3,.gridlayout-item--4,.gridlayout-item--5 {
			display: none;
			@include bp(md){
				display: initial;
			}
		}
		
		
	}
	
	&--9 {
		grid-template-areas:
		'item1'
		'item2'
		'item3'
		'item4'
		'item5'
		'item6'
		'item7'
		'item8'
		'item9';
		grid-template-rows: 130px 495px 380px 266px 266px 266px 380px 155px 129px;
		@include bp(md){
			grid-template-areas:
				'item1 item2'
				'item1 item2'
				'item1 item2'
				'item3 item4'
				'item3 item4'
				'item3 item4'
				'item5 item6'
				'item5 item6'
				'item5 item6'
				'item7 item8'
				'item7 item8'
				'item7 item8'
				'item9 item9'
				'item9 item9'
				'item9 item9';
			grid-template-rows: repeat(12, 76px);
		}
		
		@include bp(ml){
			grid-template-areas:
				'item1 item1 item1'
				'item1 item1 item1'
				'item1 item1 item1'
				'item1 item1 item1'
				'item2 item3 item4'
				'item2 item3 item4'
				'item2 item3 item4'
				'item2 item3 item7'
				'item2 item6 item7'
				'item5 item6 item7'
				'item5 item6 item7'
				'item5 item8 item8'
				'item9 item8 item8'
				'item9 item8 item8';
			grid-template-rows: repeat(14, 76px);
		}
		@for $i from 1 through 9 {
			.gridlayout-item--#{$i} {
				grid-area: item#{$i};
			}
		}
	}
	&--10 {
		grid-template-areas:
		'item1'
		'item2'
		'item3'
		'item4'
		'item5'
		'item6'
		'item7'
		'item8'
		'item9'
		'item10';
		grid-template-rows: 266px 129px 266px 380px 155px 380px 266px 495px 129px 129px;
		@include bp(md){
			grid-template-areas:
				'item1 item2'
				'item1 item2'
				'item1 item2'
				'item3 item4'
				'item3 item4'
				'item3 item4'
				'item5 item6'
				'item5 item6'
				'item5 item6'
				'item7 item8'
				'item7 item8'
				'item7 item8'
				'item9 item10'
				'item9 item10'
				'item9 item10';
			grid-template-rows: repeat(12, 76px);
		}
		
		@include bp(ml){
			grid-template-areas:
				'item1 item2 item2'
				'item1 item2 item2'
				'item1 item2 item2'
				'item3 item4 item5'
				'item3 item4 item5'
				'item3 item4 item8'
				'item6 item4 item8'
				'item6 item7 item8'
				'item6 item7 item8'
				'item6 item7 item8'
				'item9 item9 item10'
				'item9 item9 item10'
				'item9 item9 item10';
			grid-template-rows: repeat(14, 76px);
		}
		@for $i from 1 through 10 {
			.gridlayout-item--#{$i} {
				grid-area: item#{$i};
			}
		}

	}
	&--12 {
		grid-template-areas:
		'item1'
		'item2'
		'item3'
		'item4'
		'item5'
		'item6'
		'item7'
		'item8'
		'item9'
		'item10'
		'item11'
		'item12';
		grid-template-rows: 266px 129px 380px 266px 266px 380px 495px 380px 129px 380px 266px 129px;
		@include bp(md){
			grid-template-areas:
				'item1 item2'
				'item1 item2'
				'item1 item2'
				'item3 item4'
				'item3 item4'
				'item3 item4'
				'item5 item6'
				'item5 item6'
				'item5 item6'
				'item7 item8'
				'item7 item8'
				'item7 item8'
				'item9 item10'
				'item9 item10'
				'item9 item10'
				'item11 item12'
				'item11 item12'
				'item11 item12';
			grid-template-rows: repeat(12, 76px);
		}
		
		@include bp(ml){
			grid-template-areas:
				'item1 item2 item2'
				'item1 item2 item2'
				'item1 item2 item2'
				'item3 item4 item5'
				'item3 item4 item5'
				'item3 item4 item5'
				'item3 item7 item8'
				'item6 item7 item8'
				'item6 item7 item8'
				'item6 item7 item8'
				'item6 item7 item10'
				'item9 item9 item10'
				'item9 item9 item10'
				'item9 item9 item10'
				'item11 item12 item12'
				'item11 item12 item12'
				'item11 item12 item12';
			grid-template-rows: repeat(17, 76px);
		}
		@for $i from 1 through 12 {
			.gridlayout-item--#{$i} {
				grid-area: item#{$i};
			}
		}

	}
}
