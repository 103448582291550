.order{

	&-details {
	
		&-items {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}	

		&-comments {
			display: flex;
			flex-direction: column;

			& + ul > li > a {
				color: $color-green;
			}
		}
	}

	&-comment {

		&s {
			padding: 0 20px;
		}

		& + & {
			padding-top: 20px;
		}

		&-content {
			font-size: 14px;
		}

		&-date {
			font-weight: 400 !important;
			font-size: 12px !important;
			color: $color-secondary-darker !important;			

		}
	}
	
	&-begin{
		color: $color-black;	
	}
	
	&-date{
		font-weight: 300;
		font-size: 16px;
		margin-bottom: 10px;
	}
	
	&-title{
		margin-bottom: -15px;
		padding-right: 38px;
	}
	
	&-subtitle {
		&-wrapper {
			margin-bottom: 20px;

            .page-subtitle {
                margin-bottom: 7px;
                font-size: 22px;
            }

			& + ul > li > a {
				color: $color-green;
			}
		}
	}
	
	&-actions{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		
		&-toolbar {
			margin: 25px 0 5px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			&:before {
				content: unset !important;
			}
			
			a.btn {
				width: 100%;

				@include bp(md) {
					width: calc(50% - (20px / 2));					
				}

				&.btn--secondary {
					margin-left: auto;
	
					@include bp(xs, sm) {
						margin-top: 20px;
					}
				}
			}
		}
	}
	
	&-status{
		font-size: 14px;
		font-weight: 600;
		letter-spacing: normal;
		line-height: normal;
		color: $color-primary;
		margin-left: 15px;
	}
	
	&-header{
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	
	&-item{
		&s{
			
			width: 100%;
			&-title{
				display: inline-block;
				padding: 25px;
				font-weight: 500;
				margin-bottom: -2px;
				border: 1px solid $color-grey-lighter;
				border-bottom: 0;
				background:$color-white;
				margin-top: 40px;
				color: $color-black;
			}
			
			&-wrapper{
				width: 100%;
				border: 1px solid $color-grey-lighter;
				padding: 30px;
				margin-bottom: 35px;
				color: $color-black;
			
				& table{
					border: 0;
				}
				
			}
			
			& table.table-order-items {
				@include bp(sm, md) {
					display: block;
				}
			}

			& thead,
			& tbody{
				
				& tr{
					
					& > :last-child{
						padding-right: 15px;
						
						@include bp(md, lg) {
							text-align: center !important;
							padding-right: 0;
						}
					}
					
					text-align: center;
					
					& > :first-child{
						text-align: left;
						flex-wrap: wrap;
						
						@include bp(ml){
							padding-left: 0;
							width: 540px;
						}
					}
				}
				
				& td{
					vertical-align: top;
					
					&.qty{
						white-space: nowrap;
					}
				}
			}
			
			tbody {

				@include bp(sm, md) {
					display: block
				}

				td{
					border-bottom: 1px solid $color-grey-lighter;

					.price-including-tax, .price-excluding-tax {
						font-size: 1.4rem;
					}

					@include bp(sm, md) {
						&.table-body-qty {
							justify-content: start;
						}

						> * {
							margin-left: auto !important;
						}
					}
				}
				
				:last-child > td{
					border-bottom: 0;
				}
			}
			
			& .table-footer{
				text-align: right;
				background:$color-primary-lighter-1;
				
				@include bp(xs, md){
					margin-top: 15px;	
                    display: block;

                    tr {
                        display: block;
                    }
                    
                    .subtotal {
                        .amount {
                            padding-top: 10px !important;
                        }
                    }
				}

				@include bp(sm, md) {
					// display: flex;
					// flex-direction: column;
				}		
				
				& .price{
					font-weight: inherit;
				}
				
				& tr{
                    
                    .mark, .amount {
                        font-weight: 300 !important;
                    }


                    .amount {
                        padding-right: 10px !important;

                        @include bp(ml){
                            padding-right: 30px !important;
                        }
                    }
					
					text-align: right;
					margin-top: 0;

					@include bp(md, lg) {
						width: 100%;
					}

                    &:last-child,
                    &:first-child {
                        .amount, .amount strong  {
                            font-weight: 600 !important;
                        }
                    }
					
					&:last-child{
                        .mark strong  {
                            font-weight: 300 !important;
                        }

						td,th{
							@include bp(md){
								padding-bottom: 15px;
							}
						}
					}	

					&:first-child{
						td,th{
							@include bp(md){
								padding-top: 15px;
							}
						}
					}	
				}
				
				& td{
					border-top: none !important;

					@include bp(xs, md) {
						width: 100% !important;
					}

					@include bp(ml){
						padding: 5px 15px 5px 55px;
					}

					> * {
						margin-left: auto;
					}
				}
				
				& th{
					text-align: right;
					font-size: 14px;
					padding: 5px 20px;
					border-top: none !important;
					
					@include bp(xs, md){
						display: none;			
					}
				}
			}
			
			
		}
		
		&{
			
			&-name{
				font-size: 17px;
			}
			
			&-attributes{
				color: $color-secondary;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		} 
	}
	
	&-infos{
		
		color: $color-black;	
		width: 100%;
		text-align: center;
		
		@include bp(md){
			text-align: left;			
		}	
		
		&-title{
			font-weight: 700;
			font-size: 22px;
			margin-bottom: 22px;
		}
		
		&-col{
			
			font-size: 13px;
			text-align: center;
			margin-bottom: 30px;
			flex-wrap: wrap;
			align-items: flex-start;
			align-content: flex-start;
			justify-content: center;
			
			@include bp(md){
				text-align: left;
				justify-content: flex-start;			
			}
			
			&-title{
				font-size: 14px;
				font-weight: 700;
				margin-bottom: 10px;
				width: 100%;
			}	
		}
	}
}

body.sales-order-print {
	.actions-toolbar.order-actions-toolbar {
		display: none;
	}

	.customer-order-details-view {
		margin-bottom: 40px !important;
	
		&-content {
			display: block;

			.box {
				display: inline-block;
				vertical-align: top;
				margin: 0;
				width: calc((100% / 2) - 10px);
			}
		}
	}	

	.sales-order-print-footer {
		display: block;
		margin-bottom: 40px;
	}
}