#{$prefix} .ambrands {
	&-brand-item {
		transition: 0.5s ease-in-out;
		border: 2px solid transparent;
		border-radius: 10px;
		
		&::before {
			content: unset;
		}
		
		&:hover {
			border-color: $color-primary;
		}
	}
	&-inner {
		border: none;
		text-decoration: none;
	}
	
	&-image-block {
		width: 100%;
	}
	
	&-empty {
		width: auto;
		margin: auto;
	}
}

body.ambrand-index-index {
	.category-cover-background {
		background-repeat: no-repeat;
		background-size: contain;
	}
}