#{$prefix} .bytheme {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    &-header {
        order: 1;
        width: 100%;
        justify-content: center;

        @include bp(ml) {
            width: 850px;
            margin: 0 auto 40px 350px;
        }
    }

    &-button {
        align-items: center;
        order: 3;
        width: 100%;
        margin-top: 0;
        align-self: baseline;
        
        @include bp(ml) {
            order: 2;
            margin-left: auto;
            margin-top: 0;
            width: fit-content;
        }

        span {
            color: $color-main;
        }
        
        em.icon {
            margin-left: 15px;
            font-size: 10px;
            color: $color-main;
            width: auto !important;
        }
    }

    &-body {
        order: 2;
        padding: 0;
        
        @include bp (xs, md) {
            min-height: auto !important;
        }

        @include bp(md) {
            width: 100%;
        }
        
        @include bp(ml) {
            order: 3;
        }
    }

    &-wrapper {
        height: inherit;
        width: inherit;
        position: relative;
        min-height: 270px;
        height: 270px;
        
        @include bp(md) {
            max-height: 350px;
            height: 350px;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0%;
        }
    }

    &-content {
        position: absolute;
        bottom: 0;
        height: 130px;
        width: 100%;
        z-index: 10;
        background-image:  linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, 0.44) 100%);
        display: flex;
        align-items: flex-end;
        
        h2 {
            color: $color-white;
            font-size: 12px;
            font-weight: 400;
            margin: 0 !important;
            width: 100%;
            line-height: 1.3;
            padding: 13px;

            @include bp(md) {
                font-size: 14px;
                padding: 16px;
            }
        }

        span {
            font-size: 20px;
            font-weight: 500;
            text-align: left;

            @include bp(md) {
                font-size: 22px;
            }
        }
    }
}

// BEST SELLERS SECTION
#{$prefix} .best-sellers {
    &-slider {
        @include bp(xs, md) {
            min-height: auto !important;

            .slick {
                &-slide {
                    height: auto;
                }
            }
        }
    }
}