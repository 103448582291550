input[type=text]::-ms-clear {
    display: none;
}

.requiredfields {
    font-weight: 700;
    text-align: right;
    font-size: 10px;
}

#{$prefix} .form {

    &-container {
        padding: 30px 0;
        max-width: 728px;
        width: 100%;
        margin: auto;
        position: relative;

        &--bordertop::before {
            content: '';
            position: absolute;
            height: 1px;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            border-top: 1px solid $color-grey-light;
            width: calc(100% - 40px);
            max-width: 728px;
        }
    }

    &-separator {
        height: 1px;
        width: 100%;
        background: $color-grey-light;
        margin: 15px 0;

        @include bp(md) {
            margin: 30px 0;
        }
    }

    &-item {
        display: flex;
        flex-wrap: wrap;
        padding: 0 5px;
        position: relative;
    }

    &-image {
        overflow: hidden;
        margin-left: -15px;
        margin-right: -15px;


        & img {
            width: calc(100% + 60px);
            position: relative;
            left: -30px;
            align-self: center;

            &+img {
                width: auto;
                left: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
                height: 100%;
            }
        }
    }

    &-title {
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &--big {
            font-size: 33px;

            @include bp(xs, md) {
                font-size: 15px;
            }
        }

        &--down {
            &::after {
                transform: rotate(180deg);
                transition: transform 330ms linear;
            }
        }

        @include bp(xs, md) {
            margin-bottom: 10px;
        }

        &::before {
            color: $color-primary;
            font-size: 36px;
            margin-right: 17px;

            @include bp(xs, md) {
                font-size: 25px;
                margin-right: 11px;
            }
        }
    }

    &-subtitle {
        font-weight: 700;
        color: $color-black;
        font-size: 16px;
        margin-bottom: 10px;
    }

    &-text {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    &-section {
        &+& {
            margin-top: 30px;
        }
    }

    &-required-message {
        display: flex;
        justify-content: center;
        color: $color-error;
        font-size: 11px;
        margin-bottom: 20px;
        margin-top: -15px;

        @include bp(md) {
            position: absolute;
            margin: 0;
            right: 20px;
            top: 0;
            width: 100%;
            justify-content: flex-end;
        }
    }

    &-actions-toolbar {
        margin-top: 30px;
        margin-left: 0;

        @include bp(xs, xs) {
            margin-left: auto;
            margin-right: auto;
        }

        &--center {
            margin-left:auto;
            margin-right: auto;
        }

        &--whislist {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            button {
                @include bp(xs, xs) {
                    width: 100%;
                }

                &:nth-child(even) {
                    @include bp(xs, xs) {
                        margin-top: 10px;
                        margin-left: 0;
                        margin-right: 0;
                    }

                    margin: 0 10px;
                }
            }
        }

        .primary {
            width: 100%;

            @include bp(md) {
                margin-right: 30px;
                width: auto;
            }

            button.btn {
                @include bp(xs, md) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }

        .secondary {
            @include bp(xs, xs) {
                width: 100%;
                text-align: right !important;

                .customer-showpassword,
                a.action {
                    text-align: center !important;
                }
            }
        }
    }
}



.field-error,
.message.warning,
.mage-error {
    font-size: 10px;
    color: $color-error;
    margin-top: 3px;
    font-style: italic;

    &--global {
        display: none;
        margin-top: 15px;
        font-size: 14px;

        .form-error & {
            display: inline-block !important;
        }
    }
}

div.mage-error {
    order: 10;
}

.password-strength-meter {
    font-size: 11px;
    padding-top: 3px;
}

.input-label,
.select-label {
    color: $color-main;
    font-family: $fp;
    display: block;
    font-size: 13px;
    text-align: left;
    margin-bottom: 7px;
    font-weight: 500;
    text-align: left;

    @include bp(md) {
        font-size: 14px;
    }

    & small {
        text-transform: none;
    }

    &::after {
        content: '*';
        color: #e02b27;
        font-size: 1.2rem;
        margin: 0 0 0 5px;
    }

    &--no-required {
        &::after {
            content: none !important
        }
    }

}


#{$prefix} .input-text,
.input-subject,
.input-file-box,
#{$prefix} ._has-datepicker,
#{$prefix} .select-box>select,
#{$prefix} .select {
    @include appearance (none);
    position: relative;
    display: block;
    width: 100%;
    background: $color-white;
    font-size: 13px !important;
    font-weight: 300;
    z-index: 1;
    height: 50px;
    line-height: 50px;
    color: inherit;
    outline: none;
    border: 1px solid #a8a8a8 !important;
    font-family: $font-primary;
    transition: border 0.5s, opacity 0.3s, box-shadow 0.3s;
    padding: 0 45px 0 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    border-radius: 0;

    &--border {
        border-color: $color-primary;
    }

    &:not([disabled]):focus {
        box-shadow: 0 0 2px 1px $color-primary inset;
        border: 1px solid rgba($color-primary-lighter,0.5) !important;
    }

    &[disabled] {
        opacity: 0.4;
    }

    &--secondary {
        border: none;
        background: $color-grey-lighter !important;
    }

    ._error &,
    &.mage-error {
        border-color: $color-primary !important;
        margin-top: 0;
        font-style: normal;
    }

    &::placeholder {
        font-family: $font-primary !important;
        font-weight: 400;
        color: $color-grey;
        opacity: 1;
    }
}

.select-box>select,
.select {
    line-height: normal;
    padding: 0 45px 0 10px !important;
    width: 100%;
    border: 1px solid $color-border !important;
    background: $color-white;
}


.helper {
    cursor: pointer;
}

[type=tel]+.field-tooltip {
    right: 6px;
    top: 32px;
    z-index: 10;
}

.input {

    &-mandatory {
        font-size: 12px;
        font-style: normal;
        color: $color-primary;
        font-weight: bold;
        margin-bottom: 20px;

        &::before {
            content: '* ';
        }

        &.nobefore::before {
            display: none;
        }
    }

    &-tooltip{
        &-toggle {
            right: 6px;
            top: 32px;
            z-index: 10;
        }
    }

    &-btn {

        & {
            height: 39px;
            line-height: 39px;
            font-size: 12px;
            padding: 0 15px;
            width: 100%;
            margin-top: 20px;

            @include bp(md) {
                width: auto;
            }
        }

        &--big {
            border-radius: 0 100px 100px 0;
            margin-left: -2px;
        }

    }

    &-box {

        &--flex {
            display: flex;
            align-items: center;
            margin-top: 11px;

            & input {
                flex-grow: 3;
            }
        }

        position: relative;
        width: 100%;
        @include transition(color $duration);
        margin-bottom: 20px;

        .search & {
            margin-bottom: 0;
            margin-top: 0;

            @include bp(xs, ml) {
                &::before {
                    right: 30px;
                }
            }
        }

        &+& {
            margin-top: 4px;
        }

        &[class*="icon-"] input {
            padding-right: 45px;

            @include bp(md) {
                padding-right: 55px;
            }
        }

        &::before {
            z-index: 3;
            display: block;
            position: absolute;
            top: 50%;
            right: 15px;
            @include transform(translate(0, -50%));
            font-size: 25px;
        }

        &--btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-title {
            font-size: 15px;
            font-weight: 700;
            padding-bottom: 7px;
            padding-left: 10px;
        }

        &--long {
            width: 450px;
            max-width: 100%;
            margin: 0 auto;

            & input+.mage-error {
                position: absolute;
                bottom: -4px;
                @include transform(translateY(100%));
                width: 100%;
            }
        }

        &-label {
            margin-bottom: 0;
            margin-right: 20px;

            @include bp(lg) {
                margin-right: 38px;
            }
        }

        &--btn {
            display: flex;
            margin-top: -5px;
            position: relative;
            width: 100%;

            @include bp(ml) {
                width: auto;
            }

            & .input-text {
                @include bp(ml) {
                    width: auto;
                }
            }

            & .mage-error+.mage-error {
                position: absolute;
                left: 0;
                bottom: -18px;
            }
        }

        &-secondary {
            display: flex;
            align-items: center;
            margin-top: -5px;
            font-size: 11px;
            flex-wrap: wrap;

            &>* {
                margin-right: 10px;
                margin-bottom: 5px;
            }
        }

        &-button {
            margin-left: -4px;
            max-width: none;
            white-space: nowrap;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            height: 50px;
            line-height: 50px;
            position: absolute;
            right: 0;
            top: 26px;

            .btn.btn--primary {
                height: inherit;
            }
        }
    }

    &-subject {
        background: $color-white;
        text-align: left;
        padding: 0 20px;

        label {
            vertical-align: middle;

            &::after {
                content: ' : ';
            }
        }

        .input-text {
            border: none;
            background: none;
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
            padding: 0;
            @include transition(0s);
        }

        label+.input-text {
            width: calc(100% - 70px);
        }

    }

    &-text {
        padding: 0 45px 0 20px !important;
        background: $color-white;
        text-overflow: ellipsis;

        @include transition(.3s);

        &[type=password] {
            &::-ms-reveal {
                display: none;
            }
        }

        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px white inset;
        }

        &[readonly] {
            cursor: default;
        }

        &:not([readonly]) {

            &.error {
                border-color: $color-error;
            }

            &.valid {
                border-color: $color-success;
            }
        }

        &--search {
            font-size: 10px;
            background: rgba($color-white, 0.1);
            border: 0;
            border-radius: 27px;

            @include bp(xs, ml) {
                width: 100%;
            }


            &:focus {
                background: rgba($color-white, 0.3);
            }
        }

        &--box {
            align-items: flex-start;
            align-content: flex-start;
            height: auto;
            z-index: 15;
            overflow: hidden;

            @include bp(md) {
                border-radius: 30px;
            }
        }

        &--simple {
            border: 0;
            padding-left: 0;
            padding-right: 0;
            background: none;
            line-height: 1.2;
        }


        &--big {
            height: 50px;
            line-height: 50px;
            padding: 0 50px 0 10px;

            @include bp(md) {
                padding: 0 50px;
            }
        }

    }

    &-submit {
        &--search {
            position: absolute;
            right: 7px;
            top: 50%;
            z-index: 5;
            @include transform(translate(0, -50%));
            background: $color-primary;
            border-radius: $corner-radius;
            height: 38px;
            width: 44px;
            font-size: 0;

            &::before {
                font-size: 30px;
                content: '\e8b6';
                font-family: $icon;
                color: $color-white;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
                z-index: 11;
            }
        }
    }

    &-qty {
        @include appearance (none);
        text-align: center;
        width: 53px;
        min-width: 53px;
        height: 38px;
        display: inline-block;
        vertical-align: middle;
        padding: 0 5px;
        -moz-appearance: textfield;
        font-weight: 500;
        border-radius: 0;

        &-box {

            &-container {

                text-align: center;
                justify-content: center;
                display: flex;

                @include bp(md) {
                    display: block;
                    flex-wrap: wrap;
                }

                &+& {
                    margin-top: 20px;

                    @include bp(md) {
                        margin-top: 0;
                    }
                }
            }

            justify-content: flex-start;
            display: flex;
            align-items: center;
            padding: 0 17px;
            width: 100%;

            @include bp(md) {
                justify-content: center;
            }

            &+& {
                margin-top: 20px;

                @include bp(md) {
                    margin-top: 0;
                }
            }

            &es {
                justify-content: center;
                text-align: center;
                margin: 0 auto;

                @include bp(md) {
                    display: flex;
                }
            }
        }


        &.error {

            border-color: $color-error;
        }

        &-apply {
            font-size: 12px;
            margin-top: 2px;
            display: none;
        }

        &--small {
            height: 27px;
            width: 50px;
            line-height: 27px;
        }

        &-button {
            display: inline-block;
            width: 30px;
            height: 38px;
            font-size: 20px;
            font-weight: bold;
            @include appearance (none);
            position: relative;
            vertical-align: middle;
            border: 1px $color-main solid !important;
            background: $color-main;
            transition: 0.3s;
            color: $color-white;

            &:hover,
            &:active {
                color: $color-main;
                background: $color-white;
            }

            &--small {
                height: 27px;
                width: 27px;
                min-width: 27px;
            }


            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
            }

            &--more {
                border-radius: 0 2px 2px 0;

                &::before {
                    content: '+';
                }
            }

            &--less {
                border-radius: 2px 0 0 2px;

                &::before {
                    content: '-';
                }
            }

        }

        &[disabled]~&-button {
            background: #2f333a;
            cursor: default;
            outline: 0;
        }

        &-label {

            text-align: right;
            margin-right: 20px;
            color: $color-grey-darker;
            display: block;
            font-size: 12px;
            margin-bottom: 7px;
            padding-top: 5px;
            font-weight: 700;
            width: 100%;

            & span {
                font-size: 10px;
                font-weight: 400;
                display: block;

                @include bp(md) {
                    display: inline-block;
                }
            }

            @include bp(md) {
                text-align: center;
                justify-content: center;
                margin-right: 0;
            }

        }
    }

    &-file {

        display: none;

        &-box {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;

            @include bp(md) {
                padding: 5px;
                padding-left: 20px;
                flex-wrap: nowrap;
            }
        }


        &-button {

            line-height: 1.1;

            @include bp(xs, sm) {
                line-height: 25px;
            }
        }

        &-label {
            cursor: pointer;
            width: 100%;

            @include bp(md) {
                margin-right: 10px;
                width: auto;
            }
        }

        &-image {
            max-width: 100%;
            height: auto;
        }

        &-trigger {
            &::before {
                font-family: $icon;
                content: '\e944';
                position: absolute;
                top: 50%;
                right: 15px;
                font-size: 25px;
                cursor: pointer;
                @include transform(translate(0, -50%));
            }
        }

    }

    &-label {

        &-required {

            &::after {
                content: '*';
                font-size: 100%;
                line-height: 0;
                position: relative;
                top: -.2em;
                // color: $color-error;
            }
        }
    }

    &-advice {
        font-size: 12px;
        font-style: italic;
        margin-top: 9px;
        color: $color-black;
        text-shadow: 0 0 0 #000;
        text-align: left;

        @include bp (ml) {
            margin-top: 5px;
            margin-left: 20px;
        }
    }

    &-date {

        text-transform: uppercase;

        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator,
        &::-webkit-clear-button {
            display: none;
        }

        &-label {
            text-transform: none;
            font-weight: normal;
            text-align: right;
            padding-top: 15px;

            &::after {
                content: '\00a0:';
            }
        }
    }
}

textarea.input-text {
    padding: 10px 10px !important;
    line-height: 1.15;
    resize: vertical;
    min-height: 110px;
}

// Select
.select {
    background: $color-grey-lighter;
    padding: 10px 36px 10px 10px;
    border: none;

    &::-ms-expand {
        display: none;
    }

    &.error {
        border-color: $color-error;
    }

    &.valid {
        border-color: $color-success;
    }

    &-label {
        display: inline-block;
        white-space: nowrap;
        margin-right: 10px;
        font-size: 14px;
        text-transform: none;

        &--side {
            font-size: 16px;
            margin-right: 20px;
            margin-bottom: 0;
            padding-top: 0;
        }

    }

    &-box {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-width: 80px;

        &--alone {
            margin-bottom: 0;
        }

        &::before {
            position: absolute;
            pointer-events: none;
            content: '\e90d';
            font-family: $icon;
            right: 18px;
            top: 50%;
            margin-top: -.65em;
            font-size: 10px;
            z-index: 5;
            color: $color-primary;
            @include transform(translate(0, 0));
            transition: 0.5s;

        }


        &-small {
            display: inline-block;
            margin-right: 10px;

            &::before {
                font-size: 11px;
                right: 8px;
            }

            &::after {
                right: 24px;

            }

            .select {

                padding: 0 33px 0 12px;
                height: 37px;
                line-height: 37px;
            }
        }

        &-smaller {
            display: inline-block;

            @include bp(xs) {
                margin-top: 8px;
            }

            &::before {
                font-size: 11px;
                right: 8px;

                .ie9 & {
                    line-height: 22px !important;
                    width: 32px !important;
                    right: 1px !important;
                    padding-left: 8px;
                }
            }

            &::after {
                right: 24px;
            }

            .select {
                padding: 0 33px 0 12px;
                height: 24px;
                line-height: 24px;

                .ie9 & {
                    padding-right: 13px;
                }
            }
        }
    }

    &-qty,
    &-size {

        &-box {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            background: #fff;
            border: 1px solid $color-grey;

            &::before {
                position: absolute;
                pointer-events: none;
                content: '\e900';
                color: $color-black;
                font-family: $icon;
                right: 10px;
                top: 50%;
                margin-top: -.5em;
                font-size: 8px;
            }
        }

        & {
            @include appearance (none);
            background: none;
            padding: 0 40px 0 10px;
            direction: rtl;
            height: 54px;
            line-height: 54px;
            font-size: 20px;
            border: none;
        }


        &.error {
            border-color: $color-error;
        }
    }

}


.radio,
.checkbox {

   #{$prefix} & {
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    &-title {
        text-align: left;
        font-size: 12px;
        line-height: 19px;
        font-weight: 500;
        cursor: pointer;

        @include breakpoint (md) {
            margin-bottom: 15px;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 0;
        }
    }

    #{$prefix} &-box {
        text-align: left;
        font-size: 0;
        @include clearfix();
        display: flex;
        margin-bottom: 15px;
        flex-wrap: wrap;
        position: relative;

        @include bp(xs, sm) {
            &:nth-child(odd){
                 margin-bottom: 10px;
             }
        }

        div.field-tooltip {
            right: 0;
            top: -10px !important;

            @include bp(xs, md) {
                display: none;
            }
        }


        .input-label+& {
            margin-top: 15px;
        }

        input[type="checkbox"].checkbox {
            display: none;
        }
    }

    &-title+&-box {
        @include breakpoint (md) {
            display: inline-block;
            vertical-align: top;
        }
    }

    &-label {
        font-size: 13px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        cursor: default;
        user-select: none;
        // line-height: 20px;
        transition: color 0.3s;
        min-height: 24px;
        font-weight: 300;
        color: $color-grey;
        padding-left: 35px;
        display: flex;
        align-items: center;

        @include bp(md) {
            // line-height: 32px;
            padding-left: 40px;
        }

        &-price {
            display: block;
            font-weight: bold;
            font-family: $fp;
            font-size: 16px;
        }


        &~& {
            margin-left: 40px;
        }

        @include breakpoint (md) {
            &~& {
                margin-left: 45px;
            }
        }

        #{$prefix} &::before,
        #{$prefix} &::after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 15px;
            left: 0;
            box-shadow : none;
        }

        #{$prefix} &::before {
            content: '';
            border: 1px solid;
            background: $color-white !important;
            @include transform(translateY(-50%));
            @include transition($duration);
            border-color: #a8a8a8;
            box-sizing: border-box;
            height: 26px;
            width: 26px;
            margin: 0;
        }

        #{$prefix} &::after {
            content: '';
            left: 4px;
            // margin-top: 4px;
            font-family: $icon;
            background: none;
            color: $color-white;
            height: 18px;
            width: 18px;
            @include transform(translateY(-50%) scale(0));
        }


        &--switch {

            & {
                min-height: 26px;
                padding-top: 5px;
                padding-left: 57px;
                margin-top: 10px;
                margin-bottom: 10px;
                position: relative;
            }

            &::before {
                height: 26px;
                width: 50px;
                border-radius: 13px;
                background: $color-white;
                border: 1px solid $color-grey-dark;
            }

            &::after {
                @include transform(translateY(-50%) scale(0.7692) !important);
                background: $color-grey-lighter;
                left: 0;
                width: 26px;
                height: 26px;
                border-radius: 50%;
            }
        }

        &--top {

            &::after,
            &::before {
                top: 12px;
            }
        }

        &-top,
        &-bottom {
            padding-left: 0;

            &::before,
            &::after {
                @include transform(translateX(-50%));
            }

            &::before {
                left: 50%;
            }

            &::after {
                left: calc(50% - 4px);
            }
        }

        &-top {
            padding-top: 24px;
        }

        &-bottom {
            padding-bottom: 24px;

            &::before,
            &::after {
                top: auto;
            }

            &::before {
                bottom: 0;
            }

            &::after {
                bottom: 3px;
            }
        }





    }

    &.mage-error~&-label,
    &.validation-failed+&-label {

        &::before {
            border-color: $color-error;
        }
    }


    &~&-label {
        cursor: pointer;

        &::after {
            @include transform(translateY(-50%) scale(1));
            @include transition($duration);
        }
    }

    #{$prefix} &:checked~&-label {
        font-weight: 500;

        &::after {
            opacity: 1;
            @include transform(translateY(-50%) scale(1));
            @include transition($duration);
            background: $color-primary;
        }


        &--switch {
            &::before {
                background: $color-primary;
            }

            &::after {
                left: 24px;
                background-color: $color-white;
            }
        }
    }

    &-big {

        padding-left: 35px;
        line-height: 25px;
        min-height: 25px;

        &::before,
        &::after {
            width: 25px;
            height: 25px;
            border-radius: 2px;
        }

        &::after {
            font-size: 11px;
            top: 0;
            left: 0;
            text-align: center;
        }
    }

    &-inner {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        width: 100%;
    }

}
.radio {

    &-label {

        &-title {
            font-size: 18px;
            margin-bottom: 4px;
            font-weight: bold;
            color: $color-main;
            display: block;

        }

        #{$prefix} &::before,
        #{$prefix} &::after {
            border-radius: 100%;
        }

        #{$prefix} &::before {
            left: 1px;
            margin-top: 0px;
            width: 24px;
            height: 24px;
        }

        #{$prefix} &::after {
            left: 4px;
            // margin-top: 12px;
            margin-top: 0;
            font-size: 0;
            opacity: 1;
            background: transparent;
            border: 1px solid;
            transform: translateY(-50%) !important;
            width: 16px;
            height: 16px;
        }
    }


    &-box {

        &.checked {

            &+.radio-label::after {
                background: $color-primary;
            }
        }
    }
}

//RANGE SLIDER

.price {


    &-range {


        &-min,
        &-max {
            &::after {
                content: attr(data-currency) !important;
            }

            @include transition(background 0.3s);
            padding: 5px;
            margin: -5px;

            &:hover {
                background: $color-grey;
                cursor: pointer;
            }
        }

        &-min {
            float: left;
        }

        &-max {
            float: right;
        }

        &-current {
            @include clearfix();
            font-size: 12px;
            font-weight: 500;
        }
    }
}

.ui-slider {
    margin-top: 5px;
}



.field {
    min-width: 50%;

    @include bp(md) {
        min-width: unset;
    }

    &+& {
        margin-bottom: 5px;

        @include bp(md) {
            margin-bottom: 0px;
            margin-left: 30px;
        }
    }

    &-address {
        margin: 0 -15px -10px;
        width: calc(100% + 30px)
    }
}

.fieldset {

    .opc & {
        margin-bottom: 10px !important;
    }

    &>.label {
        display: none;
    }

    &>.field {
        margin: 0 0 20px;

        @include bp(xs, sm) {
            margin: 0 0 15px;
        }

        .opc-wrapper & {
            margin: 0;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.qty {
    #{$prefix} &{
        &:not(th):not(td){
            display: flex;
        }
        align-items: center;
        justify-content: space-between;
        margin: 0 10px;
    }

    $qtySmall : #{&}+"--small";

    &--small {
        justify-content: flex-start !important;
        margin: 0 !important;
    }

   &-input {

    #{$prefix} & {
            font-size: 20px;
            font-weight: 200;
            text-align: center;
            width: 55px;
            padding: 0 5px;
            height: 40px;
            line-height: 40px;
        }

        #{$qtySmall} & {
            font-size: 10px;
            width: 70px;
            height: 50px;
            margin: 0 10px;
            border: 1px solid $color-border;

        }
    }


    &-btn {

        & {
            height: 40px;
            width: 40px;
            min-width: 40px;
            line-height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: bold;
            transition: 0.5s;
            color: $color-main;
            cursor: pointer;
            background-color: #f4f3e9;

            &:hover {
                background: $color-btn-hover-background;
            }


            &--minus {
                &::before {
                    content: '-';
                }
            }

            &--plus {
                &::before {
                    content: '+';
                }
            }
        }

        #{$qtySmall} & {
            font-size: 14px;
            width: 38px;
            height: 40px;
            min-width: 26px;
        }


    }

}
