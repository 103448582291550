.table {

    color: $color-black;
    background: $color-white;
    width: 100%;

    @include bp(ml) {
        padding: 0 25px;
        border: 1px solid $color-grey-lighter;
    }

    @include bp(xs, sm) {
        display: block;
    }

    & .price {
        font-size: inherit;
        font-weight: normal;

        &-including-tax {
            text-align: center;
        }
    }

    thead {

        @include bp(xs, md) {
            display: none;
        }
    }

    &-head {
      font-weight: 500;
      font-size: 13px;

      th {
        vertical-align: middle;
        text-align: center;
        padding: 15px 0;
        font-weight: 500;
      }
      
            @include bp(ml) {
        width: calc(100% / 6);
      }
      
      .col.total {
          width: 100px;
      }
    }

    tbody,
    tfoot {

      @include bp(xs, sm) {
          display: block;
      }
    }

    &-body {
        &-item {
            border-bottom: 1px solid $color-border;
            font-weight: 400;
            font-size: 12px;

            @include bp(xs, md) {
                display: block;
                background: rgba($color-grey-lighter, .3);
                padding: 10px;
                border-bottom: 0;
                border-radius: 4px;

                &+tr {
                    margin-top: 20px;
                }
            }
            
            @include bp(ml) {
                width: 40%;
            }

            &:last-child {
                border: none;
            }
        }

        &-qty {

            @include bp(ml) {   
                width: 11%;
            }
        }
        
        &-sku {
            @include bp(ml) {   
                width: 11%;
            }
        }

        &-price {
            width: 100%;
            order: 3 !important;
            
            @include bp(ml) {
                height: 70px !important;
                width: 11% !important;
            }
            
            &-wrapper {
                margin-right: 10px;

                @include bp(md) {
                    margin: 0 40px;
                }
            }

            &__promo {

                &-tag {
                    padding: 5px;
                    color: $color-white;
                    font-size: 16px;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    text-align: center;
                    background: $color-primary;

                    @include bp(xs, sm) {
                        position: absolute !important;
                        top: 105px !important;
                        left: 143px !important;
                        z-index: 12 !important;
                    }
                }

                &-price {
                    color: $color-main;
                    font-size: 14px;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: 33px;
                    text-align: center;
                    text-decoration: line-through;
                }
            }
        }
        
        &-subtotal {
            @include bp(ml) {
                text-align: center !important;
                width: 11%;
            }
        }

        &-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include bp(md, ml) {
                justify-content: start;
            }

            @include bp(lg) {
                a.link--revert {
                    margin-right: 0 !important;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            
        }

        span {
            @include bp(md, ml) {
                margin-left: auto;
            }
        }
    }

    td {
        padding: 20px 10px;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid $color-grey-lighter;

        :last-child>td {
            border-bottom: 0;
        }

        @include bp(xs, md) {
            display: flex;
            align-items: center;
            text-align: left;
            padding: 5px 0;

            &::before {
                font-weight: 300;
                content: attr(data-th) ': ';
                margin-right: 10px;
                text-align: right;
                font-size: 13px;
            }
            
            &:first-child {
                padding-top: 0 !important;
            }
        }
        
        &.amount {
            padding-left: 15px !important;
        }
    }

    &-icon {
        & {
            font-size: 29px;
            line-height: 17px;
        }

        &s {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &+& {
            margin-left: 15px;
        }
    }
}


.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody>tr>td:last-child {
    @include bp(xs, ml) {
        padding-bottom: 0 !important;
        padding-right: 0;
    }
}

.settings {

    & a {
        color: $color-primary;
        @include transition($duration);

        &:hover {
            color: $color-primary-lighter;
        }
    }

    border-collapse: collapse;

    @include bp(xs, sm) {

        margin-top: 15px;

        &-wrapper {
            position: relative;
        }

        &-container {
            overflow-x: auto;
        }
    }

    &-container {
        margin-bottom: 40px;
    }


    @include bp(lg) {
        &-wrapper {
            padding: 0 115px;
        }
    }

    &+& {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 140px;
        width: calc(50vw - 20px);
        background: $color-white;
        display: block;

        @include bp(md) {
            display: none;
        }
    }

    & &-merged {
        @include bp(xs, sm) {
            display: none;
        }

        @include bp(md) {
            text-align: left;
        }
    }

    & .text-right {
        position: relative;
        width: 139px;
        width: calc(50vw - 21px);
        font-weight: 500;
        background: $color-grey-lighter;

        @include bp(xs, sm) {
            border: 0;		
            border-bottom: 1px solid $color-white;
        }

        @include bp(md) {
            background: transparent;
            font-weight: normal;
            text-align: right;
            width: auto;
        }


    }

    color: $color-black;
    width: 100%;

    & .price {
        font-size: inherit;
        font-weight: normal;
    }

    thead {

        @include bp(xs, sm) {
            display: none;
        }
    }

    &-head {

        & th {
            vertical-align: middle;
        }
    }

    thead+tbody> :first-child {
        font-weight: 700;
    }

    td,
    th {
        padding: 12px 12px;
        vertical-align: middle;
    }

    th {
        background: $color-grey-lighter;

        @include bp(xs, sm) {
            padding: 0;
            width: 0;
            min-width: 0;	
        }
    }

    @include bp(xs, sm) {
        tr> :first-child {
            display: none;
        }
    }

    td {

        text-align: center;
        vertical-align: middle;
        border: 1px solid $color-secondary;


        @include bp(xs, sm) {
            align-items: center;
            justify-content: center;
            width: 139px;
            width: calc(50vw - 21px);
            min-width: 139px;
            min-width: calc(50vw - 21px);

            &::before {
                content: attr(data-th);
                text-align: right;
            }
        }
    }

    &-separator {

        td {
            border: 0;
            padding: 4px;
            display: table-cell !important;
        }

        &--first td {
            padding: 10px;
        }
    }

    &-invisible {
        background: transparent;
        border: 0 !important;
    }

    &-group {
        &-name {
            text-transform: uppercase;
            margin-bottom: 7px;
            font-weight: 700;

            @include bp(md) {
                display: none;
            }
        }
    }

    &-arrow {
        display: inline-block;
        font-size: 45px;
        margin-left: -10px;
        width: 30px;
    }

}
