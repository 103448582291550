.reinsurance {
    margin: 12px 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    position: relative;
    text-align: left;
    width: 100%;
    
    @include bp(md) {
        margin: 20px 0;
        width: calc((100% / 3) - 50px);
    }

    &s {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }

    &-content {
        margin-left: 12px;
        
        @include bp(md) {
            margin-left: 20px;
        }
    }

    &-title {
        color: $color-main;
        font-family: $fp;
        font-size: 15px;
        font-weight: 500;
        margin: 0;
        line-height: 1.2;
    }

    &-details {
        color: $color-main;
        font-family: $fp;
        font-size: 13px;
        font-weight: 300;
    }
    
    &-logo {
        max-width: 32px;
        max-height: 32px;
        
        @include bp(md) {
            max-width: 45px;
            max-height: 45px;
        }
    }
}
