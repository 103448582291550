@import '../base/variables';
@import '../mixins/breakpoint';

//BASE VIDE A REPRENDRE SUR UN AUTRE PROJET SI SIMILARITES

$menu: '.menu';
$menuItem: #{$menu}-item;

.page-header {
    display: flex;
    flex-direction: column;
    background: $color-white;
}

.header {

    order: 1;
    padding: 20px;
    width: 100% !important;
    margin-bottom: 0 !important;

    @include bp(md) {
        order: 2;
    }

    &-content {
        padding: 20px 0;
        display: flex !important;
        align-items: center;

        @include bp(xs, md) {
            flex-wrap: wrap;
            padding-bottom: 7px;
            justify-content: space-between;
            align-items: baseline;
        }
    }

    &-top-banner {
        color: $color-white;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        margin: 0 !important;

        @include bp(md) {
            font-size: 14px;
            line-height: 1.4;
        }

        &-wrapper {
            padding: 8px;
            background: $color-primary;
            order: 2;

            @include bp(md) {
                order: 1;
                padding: 8px 15px;
            }
        }
    }

    &-burger-button {
        display: inline-flex !important;
        order: 3;
        margin: 0 !important;

        @include bp(md) {
            display: none;
        }
    }

    &-link {
        position: relative;

        &s {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            order: 2;
            width: 48%;
            margin-right: 8px;

            @include bp(md, ml) {
                margin-bottom: 0;
                margin-right: 10px;
            }
            @include bp(ml) {
                margin-top: 5px;
            }

            @include bp(ml) {
                order: 3;
                width: 155px;
            }
        }

        &:nth-child(even) {
            margin: 0 20px;
        }
        
        &:hover .header-icon-hover-overlay {
           opacity: 0.5;
        }

        &-sticker {
            position: absolute;
            top: -8px;
            right: -15px;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: $color-primary;
            padding: 10px;
            color: $color-white;
            font-size: 12px;
            display: none;
            
            @include bp(xs, sm) {
                top: -5px;
                right: -10px;
                font-size: 10px;
            }

            &--visible {
                display: flex;
            }
        }
    }

    .logo {
        margin: 0;
        padding: 0;
        max-width: unset;
        min-width: 100%;

        &-wrapper {
            width:49%;
            order: 1;
            height: fit-content;
            margin: 0 auto 0 0;

            @include bp(xs, md) {
                img{
                    max-width: 180px;
                }
            }
            
            @include bp(md, ml) {
                width: 50%;
            }
            
            @include bp(md) {
                margin-right: auto !important;
            }
            
            @include bp(ml) {
                width: 300px;
            }
        }
    }


    &-btn {
        &s {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & {
            height: 43px;
            line-height: 43px;
            margin-right: 25px;
        }

    }

    &-icon {

        color: $color-main;
        transition: 0.5s;
        text-decoration: none;
        font-size: 14px;
        line-height: 24px;

        @include bp(xs, sm) {
            font-size: 85px;
            line-height: 0px;
        }

        &:hover::before,
        &:hover::after {
            color: $color-primary-darker;
        }

        &::after,
        &::before {
            transition: 0.5s;
            font-size: 20px;

            @include bp(md) {
                font-size: 24px;
            }
        }

        &-hover {
            display: block;
            box-shadow: none;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.5s, margin 0.3s;
            color: $color-main;
            position: relative;
            padding-top: 15px;
            position: absolute;
            right: -10px;
            min-width: 300px;
            background: none;
            border: 0;
            margin-top: -10px;
            z-index: 99;

            @include bp(xs, sm) {
                display: none !important;
            }
            
            &--account {
                right: -23px;
            }
            
            &--wishlist {
                right: -20px;
            }

            & .minicart {
                display: block !important;
            }

            :hover>&,
            :hover+& {
                margin-top: 0;
                opacity: 1;
                pointer-events: initial;
            }
            
            &-overlay {
                width: 100vw;
                height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
                background-color: $color-border;
                opacity: 0; 
                z-index: 99;   
                pointer-events: none;
                transition: opacity .5s ease;

                @include bp(xs, sm) {
                    display: none !important;
                }
            }


            #{$prefix} &-content {
                background-color: $color-white;
                padding: 20px;
                display: block !important;
                position: relative;
                width: 400px;
                
                &::before {
                    content:'';
                    position: absolute;
                }
                
                &::before{
                    z-index: 151;
                    border: 6px solid;
                    border-color: transparent transparent $color-white transparent;
                    
                    @include bp(lg){
                        right: 26px;
                        top: -12px;
                    }
                }
                
                // &::after {
                //     z-index: 150;
                //     border: 7px solid;
                //     border-color: transparent transparent $color-border transparent;
                    
                //     @include bp(lg) {
                //         right: 25px;
                //         top: -15px;
                //     }
                // }
                
                &--account {
                    width: auto;
                    .btns .btn:not([style*="display: none"]) + .btn{
                        margin-left: 0;
                    }
                }
            }
        }
    }

    &-minicart {
        position: relative;
        margin: 0 !important;
        color: $color-main;
        z-index: 100;
    }

    &-search {

        position: relative;
        width: calc(100% - 35px);
        order: 4 !important;
        padding: 0;
        margin-left: auto;
        margin-top: 0;
        
        @include bp(md) {
            width: calc(100% - 300px);
            margin: 0 40px;
        }
        
        @include bp(xs, md) {
            margin: 15px 0 0 auto;
        }
        
        @include bp(md, md) {
            width: 95%;
        }

        @include bp(ml) {
            order: 2 !important;
        }

        @include bp(lg) {
            order: initial;
            margin-top: 0;
        }

        #{$prefix} &-input {
            color: $color-main !important;
            border-radius: 20px !important;
            padding: 0 20px !important;
            line-height: 40px !important;
            height: 40px;
            margin-right: 5px !important;
            width: 100% !important;
            border: 1px solid $color-primary !important;
            transition: border 0.5s, opacity 0.3s, box-shadow 0.3s !important;

            @include bp(ml, lg) {
                margin: 0 15px;
            }

            @include bp(xs, md) {
                position: relative;
                left: 0 !important;
                margin: 0 !important;
                position: relative !important;
            }

            &:not([disabled]):focus {
                box-shadow: 0 0 2px 1px $color-primary;
            }

            &::placeholder {
                opacity: 1;
                font-family: 'Montserrat';
                color: $color-black;
                font-weight: 300;
                font-size: 13px;
            }
        }

        .label {
            @include bp(xs, md) {
                position: absolute;
                z-index: 2;
                right: 14px;
                top: 50%;
                transform: translateY(-50%);
            }

            &::before {
                color: $color-black;
                font-size: 22px;
                display: none;
            }
        }

        &-submit {
            padding: 0;
            border: 0 !important;
            font-size: 21px !important;
            position: absolute !important;
            top: 50% !important;
            right: 17px !important;
            opacity: 0.5 !important;
            transform: translate(0, -40%) !important;
            background: none !important;
            
            @include bp(ml){
                right: 0 !important;
            }
            
            @include bp(xl){
                right: 17px !important;
            }
            
            &:before{
                content: '\e907'!important;
                font-family: 'badaboum'!important;
            }
            
        }
    }
}
