// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
@use 'sass:math';
$custom_columns : 0 1 2 3 4 5 6 7 8 9 10 11 12 '20p' '40p' '60p' '80p';

@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-ml-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}") {
  @each $i in $custom_columns{
      $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-ml-#{$i}, .col-lg-#{$i}, .col-xl-#{$i}";
  }

  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(math.div($grid-gutter-width, 2));
    padding-right: floor(math.div($grid-gutter-width, 2));
  }
}


// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
  @each $i in $custom_columns{
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }
  #{$list} {
    float: left;
  }
}

@mixin check-coltype($index,$property,$reverse:false) {
  @if ($index == '20p') {
    #{$property} : 20%;
  } @else{
    @if ($index == '40p') {
      #{$property} : 40%;
    } @else{
      @if ($index == '60p') {
        #{$property} : 60%;
      } @else{
        @if ($index == '80p') {
          #{$property} : 80%;
        } @else{
          @if ($reverse == true) {
            $index: $index * -1;
          }
          #{$property} : percentage(math.div($index, $grid-columns));
        }
      }
    }
  }
}

@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index != 0) {

    .col-#{$class}-#{$index} {

      @include check-coltype($index,'width');

    }

  }

  @if (type_of($index) == 'number'){


    @if ($type == push) and ($index > 0) {
      .col-#{$class}-push-#{$index} {
        @include check-coltype($index,'left');
      }
    }
    @if ($type == push) and ($index == 0) {
      .col-#{$class}-push-0 {
        left: auto;
      }
    }
    @if ($type == pull) and ($index > 0) {
      .col-#{$class}-pull-#{$index} {
        @include check-coltype($index,'left', true);
      }
    }
    @if ($type == pull) and ($index == 0) {
      .col-#{$class}-pull-0 {
        right: auto;
      }
    }
    @if ($type == offset) and ($index > 0) {
      .col-#{$class}-offset-#{$index} {
        @include check-coltype($index,'margin-left');
      }
    }
    @if ($type == offset) and ($index == 0) {
      .col-#{$class}-offset-0 {
        margin-left: 0;
      }
    }

  }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
  @each $i in $custom_columns{
    @include calc-grid-column($i, $class, $type);
  }
}


// Create grid for specific class
@mixin make-grid($class) {
  @include float-grid-columns($class);
  @include loop-grid-columns(($grid-columns + 1), $class, width);
  @include loop-grid-columns($grid-columns, $class, pull);
  @include loop-grid-columns($grid-columns, $class, push);
  @include loop-grid-columns($grid-columns, $class, offset);
}
