.view-more {
	
    position: relative;

    @include bp(xs, md) {
        padding: 0 15px;
    }

    &[data-elevator]:not(.active) {
        max-height: 100%;

        @include bp(xs, sm) {
            max-height: 150px !important;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffffff 100%);

            @include bp(md) {
                content: none;
                display: none;
            }
        }
    }

    &.active:after {
        height: 0;
        transform: all;
        transition: transform 0.5s ease;
    }

    &-btn {
        text-align: center;
        text-decoration: underline;
        margin-top: 20px;
        display: none;
        color:  $color-primary;

        @include bp(xs, sm) {
            display: block;
        }
    }
}
