#{$prefix} .ticket {
   margin-bottom: 20px;
   
	&-primary {
      background: $color-background;
      border-right-color: $color-border;
   }
   
   &-warning {
      background: $color-primary-lighter-1;
      border-left-color: $color-primary-darker;
   }
   
   &-status {
      padding: 10px 15px;
      font-size: 12px;
      font-weight: 400;
      
      &--open {
         background: $color-primary-lighter-1;
      }
      
      &--close {
         background: $color-error;
         color: $color-white; 
      }
   }
   &-chat {
      &-wrapper {
         display: flex;
         align-items: center;
         justify-content: space-between;
         margin-bottom: 20px;
      }
      
      &-header {
         padding: 20px 0;
      }
   }
}