// Buttons
@use 'sass:math';


$btn : '.btn';


@mixin btn-after () {

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 20%;
        width: calc(800% + 8px); // wtf?
        height: 150%; // why?
        padding-top: calc(800% + 8px); // wtf?
        opacity: 0;
        z-index: -1;
        -webkit-backface-visibility: hidden;
        outline: 1px solid transparent;
        transform: translate(-32%, 0) rotate(-17deg) translate3d(0, 0, 0);
        @include transition(opacity 0.6s, top 0s $duration, transform 0s $duration);
    }


    &:hover::after,
    a:not(.func):hover ::after {
        opacity: 1;
        top: 0;
        @include transition(opacity 0s, top 0.6s, transform 0.8s);
        transform: translate(-50%, 0) rotate(0) translate3d(0, 0, 0);
    }

    &:active::after,
    &.active::after,
    a:active &:not(.button-alone)::after {
        opacity: 1;
    }

}


@mixin btn() {

    @include appearance(none);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $fp;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    color: inherit;
    line-height: 1;
    position: relative;
    overflow: hidden;
    z-index: 1;
    max-width: 100%;
    transition: 0.6s;
    border: 0;
    box-shadow: 0 5px 9px transparent; //fix bug left-cropped button
    white-space: nowrap;
    @include transition(opacity 0.3s, top 0.6s, transform 0.8s);
    font-weight: 400;
    text-transform: initial;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    height: 50px;
    padding: 0 15px !important;
    border: 0 !important;
    border-radius: 0 !important;
    color: $color-white !important;

    @include bp(xs, sm) {
        font-size: 16px;
    }

    &-text,
    em,
    span {
        z-index: 15;
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        white-space: nowrap;
        color: inherit;

        &::before,
        &::after {
            font-size: math.div(25, 15)*1em;
            line-height: math.div(25, 15)*1;
            vertical-align: middle;
        }

        &::before {
            margin-right: 10px;
        }

        &::after {
            margin-left: 10px;
        }

    }

    @include btn-after();

}


@mixin btn-primary () {

    @include btn();

    color: $color-white !important;
    background-color: $color-primary !important;

    &:hover {
        color: $color-white;
    }

    &:active,
    &.active {
        color: $color-white;

        &::after {
            background: $color-primary-lighter !important;
            top: 0;
            @include transition(opacity 0s, top 0.6s, transform 0.8s);
            @include transform(translate(-50%, 0) rotate(0) translate3d(0, 0, 0));
        }
    }

    &::after {
        background: $color-primary-lighter !important;

    }
}

@mixin btn-secondary () {

    @include btn();

    background-color: $color-background !important;
    color: $color-main !important;

    &:hover {
        background-color: $color-background !important;
        color: $color-main !important;
    }
    
    span {
     color: $color-main !important;   
    }

    &:active,
    &.active {
        color: $color-btn-hover-background !important;

        &::after {
            background: $color-btn-hover-background !important;
            top: 0;
            @include transition(opacity 0s, top 0.6s, transform 0.8s);
            @include transform(translate(-50%, 0) rotate(0) translate3d(0, 0, 0));
        }
    }

    &::after {
        background: $color-btn-hover-background !important;
    }

}

@mixin btn-tertiary () {

    @include btn();

    background-color: $color-btn-out-of-stock;
    color: $color-white;

    &:hover {
        background-color: $color-btn-out-of-stock;
        color: $color-white;
    }

    &:active {
        color: $color-white;

        &::after {
            background: $color-grey-dark;
        }
    }

    &::after {
        background: $color-grey-dark;
    }
    
    @include bp(ml, xxl){
        font-size: 1.8rem;
        font-weight: 600;
    }
}


@mixin btn-quaternary () {

    @include btn();

    color: $color-white;
    background: $color-quaternary;
    font-weight: 500;
    height: 60px;
    margin: 20px 0;

    &:hover,
    a:hover &:not(.button-alone) {
        background-color: $color-quaternary;
        color: $color-white;
    }

    &:active {
        color: $color-white;

        &::after {
            background: $color-grey-dark;
        }
    }

    &::after {
        background: $color-quaternary-lighter;
    }

}



#{$btn} {

    &s {
        margin-top: 25px;
        font-size: 0;
        width: 100%;
        display: flex;
    }

    &s--line {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &s--full {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &s & {
        // margin-bottom: 15px;
    }

    &s--break {
        flex-direction: column;
        justify-content: center;
    }

    &s--break--mobile {
        @include bp(xs, sm) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 0 !important;
        }
    }

    &s--break--tablet {
        @include bp(xs, md) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &s--break & {
        margin-left: 0;
        margin-right: 0;
    }

    &s &:not([style*="display: none"])+& {
        margin-left: 15px;

        @include bp(md) {
            margin-left: 20px;
        }
    }

    &s--gap &+& {
        @include bp(md) {
            margin-left: 60px;
        }
    }

    &s--break &+& {
        margin-top: 10px;
        margin-left: 0;
    }

    &s--break--mobile &+& {
        @include bp(xs, sm) {
            margin-top: 10px;
            margin-left: 0;
        }
    }

    &s--break--tablet &+& {
        @include bp(xs, md) {
            margin-top: 10px;
            margin-left: 0;
        }
    }

    &s--wrap {

        &--nomobile {
            @include bp(md) {
                flex-wrap: wrap;
                margin-bottom: -10px;
            }
        }
    }

    &s--wrap--nomobile &,
    &s--wrap & {
        margin-left: 0 !important;
        margin-right: 12px;
        margin-bottom: 10px;
    }

    &#{$btn}--icon {
        width: 40px;
        padding: 0 20px;
        height: 40px;

        & em::before {
            margin-right: 0;
        }
    }

    &#{$btn}--shadow {
        box-shadow: 0 5px 9px rgba($color-black, 0.06) !important;
    }


    @include btn();


    &:focus {
        outline: 0;
    }


    &--big {
        font-size: 17px !important;
        height: 50px !important;
        line-height: 50px !important;
    }


    &--inactive {
        pointer-events: none;
    }

    &--gap {
        margin-bottom: 10px;
    }

    &--primary {
        @include btn-primary();
    }

    &--secondary {
        @include btn-secondary();
    }

    &--tertiary {
        @include btn-tertiary();
    }


    &--quaternary {
        @include btn-quaternary();
    }

    &--small {
        font-size: 14px;
        height: 39px;
        font-weight: 300;
    }

    &--subtitled {
        height: 54px;
        border-radius: 27px;
        flex-direction: column;
        line-height: 16px;

        & em {
            font-size: 12px;
        }
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        opacity: 0.5;
        cursor: default;
        pointer-events: none; // Disabling of clicks
    }

    &-link {
        font-size: 12px;
        margin-top: 5px;
    }


    &s--break--mobile.full>& {
        @include bp(xs, sm) {
            width: 100%;
        }
    }

    &s--break--tablet.full>& {
        @include bp(xs, sm) {
            width: 100%;
        }

        @include bp(md, md) {
            width: 65%;
        }
    }
}

.btt {
    display: flex;
    position: fixed;
    bottom: 95px;
    right: 18px;
    z-index: 100;
    width: 40px;
    height: 40px;
    border-radius:50%;
    text-align: center;
    background: $color-primary;
    color: $color-white;
    cursor: pointer;
    border: 0;
    text-decoration: none;
    pointer-events:none;
    @include transition($duration);
    opacity: 0;
	align-items: center;
	justify-content: center;
    
    @include bp(xs, sm) {
        bottom: 85px;
    }

    &.active {
        pointer-events: all;
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }

    &::before {
        font-size: 15px;
    }
}

#{$prefix} #axeptio_main_button {
    @include bp(xs, sm) {
        bottom: 60px !important;
    }
}