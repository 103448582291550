$slick-font-path: "./fonts/" !default;
$slick-font-family: "badaboum" !default;

#{$prefix} .slick {
    &-slider {
        padding-left: 0;
        padding-right: 0;
    }
    
    &-list, &-track {
        min-width: 100% !important  ;
    }
    
    &-slide {
        margin: 0 15px;
        height: auto;
        width: 173px;

        @include bp(md) {
            max-width: 273px;
            width: auto;
        }
        
        @include bp(xl){
            margin: 0 22px;
        }
        
        > div {
            height: inherit;
            width: inherit;
        }
    }

    &-disabled {
        display: none !important;
    }

    &-arrow {
        align-items: center;
        justify-content: center;
        background: $color-background !important;
        background-image: unset !important;
        width: auto !important;
        height: auto !important;
        z-index: 2;

        &:before {
            font-family: "badaboum";
            content: '\e911';
            font-size: 20px;
            padding: 10px;
        }

        &--right {
            right: 0;
        }

        &--left {
            left: 0;
        }

        &:not(.slick-disabled) {
            display: flex !important;
        }
    }
}

#{$prefix} .mobile-slider {
    max-width: 100%;
}

#{$prefix} .common-slider {
    @include bp(xs, sm) {
        width: 100vw;
        min-height: 290px;
    }
}


#{$prefix} .best-sellers-slider {
    .slick-arrow {
        background: $color-white !important;
    }
}