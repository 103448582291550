@use 'sass:math';

section.header_custom {
    margin-top: 15px !important;
    margin-bottom: 25px;
    
    @include bp(ml){
        height: 100%;
        max-height: 620px;
    }
}

#{$prefix} .home {
    &-category {
        &-img-wrapper {
            height: 100%;
            width: 100%;
        }
        
        /*** HOME HEADER - SEO ***/
        &-seo {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: 100%;

            &-card {
                position: relative;
                max-height: 130px;
                height: 130px;
                margin-bottom: 20px;
                
                @include bp(md) {
                    height: 250px;
                    max-height:  calc((100% / 2) - 10px);
                }

                &--left {
                    width: 100%;
                    order: 1;
                    
                    @include bp(xs, sm) {
                        height: 130px;
                    }
                    
                    @include bp(md) {
                        height: 250px;
                        max-height: 250px;
                    }
                    
                    @include bp(ml) {
                        width: calc(50% - 10px);   
                    }
                }

                &--right {
                    width: 100%;
                    order: 6;
                    height: 100%;
                    .home-category-seo-card-content--left{
                        p, span{
                            color: $color-home-right-section;
                        }
                    }

                    @include bp(xs, sm) {
                        height: 130px;
                    }
                    
                    @include bp(md) {
                        max-height: 250px;
                        height: 250px;
                    }
                    
                    @include bp(ml) {
                        order: 2;
                        width: calc(50% - 10px);
                    }
                }

                &--small {
                    width: calc((100% / 2) - 5px);
                    
                    @include bp(ml) {
                        width: calc((100% / 4) - 15px);
                    }

                    &-1 {
                        order: 3;
                    }

                    &-2 {
                        order: 5;

                        @include bp(ml) {
                            order: 4;
                        }

                    }

                    &-3 {
                        order: 1;

                        @include bp(ml) {
                            order: 5;
                        }

                    }

                    &-4 {
                        display: block;
                        order: 2;

                        @include bp(ml) {
                            display: none;
                        }
                    }
                }


                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                &-content {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 130px;
                    padding-left: 10px;

                    @include bp(sm) {
                        width: 215px;
                        padding-left: 20px;
                    }

                    @include bp(sm, ml) {
                        width: 270px;
                    }

                    &--left {
                        left: 0;
                    }

                    &--right {
                        right: 0;
                    }

                    &--bottom {
                        bottom: 0;
                        top: unset;
                        transform: none;
                        width: 100%;
                        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 100%);
                    }
                }

                &-title {
                    color: $color-white;
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    text-align: left;

                    @include bp(md) {
                        font-size: 20px;
                        line-height: 25px;
                    }
                }
                &-desc{
                    color: $color-white;
                    font-weight: 400;
                }

                &-subtitle {
                    color: $color-white;
                    font-size: 10px;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;

                    @include bp(md) {
                        font-size: 12px;
                    }
                }

                &-price {
                    color: $color-white;
                    font-size: 10px;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    text-align: left;

                    @include bp(md) {
                        font-size: 18px;
                    }
                }
            }

            &-content {
                color: $color-main;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                order: 6;
                height: 100%;

                @include bp(xs, md) {
                    width: 100%;
                }

                &-title {
                    font-size: 16px;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: center;

                    @include bp(lg) {
                        text-align: left;
                    }
                }

                p {
                    text-align: center;
                    line-height: 23px;

                    @include bp(lg) {
                        text-align: justify
                    }

                    &+& {
                        margin-bottom: 0;
                    }
                }
            }

        }
        /*** END - HOME HEADER - SEO - END***/

        /*** HOME HEADER - SLIDER ***/
        &-slider {
            height: 100%;
            
            @include bp(xs, md) {
                width: 100vw;
                margin-left: -15px;
                margin-right: -15px;
            }

            @include bp(md, lg) {
                margin-left: -20px;
                margin-right: -20px;
            }
            
            img {
                max-height: 620px;
                height: 100%;
                width: 100%;
            }

            .slick {
                
                &-list {
                    height: 100%;
                }
                
                &-track {
                    height: 100%;
                }
                &-slide {
                    margin: 0 !important;
                    height: 100% !important;
                    max-width: 100%;
                }

                &-dots {
                    position: relative;

                    @include bp(lg) {
                        position: absolute;
                        bottom: 25px;
                    }

                    li {
                        width: 40px;
                        height: 2px;

                        button {
                            width: 40px;
                            height: 2px;
                            background: $color-grey-dark;
                            padding: 0;

                            &:before {
                                content: none;
                            }
                        }

                        &.slick-active button {
                            background-color: $color-black;
                        }

                    }
                }
            }

            .slick-arrow {
                @include bp(xs, ml) {
                    display: none !important;
                }
            }
        }

        &-slide {
            position: relative;
            height: 100%;

            &-content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                @include bp(lg) {
                    width: 50%;
                }

                &-title {
                    font-size: 20px;
                    color: $color-primary;
                    font-weight: 500;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: center;
                    text-transform: capitalize;
                    margin-bottom: 10px;

                    @include bp(lg) {
                        font-size: 50px;
                    }
                }

                &-text {
                    text-align: center;
                    font-size: 12px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    padding: 0 15px;
                    margin-bottom: 15px;

                    @include bp(lg) {
                        font-size: 14px;
                    }
                }

                a.btn {
                    width: fit-content;
                    font-size: 16px;
                    padding: 16px 60px !important;
                    
                    @include bp(lg) {
                        padding: 23px 69px !important;
                        font-size: 20px;


                    }
                }
            }
        }
        /*** END -- HOME HEADER - SLIDER -- END ***/
    }

    &-description {
        position: relative;

        @include bp(xs, md) {
            padding: 0 15px;
        }

        &[data-elevator]:not(.active) {
            max-height: 100%;

            @include bp(xs, sm) {
                max-height: 150px !important;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffffff 100%);

                @include bp(md) {
                    content: none;
                    display: none;
                }
            }
        }

        &.active:after {
            height: 0;
            transform: all;
            transition: transform 0.5s ease;
        }

        &-btn {
            text-align: center;
            text-decoration: underline;
            margin-top: 20px;
            display: none;

            @include bp(xs, sm) {
                display: block;
            }
        }

        &-section {
            border-bottom: 1px solid $color-border;
            margin: 0;
            padding-bottom: 60px;
    
            @include bp(xs, md) {
                margin: 0 -15px;
            }
        }
    }
}

#{$prefix} .pagebuilder {

    &-button {

        &-primary {
            margin-left: auto;
            margin-right: auto;
            opacity: 1 !important;
            visibility: visible !important;
            @include btn-primary();


        }

        &-secondary {
            @include btn-secondary();
        }
    }


    &-poster {

        &-content {
            color: $color-main;

            h2 {
                color: $color-main;
                font-weight: 900 !important;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;

                span {
                    font-size: 25px !important;

                    @include bp(lg) {
                        font-size: 50px !important;
                    }
                }
            }

            p {
                color: $color-main;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                letter-spacing: normal;
                line-height: 22px;
                text-align: center;
                margin-bottom: 20px;
                max-width: 500px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-left: auto;
                margin-right: auto;

                @include bp(lg) {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
        }
    }
}
