.pager {
    @include bp(xs, md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .pages {
        text-align: center;
        padding: 0;
        
        @include bp(xs, md) {
            margin-bottom: 0;
        }

        .item {
            font-size: 14px;
            margin: 0 3px;
            font-weight: 700;
            min-width: 15px;

            @include bp(xs, md) {
                margin: 0 2px;
            }

            &s {
                display: flex;
                align-items: center;
                @include bp(xs, md) {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .page {
                font-size: 14px;
                font-weight: 400;
                color: $color-main;
            }
            &.current{
                font-size: 16px;
            }
        }

        .action {
            border: 1px solid $color-border;
            background: $color-white;

            @include bp(xs, md) {
                height: 40px;
                width: 28px;
                display: block;
                margin-left: 5px;

                span {
                    display: none;
                }
            }
            &.next, &.previous{
                height: 37px;
                width: 25px;

                &::before{
                    margin: 0 0 0 -11px;
                }
            }
            &.next{
                margin-left: 3px;
            }
            &.previous{
                margin-right: 3px;
            }

            &.next::before, &.previous::before {
                color: $color-primary;
                line-height: 35px;

                @include bp(xs, md) {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                }
            }
        }
    }

    .limiter {
        display: flex !important;
        align-items: center;
        margin-bottom: 0 !important;

        @include bp(xs, md) {
            display: inline-flex !important;
            justify-content: center;
        }

        select {
            margin: 0 10px;
            border: 1px solid $color-border;
            background: $color-white;
            padding-right: 0 !important;
        }

        &-label {
            @include bp(xs, md) {
                font-weight: 300;
                font-size: 15px;
            }
        }

        &-text {
            white-space: nowrap;

            @include bp(xs, md) {
                display: none;
            }
        }

        &-options {
            @include bp(xs, md) {
                height: 40px !important;
                line-height: 40px !important;
                font-size: 14px !important;
            }
        }
    }
}


#{$prefix}{
    .toolbar {
        &-product-qty {
            color: $color-main;
            font-size: 16px;
            font-weight: 700;
            @include bp(xs ,md) {
                text-align: center;
            }
        }
        &-products {
            background-color: $color-background !important;
            padding: 10px 15px !important;
            @include bp(xs ,sm) {
                padding: 10px 5px !important;
            }
        }
        .pager{
            justify-content: space-between;
            .limiter, .pages{
                display: block;
            }
            .pages{
                order: 2;
                &-items{
                    margin-top: 4px;
                }
            }
            .limiter{
                order: 3;
                #limiter{
                    height: 40px;
                    line-height: 40px;
                }
            }
            .toolbar-amount{
                order: 1;
                line-height: 29px;
                @include bp(sm ,md) {
                    padding: 0;
                }
            }
            .toolbar-sorter{
                order: 4;
                &.sorter{
                    .action{
                        display: none;
                    }
                    #sorter{
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }
        &:not(.toolbar-bottom){
            @include bp(xs ,sm) {
                display: none;
            }
        }
    }
    .products.wrapper ~ .toolbar{
        .toolbar-amount{
            display: block;
            @include bp(xs ,sm) {
                display: none;
            }
        }
        .sorter{
            display: flex;
            @include bp(xs ,sm) {
                // display: none;
                padding: 20px 10px 0;

            }
        }
    }
}