@mixin icon {
    font-family: $icon !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    //line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
	&::before, 
	&[class$="-after"]::after, 
	&[class*="-after "]::after {
		@include icon;
	}
}

$icons: (

	(user1, '\e920'),
	(star, '\e91f'),
	(facebook, '\e910'),
	(instagram, '\e914'),
	(linkedin, '\e937'),
	(twitter, '\e91a'),
	(youtube, '\e91b'),
	(pinterest, '\e917'),

	(chevron-up, '\e90e'),
	(chevron-up-scroll, '\e912'),
	(chevron-down, '\e90d'),
	(chevron-right, '\e91e'),
	(chevron-left, '\e91d'),
	(arrow-left, '\e912'),
	(arrow-right, '\e911'),
	(backtoback, '\e916'),
	(return, '\e90a'),
	(dropdown, '\e90a'),
	(check, '\e913'),
	(close, '\e90f'),
	(like, '\e90b'),

	(time, '\e909'),
	(filter '\e914'),
	(calendar, '\e904'),
	(new, '\e901'),
	(search, '\e921'),
	(pin, '\e903'),
	// (cart2, '\e909'),
	// (cart3, '\e907'),
	(delivery, '\e918'),
	(trash, '\e919'),
	(fid, '\e91a'),
	(mobile, '\e909'),
	(fav, '\e90a'),
	(fav2, '\e923'),
	(star, '\e90b'),
	(star2, '\e90e'),
	(call, '\e917'),
	(handi, '\e921'),
	(handi2, '\e924'),
	(handi3, '\e922'),
	(foot, '\e925'),
	(foot2, '\e928'),
	(foot3, '\e926'),
	(covid, '\e925'),
	(covid2, '\e928'),
	(covid3, '\e926'),
	(inject, '\e929'),
	(inject2, '\e92c'),
	(inject3, '\e92a'),
	(leaf, '\e92d'),
	(leaf2, '\e930'),
	(leaf3, '\e92e'),
	(truck, '\e931'),
	(truck2, '\e934'),
	(truck3, '\e932'),
	(phone, '\e91b'),
	(fidcard, '\e93a'),
	(purse, '\e93c'),
	
	(secure, '\e91b'),
	
	(sav, '\e91c'),
	(user, '\e909'),
	(clock, '\e904')
	(mail, '\e901'),
	(tel, '\e907'),

	(itineraire, '\e901'),
	(contacteznous, '\e900'),
	(cart, '\e908'),
	(loupe, '\e907'),
	(heart, '\e905'),
	(lock, '\e900'),
	(headphone, '\e903'),
	(shipping, '\e902'),
	(map, '\e915'),
	(document, '\e90c'),
	(trash, '\e919'),
);


.icon {
    @each $icon in $icons {
        &-#{nth($icon, 1)} {

            &::before,
            &-after::after {
                content: nth($icon, 2) !important;
            }
        }
    }

}
