.article{
	&s{
		display: flex;
		align-items: stretch;
		justify-content: center;
		width: 100%;
		opacity: 0;
		transition: opacity 1s;

		.slick-list{
			overflow: visible;
		}

		&.slick-initialized{
			opacity: 1;
		}
	}

	&-box{
		padding: 5px 10px 35px;
		@include bp(lg){
			padding: 5px 35px 40px;
		}
	}

	&{
		min-height: 100%;
		overflow: hidden;
		border-radius: 40px;
		display: flex;
		flex-direction: column;
		box-shadow: 0 2px 4px 0 rgba(50,51,53,0.2);

		@include bp(lg){
			box-shadow: 0 0 30px 0 rgba($color-black,0.15);
		}

		&-content{
			padding: 10px 20px;
		}

		&-image{
			width: 100%;
			height: 200px;
			object-fit:cover;
		}

		&-title{
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 10px;
			font-weight: bold;
		}

		&-excerpt{
			line-height: 20px;
			font-size: 14px;
			margin-bottom: 10px;
		}
	}
}


.cms {

	& {
		margin-bottom: 20px;
	}

	&--nogap{
		margin-bottom: 0;
	}

	& a {
		color: $color-primary;
		@include link;
		@include transition($duration);

		&:hover {
			color: $color-primary-lighter;
		}
	}

	& table {
		width: 100% !important;
	}

	& td {
		width: auto !important;
	}

	& h1,
	& h2,
	& h3,
	& h4 {
		margin-top: 10px;
		margin-bottom: 20px;
	}

	& div + h2,
	& div + h3,
	& div + h4{
		margin-top: 40px;
	}


	& h1 {
		color: $color-main;
		font-family: "Montserrat";
		font-size: 26px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		margin-top: 10px;
		position: relative;

		@include bp(md) {
			margin-bottom: 40px;
		}
	}

	& h2 {
		font-family: $fs;
		font-size: 20px;
		font-weight: 500;
		color: $color-main;
		width: 100%;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
	}

	& h3 {
		font-family: $fs;
		font-size: 18px;
		font-weight: normal;
		color: $color-main;
		width: 100%;
		font-weight: 500;
		letter-spacing: normal;
		line-height: normal;
	}

	& h4 {
		font-family: $fs;
		font-size: 16px;
		font-weight: normal;
		color: $color-secondary;
		width: 100%;
	}

	& p, p span, h2  {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.70;
		letter-spacing: normal;
		color: $color-main;
		letter-spacing: normal;
	}

	& p a {
		color: $color-primary !important;
		cursor: pointer !important;
		text-decoration: underline !important;
		font-size: 11px;


		&:hover,
		&:active {
			color: $color-primary-darker !important;
		}

	}

	& ol {
		list-style-type: decimal;
		list-style-position: inside;
	}

	& ul {
		list-style-type: disc;
		list-style-position: inside;
		padding-left: 30px;
		font-family: $fp;
		font-size: 14px;
		font-weight: normal;
		line-height: 1.57;
		text-align: justify;
		margin-bottom: 40px;
		& li {
			list-style: none;
			&:before {
				content: '-';
				padding-right: 3px;
			}
			& a {
				color: $color-secondary;
				text-decoration: revert;
			&:hover,
			&:active {
				color: $color-primary-darker;

				}
			}
		}
	}

	& strong {
		font-weight: 500;
		color: $color-primary;
	}
	& em {
		font-weight: 400;
	}

	& em {
		font-style: italic;
	}

	@include bp(xs, sm) {

		& p,
		& h1,
		& h2,
		& h3,
		& p,
		& img,
		& ul {
			width: 100% !important;
			display: block;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	& .elevator-target {
		margin-bottom: 15px;
	}

	& .section {

		margin: 15px 0 20px;

		&-title {
			cursor: pointer;
			padding-right: 30px;
			position: relative;
			padding-bottom: 15px;
			border-bottom: 1px solid $color-grey-light;

			&::after {
				position: absolute;
				top: 0;
				right: 2px;
				@include transition($duration);
				font-size: 1.3em;
			}

			&.active::after {
				@include transform(rotate(180deg));
			}
		}
	}


	& iframe {
		max-width: 100%;
	}
}

.cms-myprivilege {

	.breadcrumb-container{
		display: none;
	}

	.main{
		min-height:0;
	}
}

img{
	image-rendering: -webkit-optimize-contrast;
}

.mp{
	&-header{

		max-width: 1920px;
		margin: -20px auto 0 -10px;

		width: calc(100% + 20px);
		margin-left: -10px;

		@include bp(md){
			width: auto;
			margin: -25px auto 0;
		}

		&::before,
		&::after{

			@include bp(xs,sm){
				display: none;
			}

			content: '';
			background: #f0cccc;
			position: absolute;
			top: 0;
			left: 50%;
			width: 100%;
			z-index: -1;
			width: 50%;

			@include bp(md){
				box-shadow: inset 1px -15px 16px -22px #978888e6;
			}

			@include bp(ml){
				box-shadow: inset 1px -17px 18px -24px #978888;
			}

			@include bp(lg){
				box-shadow: inset 1px -18px 15px -20px #978888e6;
			}

		}

		&::before{
			right: 0;
			@include bp(md){
				height: 244px;
			}

			@include bp(ml){
				height: 342px;
			}

			@include bp(lg){
				height: 332px;
			}
		}

		&::after{
			background: #f4d0d0;
			left: 0;
			@include bp(md){
				height: 265px;
			}

			@include bp(ml){
				height: 369px;
			}

			@include bp(lg){
				height: 366px;
			}
		}
	}

	&-connect{
		overflow: hidden;
		border-radius: 20px;

		@include bp(md){
			border-radius: 50px;
		}
	}

	&-text{
		text-align: center;
		max-width: 815px;
		margin: 0 auto;
		@include bp(md){
			text-align: left;
		}
	}

	&-gold{
		&s{
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& + &{
			margin-left: 10px;
		}
	}

	&-slide{
		&s{
			&-container{
				position: relative;
				padding-top: 24%;
				width: calc(100% + 20px);
				margin-left: -10px;

				@include bp(md){
					padding-top: 27%;
				}

				@include bp(lg){
					width: 100%;
					padding-top: 0;
					height: 300px;
				}
			}

			position: absolute;
			padding: 0;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
			width: 100vw;
			max-width: 1220px;

			@include bp(lg){

			}
		}
	}
}
