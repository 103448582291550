#{$prefix} .sorter {
	width:100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&-label{
		white-space: nowrap;

		@include bp(xs, sm) {
			font-size: 15px;
		}
	}
	
	@include bp(md) {
		width: 230px;
	}

	&-select {
		width: 45%;
		
		@include bp(md) {
			width: 100%;
		}
	}

	&-options {
		background: $color-white;
	}

	&-action {
		&::before {
				color: $color-primary;
		}
	}

}
