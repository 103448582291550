body{
	.pac{
		
		&-container::after{
			display: none !important;
		}
		
		&-item{
			padding-top: 5px;
			padding-bottom: 5px;
			cursor: pointer;
		}
	}
}

.cluster{
	
	width: 50px !important;
	height: 50px !important;
	
	img{
		clip: rect(0px, 55px, 55px, 0px) !important;
		width: 100% !important;
	}
	
	div{
		height: 100% !important;
		width: 100% !important;
	}
	
	span{
		font-weight: bold;
		color: $color-white;
		font-size: 18px;
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
	}
}


$locator : '.locator';
$section : #{$locator}-content-section;
$locatorPopin : #{$locator}--popin;

#{$locator}{
	
	&-header{
		background: $color-grey-lighter;
		border-radius: 4px;
		
		 &-search{
			margin-top: 0;
			margin-bottom: 20px;
			position: relative;
			
			&::before,
			&-geoloc{
				z-index: 3;
				font-size: 21px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			
			&::before{
				display: none;
				@include bp(md){
					display: block;
				}
				color: $color-primary;
				left: 20px;
				right: auto;
			}
			
			
			&-geoloc{
				right: 131px;
				@include bp(md){
					right: 155px;
				}
				left: auto;
			}
		}
		
		&-filter{
			&s{
				&-content{
					margin-bottom: -13px;
				}
			}
		}
	}
	

	&{
		padding: 35px 0 0;
		position: relative;
		// background: $color-grey-lighter;
	    z-index: 50;
	    min-height: 312px;
		
		@include bp(md){
			padding: 35px 10px 0px;
		    min-height: 377px;
		}
		// overflow: hidden;

		&::before{
			// content:'';
			// background: $color-primary-lighter url(../img/bg1_mobile.png) center 20% repeat-x;
			height: 132px;
			width: 100%;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
			max-height:545px;
			
			@include bp(md){
				// background: $color-primary-lighter url(../img/bg1.png) center 160% repeat-x;
				height: 455px;
				background-size: 60%;
				width: 2214px;
				border-radius: 50%;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
		
		&--partial{
			margin-bottom: 376px;
		}
		
		&--popin{
			z-index: 500;
			position: fixed;
			left: 0;
			width: 100%;
			background: none;
			padding-top: 0;
			padding-bottom: 40px;
			overflow: auto;
			height: 100vh;
			overflow: auto;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.5s, padding 0.5s;
			
			@include bp(md){
				padding-top: 10px;
			}
			
			@include bp(xl){
				padding-top: 90px;
			}
			
			&::before{
				content:none;
				display: none;
			}
			
			&.active{
				padding-top: 50px;
				opacity: 1;
				pointer-events: initial;
				
				@include bp(md){
					padding-top: 80px;
				}
				
				@include bp(xl){
					padding-top: 190px;
				}
			}
		}
		
		&--popin &-content{
			margin-left: 0;
			width: 100%;
		}
		
		&--popin &-list{
			margin-left: -15px;
			width: calc(100% + 30px);
			background: transparent;
		}
		
		&--popin &-selector{
			@include bp(xs,sm){
				position: relative;
			    left: 50%;
			    transform: translateX(-50%);
			}
		}
		
	}
	
	&-main{
		margin-bottom: 5px;
		@include bp(md){
			margin-bottom: 35px;
		}
	}
	
	&-overlay{
		position: fixed;
		background: $color-grey;
		opacity: 0.7;
		top: 0;
		left: 0;
		right: 0;
		left: 0;
		width: auto;
		max-height: none;
		height: 100vh;
		border-radius: 0;
		transform: none;
		z-index: -1;
	}
	
	&-cancel{
		position: fixed;
		bottom: 30px;
		width: 100%;
		left: 0;
		margin-top: 40px;
		z-index: -1;
		@include bp(md){
			bottom: initial;
			position: absolute;
		}
	}

	&-title{
		text-align: center;
		font-weight: bold;
		color: $color-white;
		font-size: 24px;
		margin-bottom: 55px;
		
		@include bp(md){
			font-size: 36px;
		}
	}
	
	&-map{
		width: 100%;
		height: 225px;
		
		@include bp(md){
			height: 500px;
		}
		
		&-chosen{
			background: $color-grey-lighter;
			
			&.active{
				padding: 20px;
			}
		}
	}

	&-content{
		width: calc(100% + 26px);
		margin-left: -13px;
		padding: 0;
		
		@include bp(xs,sm){
			transition: margin 0.4s 0.1s;
			&.active{
				margin-bottom: -200px;
			}
		}
		
		@include bp(md){
			width: 100%;
			margin: 0;
			padding: 0 30px;
		}
		
		@include bp(ml){
			padding: 0 65px;
		}

		&-section{
			display: none;
			width: 100%;
			
			&-content{
				position: relative;
			}

			:checked + &{
				display: block;
			}
			
			&-map{
				overflow: hidden;
				position: relative;
				transition: max-height 0.5s;
				max-height:0;
				// margin-top: -149px;
				// margin-bottom: -30px;
				
				
				@include bp(xs,sm){
					.gmnoprint{
						display: none !important;
					}
					
					.gm-fullscreen-control{
						display: none !important;
					}
				}

				
				&.active{
					max-height: 443px;
				}
				
				@include bp(xs,sm){
					padding: 0;
				}			
				
				@include bp(md){
					&,&.active{
						margin: 0;
						max-height:none;
					}
				}
				
				&-toggle{
					margin-top: -36px;
				    z-index: 10;
				    transform: translateY(76px);
				    
				    #{$section}:not(#{$section}--partial) &,
				    &.active{
			    		opacity: 0;
			    		pointer-events: none;
				    }
				    
					@include bp(md){
						display: none;
					}
				}
				
				&-back{
					position: absolute;
					top:40px;
					left:50%;
					transform:translate(-50%);
					z-index: 10;
					@include bp(md){
						display: none;
					}
				}
			}
			
			#{$section}:not(#{$section}--partial) &-main {
				width: 100%;
			}
			
			#{$section}:not(#{$section}--partial) &-map{
				display: none;
			}
			
			#{$section}:not(#{$section}--partial) &-title{
				display: block;
			} 
			
			&-title{
				text-align: center;
				font-size: 16px;
				font-weight: bold;
				padding: 17px 10px;
				@include bp(md){
					display: none;
				}
			}
			
		}
	}
	
	&-input{
		
		width: 100%;
		&,&:valid{
			// border-color : $color-grey-light;
			border: 0;
		}
		
		@include bp(md){
			height: 60px;
		}
		
		&-container{
			#{$section}:not(#{$section}--partial) &--home{
				display: block;
			} 
			
			&--home{
				@include bp(md){
					display: none;
				}
			}
			
			position: relative;
			width: 100%;
			max-height: 50px;
			overflow: visible;
			transition: opacity 1s;
			@include bp(xs,sm){
				&.active{
					opacity: 0;
					pointer-events: none;
				}
			}
		}
		
		&-reset{
			position: absolute;
			z-index: 5;
			width: 37px;
			height: 37px;
			right: 5px;
			top: 5px;
			border-radius: 50%;
			background: $color-grey-lighter;
			pointer-events: initial;
			opacity: 1;
			transition: 0.3s;
			cursor: pointer;
			
			@include bp(md){
				width: 48px;
				height: 48px;
				right: 7px;
				top: 6px;
			}
			
			&:hover{
				background: $color-grey-light;
				color: $color-white;
			}
			
			:placeholder-shown ~ &{
				opacity: 0;
				pointer-events: none;
			}
			
			&::before{
				font-size: 13px;
				font-family: $icon;
				content:'\e90e';
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				
				@include bp(md){
					font-size: 18px;
				}
			}
		}
		
		&-geoloc{
			position:absolute;
			top: 17px;
			right: 75px;
			line-height: 1.2;
			z-index: 10;
			transition: right 0.3s;
			
			display: none;
			@include bp(md){
				top: 22px;
				
				:placeholder-shown ~ &{
					right:25px;
				}
				
				display: flex;
			}
			
			
			&--btn{
				display: flex;
				padding: 0;
				top: 5px;
				right: 5px;
				transition: 0.3s;
				pointer-events: none;
				opacity: 0;
				
				:placeholder-shown ~ &{
					opacity: 1;
					pointer-events: initial;
				}
				
				@include bp(md){
					display: none;
				}
			}
		}
		
		&-results{
			display: none;
			margin-left: -20px;
			margin-right: -20px;
			
			&-item{
				padding: 10px 20px;
				font-size: 12px;
				cursor: pointer;
				transition: 0.5s;
				&:hover{
					transition: 0s;
				}
			}
		}
		
	}

	&-box{
		display: flex;
		flex-direction: column;
		// align-items: center;
		justify-content: flex-start;
		min-height:142px;
		@include bp(md){
			min-height:231px;
		}
		
		@include bp(lg){
			min-height:241px;
		}
		
		@include bp(xs,sm){
			padding: 30px 15px;
		}
	}

	&-selector{

		&{
			width: 340px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px;
			box-shadow: 0 5px 8px 0 rgba($color-black,0.06);
			background: $color-white;
			border-radius: 33px;
			margin-top: -57px;
			margin-bottom: 2px;
			margin-left: auto;
			margin-right: auto;
			z-index: 20;
			
			@include bp(md){
				width: 100%;
				margin-top: -73px;
				margin-bottom: 40px;
				margin-left: 0;
			}
		}

		&-item{
			font-size: 14px;
			padding: 5px 23px;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 28px;
			cursor: pointer;
			transition: 0.5s;
			width: 50%;
			
			@include bp(md){
				font-size: 14px;
				padding: 10px;
			}
			
			@include bp(lg){
				padding: 10px 75px;
				font-size: 22px;
			}

			& ~ &{
				margin-left: 20px;
			}

			&::before{
				margin-right: 10px;
				line-height: 1;
				font-size: 26px;
				
				@include bp(md){
					margin-right: 10px;
					font-size: 26px;
				}
				
				@include bp(lg){
					margin-right: 22px;
					font-size: 36px;
				}
			}

			&:hover{
				background: $color-grey-lighter;
			}

			:checked + &{
				color: $color-white;
				background: $color-primary;
			}
		}
	}

	&-list{
		// margin-top: 25px;
		height: 440px;
		overflow: auto;
		z-index: 10;
		width: 100%;
		padding: 0 0;
		// position: absolute;
		
		@include bp(xs,sm){
			// margin-top: 97px;
			transition: margin 0.5s;
		}
		
		&.active{
			background: transparent;
			@include bp(md){
				margin-top: 25px;
			}
		}
		
		&-header{
			padding: 20px;
			border-bottom: 2px solid $color-grey-light;
			font-weight: bold;
			font-size: 14px;
			width: 100%;
			
			&-count{
				color: $color-primary;
			}
		}
		
		&-background{
			position: relative;
			background: $color-grey-lighter;
			width: 100%;
			
			&::before{
				// content:'';
				position:absolute;
				height:30px;
				left:0;
				top: 30px;
				z-index: -1;
				background: $color-grey-lighter;
				width: 100%;
				
				#{$locatorPopin} &{
					display: none;
				}
				
				#{$section}--partial &{
					height: 150px;
				}
				
				@include bp(md){
					display: none;
				}
			}

		}
		
		
		@include bp(md){
			width: 100%;
			position: initial;
			left: 0;
		}
		
		&-item{
			cursor: pointer;
			width: 100%;
			// background: $color-white;
			transition: 0.3s;
			// display: flex;
			// align-items: center;
			// justify-content: space-between;
			padding: 20px 10px;
			font-size: 12px;
			position: relative;
			
			&::before{
				content:'';
				position:absolute;
				top: 0;
				left:50%;
				transform:translate(-50%,0);
				width: calc(100% - 20px);
				height: 1px;
				background: $color-grey-light;
			}
			
			&:first-child{
				&::before{
					display: none;
				}
			}
			
			&[data-index]{
				cursor: pointer;
			}
			
			& + &{
				// border-top: 1px solid $color-grey-light2;
				margin-top: 10px;
				@include bp(md){
					margin-top: 0;
				}
			}
			
			&:hover{
				background: $color-white;
				// color: $color-white;
				transition: 0.1s;
			}
			&--active{
				background: $color-white !important;
				// color: $color-white;
			}
			
			&--active &-name{
				// color: $color-white;
			}
			
			&-img{
				border-radius: 4px;
				width: 30px;
				max-width: 30px;
				height: 30px;
				margin-right: 10px;
				align-self: flex-start;
				
				@include bp(md){
					align-self: center;
					border-radius: 50%;
					width: 65px;
					max-width: 65px;
					height: 65px;
					margin-right: 35px;
				}
			}
			
			&-image{
				margin-right: 20px;
				width: 75px;
				max-width: 75px;
				border-radius:5px;
			}
			
			&-content{
				font-size: 12px;
				line-height: 19px;
				width: 100%;
				display: flex;
				align-items: flex-start;
				
			}
			
			&-name{
				transition: 0.1s;
				font-weight: bold;
				font-size: 16px;
				color: $color-primary;
				@include bp(md){
					color: $color-main;
				}
			}
			
			&-detail{
				
				&s{
					margin-bottom: 8px;
				}
				
				font-weight: 500;
				font-size: 12px;
				display: flex;
				align-items: center;
				align-items: flex-start;
				margin-top: 4px;
				line-height: 1.3;
				
				&--inline{
					@include bp(ml){
						display: inline-flex;
						margin-right: 15px;
					}
					
				}
				
				&::before{
					margin-right: 5px;
					font-size: 12px;
					color: $color-primary;
				}
			}
			
			&-slot{
				margin-top: 5px;
				font-weight: bold;
				padding-left: 17px;
				&--value{
					color: $color-primary;
					margin-top: 0;
				}
			}
			
			&-distance{
				position: absolute;
				top: 10px;
				right: 10px;
				opacity: 0.7;
				display: flex;
				align-items: center;
				&::after{
					font-size: 17px;
					margin-left: 5px;
					position: relative;
					top: 1px;
				}
			}
			
			&-address{
				&--emphasis{
					opacity: 0.7;
					@include bp(md){
						opacity: 1;
						font-weight: bold;
					}
				}
			}
			
			&-schedule{
				font-weight: bold;
				line-height: 1.2;
				margin-top: 5px;
				&::before{
					content:'•';
					margin-right: 3px;
					
				}
				&--open{
					color: $color-ok;
				}
				
				&--closed{
					color: $color-error;
				}
				
				&--big{
					font-size: 16px;
					margin-bottom: 20px;
					margin-top: -10px;
				}	
			}
			
			&-slot{
				font-weight: bold;
			}
			
			&-reinsurance{
				&s{
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 10px 0;
				}
				
				&{
					margin: 0 10px;
					font-size: 12px;
					font-weight: 500;
					display: flex;
					align-items: center;
					
					&::before{
						color: $color-white;
						background: $color-primary;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 19px;
						border-radius: 50%;
						margin-right: 5px;
						width: 30px;
						height: 30px;
						min-width:30px;
						max-width:30px;
					}
				}
			}
		}
		
	}
	
	
	&-band{
		&{
			display: flex;
			flex-direction: column;	
			align-items: flex-start;
			@include bp(ml){
				align-items: center;
				flex-direction :row;
			}
		}
		
		&-content{
			padding-right: 20px;
		}
		
		&-main{
			display: flex;
			align-items: flex-start;
		}
		
		&-btn{
			flex-shrink:0;
			margin: 10px auto 0;
			@include bp(ml){
				margin: 0 0 0 auto;
			}
		}
		
		&-image{
			@include bp(ml){
				margin-right: 30px;
				width: 120px;
				max-width: 120px;
				height: 80px;
				flex-shrink:0;
			}
		}
	}

	
	&-home{
		
		display: none;
		margin: 20px -12px -50px;
		padding: 0 12px 20px;
		background: $color-grey-lighter;
		
		#{$section}:not(#{$section}--partial) &{
			display: none !important;
		}
		
		@include bp(md){
			margin: 0;
			padding: 0;
			background: none;
		}
		
		&-title{
			margin-bottom: 20px;
			text-align: center;
			
			font-size: 12px;
			font-weight: bold;
			top: 10px;
			position: relative;
			
			@include bp(md){
				font-weight: normal;
				font-size: 16px;
				margin-top: 0 !important;
				top: 0;
			}
			
			&--rmg{
				// margin-top: -20px !important;
				top: 0;
			}
			
		}
		
		&-back{
			justify-content: center;
		}
		
		&-head{
			
			&-container{
				
			}
			
			&{
				padding: 15px 10px 20px;
				margin-bottom: 20px;
				background: $color-grey-lighter;
				max-width:775px;
				margin-left: auto;
				margin-right: auto;
				// margin-bottom: 30px;
				
				@include bp(md){
					padding: 15px 45px 20px;
				}
			}
			
			&-address{
				text-align: center;
			}
			
			&-available{
				font-weight: bold;
				text-align: center;
				color: $color-ok;
				margin-top: 7px;
				text-align: center;
				font-size: 14px;
				
				&--not{
					color: $color-error;
				}
			}
			
			&-availability{
				text-align: center;
				font-size: 16px;
				
				& span{
					font-weight: bold;
				}
			}
			
			&-section{
				& + &{
					margin-top: 15px;
					padding: 10px 10px 20px 10px;
					border-radius: 4px;
					background: $color-white;
					
					
					@include bp(md){
						border-top: 1px solid rgba($color-grey-dark,0.5);
						padding: 20px 0 0 0;
						background: none;
					}

				}
			}
			
			&-store{
				
				display: flex;
				align-items: center;
				
				@include bp(md){
					margin-top: 20px;
				}
				
				@include bp(md,ml){
					flex-wrap: wrap;
					& > :last-child{
						margin-left: auto;
						margin-right: auto;
						margin-top: 20px;
					}
				}
				
				&-details{
					font-size: 12px;
					padding-right: 15px;
					
					
					&-title{
						font-weight: bold;
					}
					
					&-text{
						opacity: 0.7;
						margin-top: 7px;
					}
					
				}
				
				&-btn{
					margin-left: auto;
				}
			}
		}
		
		&-detail{
			
			width: 100%;
			background: $color-grey-lighter;
			padding: 10px 35px;
			margin-top: 10px;
			
			& + &{
				margin-top: -10px;
				margin-bottom: 10px;
				padding-top: 0;
			}
			
			
			
			@include bp(md){
				padding: 0;
				margin: 10px 50px 30px 50px !important;
				flex-wrap: nowrap;
				width: 225px;
				background: none;
			}
			
			&s{
				flex-wrap: wrap;
				display: flex;
				justify-content: center;
				width: calc(100% - 20px);
				margin-left: 10px;
				background: $color-white;
				margin-top: -40px;
				padding: 0 15px;
				overflow: hidden;
				max-height: 0;
				transition: max-height 1s;
				
				&.active{
					max-height: 194px;
				}
				
				
				@include bp(md){
					max-height:none;
					width: 100%;
					margin: 0;
					padding: 10px 35px;
					padding: 0;
					background: none;
				}
			}
			
			&-title{
				font-weight: bold;
				color: $color-ok;
				margin-bottom: 15px;
				display: none;
				@include bp(md){
					display: block;
				}
			}
			
			&-value{
				font-size: 12px;
				padding: 5px 0;
				display: flex;
				align-items: center;
				
				@include bp(md){
					padding: 5px 9px;
				}
				
				&-amount{
					margin-left: auto;
				}
				
				&s{
					@include bp(md){
						border-left: 1px solid $color-grey-light;
					}
				}
			}
		}
		
		&-first{
			&.active{
				display: none;
			}
		}
		
		&-list{
			
			&-container{
				display: none;
				
				#{$section}:not(#{$section}--partial) &--rmg{
					display: none !important;
				}
				
				&.active{
					display: block;
				}
			}
			
			height: 300px;
			width: 100%;
			max-width: 810px;
			margin: 0 auto;
			overflow: auto;
			
			@include bp(md){
				height: 275px;
			}
			
			@include bp(lg){
				padding: 0 100px;
			}
		}
		

	}
	
}

.location{
	
	&-map{
		height: 250px;
	}
	
	&-header{
		
		display: flex;
		align-items: flex-start;
		position: relative;
		flex-wrap: wrap;
		width: 100%;
		
		@include bp(ml){
			flex-wrap: nowrap;
		}
		
		
		&-image{
			@include bp(xs,md){
				position: absolute;
				top: 0;
				left: 0;
				width: 125px;
				height: 105px;				
			}
			margin-right: 40px;
			border-radius: 5px;
			object-fit: cover;
		}
		
		&-details{
			width: 100%;
			
			&-main{
				@include bp(xs,md){
					width: 100%;
					min-height: 105px;
					margin-bottom: 40px;
					padding-left: 135px;
				}
			}
		}
		
		&-line{
			width: 100%;
			@include bp(xs,md){
				padding: 0 !important;
			}
			
			& + &{
				margin-top: 20px;
			}
		}
		
		&-item{
			display: block !important;
			font-weight: 500;
			word-break: break-word;
			
			margin-top: 20px;
			@include bp(md){
				margin-top: 0;
			}
			
			&-label{
				display: flex;
				align-items: center;
				font-size: 14px;
				text-transform: uppercase;
				color: $color-primary;
				font-weight: bold;
				margin-bottom: 7px;
				&::before{
					font-size: 13px;
					margin-right: 10px;
				}
			}
		}
		
		&-schedule{
			
			@include bp(md){
				columns: 2;
				column-gap: 40px;
			}
			
			@include bp(lg){
				columns: 3;
				column-gap: 95px;
			}
			
			&-item{
				display: flex;
				align-items: center;
				justify-content: space-between;
				break-inside: avoid-column;
				
				& > :first-child{
					font-weight: bold;
				}
			}
		}
	}
}