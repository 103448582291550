body[class*="appoint"] .breadcrumb-container{
	@include bp(xs,sm){
		display: none;
	}
}

.footstep{
	/*	
	&-button{
		margin-top: 20px;
		width: 100%;
		@include bp(md){
			margin-top: 40px;			
		}
		
		&s{
			width: 100%;
			display: flex;
			align-items: center;
		}
		
		@include bp(md){
			width: auto;	
			margin: 40px auto 0;		
		}
	}
	*/
	
	&s{
		counter-reset: number 0;
		display: flex;
		width: calc(100% + 20px);
		background: $color-primary;
		padding: 20px;
		color: $color-white;
		margin-left: -10px;
		margin-top: -20px;
		margin-bottom: 20px;
		
		
		&::after{
			content: ' /' attr(data-count);
			font-size: 16px;
			font-weight: bold;
			margin-left: 1px;
			
			@include bp(md){
				content: none;
			}
		}
		
		@include bp(md){
			padding: 0;
			background: none;
			justify-content: center;
			margin-bottom: 60px;
			margin-top: 40px;
			margin-left: 0;
			width: 100%;
			color: $color-main;
		}
	}
	
	display: flex;
	align-items: center;
	font-weight: 500;
	@include transition($duration);
	position: relative;
	width: 100%;
	margin-bottom: 0;
	position: absolute;
	opacity: 0;
	pointer-events: none;
	
	@include bp(md){
		display: flex;
		font-size: 20px;
		margin-top: -10px;
		width: auto;
		pointer-events: initial;
		position: relative;
		opacity: 1;
	}
	
	&--active{
		position: relative;
		pointer-events: initial;
		opacity: 1;
		display: flex;
	}
		
	&-content{
		
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		
		@include bp(md){
			justify-content: center;
			flex-direction:column;
		}
		
		&::before{
			counter-increment: number 1;
			content: counter(number);
			margin-left: auto;
			font-size: 16px;
			font-weight: bold;
			
			@include bp(md){
				font-size: 20px;
				margin-left: 0;
				height: 40px;
				width: 40px;
				border-radius:50%;
				@include transition($duration);
				line-height: 40px;
				text-align: center;
				margin-bottom: 7px;
				font-family: $icon;
				content:'\e913';
				color: $color-white;
				background: $color-primary;
				font-weight: 400;
			}
		}
	}
	
	& + &{
		&::after{
			
			@include bp(md){
				content:'';
				height: 2px;
				width: 75px;
				order:-1;
				background:$color-secondary;
				margin: 0 15px;
				position: relative;
				top: -5px;
			}
			
			@include bp(lg){
				width: 115px;			
			}
			
		}
	}
	
	&--active &-content::before{
		@include bp(md){
			content: counter(number);
			color: $color-white;
			background:$color-primary;
			font-family: $fp;
			font-weight: 700;
		}
	}
	
	&--active ~ & &-content::before{
		@include bp(md){
			content: counter(number);
			background: $color-grey-lighter;
			background: $color-grey-lighter;
			color: $color-main;
			font-family: $fp;
			font-weight: 700;
		}
		
	}
	
	&-text{
		
		display: block;	
		font-size: 14px;
		order: -1;
		
		@include bp(md){
			position: absolute;
			bottom: 0;
			left:50%;
			transform:translate(-50%,100%);
			text-align: center;
			font-size: 15px;
		}
		
		@include bp(lg){
			font-size: 20px;
		}
	}
	
}

.recap{
	&-container{
		width: 100%;
		margin: 40px 0 0;
		
		@include bp(md){
			margin: 0;
		}
		
		@include bp(lg){
			padding-left: 70px;
		}
	}
	
	&-section{
		background: $color-grey-lighter;
	}
}

.schedule{
	width: 100%;
	background: $color-grey-lighter;
	border-radius: 4px;
	padding: 20px;
	display: flex;
	position: relative;
	color: $color-primary;
	
	&-col{
		align-self: flex-start;
	}
	
	 & .slick{
		&-next,
		&-prev{
			position: absolute;
			top: -8px;
			z-index: 10;
		}
		
		&-prev{
			left: 40px;
		}
		
		&-next{
			right: 40px;
		}
	}
	
	&-column{
		
		&s{
			background: $color-white;
			width: 100%;
			display: flex;
			align-items: flex-start;
			align-content: flex-start;
			justify-content: center;
			position: relative;
			overflow: hidden;
			min-height: 230px;
			padding-left: 0;
			padding-right: 0;
			
			opacity: 0;
			transition: 1s;
			
			&.slick-initialized{
				opacity: 1;
			}
			
			&::before,
			&::after{
				content:'';
				background: $color-white;
				position:absolute;
				top:0;
				left:0;
				width: 45px;
				height: 45px;
				z-index: 5;
			}
			
			&::after{
				left: auto;
				right: 0;
			}
			
			@include bp(md){
				padding-left: 50px;
				padding-right: 50px;
			}
		}
		
		&{
			color: $color-main;
			padding: 0 10px;
			
			@include bp(md){
				padding: 0 7px;
			}
		}
		
		&-content{
			display: flex;
			flex-wrap: wrap;
			padding-top: 57px;
			position: relative;
			margin-left: -7px;
			margin-right: -7px;
			
			@include bp(md){
				margin-left: 0;
				margin-right: 0;
				align-items: center;
				flex-direction: column;
				flex-wrap: nowrap;
				padding-top: 0;
			}
		}
		
		&-head{
			margin-bottom: 20px;
			font-size: 15px;
			
			@include bp(xs,sm){
			    width: 100%;
			    position: absolute;
			    left: 50%;
			    top: 0;
			    transform: translate(-50%, 0);
			}
			
			& :first-child{
				margin-bottom: 2px;
				font-weight: bold;
			}
		}
	}
	
	&-cell{
		width: calc(33.333% - 14px);
		background: $color-primary-lighter;
		border-radius: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: center;
		font-weight: bold;
		font-size: 15px;
		padding: 12px 21px;
		margin-bottom: 15px;
		transition: color 0.5s, background 0.5s;
		cursor: pointer;
		
		margin: 0 7px 15px 7px;
		
		&:hover{
			background: $color-grey-lighter;
		}
		
		 &.active{
			background: $color-primary;
			color: $color-white;
		}
		
		@include bp(md){
			width: 100%;
			margin: 0 0 15px 0;
		}
	}
}




