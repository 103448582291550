[data-sidebar-toggle] {
    cursor: pointer;
}

#amasty-shopby-product-list {
    width: 100%;
    height: fit-content;
    position: sticky;
    top: 80px;
    width: 100%;
}

.catalog-topnav.amasty-catalog-topnav {
    @include bp(sm, md) {
        display: none !important;
    }
}

.am_shopby_apply_filters {

    @include bp(sm, md) {
        position: fixed;
        z-index: 999;
        width: 100%;
        display: block;

        &.visible {
            bottom: 0;
        }
    }

    .am-show-button {
        @include bp(sm, md) {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            margin: 0 auto;
            padding: 15px 0;
            width: 100%;
            border-top: 1px solid #cccccc;
            background: #ffffff;
            text-align: center;

            &:before {
                content: unset !important
            }

            ;
        }

        >.am-items {
            @include bp(sm, md) {
                margin: 0 !important;
            }
        }
    }
}

.sidebar {

    &-filter {
        @include bp(ml) {
            position: sticky;
            top: 70px;
        }

        @include bp(xs, sm) {
            margin-bottom: 100px;
        }

        &-item {
            #{$prefix} &--category {
                border-bottom: none;
                
                @include bp(xs, sm) {
                    display: none;
                }

                >.sidebar-filter-title {
                    pointer-events: none;

                    &:after {
                        display: none;
                    }
                }

                >.icon-chevron-down-after::after,
                .am-collapse-icon:after {
                    color: lighten($color-grey, 50%);
                }
                
                .checkbox-label {
                    
                    @include bp(md) {
                        padding-left: 0;
                    }
                    
                    &::before, &::after {
                        display: none;
                    }
                }
            }

            #{$prefix}.catalogsearch-result-index &--category {
                display: none;
            }
        }

        &-overlay {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: #D8D8D8;
            opacity: 0;
            z-index: 99;
            pointer-events: none;
            transition: opacity 0.5s ease;
        }

        #{$prefix} &-wrapper {
            position: fixed;
            left: -1000px;
            top: 0;
            bottom: 0;
            z-index: 150;
            background-color: $color-white;
            transition: left .7s ease-in-out;
            
            @include bp(xs, sm) {
                width: 100vw;
                overflow: auto;
                max-height: 100vh;
            }

            @include bp(sm, md) {
                width: 350px;
                overflow: auto;
            }

            @include bp(ml) {
                position: unset;
                height: auto;
                background-color: transparent;
                z-index: 9;
                width: 20%;
            }

            &.open {
                left: 0;

               & ~ .sidebar-filter-overlay{
                    opacity: 0.5;
                    pointer-events: inherit;
                }

                .page-title-wrapper {
                    display: none;
                }
            }

            .page-title-wrapper {
                display: none;

                @include bp(ml) {
                    display: block;
                }
            }

        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 20px 0;
            color: $color-primary;
            visibility: visible;
            opacity: 1;

            p {
                font-size: 18px;
                font-weight: 700;
            }

            @include bp(ml) {
                margin: 0;
                display: none;
                visibility: hidden;
                opacity: 0;
            }

        }

        &-button {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            display: block;
            visibility: visible;
            opacity: 1;
            z-index: 999;

            @include bp(ml) {
                display: none;
                visibility: hidden;
                opacity: 0;
            }
        }

        &-close {
            position: absolute;
            right: 0;
            display: block;
            visibility: visible;
            opacity: 1;

            .icon-close::before {
                font-weight: bold;
            }

            @include bp(ml) {
                display: none;
                visibility: hidden;
                opacity: 0;
            }
        }

        &-title {
            padding: 10px 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 700;
            color: $color-main;
            text-transform: uppercase;

            &::after {
                font-size: 8px;
                color: $color-primary;
                transition: transform .5s ease;

                @include bp(xs, sm) {
                    font-size: 8px;
                }
            }

            &.active {

                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        &-content {
            border-top: 1px solid $color-border;
            padding-bottom: 0;
            padding: 0 10px;

            &-form {

                .checkbox:checked~.checkbox-label {
                    color: $color-primary !important;
                    font-size: 12px;
                }

                .checkbox-label {
                    padding-left: 30px;
                    padding-right: 25px;
                
                    @include bp(md) {
                        line-height: unset;
                    }
                    
                    &::before, &::after {
                        display: block;
                    }
                    
                    &::before {
                        height: 16px !important;
                        width: 16px !important;
                    }
                    
                    &::after {
                        height: 8px !important;
                        width: 8px !important;
                    }
                    
                }
                
                .am-filter-items-color {
                    input.input {
                        display: none;
                    }
                }
            }
        }

        &-result {
            &-remove {

                &::before,
                &::after {
                    background-color: $color-primary !important;
                }
            }
        }

        &-category {

            &-wrapper {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                padding: 10px;
                margin: 0 -10px !important;
                border-bottom: 1px solid $color-border;

                &:last-child {
                    border-bottom: none;
                }

                .checkbox-box {
                    margin-bottom: 0 !important;
                }

                .am-collapse-icon {
                    right: 10px;
                    top: 15px;
                    left: unset;
                    margin: 0;
                    width: auto;

                    &::after {
                        font-family: "badaboum";
                        content: '\e90d';
                        background: unset;
                        color: $color-primary;
                        width: auto;
                        height: auto;
                        font-size: 8px;
                        transition: transform 0.5s ease;;
                    }

                    &.-active {

                        &::after {
                            font-family: "badaboum";
                            content: '\e90d';
                            background: unset;
                            transition: all .5s ease-in-out;
                        }
                    }
                }
            }
			
            &-content {
                &-wrapper {
                    transition: display .5s ease-in-out;
                    margin-left: 1em;
                    width: 100%;

                    span.label {
                        position: relative;
                        padding-left: 10px;

                        &:before {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            content: "-";
                            transform: translateY(-50%);
                            display: flex;
                            align-items: baseline;
                            height: 100%;
                        }
                    }
                    
                    &.level-1{
                        .sidebar-filter-category-wrapper{
                            margin-left: -24px!important;
                            padding-left: 24px;
                        }
                    }

                    &.level-2{
                        .sidebar-filter-category-wrapper{
                            margin-left: -37px!important;
                            padding-left: 37px;
                        }
                    }
                }
            }
        }
    }
}


#{$prefix} .am-swatch-wrapper {
    
    .swatch-option.color, .swatch-option.image {
        border-radius: 50%;
        min-width: 20px;
        border: 1px solid #bbbbbb;
    }
    
    .swatch-option.color:not(.disabled):hover {
        border: 1px solid #bbbbbb;
    }
}