.recap {
    padding: 20px 10px;

    &-title {
        font-size: 16px;
        font-weight: bold;
        color: $color-main;
    }

    &-content {
        margin-top: 17px;
        padding: 5px 15px;
        border-radius: 4px;
        background-color: $color-grey-lighter;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        &-icon {
            font-size: 45px;
            color: $color-primary;
            margin-right: 15px;
            margin-top: 5px;
        }

        &-image {
            height: 40px;
            width: 40px;
            min-height: 40px;
            min-width: 40px;
            border-radius: 50%;
            margin-top: 10px;
            margin-right: 14px;
        }

        &-text {
            color: $color-main;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 5px 0;
        }

        &-top {
            font-size: 16px;
            font-weight: bold;
        }

        &-middle {
            font-size: 14px;
            letter-spacing: 0.7px;
            padding: 5px 0;
        }

        &-bottom {
            font-size: 12px;
            opacity: 0.7;
        }

        &-link {
            color: $color-main;
            text-decoration: underline;
            font-size: 12px;
        }
    }
}

.address {
    &es {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        @include bp(md) {
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: $color-white;
    border-radius: 10px;
    transition: 0.5s;
    border: 2px solid transparent;
    width: 100%;
    transition: 0.5s ease-in-out;
    font-size: 14px;

    &--selected {
        border-color: $color-primary;
    }

    &:hover {
        border-color: $color-primary;
    }

    &-box {
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;

        @include bp(xs, md) {
            max-width: 300px;
        }
    }

    &:not(:first-child) {
        &::before {
            width: calc(100% + 40px);
            height: 1px;
            background-color: $color-grey-lighter;
            position: absolute;
            top: 0;

            @include bp(md) {
                &::before {
                    display: none;
                }
            }
        }
    }

    @include bp(md) {
        &:nth-child(2n) {
            &::before {
                width: 1px;
                height: 200px;
                background-color: $color-grey-lighter;
                position: absolute;
                left: -45px;
                top: 40px;

                @include bp(ml) {
                    left: -30px;
                }

                @include bp(lg) {
                    left: -50px;
                }
            }
        }
    }

    &-recap {
        display: flex;
        flex-direction: row;
        background-color: $color-grey-lighter;
        border-radius: 4px;
        padding: 0 15px;
        margin: 10px;

        &-title {
            font-size: 16px;
            font-weight: bold;
            padding: 26px 12px 5px 12px;
        }

        &-icon {
            height: 100%;
            margin-top: 10px;
            margin-right: 15px;

            &::before {
                color: $color-primary;
                font-size: 36px;
            }
        }
    }

    &-title {
        color: $color-main;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        &-icon {
            margin-right: 15px;

            &::before {
                color: $color-primary;
                font-size: 30px;
            }
        }

        &-highlight {
            color: $color-primary;
        }
    }

    &-select {
        width: 240px;

        &>select {
            cursor: pointer;
            width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &-detail {
        &s {
            padding: 15px 0;
            color: $color-main;
            font-size: 15px;
        }

        padding-bottom: 20px;

        &-name {
            font-size: 20px;
            font-weight: bold;
        }

        &-link {
            color: $color-primary;
            text-decoration: underline;
            font-size: 12px;
            font-weight: bold;
        }
    }

    &-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 20px;
    }
}

.checkout {
    #{$prefix} &-container {
        @include bp(xs, sm) {
            .opc-estimated-wrapper {
                margin: 0 0 20px;
                padding: 20px 0 12px;
            }
        }
    }

    &-shipping {
        &-method {

            &s {
                border-collapse: separate;
                border-spacing: 0 20px;
            }

            border-radius: 8px;
            cursor: pointer;

            &-col {
                background: $color-grey-lighter;
                border-top: none;
                vertical-align: middle !important;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    padding-left: 20px;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    padding-right: 20px;
                }
            }

            &-price {
                font-size: 18px;
            }
        }
    }

    #{$prefix} &-header {
        padding: 20px 0;

        @include bp(xs, sm) {
            width: 100%;
            text-align: center;
            margin: 0;
            padding: 0;

            .page {
                &-description {
                    margin: 0;
                    font-weight: 100;
                }
            }
        }

        &::before {
            background: $color-grey !important;
        }

        @include bp(md) {
            margin-bottom: 0;
        }

        &-back {

            font-weight: bold;
            text-transform: uppercase;
            transition: 0.5s;
            font-size: 0;
            margin-right: 20px;

            @include bp(md) {
                font-size: 14px;
            }

            &:hover {
                @include bp(md) {
                    padding-right: 3px;
                }
            }

            &::before {
                display: inline-block;
                color: $color-white;
                padding: 5px 6px 5px 4px;
                line-height: 1;
                font-size: 12px;
                border: 2px solid;
                border-radius: 50%;
                margin-right: 10px;
                transition: 0.5s;

                @include bp(md) {
                    padding: 7px 8px 7px 6px;
                    font-size: 14px;
                }
            }

            &:hover::before {
                @include bp(md) {
                    transform: translateX(2px);
                    margin-right: 7px;
                }
            }

        }

        &-logo {

            max-width: 150px;

            @include bp(md) {
                max-width: none;
            }

            &-img {
                display: block;
            }

            /*color: $color-black;

            & .logo{
                font-size: 0;
                &::before{
                    font-family: $icon;
                    content:'\e913';
                    font-size: 56px;

                    @include bp(md){
                        margin-right: 50px;
                    }

                    @include bp(lg){
                        font-size: 85px;
                        margin-right: 127px;
                    }
                }
            }*/
        }


        &-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            padding: 0;

            @include bp(md) {
                justify-content: space-between;
                margin: 0;
            }

        }

        &-secure {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: center;
            width: 175px;
            font-weight: bold;

            @include bp(md) {
                justify-content: flex-start;
                text-align: left;
                width: auto;
            }

            &::before {
                font-size: 20px;
                margin-right: 5px;

                @include bp(md) {
                    font-size: 30px;
                    margin-right: 10px;
                }
            }

            &-text {
                width: 100%;
                font-size: 10px;
                font-weight: bold;
                max-width: 82px;
                text-align: left;
                line-height: 12px;

                @include bp(md) {
                    max-width: 115px;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }


        &-help {
            display: flex;
            text-align: center;
            white-space: none;
            align-items: center;
            text-align: left;

            @include bp(md) {
                margin-left: auto;
            }

            &::before {
                font-size: 0;

                @include bp(md) {
                    font-size: 32px;
                    padding-right: 13px;
                }
            }

            &-text {
                display: block;
                font-weight: 700;
                font-size: 0;

                @include bp(md) {
                    font-size: 12px;
                }

                &::before {
                    font-size: 30px;
                }
            }
        }
    }

    &-footer {

        margin-top: 80px;

        &-content {
            width: 100%;
            max-width: 1150px;
            padding: 0 25px;
            margin: 0 auto;
        }

        &-item {
            &s {
                border-top: 1px solid;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 50px;
                padding: 20px;

                @include bp(md) {
                    padding: 30px 25px;
                    flex-wrap: nowrap;
                    margin-bottom: 0;
                }
            }

            & {
                font-size: 14px;
                line-height: 1.8;
                width: 100%;
                text-align: center;


                @include bp(md) {
                    line-height: 32px;
                    width: auto;
                    font-size: 14px;
                    line-height: 26px;
                }
            }

            @include bp(md) {
                &+& {
                    &::before {
                        content: '|';
                        padding: 0 4px;
                    }
                }
            }
        }
    }

    &-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        color: $color-main;
        padding: 5px 0 27px 15px;

        @include bp(md) {
            padding: 5px 0 45px 15px;
        }

        &::before {
            font-size: 30px;
            margin-right: 15px;
        }

        &--center {
            width: 100%;
            justify-content: center;

            @include bp(xs, md) {
                justify-content: left;
                padding-left: 0;
                font-size: 18px;
                padding-bottom: 20px;
            }
        }

        &--noicon {
            padding-top: 10px;
        }
    }

    &-subtitle {
        color: $color-black;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 25px;
    }

    &-text {
        padding: 5px;
    }

    &-same {
        margin: 25px 0;
    }

    &-btn {
        &s {
            margin: 0 -5px 10px;
            display: flex;
            flex-wrap: wrap;
        }

        margin: 0 5px;
    }

    &-step {

        &-button {
            margin-top: 20px;
            width: 100%;

            @include bp(md) {
                margin-top: 40px;
            }

            &s {
                width: 100%;
                display: flex;
                align-items: center;
            }

            @include bp(md) {
                width: auto;
                margin: 40px auto 0;
            }
        }

        &s {
            background: $color-white;
            display: flex;
            justify-content: space-around;
            width: 100%;
            height: 100%;
            position: absolute;
            max-height: 80px;
            top: 90px;
            left: 0;
            border-top: 1px solid $color-grey-light;

            @include bp(md) {
                border: none;
                position: static;
                width: 800px;
            }
        }

        & {
            width: 100%;
            color: $color-grey-light;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            font-family: $fs;
            font-size: 14px;
            @include transition(0.5s);

            @include bp(md) {
                justify-content: center;
                flex-direction: row;
            }

            /*&:not(:first-child) {
                &::before {
                    content: '';
                    height: 1px;
                    max-width: 80px;
                    background-color: $color-primary;
                }
            }*/
        }

        &:first-child {
            @include bp(md) {
                width: auto;
            }
        }

        &-link {
            transition: 0.5s;

            &:hover {
                color: $color-black;
            }
        }

        &+& {
            position: relative;

            &::before {
                /*padding: 0 5px;
                order:-1;*/

                content: '';
                width: calc(100% - 75px);
                height: 1px;
                background-color: $color-grey-lighter;
                position: absolute;
                left: -25%;
                top: 50%;

                @include bp(sm) {
                    width: calc(100% - 70px);
                    left: -33%;
                }

                @include bp(md) {
                    position: static;
                    max-width: 80px;
                }

                /*@include bp(md){
                    padding: 0 20px;
                }*/
            }
        }

        &.active {
            &::before {
                background-color: $color-primary;
            }
        }

        &-text {
            position: relative;
            padding: 0 10px 17px 10px;
            font-size: 14px;
            font-weight: bold;

            @include bp(md) {
                padding: 35px 15px;
            }

            &.active {
                color: $color-main;

                /*&::after{
                    content:'';
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    transition: 0.5s;
                    background: currentColor;

                    @include bp(md){
                        height: 2px;
                    }
                }*/
            }
        }

        &-icon {
            @include bp(md) {
                padding-left: 15px;
            }

            &::before {
                font-size: 30px;
                color: $color-grey-light;
                /*position: absolute;
                top: -50%;
                left: 50%;*/

                @include bp(md) {
                    font-size: 40px;
                    position: static;
                }
            }

            &.active {
                &::before {
                    color: $color-primary;
                }
            }
        }
    }

    &-controls {

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 35px 0;

        @include bp(md) {
            padding: 40px 0;
        }

        &--sticky {

            background: $color-white;
            padding: 10px;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100vw;
            z-index: 10;

            @include bp(md) {
                display: none;
            }
        }

        &-btn {
            @include bp(xs, sm) {
                display: inline-block;
            }
        }

        &--footer {
            border-top: 1px solid $color-grey-darker;
            margin-top: 35px;
            padding-top: 30px;

            @include bp(xs, sm) {
                display: flex;
                flex-direction: row-reverse;
                text-align: center;
                justify-content: center;
                flex-wrap: wrap;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
            }
        }

        &-content {
            text-align: center;
            padding: 0px 40px;
            width: 100%;

            @include bp(md) {
                width: auto;
            }
        }

        &-title {

            padding: 0 15px;
            font-size: 16px;
            font-family: $fs;

            @include bp(md) {
                font-size: 22px;
            }

            &-detail {
                font-family: $fp;
                font-size: 12px;
                white-space: nowrap;
                margin-top: 4px;
                display: block;

                @include bp(md) {
                    margin-top: 0;
                    display: inline-block;
                }
            }
        }

        &-subtitle {
            max-width: 400px;
            margin: 7px auto 0;
            font-size: 12px;
            line-height: 20px;
        }

    }

    &-delivery {

        &-method {

            background: $color-white;
            padding: 30px;
            position: relative;
            cursor: pointer;

            &s {
                // margin-top: 30px;
            }

            &-title {
                font-size: 26px;
                font-weight: 700;
                text-align: center;
                padding-top: 50px;

                @include bp(md) {
                    padding: 0 100px;
                }

            }

            &-price {
                position: absolute;
                top: 50%;
                right: 30px;
                font-weight: 700;
                color: $color-primary;
                text-transform: uppercase;
                font-size: 28px;
                top: 22px;
                right: 45px;

                @include bp(md) {
                    top: 40px;
                    right: 45px;
                }

                &.price {
                    font-size: 28px;
                }
            }

            &-delay {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                font-size: 20px;
                text-transform: uppercase;
                color: $color-white;
                font-weight: 700;
                background-color: #93c3ce;
                padding: 25px;
                margin-bottom: 30px;
            }
        }
    }

    &-option {
        &s {
            // margin-top: 30px;
        }

        & {
            background: $color-white;
            padding: 15px 30px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;

            @include bp(md) {
                flex-wrap: nowrap;
            }
        }

        &+& {
            margin-top: 15px;
        }

        &-text {

            padding-left: 20px;
            margin-bottom: 10px;
            width: calc(100% - 30px);
            cursor: pointer;

            @include bp(md) {
                width: auto;
                margin-bottom: 0;
            }

            &-primary {
                font-weight: 700;
                font-size: 18px;
            }

            &-secondary {
                font-size: 13px;
                color: $color-black;
            }
        }

        &-price {

            padding-left: 20px;
            margin-left: auto;

            .price {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }

    &-block {

        &s {
            margin: 40px -45px -30px;
            padding-top: 40px;
            background: $color-white;
        }

        &+& {
            margin-top: 20px;
        }

        & {
            display: flex;
            color: $color-black;
            flex-wrap: wrap;
            padding: 0 30px;

            @include bp(md) {
                flex-wrap: nowrap;
                padding: 0;
            }
        }

        & img {
            width: 100%;
            height: 100%;
            margin-bottom: 15px;

            @include bp(md) {
                width: auto;
                height: auto;
                margin-bottom: 0;
            }
        }

        &-content {
            @include bp(md) {
                padding-left: 15px;
                padding-top: 5px;
            }
        }

        &-title {
            font-size: 14px;
            font-weight: 700;
        }

        &-text {
            font-size: 13px;
        }
    }

    &-fixed {
        position: fixed;
        bottom: 0;
        padding: 10px;
        background: $color-white;
        width: 100vw;
        left: 0;
        z-index: 100;

        &-button {
            margin: 0;
        }
    }

    &-total {

        padding: 28px 20px 15px;

        &s {

            &-wrapper {
                margin-top: 30px;
                background: $color-grey-lighter;
                padding: 15px 30px;
                text-align: center;
                margin-bottom: 20px;
            }

            &-label {
                font-size: 24px;
                font-weight: 700;
                text-transform: uppercase;
            }

            &-amount {
                font-weight: 700;
                font-size: 30px;
                color: $color-primary;
            }
        }
    }

    &-sticky {
        &-footer {
            background-color: $color-white;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 88px;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 15;

            @include bp(lg, xl) {
                display: none;
            }
        }
    }

    &-success {
        .payment-method {
            &:hover {
                box-shadow: unset !important;
            }
        }
    }
}

.payment {

    #{$prefix} &-method {

        & {
            border: 0 !important;
        }

        &-content {
            padding: 0 20px 20px !important;
        }

        &-billing-address {
            margin-bottom: 20px !important;
        }
    }

    &-header {
        .btn {
            @include bp(xs, md) {
                display: none;
            }
        }
    }

    &-fidelity {
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: $color-white;
        border-radius: $border-radius-base;
        margin-bottom: 20px;
        margin-top: 30px;

        @include bp(xs, md) {
            flex-direction: column-reverse;
        }

        &-left,
        &-right {
            width: 50%;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include bp(xs, md) {
                width: 100%;
            }
        }

        &-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            @include bp(xs, md) {
                height: 115px;
            }
        }

        &-right {
            background-size: 300%;

            @include bp(xs, md) {
                height: 300px;
                background-color: rgba($color-primary, 0.25);
                background-image: none !important;
            }
        }

        &-subtitle {
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            line-height: 15px;
            margin-bottom: 9px;
        }

        &-title {
            font-size: 28px;
            letter-spacing: 0;
            line-height: 34px;
            font-weight: bold;
            text-shadow: 1px 1px 2px 0 $color-primary-darker;
            margin-bottom: 24px;

            @include bp(xs, md) {
                display: none;
            }

            &--mobile {
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
                margin-top: 20px;

                @include bp(ml) {
                    display: none;
                }
            }
        }

        &-input {
            border: 1px solid $color-grey-light;
            box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.06);
            max-width: 140px;

            &-sign {
                position: relative;
                right: 30px;
                top: 4px;
                font-size: 22px;
                font-weight: bold;
                color: $color-grey;

                @include bp(xs, md) {
                    font-size: 12px;
                    right: -5px;
                    top: 0;
                }
            }
        }

        &-img {
            margin-bottom: 11px;

            @include bp(xs, md) {
                // background: url(../../img/bg/card-info.png);
            }
        }

        &-amount {
            width: 200px;
            background-color: $color-white;
            text-align: center;
            padding: 9.7px 0;
            color: $color-primary;
            font-size: 28px;
            font-weight: bold;
            border-radius: 4px;
        }
    }

    &-choice {
        margin-bottom: 20px;

        &-title {
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            &::before {
                color: $color-primary;
                font-size: 30px;
                margin-right: 15px;
            }
        }

        &-remaining {
            text-align: center;
            font-size: 18px;
            color: $color-grey;
            font-weight: bold;
            margin-bottom: 25px;

            &--amount {
                color: $color-green;
            }
        }

        &-mean {
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: row;
            border: 1px solid $color-grey-light;
            padding: 10px;
            margin-bottom: 25px;
            border-radius: $border-radius-base;
            justify-content: space-between;

            &s {
                padding: 0 45px;

                @include bp(xs, md) {
                    padding: 0 10px;
                }
            }
        }
    }
}

.amcheckout-step-container .amcheckout-form-login .amcheckout-password, .amcheckout-step-container .form-login .field.amcheckout-password {
    width: 100%;
}

#{$prefix} .amcheckout,
#{$prefix} .payment {
    &-step {
        &-container {
            @include bp(xs, sm) {
                margin-top: 0 !important;
                color: black;
                font-weight: 300;

                hr {
                    margin-top: 0;
                }

                .items-in-cart {
                    strong {
                        font-weight: 500;
                    }

                    .product {
                        &-item {
                            padding-bottom: 10px;

                            &-details {
                                padding-left: 65px;

                                .price {
                                    font-weight: 500;
                                    font-size: 16px;
                                }
                            }

                            &-inner {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-block {
        border: 0 !important;
    }

    &-title {
        color: $color-white;
        background: $color-primary;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;

    }

    &-method {
        border: 0;
        border-radius: 0;
        box-shadow: 0 0px 0px 1px transparent;
        transition: 0.5s;
        background: $color-white !important;
        font-weight: 300;

        .col {
            font-size: 13px;
            vertical-align: middle;
            &-price{
            	padding-left: 0;
            }

        }

        &:hover {
            transition: 0s;
            box-shadow: 0 0px 0px 1px $color-primary !important;
        }

        &.-selected,
        &._active {
            box-shadow: 0 0px 0px 1px transparent !important;
            background: $color-grey-lighter !important;
            font-weight: 500;
        }

        &.checkmemo {
            pointer-events: none;
        }

        & img{
        	min-width: 60px;
        	width: 60px;
        	max-width: 60px;
        	// margin: 10px 0;
        }
    }

    &-dropshipping {
        &-wrapper {
            margin-bottom: 20px;

            &::before {
                content: "*";
                background: none;
                border-radius: unset;
                font-size: 20px;
            }
        }
    }

    &-additional-options {
        border: none !important;
    }

}

body.checkout-index-index {
    .page-main {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    @include bp(xs, md) {
        .logo-wrapper {
            margin: auto;
        }
    }
}
