.customer {
    &-account {

        &-login {
            .secondary {
                text-align: left;
            }
        }

        &-column {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            margin-left: auto;

            @include bp(sm, md) {
                width: 100%;

                .page {
                    &-subtitle {
                        width: 100% !important;
                        margin-left: 0 !important;
                    }
                }
            }
            
            @include bp(xs, md) {
                margin-bottom: 100px;

                .page {
                    &-subtitle {
                        font-size: 16px;
                        margin-bottom: 15px;
                        padding: 25px 20px;
                        background: rgba(206, 10, 78, 0.1);
                        border-left: 4px solid $color-primary;
                        width: 100vw;
                        margin-left: -15px;
                    }
                }

                .form {
                    &-actions {
                        &-toolbar {
                            top: calc(100% + 15px);
                            position: absolute;
                            width: 90%;
                            left: 50%;
                            transform: translateX(-50%);
                            margin-top: 0 !important;
                        }
                    }
                }
            }

            .form{
                &.wishlist.share{
                    .legend{
                        margin-left: 0;
                    }
                    .field{
                        display: flex;
                        flex-direction: column;
                        .control{
                            width: 100%;
                            textarea{
                                width: 100%;
                                resize: none;
                                &#email_address{
                                    height: 42px;
                                }
                                &#message{
                                    height: 200px;
                                }
                            }

                        }
                    }
                }
            }
        }

        &-nav {
            .nav.item {
                margin: 0;

                a {
                    color: $color-main;
                    font-size: 14px;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    text-align: left;
                    padding: 20px;
                    display: flex;
                    align-items: center;

                    &:before {
                        margin-right: 15px;
                        font-size: 20px;
                        width: 22px;
                        text-align: center;
                    }

                    &:hover {
                        background: $color-primary-darker;
                        color: $color-white;
                    }

                    &.btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                &.current {
                    background: $color-primary-lighter-1;

                    a {
                        border-left: 3px solid $color-primary;
                        padding: 0;
                        padding-left: 20px;
                        
                        &:before {
                            color: $color-primary;
                        }

                        strong {
                            padding-left: 0;
                        }
                    }
                    
                    strong {
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        line-height: 1;
                        border-color: $color-primary;
                        
                        &:before {
                            margin-right: 15px;
                            font-size: 20px;
                            color: $color-primary;
                        }
                    }
                }
                
                &:last-child {
                    padding: 20px;
                    .btn--primary{
                        height: 50px;
                    }
                }
            }
        }

        &-address {
            margin: 0 0 20px;
        }

        &-dob {
            display: flex !important;
            align-items: center;
        }

    }

    &-login {
        .fieldset.login {
            &:after {
                margin: 10px 0 !important;
            }
        }

        &-btn {
            margin-top: 35px;
        }

    }

    &-showpassword {
        cursor: pointer;
    }

    &-form {

        &-create {
            width: 100% !important;

            .form-actions-toolbar {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-section {
            display: flex;
            flex-direction: column;
            width: 100%;

            &-title {
                margin-top: 10px;
                margin-bottom: 10px;

                @include bp(md) {
                    font-size: 22px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
            }
        }


        input[type="checkbox"].checkbox {
            position: unset;
            margin: 0;
            top: unset;
        }
    }
    
    &-captcha {
        width: 100%;

        @include bp(md) {
            width: 50%;

        }

        .field.captcha {
            @include bp(md) {
                padding-right: 15px;
            }
            
            .control.captcha-image .captcha-img {
                height: 100px;
            }

            button.captcha-reload.btn--secondary > span{
                color: $color-main !important;
            }
        }
    }

    &-block {
        &--new-customer {
            @include bp(xs, md) {
                margin-top: 20px;
            }
        }
    }

    &-order {
        &-history {
            margin-bottom: 20px;
        }

        &-details-view {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            
            &-content {
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;                

                .box {
                    width: 100%;
                    margin-bottom: 20px;
                    
                    @include bp(ml) {
                        width: calc(50% - (20px / 2));

                        &:nth-child(odd) {
                            margin-right: 10px;
                        }
                        
                        &:nth-child(even) {
                            margin-left: 10px;
                        }
                    }
                }
                
                address {
                  line-height: 22px;  
                }
            }
        }
    }

    &-wishlist {
        &-select {
            width: 40%;
        }
        
        &-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 30px 0;
            
            &::before, &:after {
                content: unset !important;
            }
            

            
            button.btn {
                @include bp(xs, md) {
                    width: 100%;
                    &:nth-child(even){
                        margin: 20px 0;
                    }
                }
            }
        }
    }
}

body.customer-account-login {
    .login-container {
        p {
            @include bp(xs, sm) {
                font-size: 13px;
                line-height: 1.8;
            }
        }

        .fieldset {
            margin-bottom: 0 !important;

            &::after {
                content: none !important;
            }
        }
    }
}

body.customer-account-forgotpassword {

    .customer-captcha {
        width: 100%;

        .field.captcha {
            @include bp(md) {
                padding-right: 0;
            }
            
            .control.captcha-image .captcha-img {
                height: 100px;
            }
        }
    }
}

body.customer-account-create {

    ._has-datepicker ~ .ui-datepicker-trigger {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
    }

    #remember-me-box {
        display: none;
    }
}

body.account {

    ._has-datepicker ~ .ui-datepicker-trigger {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
    }

    .sidebar {
        @include bp(xs, md) {
            padding: 0;
        }

        &-main {
            @include bp(sm, md) {
                width: 100% !important;
            }
        }
    }

    fieldset.fieldset.password {

        @include bp(xs, md) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .block-collapsible-nav {
        width: 100% !important;

        @include bp(xs, md) {
            position: initial !important;
            left: 0 !important;
            top: 0 !important;
            z-index: 5 !important;
            flex-direction: column !important;
            background: #fff !important;
            margin-bottom: 15px;
        }

        &-title {
            @include bp(xs, md) {
                padding: 20px 40px 20px 15px !important;
                border: 0 !important;
            }

            &:after {
                color: #fff;
                font-size: 50px !important;
                background: #6DB332;
                height: 30px;
                border-radius: 50%;
                width: 30px;
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .content {
            background-color: $color-white;
            padding: 0;
            width: 100%;
            height: fit-content;
        }
    }

    .page-title-wrapper {
        width: 100%;

        @include bp(xs, xs) {
            display: none;
        }
    }

    .page {
        &-main {
            @include bp(xs, sm) {
                padding-top: 15px;
            }
        }
    }
}
