#{$prefix} .cart {
    &-header {
        &-text {
            display: flex;
            margin-left: 30px;
            font-size: 24px;
            font-weight: bold;

            &::before {
                margin-right: 7px;
                font-size: 26px;
            }

            @include bp(xs, ml) {
                margin-left: 0;
            }
        }
    }

    &-action {
        display: flex;
        width: auto;
        justify-content: flex-start;
        margin-top: 40px;

        &-empty {
            font-size: 11px;
        }
    }

    &-suggestions {
        margin-top: 60px;
        padding: 32px 0 40px 0;
        background-color: $color-white;
        width: 100vw;

        @include bp(xs, ml) {
            display: none;
        }

        &-title {
            display: flex;
            align-items: center;
            font-size: 25px;
            font-weight: bold;
            line-height: 30px;
            margin-bottom: 23px;

            &::before {
                color: $color-primary;
                font-size: 33px;
                margin-right: 15px;
            }
        }

        &-products {
            display: flex;
            flex-direction: row;
            max-width: 1137px;
            overflow: scroll;

            .item-box:last-child {
                padding-right: 0;
            }
        }
    }

    &-msidebar {
        &-totals {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-total,
        &-discount {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;

            @include bp(lg, xl) {
                display: none;
            }
        }

        &-total {
            background-color: $color-grey-lighter;
            font-size: 20px;
            font-weight: bold;
            padding: 7px 25px;
            margin: 40px 0 7px 0;

            &--sticky {
                margin: 10px auto;
            }
        }

        &-discount {
            font-size: 16px;
            font-weight: bold;
            color: $color-red;
            margin-bottom: 32px;
        }
    }

    &-promo {
        &-container {
            @include bp(xs, md) {
                padding: 20px;
            }
        }
    }

    &-sidebar {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include bp(xs, ml) {
            align-items: center;
        }

        @include bp(xs, md) {
            .box {
                padding: 20px;
            }
        }

        &-summary {
            top: 80px;
            height: fit-content;
            
            @include bp(md) {
                position: sticky;
            }
        }

        &-content,
        &-promotions {
            border-radius: $corner-radius;
            display: flex;
            flex-direction: column;
            width: 300px;

            @include bp(xs, ml) {
                width: 100%;
            }
        }

        &-content {
            margin-top: 26px;
            min-height: 427px;
            background-color: $color-white;

            &--checkout {
                margin-top: 0;
            }
        }

        &-user {
            display: flex;
            justify-content: center;
            margin-top: 26px;
            font-size: 14px;
            font-weight: bold;
            color: $color-grey;

            @include bp(xs, ml) {
                display: none;
            }

            &::before {
                margin-right: 12px;
                font-size: 18px;
            }

            &--mobile {
                @include bp(xs, ml) {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &-discount,
        &-promo {
            margin-top: 11px;
        }

        &-discount {
            color: $color-red;
        }

        &-promo {
            color: $color-primary;

            @include bp(xs, ml) {
                display: none !important;
            }

            &-title {
                font-size: 12px;
            }

            &-action {
                font-size: 10px;
                margin-top: 3px;
                width: max-content;

                @include bp(xs, ml) {
                    display: none;
                }
            }
        }


        &-total,
        &-discount,
        &-promo,
        &-shipping {
            display: flex;
            flex-direction: row;
            font-size: 20px;
            font-weight: bold;

            @include bp(xs, ml) {
                display: none;
            }

            &-title {
                width: 50%;
            }

            &-price {
                margin-left: auto;
            }

            &--mobile {
                @include bp(xs, ml) {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                }
            }
        }

        &-shipping {
            font-size: 14px;
            margin-top: 10px;
        }

        &-totals {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 18px;
            font-size: 19px;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;

            @include bp(md) {
                font-weight: 500;
            }

            @include bp(xs, sm) {
                font-size: 16px;
            }

            &.shipping {
                @include bp(xs, sm) {
                    font-size: 14px;
    
                    .value {
                        font-weight: 400;
                    }
                }
            }

			td:before {
				content: none !important;
			}

            &--mobile {
                @include bp(xs, ml) {
                    display: flex;
                    flex-direction: column;
                    margin: 20px auto 8px auto;
                    height: 55px;
                    width: 200px;
                    border-radius: 4px;
                    background-color: $color-grey-lighter;
                    padding: 7px 25px;
                }
            }

            .mark {
                font-weight: 500;

                @include bp(xs, md) {
                    font-size: 20px;
                }
            }

            &.grand.totals{
                padding-top: 20px;
                
                .amount{
                    .cart-sidebar-price{
                        font-size: 16px;
                        font-weight: 400;

                        @include bp(xs, md) {
                            font-size: 17px;
                        }
                    }
                }
            }

            .discount{
                color: $color-primary;
            }

            .shipping.mark {
                padding: 0px 20px 0px 0px;
                font-weight: 400;
                
                .value {
                    font-size: 12px;
                }
            }
        }

        &-subtotal {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #d9d9d9;
            font-size: 17px;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            padding-bottom: 15px;

            @include bp(md) {
                font-size: 16px;
            }

            .mark {
                font-weight: 400;
            }

			td::before {
				content: none !important;
			}
        }


        &-price {
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }

        &-including {
            font-size: 15px;
            font-style: italic;
            width: 100%;

            @include bp(lg) {
                display: none;
            }
        }

        &-saving {
            font-size: 16px;
            font-weight: bold;
            color: $color-red;
            margin: 0 auto;
            padding-bottom: 25px;

            @include bp(lg) {
                display: none;
            }
        }

        &-fidelity {
            padding: 10px;

            &-content {
                background-color: $color-grey-lighter;
            }


            &-header {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: 17px 20px 0 0;

                &-img {
                    width: 100px;
                    height: 56px;
                    object-fit: contain;
                }

                &-text {
                    font-weight: bold;
                    max-width: 144px;
                    font-size: 16px;

                    &--big {
                        font-size: 23px;
                    }

                    &--green {
                        color: $color-primary;
                    }
                }
            }

            &-card {
                padding: 0 12px 20px 12px;

                &-title {
                    font-size: 15px;
                    font-weight: bold;
                    text-align: center;
                    margin: 17px 0 12px 0;
                }

                .price {
                    font-size: 20px;
                    margin: 12px auto 16px auto;
                    max-width: 215px;

                    &--fidelity {
                        padding: 15px 14px;
                    }

                    &--big {
                        margin: 12px auto 16px auto;
                    }
                }

                &-description {
                    text-align: center;
                    font-size: 12px;
                    line-height: 15px;

                    &--bold,
                    &--green {
                        font-weight: bold;
                    }

                    &--green {
                        color: $color-primary;
                    }
                }

                &-conditions {
                    font-style: italic;
                    font-size: 10px;
                    text-align: center;
                }
            }
        }

        &-coupon {
            &-text {
                text-align: center;
                font-size: 13px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            &-input {
                position: relative;
                padding: 0 10px;

                &::before {
                    background: $color-grey;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translate(0, -50%);
                    color: $color-white;
                    line-height: 40px;
                    text-align: center;
                    z-index: 15;
                    cursor: pointer;
                    content: "OK" !important;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        &-actions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 15px;

            .action {
                font-size: 15px;
            }

            @include bp(xs, sm) {
                margin-top: 20px;
                margin-bottom: 0;
            }
        }

        &-validate {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            padding: 0 40px 20px 40px;

            &--sticky {
                margin-top: 0;
            }
        }

		&-promotions {
            
			&-shipping {
				font-size: 13px;
				font-weight: 500;
				text-align: left;
				margin-bottom: 15px;
                margin-top: -15px;

                @include bp(xs, md) {
                    margin-top: 0;
                    font-size: 15px;
                    line-height: 1.2;
                }
			}   
        }

        &-bottom {
			background: transparent !important;
            @include bp(xs, sm) {
                width: 100%;
            }

			&-text {
				font-size: 12px;
				font-weight: 400;
				text-align: left;
				padding-left: 15px;

                @include bp(xs, sm) {
                    font-weight: 500;
                }

				@include bp(md) {
					font-size: 14px;
				}

				&-wrapper {
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					position: relative;

					&::before {
						content: '';
						position: absolute;
						top: 10px;
						left: 0;
						width: 5px;
						height: 5px;
						border-radius: 50%;
						background: #000;

                        @include bp(xs, sm) {
                            top: 5px;
                        }
					}
				}


				span {
                    color: $color-green;
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                }
			}

			&-partners {
                
                &--checkout{
                    padding-top: 20px;
                    background: #f8f8f8;

                    &>div{
                        display: grid;
                        grid-template-columns: repeat(5,1fr);
                        grid-gap: 10px 20px;
                        grid-template-rows: repeat(3,50px);
                        justify-items: center;

                        @include bp(md, md) {
                            grid-template-rows: repeat(1,50px);
                            grid-template-columns: repeat(14,1fr);
                            
                        }
                        &.cart{
                            @include bp(md, ml) {
                                grid-template-rows: repeat(1,50px);
                                grid-template-columns: repeat(14,1fr);
                                
                            }
                        }
                        
                        .img-wrapper {
                            height: 40px;
                        }

                    }
                }
			}
        }
    }

    &-body {

        &-item-name-container {
            display: flex;
            gap: 10px;
        }
    }

    &-product {

        &-name {
            font-size: 14px;
            color: $color-grey;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            line-height: 24px;
            text-align: left;

            &-link {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.6;
                font-size: 14px;
            }
        }

        &-sku {
            font-size: 12px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
            text-transform: uppercase;
        }

        &-dropshipping {
            background-color: $color-primary;
            color: $color-white;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
            margin-bottom: 0;
            padding: 7px;
        }
    }

    &-table{
        &-head {
            background: $color-background-body;
            color: $color-grey-darker;
            font-size: 14px;

            &-subtotal {
                width: 20%;
                padding-right: 20px;
                padding-left: 20px;
            }

            &-qty, &-price  {
                width: 15%;
            }

            &-price {
                font-weight: 500 !important;
                text-align: center !important;
            }
        }

        &-body {
            border-top: 10px solid $color-background-body;

            @include bp(xs, sm) {
                border-top: 10px solid $color-background-body;
                position: relative;
            }

            td {
                padding: 0;

                &:before {
                    
                    @include bp(xs, md) {
                        content: attr(data-th) !important;
                        font-size: 12px;
                        font-weight: 500 !important;
                        padding: 8px 0 6px !important;
                    }

                    @include bp(sm, md) {
                        font-size: 14px;
                    }
                }
            }

            @include bp(xs, sm) {
                .item-actions {
                    display: none !important;
                }
            }

            &-item {
                @include bp(xs, md) {
                    display: flex !important;
                    flex-wrap: wrap !important;
                    align-items: baseline !important;
                } 
                
                &-info {
                    @include bp(xs, md) {
                        order: 1 !important;
                        display: inline-flex !important;
                        margin: 0 !important;
                        margin-right: 0 !important;
                        width: 82% !important;
                        align-items: unset !important;
                        padding-top: 10px !important;
    
                        &::before {
                            content: none !important;
                        }
                    }

                    @include bp(ml) {
                        width: calc((100% / 5) + 14%) !important;
                    }
                }
            }

            &-qty {
                @include bp(xs, md) {
                    width: auto !important;
                    order: 4 !important;
                    height: 70px !important;
                    text-align: center;
                    flex: 1;
                    
                    &::before {
                        padding-bottom: 10px !important;
                        margin-right: 0 !important;
                    }
                    
                    .qty-btn {
                        height: 32px;
                        font-size: 18px;
                    }
                }

                @include bp (sm, md) {
                    flex-direction: column;
                    width: calc(100% / 3);
                }

                @include bp(ml) {
                    width: calc(100% / 5) !important;
                }
            }

            &-subtotal {
                @include bp(xs, md) {
                    width: auto;
                    order: 5 !important;
                    height: 70px;
                    text-align: center !important;
                    flex: 1;

                    &::before {
                        padding-right: 0 !important;
                        margin-right: 0 !important;
                    }

                    .price {
                        margin-top: 5px;
                        display: block;
                    }
                }

                @include bp(sm, md) {
                    width: calc(100% / 3);
                    flex-direction: column;
                    height: auto;

                    .price {
                        margin-top: 5px;
                        display: block;
                    }

                    span.price-including-tax {
                        margin-left: unset;
                    }
                }

                @include bp(ml) {
                    width: calc(100% / 5) !important;
                }
            }

            &-price {
                @include bp(xs, sm) {
                    text-align: center;
                    width: auto !important;
                    flex: 1;

                    &::before {
                        padding-right: 0 !important;
                        margin-right: 0 !important;
                    }

                    .table-body-price-wrapper {
                        margin: 0;
                    }

                    .price {
                        color: $color-primary;
                        margin-top: 5px;
                        display: block;
                    }
                }

                @include bp(sm, md) {
                    flex-direction: column;
                    width: calc(100% / 3);
                    margin-top: 20px;

                    &::before {
                        padding-right: 0 !important;
                        margin-right: 0 !important;
                    }

                    .table-body-price-wrapper {
                        margin: 0;
                    }

                    .price {
                        color: $color-primary;
                        margin-top: 5px;
                    }
                }


                @include bp(ml) {
                    width: calc(100% / 5) !important;
                }
            }
            
            &-remove {
                @include bp(xs, md) {
                    order: 2 !important;
                    width: 40px;
                    align-self: flex-start !important;
                    margin-top: 15px !important;
                    margin-left: auto !important;
                    
                    
                    &:before {
                        @include bp(xs, md) {
                            content: none !important;
                        }
                    }

                    .icon {
                        font-size: 18px;
                    }
                }

                @include bp(ml) {
                    width: calc(100% / 5) !important;
                }
                
                @include bp(md) {
                    padding-right: 20px !important;
                }
                
                .use-ajax.action.towishlist.action-towishlist, .gift-options-cart-item {
                    display: none !important;
                }
            }
        }
    }

    &-form {

        &-table {
            border: none;
        }

        .product-image-photo {            
            width: 110px;
            height: 110px;
            object-fit: cover;

            @include bp(sm) {
                width: 120px !important;
                height: 120px !important;
            }
        }

        .product-item-details {
            vertical-align: middle !important; 
            text-align: left;

            @include bp(xs, md) {
                display: inline-flex !important;
                flex-direction: column !important;
                padding: 0 12px !important;
            }
        }

        .product-item-photo {
            @include bp(xs, md) {
                display: block !important;
                width: fit-content !important;
                position: unset !important;
                max-width: 120px !important;
                max-height: 120px !important;
            }
        }
    }

    &-coupon {
        @include bp(xs, sm) {
            .input-box-button {
                top: 43px;
            }

            &-title {
                font-size: 20px;
                margin-bottom: 15px;
            }
        }
    }

    &-item {
        &-qty {
            font-size: 16px;
            font-weight: 500;
            margin: 0 5px;
            padding: 0;
            width: 60px;
            
            @include bp(xs, md) {
                height: 30px !important;
            }
        }
    }
    
    &-button {
        position: fixed;
        bottom: 0;
        left: 0;
        display: block;
        z-index: 999;
        
        @include bp(md) {
            display: none;
        }
    }
}



body.checkout-index-index,
body.checkout-cart-index {
    background: $color-background-body;

    @include bp(xs, sm) {
        .columns {
            width: 100%;
        }
    
        .page {
            &-title {
                margin: 7px 0;
    
                &-wrapper {
                    text-align: center;
                }
            }
        }
        
        .admin__data-grid-outer-wrap {
            order: 2;
        }
    }
    
    .column.main {
        padding-bottom: 0;
    }
    
    .admin__data-grid-outer-wrap {
        padding-right: 0;
        padding-left: 0;
    }
    
    .page-title-wrapper {
        display: flex;
        align-items: center;
        @include bp(md) {
            .page-title{
                margin-left: 30px;
            }
        }
        
        @include bp(xs, sm) {
            justify-content: center;
        }
        
        .page-title {
            order: 2;
        }
        
        .btn.action.back {
            order: 1;
            display: none;
            
            @include bp(md) {
                display: flex;
            }   
        }
    }
}
